import { StyleSheet } from 'aphrodite';
import { background, brandPrimary, brandPrimaryHover, error, shuffleGray } from '../../../web/styles/colors';
import { CssSelectors } from '../../../web/styles/styles';

export const styleSheet = StyleSheet.create({
	borderBottom: {
		borderBottom: `1px solid ${shuffleGray}`,
	},
	childRow: {
		marginLeft: 36,
	},
	editor: {
		backgroundColor: background,
		border: `1px solid ${shuffleGray}`,
		borderRadius: 8,
		minHeight: 136,
		width: '100%',
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'.tox .tox-tinymce'
		),
		...CssSelectors.allDescendants(
			{
				backgroundColor: background,
			},
			'.tox-toolbar-overlord'
		),
	},
	error: {
		color: error,
		fontSize: 12,
		fontWeight: 800,
		height: 24,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	flex3: {
		flex: 3,
	},
	gap24: {
		gap: 24,
	},
	gap12: {
		gap: 12,
	},
	iconButton: {
		alignItems: 'center',
		display: 'flex',
		padding: 6,
		width: 'min-content',
	},
	label: {
		fontSize: 14,
	},
	labelRow: {
		gap: 4,
		paddingBottom: 8,
	},
	linkRow: {
		alignItems: 'flex-end',
	},
	menuContainer: {
		padding: 12,
		width: 'auto',
	},
	rotate90: {
		transform: 'rotate(90deg)',
	},
	rotate270: {
		transform: 'rotate(-90deg)',
	},
	subtitleText: {
		color: brandPrimary,
		fontSize: 16,
		margin: 0,
		textTransform: 'capitalize',
	},
	submitColumn: {
		maxWidth: 140,
	},
	tableHead: {
		border: `1px solid ${shuffleGray}`,
		borderRadius: '8px 8px 0px 0px',
		height: 36,
		width: 'auto',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 12,
		backgroundColor: `${brandPrimaryHover}14`,
	},
	tableParentRow: {
		height: 56,
		border: `1px solid ${shuffleGray}`,
		borderTop: 0,
		padding: '6px 36px 6px 36px',
		width: 'auto',
		':last-child': {
			borderRadius: '0px 0px 8px 8px',
		},
	},
	tableChildRow: {
		height: 56,
		border: `1px solid ${shuffleGray}`,
		borderTop: 0,
		padding: '6px 36px 6px 36px',
		marginLeft: 36,
		width: 'auto',
		':last-child': {
			borderBottom: 0,
		},
	},
	tableTitle: {
		fontSize: 12,
		textTransform: 'uppercase',
	},
	titleRow: {
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	titleText: {
		fontSize: 18,
		margin: '32px 0px 32px 0px',
	},
	previewFrame: {
		border: 0,
		minHeight: 276,
		overflow: 'hidden',
	},
	uploadButton: {
		height: 44,
	},
});
