import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useUserQuery } from '../../../queries';
import { FabContext } from '../../components/FabContext';
import { FlyoutModal } from '../../components/FlyoutModal';
import { baseStyleSheet } from '../../styles/styles';
import { KeyFactFiltersDropdown } from '../AdvancedFiltersFlyout/KeyFactFilters';
import { StatusFilterCheckboxes } from '../AdvancedFiltersFlyout/StatusFilters';
import { useCreateDateFilter } from '../AdvancedFiltersFlyout/hooks/useCreateDateFilter';
import { useKeyFactFilters } from '../AdvancedFiltersFlyout/hooks/useKeyFactFilters';
import { useStatusFilters } from '../AdvancedFiltersFlyout/hooks/useStatusFilters';
import { useTagFilters } from '../AdvancedFiltersFlyout/hooks/useTagFilters';
import { Checkbox } from '../Checkbox';
import { CustomDateRange } from '../CustomDateRange';
import { OwnershipFilterDropdown } from '../OwnershipFilterDropdown';
import { PopoverType, TinyPopover } from '../TinyPopover';
import { TagListBuilder } from '../entities/tags/TagListBuilder';
import { InfoIcon } from '../svgs/icons/InfoIcon';
import { TagExpandIcon } from '../svgs/icons/TagExpandIcon';
import { TagNarrowIcon } from '../svgs/icons/TagNarrowIcon';
import { styleSheet } from './styles';

export interface IAdvancedFiltersProps {
	initialFilterCriteria?: Api.IContactFilterCriteria[];
	initialOwnershipFilter?: Api.IOwnershipFilter;
	onSave: (
		filterCriteria: Api.IContactFilterCriteria[],
		householdGrouping?: boolean,
		ownershipFilter?: Api.IOwnershipFilter
	) => void;
	isOpen: boolean;
	onDismissFlyout: () => void;
	initialHouseholdGroupingOn?: boolean;
	showHousehold?: boolean;
	showOwnershipFilters?: boolean;
	showSelectAUserOption?: boolean;
	initialSelectedUserId?: string;
	showStatusFilters?: boolean;
	showKeyFactFilters?: boolean;
	showCreateDateFilters?: boolean;
	disableOwnershipFilters?: boolean;
	title?: string;
	impersonationContext?: Api.IImpersonationContext;
}

export const AdvancedFiltersFlyout = ({
	initialFilterCriteria = [],
	initialOwnershipFilter,
	onSave,
	isOpen,
	onDismissFlyout,
	initialHouseholdGroupingOn = false,
	showHousehold = false,
	showOwnershipFilters = false,
	initialSelectedUserId,
	showStatusFilters = false,
	showKeyFactFilters = false,
	showCreateDateFilters = false,
	disableOwnershipFilters = false,
	title = 'Advanced Search',
	impersonationContext,
}: IAdvancedFiltersProps) => {
	const { findMatchingStatus, selectedStatuses, setSelectedStatuses, getStatusCriteria, refreshStatuses } =
		useStatusFilters(initialFilterCriteria);

	const {
		findMatchingKeyFact,
		selectedKeyFact,
		setSelectedKeyFact,
		selectedPolicies,
		setSelectedPolicies,
		selectedKeyFactStartDate,
		setSelectedKeyFactStartDate,
		selectedKeyFactEndDate,
		setSelectedKeyFactEndDate,
		selectedMinAge,
		setSelectedMinAge,
		selectedMaxAge,
		setSelectedMaxAge,
		getKeyFactCriteria,
		keyFactErrors,
		cancelChanges: cancelKeyFactChanges,
		refreshKeyFacts,
	} = useKeyFactFilters(initialFilterCriteria);

	const {
		cancelCreationDateChanges,
		creationStartDate,
		creationEndDate,
		setCreationStartDate,
		setCreationEndDate,
		getCreationDateCriteria,
		findMatchingCreationDateFilter,
		refreshCreationDateFilter,
	} = useCreateDateFilter(initialFilterCriteria);

	const [householdGroupingOn, setHouseholdGroupingOn] = useState(initialHouseholdGroupingOn);
	const [householdPopoverOpen, setHouseholdPopoverOpen] = useState(false);

	const [selectedUserId, setSelectedUserId] = useState(initialSelectedUserId);

	const [ownershipFilter, setOwnershipFilter] = useState(initialOwnershipFilter);
	useEffect(() => {
		setOwnershipFilter(initialOwnershipFilter);
		setSelectedUserId(initialSelectedUserId);
	}, [initialOwnershipFilter, initialSelectedUserId]);

	useEffect(() => {
		setHouseholdGroupingOn(initialHouseholdGroupingOn);
	}, [initialHouseholdGroupingOn]);

	useUserQuery({
		enabled: Boolean(selectedUserId),
		id: selectedUserId ?? '',
		onSuccess: loadedUser => {
			setSelectedUserId(loadedUser.id);
		},
		impersonationContext,
	});

	const {
		addAndTag,
		addNotTag,
		addOrTag,
		andTags,
		filteredNotTags,
		handleAndCheckChange,
		handleNotCheckChange,
		isTagCriteria,
		orTags,
		removeAndTag,
		removeNotTag,
		removeOrTag,
		showingAndTags,
		showingNotTags,
		toCriteria,
		cancelChanges: cancelTagChanges,
		refreshTags,
	} = useTagFilters({ initialFilterCriteria });

	useEffect(() => {
		if (isOpen) {
			refreshStatuses(initialFilterCriteria ?? null);
			refreshTags(initialFilterCriteria);
			refreshKeyFacts(initialFilterCriteria);
			refreshCreationDateFilter(initialFilterCriteria);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const extraCriteria = useMemo(() => {
		return initialFilterCriteria.filter(criterion => {
			const isTagFilter = isTagCriteria(criterion);
			const isStatusFilter = !!findMatchingStatus(criterion);
			const isKeyFactFilter = !!findMatchingKeyFact(criterion);
			const isCreationDateFilter = !!findMatchingCreationDateFilter(criterion);

			return !isTagFilter && !isStatusFilter && !isKeyFactFilter && !isCreationDateFilter;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialFilterCriteria]);

	const saveChanges = () => {
		const newCriteria: Api.IContactFilterCriteria[] = [];
		const tagCriteria = toCriteria();
		if (tagCriteria) {
			newCriteria.push(...tagCriteria);
		}
		if (selectedStatuses && selectedStatuses.length) {
			const allStatusCriteria = getStatusCriteria();
			newCriteria.push(...allStatusCriteria);
		}
		if (selectedKeyFact !== null) {
			const keyFactCriteria = getKeyFactCriteria();
			newCriteria.push(keyFactCriteria);
		}
		if (creationStartDate || creationEndDate) {
			const createDateCriteria = getCreationDateCriteria();
			newCriteria.push(createDateCriteria);
		}
		if (extraCriteria && extraCriteria.length > 0) {
			extraCriteria.forEach(criterion => {
				newCriteria.push(criterion);
			});
		}
		onSave(newCriteria, householdGroupingOn, ownershipFilter);
	};

	const cancel = () => {
		cancelTagChanges();
		cancelKeyFactChanges();
		cancelCreationDateChanges();
		onDismissFlyout();
	};

	const hasErrors = keyFactErrors.length > 0;

	return (
		<>
			<FlyoutModal isOpen={isOpen} useDefaultHeader={false} onRequestClose={cancel}>
				<header className={css(styleSheet.header)}>
					<h3 className={css(styleSheet.headerText)}>{title}</h3>
				</header>

				<div className={css(styleSheet.container)}>
					<div className={css(styleSheet.body)}>
						<section>
							<h4 className={css(styleSheet.title)}>{title}</h4>
							<p className={css(styleSheet.subtitle)}>You can combine or filter by tags.</p>
						</section>

						<section className={css(styleSheet.filterGroup)}>
							<div className={css(styleSheet.tagSection)}>
								<figure>
									<TagExpandIcon />
								</figure>
								<div>
									<p className={css(styleSheet.tagSectionTitle)}>
										Included tags (this will <span className={css(baseStyleSheet.fontBold)}>expand</span> the list)
									</p>
								</div>
							</div>
							<TagListBuilder
								styles={[styleSheet.tagListBuilderContainer, styleSheet.indentedTagList]}
								inputId='add-or-tags'
								onTagAdded={addOrTag}
								onTagRemoved={removeOrTag}
								separator={Api.FilterOperator.Or}
								tags={orTags}
								openOnFocus={true}
							/>
						</section>

						<section className={css(styleSheet.filterGroup)}>
							<div className={css(styleSheet.tagSection)}>
								<figure>
									<TagNarrowIcon />
								</figure>
								<div>
									<div className={css(styleSheet.tagSectionTitle)}>
										<span className={css(baseStyleSheet.fontBold)}>Narrow</span> the list
									</div>
								</div>
							</div>

							<div className={css(styleSheet.indentedBody)}>
								<Checkbox
									checked={showingAndTags}
									className={css(styleSheet.tagCheckbox, showingAndTags ? styleSheet.tagCheckboxExpanded : null)}
									id='and-tags'
									onChange={handleAndCheckChange}
								>
									<span className={css(styleSheet.checkboxText)}>Contacts must also have these tags...</span>
								</Checkbox>
								{showingAndTags ? (
									<TagListBuilder
										styles={[styleSheet.tagListBuilderContainer]}
										inputId='add-and-tags'
										onTagAdded={addAndTag}
										onTagRemoved={removeAndTag}
										separator={Api.FilterOperator.And}
										tags={andTags}
										openOnFocus={true}
									/>
								) : null}
								<Checkbox
									checked={showingNotTags}
									className={css(styleSheet.tagCheckbox, showingNotTags ? styleSheet.tagCheckboxExpanded : null)}
									id='not-tags'
									onChange={handleNotCheckChange}
								>
									<span className={css(styleSheet.checkboxText)}>Exclude these tags...</span>
								</Checkbox>
								{showingNotTags ? (
									<TagListBuilder
										styles={[styleSheet.tagListBuilderContainer]}
										inputId='add-not-tags'
										onTagAdded={addNotTag}
										onTagRemoved={removeNotTag}
										separator={Api.FilterOperator.Not}
										tags={filteredNotTags}
										openOnFocus={true}
									/>
								) : null}
							</div>
						</section>

						{showHousehold ? (
							<>
								<div className={css(styleSheet.separator)} />

								<section className={css(styleSheet.filterGroup)}>
									<h5 className={css(styleSheet.sectionHeader)}>Households</h5>

									<div className={css(baseStyleSheet.horizontalStack)}>
										<Checkbox
											checked={householdGroupingOn}
											onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setHouseholdGroupingOn(ev.target.checked)}
											id='household-grouping-checkbox'
										>
											<span>Display recipients by household</span>
										</Checkbox>
										<div
											className={`${css(baseStyleSheet.truncateText)}`}
											onMouseEnter={() => setHouseholdPopoverOpen(true)}
											onMouseLeave={() => setHouseholdPopoverOpen(false)}
										>
											<TinyPopover
												anchor={<InfoIcon />}
												anchorStyles={[styleSheet.infoIconContainer]}
												isOpen={householdPopoverOpen}
												onRequestClose={() => setHouseholdPopoverOpen(false)}
												placement={['top', 'left']}
												dismissOnOutsideAction={true}
												type={PopoverType.success}
											>
												<p className={css(styleSheet.householdHoverCard)}>
													<strong className={css(styleSheet.householdBold)}>Household: </strong> you can customize
													whether to send a group email or handwritten card to individuals or households.
												</p>
											</TinyPopover>
										</div>
									</div>
								</section>
							</>
						) : null}

						{showOwnershipFilters ? (
							<>
								<div className={css(styleSheet.separator)} />

								<section className={css(styleSheet.filterGroup)}>
									<h5 className={css(styleSheet.sectionHeader)}>Ownership</h5>
									<OwnershipFilterDropdown
										initialOwnershipFilter={ownershipFilter}
										onOwnershipFilterChanged={setOwnershipFilter}
										onSelectedUserChanged={setSelectedUserId}
										styles={[styleSheet.ownershipDropdown]}
										allowSelectAUserOption={true}
										disableUserSearch={true}
										selectedUserId={selectedUserId}
										disabled={disableOwnershipFilters}
										impersonationContext={impersonationContext}
									/>
								</section>
							</>
						) : null}
						{showStatusFilters ? (
							<>
								<div className={css(styleSheet.separator)} />

								<section className={css(styleSheet.filterGroup)}>
									<h5 className={css(styleSheet.sectionHeader)}>Status</h5>
									<StatusFilterCheckboxes
										styles={[styleSheet.statusFilters]}
										selectedStatuses={selectedStatuses}
										setSelectedStatuses={setSelectedStatuses}
									/>
								</section>
							</>
						) : null}

						{showCreateDateFilters ? (
							<>
								<div className={css(styleSheet.separator)} />

								<section className={css(styleSheet.filterGroup)}>
									<h5 className={css(styleSheet.sectionHeader)}>Create Date</h5>
									<CustomDateRange
										startDateRequired={false}
										forceSmallText
										defaultOpen={false}
										from={creationStartDate || null}
										to={creationEndDate || null}
										onChange={(from, to) => {
											setCreationStartDate(from);
											if (to !== from) {
												setCreationEndDate(to);
											}
										}}
										showTextInput
									/>
								</section>
							</>
						) : null}
						{showKeyFactFilters ? (
							<>
								<div className={css(styleSheet.separator)} />

								<section className={css(styleSheet.filterGroup)}>
									<h5 className={css(styleSheet.sectionHeader)}>Key Fact</h5>
									<KeyFactFiltersDropdown
										selectedKeyFact={selectedKeyFact}
										setSelectedKeyFact={setSelectedKeyFact}
										selectedPolicies={selectedPolicies}
										setSelectedPolicies={setSelectedPolicies}
										selectedKeyFactStartDate={selectedKeyFactStartDate}
										setSelectedKeyFactStartDate={setSelectedKeyFactStartDate}
										selectedKeyFactEndDate={selectedKeyFactEndDate}
										setSelectedKeyFactEndDate={setSelectedKeyFactEndDate}
										selectedMinAge={selectedMinAge}
										setSelectedMinAge={setSelectedMinAge}
										selectedMaxAge={selectedMaxAge}
										setSelectedMaxAge={setSelectedMaxAge}
										keyFactErrors={keyFactErrors}
									/>
								</section>
							</>
						) : null}
					</div>
				</div>

				<footer className={css(styleSheet.footer)}>
					<div className={css(styleSheet.separator)} />

					<div className={css(styleSheet.actions)}>
						<button
							className={css(baseStyleSheet.ctaButton, styleSheet.button)}
							onClick={() => saveChanges()}
							disabled={hasErrors}
						>
							Ok
						</button>

						<button className={css(baseStyleSheet.ctaButtonReverse, styleSheet.button)} onClick={cancel}>
							Cancel
						</button>
					</div>
				</footer>
			</FlyoutModal>

			{isOpen ? <FabContext appearance={{ hidden: true }} /> : null}
		</>
	);
};
