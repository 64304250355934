import { StyleDeclarationValue, css } from 'aphrodite';
import produce from 'immer';
import { Observer, inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	DragDropContext,
	Draggable,
	DraggableProvided,
	DraggingStyle,
	DropResult,
	Droppable,
	DroppableProvided,
	NotDraggingStyle,
} from 'react-beautiful-dnd';
import { IFileAttachment } from '../../../../extViewmodels';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { getFileSizeStringValue } from '../../../../models/UiUtils';
import { CameraIcon } from '../../../components/svgs/icons/CameraIcon';
import { useEditSocialMediaPostContext } from '../../../containers/socialMedia/EditSocialMediaPost/context';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { DragGripperIcon } from '../../svgs/icons/DragGripperIcon';
import { EnlargeIcon } from '../../svgs/icons/EnlargeIcon';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps {
	className?: string;
	onClickPixabayImage?(): void;
	onClickMedia?(): void;
	onClickEnlargeImage?(initialImageUrl: string): void;
	hasVideo: boolean;
	readOnly?: boolean;
	styles?: StyleDeclarationValue[];
}

const SocialMediaImageSearchAndDisplayBase: React.FC<IProps> = observer(props => {
	const { onClickPixabayImage, className, readOnly, hasVideo, onClickMedia, onClickEnlargeImage } = props;
	const { postImages, setImages } = useEditSocialMediaPostContext();

	const onRemoveClicked = React.useCallback(
		(image: IFileAttachment) => () => {
			setImages(val => val?.filter(x => x.id !== image.id));
		},
		[setImages]
	);

	const onDragEnd = (result: DropResult) => {
		// do nothing if the user dropped the item outside the list
		if (result.destination == null) {
			return;
		}
		const toIndex = result.destination.index;
		const fromIndex = result.source.index;
		const imageToMove = postImages[fromIndex];
		setImages(
			produce(postImages, draftImages => {
				draftImages.splice(fromIndex, 1);
				draftImages.splice(toIndex, 0, imageToMove);
			})
		);
	};

	const hasImages = postImages?.length > 6;

	const onRenderContent = (image: IFileAttachment) => {
		if (image.mimeType?.startsWith('video/')) {
			return (
				<div className={className ? className : css(styleSheet.imageDisplayArea)}>
					<figure className={css(styleSheet.imageDisplayAreaImageContainer)}>
						<video src={image.url} width={200} controls />
						<figcaption className={css(styleSheet.imageDisplayAreaInfo)}>
							<div>
								<span>File size:&nbsp;</span>
								{getFileSizeStringValue(image.fileSize)}
							</div>
						</figcaption>
					</figure>

					{/* This is where to insert other info about the content */}
					{!readOnly ? (
						<DeprecatedCloseButton className={css(styleSheet.imageDisplayAreaClose)} onClick={onRemoveClicked(image)} />
					) : null}
				</div>
			);
		}
		return (
			<div className={className ? className : css(styleSheet.imageDisplayArea)}>
				<div className={css(styleSheet.imageDisplayAreaImageContainer)}>
					<button onClick={() => onClickEnlargeImage(image.url)}>
						<img src={image.url} width={200} />
					</button>
					<div className={css(styleSheet.imageDisplayAreaInfo)}>
						<div>
							<span>File size:&nbsp;</span>
							{getFileSizeStringValue(image.fileSize)}
						</div>
						<button onClick={() => onClickEnlargeImage(image.url)} className={css(styleSheet.enlargeImage)}>
							<EnlargeIcon />
							Enlarge Image{postImages?.length > 1 ? 's' : ''}
						</button>
					</div>
				</div>

				{/* This is where to insert other info about the content */}
				{!readOnly ? (
					<DeprecatedCloseButton className={css(styleSheet.imageDisplayAreaClose)} onClick={onRemoveClicked(image)} />
				) : null}
			</div>
		);
	};

	const getItemStyle = (draggableStyles: DraggingStyle | NotDraggingStyle) => {
		return { background: '#fff', ...draggableStyles };
	};
	return (
		<>
			{!readOnly && !hasVideo ? (
				<div className={css(styleSheet.addMediaContainer, styleSheet.contentContainers)}>
					<div>
						<button
							className={css(styleSheet.iconButtons)}
							disabled={hasImages}
							onClick={onClickPixabayImage}
							title={hasImages ? 'Only one image is supported at this time' : undefined}
						>
							<CameraIcon />
							<div className={css(styleSheet.freeImage)}> Free Media</div>
						</button>
						<div className={css(styleSheet.sourceMention)}>Royalty free media library</div>
					</div>
					<div>
						<button
							className={css(styleSheet.ownImage)}
							disabled={hasImages}
							onClick={onClickMedia}
							title={hasImages ? 'Only one media is supported at this time' : undefined}
						>
							{'+ Upload Media '}
						</button>
						<div className={css(styleSheet.sourceMention)}>Choose file from your computer</div>
					</div>
				</div>
			) : null}
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable direction='vertical' droppableId='social-post' type='image'>
					{(droppableProvided: DroppableProvided) => {
						return (
							<Observer>
								{() => {
									return (
										<div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
											{postImages ? (
												<div className={css(styleSheet.imagesContainer)}>
													{postImages.map((image, idx) => {
														return !readOnly ? (
															<Draggable
																disableInteractiveElementBlocking={true}
																draggableId={image.id}
																index={idx}
																key={image.id}
															>
																{(draggableProvided: DraggableProvided) => {
																	return (
																		<div
																			className={css(styleSheet.imageContainer)}
																			key={image.id}
																			ref={draggableProvided ? draggableProvided.innerRef : undefined}
																			{...(draggableProvided?.draggableProps || {})}
																			style={getItemStyle(draggableProvided.draggableProps?.style)}
																		>
																			<div className={css(styleSheet.gripperCol)}>
																				<span {...(draggableProvided?.dragHandleProps || {})}>
																					<DragGripperIcon />
																				</span>
																			</div>
																			{onRenderContent(image)}
																		</div>
																	);
																}}
															</Draggable>
														) : (
															<div className={css(styleSheet.imageContainer)} key={image.id}>
																{onRenderContent(image)}
															</div>
														);
													})}
												</div>
											) : null}
										</div>
									);
								}}
							</Observer>
						);
					}}
				</Droppable>
			</DragDropContext>
		</>
	);
});

export const SocialMediaImageSearchAndDisplay = inject(ImpersonationContextKey)(SocialMediaImageSearchAndDisplayBase);
