import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { getContactTitleAndCompany, getDisplayName } from '../../../../models/UiUtils';
import { grayIconFill } from '../../../styles/colors';
import { Avatar } from '../../Avatar';
import { Checkbox } from '../../Checkbox';
import { SuccessCheckIcon } from '../../svgs/icons/SuccessCheckIcon';
import { TrashIcon } from '../../svgs/icons/TrashIcon';
import { styleSheet } from './styles';

interface IProps {
	checkboxId?: string;
	className?: string;
	contact: Api.IContact;
	hideCheckbox?: boolean;
	onCheckChanged?(): void;
	onClick?(): void;
	onRemoveClicked?(): void;
	selected?: boolean;
	success?: boolean;
	isBusy?: boolean;
}

export function ContactsListItem({
	checkboxId,
	className,
	contact,
	hideCheckbox = false,
	onCheckChanged: propOnCheckChanged,
	onClick,
	onRemoveClicked,
	selected,
	success,
	isBusy = false,
}: IProps) {
	const titleAndCompany = getContactTitleAndCompany(contact);

	const isSelected = React.useMemo(() => {
		if (selected) {
			return true;
		}
	}, [selected]);

	const onCheckChanged = (_e: React.ChangeEvent<HTMLInputElement>) => {
		if (propOnCheckChanged) {
			propOnCheckChanged();
			return;
		}
	};

	const containerStyles: StyleDeclarationValue[] = [
		styleSheet.container,
		isSelected && !hideCheckbox ? styleSheet.containerChecked : null,
	];

	return (
		<div className={`${css(containerStyles)} contacts-list-item ${className || ''}`} onClick={onClick}>
			{!hideCheckbox && success ? (
				<div className={css(styleSheet.success)}>
					<SuccessCheckIcon />
				</div>
			) : (
				!hideCheckbox && (
					<div className={css(styleSheet.check)}>
						<Checkbox
							checked={isSelected}
							disabled={isBusy}
							id={`${checkboxId}contacts-list-item-check-${contact.id}`}
							onChange={onCheckChanged}
						/>
					</div>
				)
			)}

			<Avatar className={css(styleSheet.avatar)} entity={contact} />

			<div className={css(styleSheet.info, hideCheckbox ? styleSheet.infoWithoutCheckbox : null)}>
				<div className={css(styleSheet.infoName)}>{getDisplayName(contact)}</div>
				{!!titleAndCompany && <div className={css(styleSheet.infoTitle)}>{titleAndCompany}</div>}
			</div>
			{!!onRemoveClicked && !success && (
				<div className={css(styleSheet.trash)} onClick={onRemoveClicked}>
					<TrashIcon fillColor={grayIconFill} />
				</div>
			)}
		</div>
	);
}
