import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { AutomationTemplatesViewModel } from '../../../extViewmodels';
import { useImpersonationContext } from '../../../hooks/useImpersonationContext';
import { IEditAutomationTemplateRequest, ILocationState } from '../../../models';
import { useEventLogging } from '../../../models/Logging';
import { getDefaultRichContentForTextingStep } from '../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../models/hooks/appStateHooks';
import { useAdminAutomationTemplateTriggerQuery } from '../../../queries/Admin/AutomationTemplate/useAdminAutomationTemplateTriggerQuery';
import { useAutomationTemplateCreateMutation } from '../../../queries/AutomationTemplate/useAutomationTemplateCreateMutation';
import { useAutomationTemplateTriggerQuery } from '../../../queries/AutomationTemplate/useAutomationTemplateTriggerQuery';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { DarkMainContainerBackground } from '../../components/MainContainerBackground';
import { IModalProps } from '../../components/Modal';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { SelectASurveyModal } from '../../components/SelectASurvey';
import { baseStyleSheet } from '../../styles/styles';
import { INavigationItemProps } from '../MainContainer';
import {
	NewAutomationTemplateCard,
	NewLeadAutomationTemplateSetupSettingsModal,
	NewTagAutomationTemplateSetupSettingsModal,
} from './presentation';
import { styleSheet } from './styles';

interface IProps extends INavigationItemProps {
	/** Return true if consumer will handle editing */
	onRequestEdit?(automationTemplate: Api.AutomationTemplateViewModel): boolean;
	templates?: AutomationTemplatesViewModel;
	impersonationAccountId?: string;
}

interface LayoutProps extends INavigationItemProps {
	children: React.ReactNode;
}

const CreateAutomationTemplatePageLayout = ({ routeContainerClassName, children }: LayoutProps) => {
	return (
		<div className={`${routeContainerClassName} create-automation-template`}>
			<div className={css(styleSheet.container)}>
				<MultiContainerHeader
					appBarHeader={<NavigationBreadcrumbsBar currentLocationName='Create New Automation' />}
					fullscreenHeader='Create New Automation'
				/>
				<DarkMainContainerBackground />
				<section className={css(styleSheet.header)}>
					<h3>Create New Automation</h3>
					<p className={css(styleSheet.headerDetails)}>Select an automation type to begin</p>
				</section>
				<div className={css(styleSheet.cards)}>
					<div className={css(styleSheet.cardsContent)}>{children}</div>
				</div>
			</div>
		</div>
	);
};

const CreateAutomationTemplatePageBody = ({
	availableAutomationTriggers,
	impersonationContext,
	onRequestEdit,
}: {
	availableAutomationTriggers: Api.IAvailableAutomationTrigger<Api.IAutomationTrigger>[];
	impersonationContext?: Api.IImpersonationContext;
	onRequestEdit?: (automationTemplate: Api.AutomationTemplateViewModel) => boolean;
}) => {
	const userSession = useUserSession();
	const history = useHistory();
	const logger = useEventLogging('CreateAutomationTemplate');
	const { pushApiError } = useErrorMessages();
	const automationTemplateCreateMutation = useAutomationTemplateCreateMutation();
	const [newLeadTriggerSettingsModalProps, setNewLeadTriggerSettingsModalProps] = React.useState<IModalProps>({
		isOpen: false,
		onRequestClose: null,
	});
	const [tagTriggerSettingsModalIsOpen, setTagTriggerSettingsModalIsOpen] = React.useState(false);
	const [eventRegistrationModalIsOpen, setEventRegistrationModalIsOpen] = React.useState(false);
	const tagAutomationTrigger: Api.IAvailableAutomationTrigger<Api.ITagAutomationTrigger> =
		availableAutomationTriggers.find(x => x.trigger?._type === Api.AutomationTriggerType.Tag);
	const eventAutomationTrigger: Api.IAvailableAutomationTrigger<Api.IEventRegistrationTrigger> =
		availableAutomationTriggers.find(x => x.trigger?._type === Api.AutomationTriggerType.EventRegistration);
	const supportedAvailableTriggerTypes = React.useMemo(() => {
		const result: Api.IAvailableAutomationTrigger[] = [];
		if (availableAutomationTriggers.length > 0) {
			[
				Api.AutomationTriggerType.NewClient,
				Api.AutomationTriggerType.NewLead,
				Api.AutomationTriggerType.NewDonor,
				Api.AutomationTriggerType.NewDonationTrigger,
				Api.AutomationTriggerType.ResourceSelector,
				Api.AutomationTriggerType.Texting,
				Api.AutomationTriggerType.Tag,
				Api.AutomationTriggerType.Meeting,
				Api.AutomationTriggerType.EventRegistration,
				Api.AutomationTriggerType.Manual,
			].forEach(automationTriggerType => {
				switch (automationTriggerType) {
					case Api.AutomationTriggerType.NewClient: {
						result.push(
							...availableAutomationTriggers
								.filter(x => x.trigger?._type === Api.AutomationTriggerType.NewClient)
								.sort(
									(
										x: Api.IAvailableAutomationTrigger<Api.INewClientAutomationTrigger>,
										y: Api.IAvailableAutomationTrigger<Api.INewClientAutomationTrigger>
									) => {
										if (x?.trigger.clientType?.indexOf(Api.NewClientType.Any) >= 0) {
											return -1;
										} else {
											const index = x?.trigger?.clientType?.indexOf(Api.NewClientType.Commercial) || -1;
											if (index >= 0 && index < y?.trigger?.clientType?.indexOf(Api.NewClientType.Commercial)) {
												return -1;
											}
										}
										return 0;
									}
								)
						);
						break;
					}
					case Api.AutomationTriggerType.EventRegistration:
					case Api.AutomationTriggerType.Meeting:
					case Api.AutomationTriggerType.Texting:
					case Api.AutomationTriggerType.NewDonor:
					case Api.AutomationTriggerType.NewDonationTrigger:
					case Api.AutomationTriggerType.Tag: {
						const availableAutomationTrigger = availableAutomationTriggers.find(
							x => x.trigger?._type === automationTriggerType
						);
						if (availableAutomationTrigger) {
							result.push(availableAutomationTrigger);
						}
						break;
					}
					case Api.AutomationTriggerType.ResourceSelector: {
						result.push(
							...availableAutomationTriggers.filter(
								x => x.trigger?._type === Api.AutomationTriggerType.ResourceSelector
							)
						);
						break;
					}
					case Api.AutomationTriggerType.NewLead: {
						const index = availableAutomationTriggers.findIndex(
							aat => aat.trigger._type === Api.AutomationTriggerType.NewLead
						);
						if (index > -1) {
							result.push({
								trigger: Api.VmUtils.Automations.triggers.defaults.NewLeadAutomationTrigger,
							});
						}
						break;
					}
					case Api.AutomationTriggerType.Manual: {
						result.push({
							trigger: {
								_type: Api.AutomationTriggerType.Manual as any,
							},
						});
						break;
					}
					default: {
						break;
					}
				}
			});
		}
		return result;
	}, [availableAutomationTriggers]);
	const createAndEditNewAutomation = React.useCallback(
		async (name?: string, trigger?: Api.IAutomationTrigger) => {
			try {
				const template = await automationTemplateCreateMutation.mutateAsync({
					name:
						name ||
						Api.VmUtils.Automations.getDefaultNameForNewAutomationTemplate(
							trigger,
							impersonationContext ?? userSession
						),
					impersonationContext,
				});
				const automationTemplateVm = new Api.AutomationTemplateViewModel(userSession, template);
				if (impersonationContext) {
					automationTemplateVm.impersonate(impersonationContext);
				}
				if (trigger) {
					try {
						await automationTemplateVm.addTrigger(trigger);
					} catch (err) {
						try {
							await automationTemplateVm.delete();
						} catch {
							// eat this
						}
						throw err;
					}
				}
				if (trigger?._type === Api.AutomationTriggerType.Texting) {
					// create default first step
					const textingStep: Api.ITextingAutomationStep = {
						_type: 'TextingAutomationStep',
						content: getDefaultRichContentForTextingStep(
							impersonationContext?.account || userSession.account.toJs(),
							trigger
						),
						name: 'Texting Step',
						schedule: {
							criteria: Api.AutomationStepScheduleCriteria.Immediately,
						},
					};
					await automationTemplateVm.addStep<Api.IAutomationStep, Api.AutomationStepViewModel>(textingStep);
				}

				if (!onRequestEdit?.(automationTemplateVm)) {
					const locationState: ILocationState<any, IEditAutomationTemplateRequest> = {
						model: {
							automationTemplate: automationTemplateVm,
						},
					};
					history?.push({
						pathname: `/automations/${automationTemplateVm.id}`,
						state: locationState,
					});
				}
			} catch (err) {
				logger.logApiError('CreateAutomationTemplate-Error', err);
				pushApiError(err);
			}
		},
		[onRequestEdit, history, automationTemplateCreateMutation, logger, pushApiError, userSession, impersonationContext]
	);
	const onCardClicked = React.useCallback(
		(trigger: Api.IAutomationTrigger) => async () => {
			if (Api.VmUtils.Automations.triggers.automationTriggerModelTypesWithCreateOptions.indexOf(trigger?._type) >= 0) {
				// prompt for more info
				switch (trigger._type) {
					case Api.AutomationTriggerType.Tag: {
						setTagTriggerSettingsModalIsOpen(true);
						break;
					}
					case Api.AutomationTriggerType.NewLead: {
						setNewLeadTriggerSettingsModalProps({
							...newLeadTriggerSettingsModalProps,
							isOpen: true,
						});
						break;
					}
					case Api.AutomationTriggerType.EventRegistration: {
						setEventRegistrationModalIsOpen(true);
						break;
					}
					default: {
						break;
					}
				}
			} else {
				createAndEditNewAutomation(undefined, trigger);
			}
		},
		[newLeadTriggerSettingsModalProps, createAndEditNewAutomation]
	);
	const eventRegistrationModalOnClose = () => setEventRegistrationModalIsOpen(false);
	const onTagTriggerSettingsModalRequestClose = (tag?: string, cancel?: boolean) => {
		setTagTriggerSettingsModalIsOpen(false);
		if (!cancel && tag) {
			const tagTrigger = {
				...tagAutomationTrigger.trigger,
				tag,
			};
			createAndEditNewAutomation(undefined, tagTrigger);
		}
	};
	const onNewLeadTriggerSettingsModalRequestCloseRef = React.useRef((_?: Api.INewLeadAutomationTrigger, __ = false) => {
		setNewLeadTriggerSettingsModalProps({
			...newLeadTriggerSettingsModalProps,
			isOpen: false,
		});
	});
	const eventRegistrationModalOnSurveySelected = (trigger: Api.IEventRegistrationTrigger) => {
		createAndEditNewAutomation(undefined, trigger);
	};
	React.useEffect(() => {
		onNewLeadTriggerSettingsModalRequestCloseRef.current = async (
			triggerToSave?: Api.INewLeadAutomationTrigger,
			cancel = false
		) => {
			setNewLeadTriggerSettingsModalProps({
				...newLeadTriggerSettingsModalProps,
				isOpen: false,
			});
			if (!cancel && triggerToSave) {
				createAndEditNewAutomation(undefined, triggerToSave);
			}
		};
	}, [newLeadTriggerSettingsModalProps, createAndEditNewAutomation]);
	React.useEffect(() => {
		setNewLeadTriggerSettingsModalProps({
			...newLeadTriggerSettingsModalProps,
			onRequestClose: (triggerToSave?: Api.INewLeadAutomationTrigger, cancel?: boolean) => {
				onNewLeadTriggerSettingsModalRequestCloseRef.current?.(triggerToSave, cancel);
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			{!supportedAvailableTriggerTypes.length ? (
				<LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} />
			) : (
				supportedAvailableTriggerTypes.map((x, i) => {
					return (
						<NewAutomationTemplateCard
							key={`${x.trigger?._type}-${i}`}
							onClick={onCardClicked(x.trigger)}
							trigger={x.trigger}
							triggerName={x.name}
						/>
					);
				})
			)}
			<NewTagAutomationTemplateSetupSettingsModal
				modalProps={{
					isOpen: tagTriggerSettingsModalIsOpen,
					onRequestClose: onTagTriggerSettingsModalRequestClose,
				}}
				trigger={tagAutomationTrigger?.trigger}
			/>
			{eventRegistrationModalIsOpen ? (
				<SelectASurveyModal
					trigger={eventAutomationTrigger?.trigger}
					onClose={eventRegistrationModalOnClose}
					onSurveySelected={eventRegistrationModalOnSurveySelected}
				/>
			) : null}
			<NewLeadAutomationTemplateSetupSettingsModal
				modalProps={newLeadTriggerSettingsModalProps}
				trigger={
					supportedAvailableTriggerTypes.find(x => x.trigger?._type === Api.AutomationTriggerType.NewLead)?.trigger
				}
			/>
		</>
	);
};

export const CreateAutomationTemplatePage = ({
	impersonationAccountId,
	routeContainerClassName,
	onRequestEdit,
}: IProps) => {
	const impersonationContext = useImpersonationContext({ accountId: impersonationAccountId });
	const automationTemplateTriggerQuery = useAutomationTemplateTriggerQuery({
		impersonationContext,
	});

	return (
		<CreateAutomationTemplatePageLayout routeContainerClassName={routeContainerClassName}>
			{!automationTemplateTriggerQuery.data?.length ? (
				<LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} />
			) : (
				<CreateAutomationTemplatePageBody
					availableAutomationTriggers={automationTemplateTriggerQuery.data}
					impersonationContext={impersonationContext}
					onRequestEdit={onRequestEdit}
				/>
			)}
		</CreateAutomationTemplatePageLayout>
	);
};

export const CreateAdminAutomationTemplatePage = ({ routeContainerClassName, onRequestEdit }: IProps) => {
	const automationTemplateTriggerQuery = useAdminAutomationTemplateTriggerQuery();
	return (
		<CreateAutomationTemplatePageLayout routeContainerClassName={routeContainerClassName}>
			{!automationTemplateTriggerQuery.data?.length ? (
				<LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} />
			) : (
				<CreateAutomationTemplatePageBody
					availableAutomationTriggers={automationTemplateTriggerQuery.data}
					onRequestEdit={onRequestEdit}
				/>
			)}
		</CreateAutomationTemplatePageLayout>
	);
};
