import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { AutomationsOnHoldViewModel, IOperationResultNoValue, ResourceSelectorId } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ILocationState } from '../../../../models';
import {
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	IErrorMessageComponentProps,
	IFullscreenModalComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { baseStyleSheet } from '../../../styles/styles';
import { AutomationPendingIcon } from '../../svgs/icons/AutomationPendingIcon';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IFullscreenModalComponentProps {
	className?: string;
	shouldHide?: boolean;
	styles?: StyleDeclarationValue[];
}

class _AutomationsPendingCard extends React.Component<IProps> {
	@observable.ref private automationsOnHold: AutomationsOnHoldViewModel;

	constructor(props: IProps) {
		super(props);

		this.automationsOnHold = new AutomationsOnHoldViewModel(props.userSession);
	}

	public componentDidMount() {
		const { userSession } = this.props;
		if (userSession?.account?.features?.automation?.enabled) {
			this.automationsOnHold
				.loadAutomationsOnHold(userSession.canSendOnBehalf)
				?.catch((err: IOperationResultNoValue) => {
					const { errorMessages, logApiError } = this.props;

					errorMessages.pushApiError(err);

					logApiError('LoadAutomationsOnHold', err);
				});
		}
	}

	public render() {
		const { className, styles } = this.props;

		if (this.shouldHideCard) {
			return null;
		}

		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} automations-pending-card ${className || ''}`}>
				<div className={css(styleSheet.titleContainer)}>
					<AutomationPendingIcon />
					<div className={css(styleSheet.title)}> We have automations for you to approve.</div>
				</div>
				<div className={css(styleSheet.pendingList)}>
					{this.canSendOnBehalf ? (
						<>
							{this.automationsOnHold.allAutomationsPending?.map(x => {
								if (x.automation.count && x.automation.template) {
									return (
										<div className={css(styleSheet.pendingListItem)} key={x.automation.template?.id}>
											<span className={css(baseStyleSheet.fontBold)}>{x.automation.template?.name} </span>
											<span>pending for {x.automation.count} contacts.</span>
										</div>
									);
								}
							})}
						</>
					) : (
						<>
							{this.automationsOnHold.myAutomationsPending?.map(x => {
								if (x.automation.count && x.automation.template) {
									return (
										<div className={css(styleSheet.pendingListItem)} key={x.automation.template?.id}>
											<span className={css(baseStyleSheet.fontBold)}>{x.automation.template?.name} </span>
											<span>pending for {x.automation.count} contacts.</span>
										</div>
									);
								}
							})}
						</>
					)}
				</div>
				<div className={css(styleSheet.ctaContainer)}>
					<button
						className={css(baseStyleSheet.ctaButtonReverseSmall, styleSheet.button)}
						onClick={this.onClickSeePending}
					>
						<span>See Pending Automations</span>
					</button>
				</div>
			</div>
		);
	}

	@computed
	private get shouldHideCard() {
		const { userSession } = this.props;

		const allAutomationsPending = this.canSendOnBehalf
			? this.automationsOnHold?.allAutomationsPending
			: this.automationsOnHold?.allAutomationsPending?.filter(
					x => x.automation?.template?.creator?.id === userSession?.user?.id
				);
		return (
			!(this.automationsOnHold?.myAutomationsPending?.length > 0 || allAutomationsPending?.length > 0) ||
			this.automationsOnHold?.isLoading ||
			(this.automationsOnHold?.allAutomationsComplete && this.automationsOnHold?.myAutomationsComplete) ||
			(this.automationsOnHold?.noMyContactsLeft && this.automationsOnHold?.noAllContactsLeft) ||
			this.props.shouldHide ||
			!this.props.userSession?.account?.features?.automation?.enabled
		);
	}

	@computed
	private get canSendOnBehalf() {
		return this.props.userSession?.account?.features?.automation?.allowAdminToStartOnBehalf;
	}

	private onClickSeePending = () => {
		const { fullscreenModal } = this.props;

		const locationState: ILocationState<AutomationsOnHoldViewModel, any> = {
			viewModel: this.automationsOnHold,
		};

		fullscreenModal.history.push({
			pathname: `/automationsonhold/${ResourceSelectorId.AutomationsOnHold}`,
			state: locationState,
		});
	};
}

const AutomationsPendingCardAsObserver = observer(_AutomationsPendingCard);
const AutomationsPendingCardWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey
)(AutomationsPendingCardAsObserver);
export const AutomationsPendingCard = withEventLogging(AutomationsPendingCardWithContext, 'AutomationsPendingCard');
