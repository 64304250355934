import * as React from 'react';
import { useLocation } from 'react-router';
import { debounce } from '../../../models/UiUtils';

type NonNullableUpdater<TPrevious, TResult = TPrevious> = TResult | ((prev: TPrevious) => TResult);
export type ScrollRestorationEntry = { scrollX: number; scrollY: number };
export type ScrollRestorationByElement = Record<string, ScrollRestorationEntry>;
export type ScrollRestorationByKey = Record<string, ScrollRestorationByElement>;
export type ScrollRestorationCache = {
	state: ScrollRestorationByKey;
	set: (updater: NonNullableUpdater<ScrollRestorationByKey>) => void;
};

let ignoreScroll = false;

export const defaultGetScrollRestorationKey = () => {
	return window.location.href; // Look into using RR location.key in the future.
};

const sessionsStorage = typeof window !== 'undefined' && window.sessionStorage;

export const storageKey = 'lev-scroll-restoration-storage-key';
export const scrollRestorationCache: ScrollRestorationCache = sessionsStorage
	? (() => {
			const state: ScrollRestorationByKey = JSON.parse(window.sessionStorage.getItem(storageKey) || 'null') || {};
			return {
				state,
				// This setter is simply to make sure that we set the sessionStorage right
				// after the state is updated. It doesn't necessarily need to be a functional
				// update.
				set: updater => {
					const value = typeof updater === 'function' ? updater(scrollRestorationCache.state) : updater;
					scrollRestorationCache.state = value || scrollRestorationCache.state;
					window.sessionStorage.setItem(storageKey, JSON.stringify(scrollRestorationCache.state));
				},
			};
		})()
	: (undefined as any);

let isScrollRestorationSetup = false;

export function useScrollRestoration(
	options: { getScrollRestorationKey?: () => string; scrollRestorationBehavior?: ScrollBehavior } = {}
) {
	const optionsRef = React.useRef(options);
	optionsRef.current = options;
	const location = useLocation<{ resetScroll?: boolean }>();
	const onScroll = (event: Event) => {
		if (ignoreScroll) {
			return;
		}
		if (event.target === document || event.target === window) {
			return;
		}
		const attrId = (event.target as Element).getAttribute('data-scroll-restoration-id');
		if (!attrId) {
			return;
		}
		const elementSelector = `[data-scroll-restoration-id="${attrId}"]`;
		const getKey = options.getScrollRestorationKey || defaultGetScrollRestorationKey;
		const restoreKey = getKey();

		scrollRestorationCache.set(state => {
			const keyEntry = (state[restoreKey] = state[restoreKey] || ({} as ScrollRestorationByElement));

			const elementEntry = (keyEntry[elementSelector] = keyEntry[elementSelector] || ({} as ScrollRestorationEntry));

			if (elementSelector) {
				const element = document.querySelector(elementSelector);
				if (element) {
					elementEntry.scrollX = element.scrollLeft || 0;
					elementEntry.scrollY = element.scrollTop || 0;
				}
			}

			return state;
		});
	};

	if (!isScrollRestorationSetup) {
		ignoreScroll = false;
		isScrollRestorationSetup = true;
		window.history.scrollRestoration = 'manual';
		// Throttle the scroll event to avoid excessive updates
		document.addEventListener('scroll', debounce(onScroll, 100), true);
	}

	React.useLayoutEffect(() => {
		function restoreScroll(key?: string, behavior?: ScrollToOptions['behavior']) {
			let byKey: ScrollRestorationByKey;

			try {
				byKey = JSON.parse(sessionStorage.getItem(storageKey) || '{}');
			} catch (error: any) {
				console.error(error);
				return;
			}

			const resolvedKey = key || window.history.state?.key;
			const elementEntries = byKey[resolvedKey];
			ignoreScroll = true;
			if (elementEntries) {
				for (const elementSelector in elementEntries) {
					const entry = elementEntries[elementSelector]!;
					if (elementSelector === 'window') {
						window.scrollTo({
							top: entry.scrollY,
							left: entry.scrollX,
							behavior,
						});
					} else if (elementSelector) {
						const element = document.querySelector(elementSelector);
						if (element) {
							element.scrollLeft = entry.scrollX;
							element.scrollTop = entry.scrollY;
						}
					}
				}
			}
			ignoreScroll = false;
		}

		const getKey = optionsRef.current.getScrollRestorationKey || defaultGetScrollRestorationKey;
		const cacheKey = getKey();

		// If the user doesn't want to restore the scroll position on navigation,
		// setting `resetScroll:false` on locationState is the way to opt-out.
		if (location.state?.resetScroll === false) {
			return;
		}

		restoreScroll(cacheKey, optionsRef.current.scrollRestorationBehavior);

		// Mark the location as having been seen
		scrollRestorationCache.set(state => {
			state[cacheKey] = state[cacheKey] || ({} as ScrollRestorationByElement);
			return state;
		});
	}, [location]);
}

export function ScrollRestoration(): React.ReactElement {
	useScrollRestoration();
	return null;
}
