import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
	AppImportContactsViewModel,
	IDictionary,
	IImportContactFieldConfig,
	IPreservableFieldValues,
} from '../../../../viewmodels/AppViewModels';
import { Checkbox } from '../../Checkbox';
import { ISelectBoxOption, SelectBox } from '../../SelectBox';
import { styleSheet } from './styles';

interface IProps {
	header: string;
	index: number;
	importVM: AppImportContactsViewModel;
	className?: string;
	styles?: StyleDeclarationValue[];
	fieldOptions: ISelectBoxOption<IImportContactFieldConfig>[];
	selections: IDictionary<ISelectBoxOption<IImportContactFieldConfig>>;
}

export const ContactsSelfImportMappingHeaderItem: FC<IProps> = observer(props => {
	const { className, styles, header, index, importVM, fieldOptions, selections } = props;
	const [search, setSearch] = useState('');
	const timeout = useRef<number>(null);

	const onTriggerClicked = useCallback(() => setSearch(''), [setSearch]);

	const onKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Backspace' || event.key === 'Delete') {
				setSearch('');
			} else if (event.key.length !== 1) {
				// Handles Shift, Ctrl, Enter, etc. Do nothing
				return;
			} else if (event.key.match(/[a-z]/i)) {
				setSearch(search.concat(event.key).trim());

				timeout.current = window.setTimeout(() => {
					setSearch('');
				}, 1000);
			}
		},
		[search, setSearch]
	);

	useEffect(() => {
		const list = document.getElementsByClassName('select-box-menu')?.[0];
		const itemsAsNodeList = document.querySelectorAll('.select-box-menu-content-option');
		const items = Array.from(itemsAsNodeList);
		const item = items?.filter(x => x?.innerHTML?.toLowerCase()?.includes(search?.toLowerCase()))?.[0];
		if (search && list && item) {
			item.scrollIntoView({ block: 'center' });
		}
	}, [search]);

	useEffect(() => {
		window.addEventListener('keydown', onKeyDown);
		return () => {
			window.removeEventListener('keydown', onKeyDown);

			clearTimeout(timeout.current);
		};
	});

	const onSelectionChanged = useCallback(
		(headerName: string) => (selection: ISelectBoxOption<IImportContactFieldConfig>) => {
			const map = { ...importVM.options.headerNameToFieldMap };
			if (selection.value === null) {
				delete map[headerName];
			} else {
				map[headerName] = selection.value;
			}
			importVM.options.setHeaderToFieldMapping(map);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const onPreserveCheckboxChanged = useCallback(
		(value: IPreservableFieldValues) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const opts = importVM.options;
			if (e.target.checked) {
				opts.setPreserveExistingFieldValues([...opts.preserveExistingFieldValues, value]);
			} else {
				opts.setPreserveExistingFieldValues(opts.preserveExistingFieldValues.filter(x => x !== value));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const isPreserveCheckboxChecked = useCallback((value: IPreservableFieldValues) => {
		return importVM.options.preserveExistingFieldValues.includes(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<th className={`${css(styleSheet.tableHeadTrTh, ...(styles || []))} ${className || ''}`} key={`${header}-${index}`}>
			<SelectBox
				className={css(styleSheet.tableHeaderSelectBox)}
				disabled={!!importVM.isBusy}
				filterOptions={true}
				filterPlaceHolder='Search mappings'
				filterClassName={css(styleSheet.tableHeaderSelectFilter)}
				onSelectionChanged={onSelectionChanged(header)}
				options={fieldOptions || []}
				selectedOption={selections[header] || fieldOptions[0]}
				onTriggerClicked={onTriggerClicked}
				triggerClassName={css(styleSheet.tableHeaderSelectBoxTrigger)}
			/>
			<div>
				{selections[header]?.title?.toString().toLocaleLowerCase().includes('name') ? (
					<Checkbox
						id={`import-checkbox-${header}`}
						onChange={onPreserveCheckboxChanged(header as IPreservableFieldValues)}
						checked={isPreserveCheckboxChecked(header as IPreservableFieldValues)}
						className={css(styleSheet.checkboxText)}
					>
						<div>Don&apos;t override if existing</div>
					</Checkbox>
				) : (
					<div className={css(styleSheet.checkboxPlaceholder)} />
				)}
			</div>
			<div className={css(styleSheet.tableHeadTrThName, index === 0 ? styleSheet.tableHeadTrThNameFirst : null)}>
				{header}
			</div>
		</th>
	);
});
