import { IModalContext, ModalChildComponentContextKey } from '@AppModels/.';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { IUser, ResourceAutoCompleteViewModelType, UserViewModel, VmUtils } from '@ViewModels';
import { asModalComponent } from '@WebComponents/Modal';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '@WebComponents/autocomplete/SimpleAutoCompleteSearchField';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

interface IProps extends IUserSessionComponentProps, IModalContext<UserViewModel>, IEventLoggingComponentProps {
	className?: string;
	title?: string;
	bodyText?: string;
	ctaText?: string;
}

interface IState {
	selectedUser?: IUser;
}

class _UserSelect extends React.Component<IProps, IState> {
	public readonly state: IState = {};
	public render() {
		const { bodyText, className, title, ctaText } = this.props;
		const { selectedUser } = this.state;
		return (
			<div className={`${css(styleSheet.container)} user-select ${className || ''}`}>
				{!!title && <div className={css(styleSheet.title)}>{title}</div>}
				{bodyText && <div className={css(styleSheet.bodyText)}>{bodyText}</div>}
				<SimpleAutoCompleteSearchField
					onClear={this.clearSelectedUser}
					// @ts-ignore
					onItemSelected={this.onUserSelected}
					onKeyDown={this.onSearchFieldKeyDown}
					placeholder='Search name'
					style={styleSheet.searchField}
					type={ResourceAutoCompleteViewModelType.User}
				/>
				<button
					className={css(baseStyleSheet.ctaButton, styleSheet.submitButton)}
					disabled={!selectedUser}
					onClick={this.onSubmitClicked}
				>
					<span>{ctaText ?? 'Okay'}</span>
				</button>
			</div>
		);
	}

	private onSearchFieldKeyDown = (e: ISimpleAutoCompleteSearchFieldEvent<React.KeyboardEvent<HTMLInputElement>>) => {
		if (!!e.sourceEvent && e.sourceEvent.keyCode !== 13) {
			this.clearSelectedUser();
		}
	};

	private clearSelectedUser = () => {
		if (this.state.selectedUser) {
			this.setState({
				selectedUser: null,
			});
		}
	};

	private onUserSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<IUser>) => {
		if (e.target) {
			e.target.setSearchQuery(VmUtils.getDisplayName(e.selection));
		}
		this.setState({
			selectedUser: e.selection,
		});
	};

	private onSubmitClicked = () => {
		const { selectedUser } = this.state;
		const { logInput, parentModal, userSession } = this.props;
		if (!!parentModal && selectedUser) {
			logInput('Okay', 'Click', { userId: selectedUser.id });

			parentModal.onRequestClose(new UserViewModel(userSession, selectedUser), false);
		}
	};
}

const UserSelectAsObserver = observer(_UserSelect);
const UserSelectWithContext = inject(UserSessionViewModelKey, ModalChildComponentContextKey)(UserSelectAsObserver);

const UserSelect = withEventLogging(UserSelectWithContext, 'UserSelect');

export const UserSelectModal = asModalComponent(UserSelect, {
	className: 'user-select-modal',
	shouldCloseOnOverlayClick: false,
	useDefaultHeader: true,
});
