import { Slot } from '@radix-ui/react-slot';
import { css, StyleDeclaration } from 'aphrodite';
import * as React from 'react';
import { ChevronRight } from '../svgs/icons/ChevronRight';
import { EllipsisIcon } from '../svgs/icons/EllipsisIcon';
import { s } from './styles';

type ReplaceClassNameWithStyleDeclarations<T> = Exclude<T, 'className'> & { styles?: StyleDeclaration[] };

const Breadcrumb = React.forwardRef<
	HTMLElement,
	React.ComponentPropsWithoutRef<'nav'> & {
		separator?: React.ReactNode;
	}
>(({ ...props }, ref) => <nav ref={ref} aria-label='breadcrumb' {...props} />);
Breadcrumb.displayName = 'Breadcrumb';

const BreadcrumbList = React.forwardRef<
	HTMLOListElement,
	ReplaceClassNameWithStyleDeclarations<React.ComponentPropsWithoutRef<'ol'>>
>(({ styles, ...props }, ref) => <ol ref={ref} className={css(s.breadcrumbList, styles)} {...props} />);
BreadcrumbList.displayName = 'BreadcrumbList';

const BreadcrumbItem = React.forwardRef<
	HTMLLIElement,
	ReplaceClassNameWithStyleDeclarations<React.ComponentPropsWithoutRef<'li'>>
>(({ styles, ...props }, ref) => <li ref={ref} className={css(s.breadcrumbItem, styles)} {...props} />);
BreadcrumbItem.displayName = 'BreadcrumbItem';

const BreadcrumbLink = React.forwardRef<
	HTMLAnchorElement,
	ReplaceClassNameWithStyleDeclarations<React.ComponentPropsWithoutRef<'a'>> & {
		asChild?: boolean;
	}
>(({ asChild, styles, ...props }, ref) => {
	const Comp = asChild ? Slot : 'a';

	return <Comp ref={ref} className={css(s.breadcrumbLink, styles)} {...props} />;
});
BreadcrumbLink.displayName = 'BreadcrumbLink';

const BreadcrumbPage = React.forwardRef<
	HTMLSpanElement,
	ReplaceClassNameWithStyleDeclarations<React.ComponentPropsWithoutRef<'span'>>
>(({ styles, ...props }, ref) => (
	<span
		ref={ref}
		role='link'
		aria-disabled='true'
		aria-current='page'
		className={css(s.breadcrumbPage, styles)}
		{...props}
	/>
));
BreadcrumbPage.displayName = 'BreadcrumbPage';

const BreadcrumbSeparator = ({
	children,
	styles,
	...props
}: ReplaceClassNameWithStyleDeclarations<React.ComponentProps<'li'>>) => (
	<li role='presentation' aria-hidden='true' className={css(s.breadcrumbSeparator, styles)} {...props}>
		{children ?? <ChevronRight />}
	</li>
);
BreadcrumbSeparator.displayName = 'BreadcrumbSeparator';

const BreadcrumbEllipsis = ({
	styles,
	...props
}: ReplaceClassNameWithStyleDeclarations<React.ComponentProps<'span'>>) => (
	<span role='presentation' aria-hidden='true' className={css(s.breadcrumbEllipsis, styles)} {...props}>
		<EllipsisIcon className={css(s.ellipsisIcon)} />
		<span className='sr-only'>More</span>
	</span>
);
BreadcrumbEllipsis.displayName = 'BreadcrumbElipssis';

export {
	Breadcrumb,
	BreadcrumbEllipsis,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
};
