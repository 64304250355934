import { css } from 'aphrodite';
import * as React from 'react';
import { getIndustries, getIndustryName } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { Industry, KnownCategories } from '../../../viewmodels/AppViewModels';
import FinraImage from '../../assets/finra.png';
import { FabContext } from '../../components/FabContext';
import { NewCampaignButtons } from '../../components/NewCampaignButtons';
import { ISelectBoxOption, SelectBox } from '../../components/SelectBox';
import { TextInput } from '../../components/TextInput';
import { SearchIcon } from '../../components/svgs/icons/SearchIcon';
import { baseStyleSheet } from '../../styles/styles';
import { CampaignBrowserTabs } from './presentation';
import { styleSheet } from './styles';

const SEARCH_DELAY = 600;

export function CampaignBrowser({
	categories,
	category,
	children,
	industry,
	onCategoryChange,
	onIndustryChange,
	onSearchChange,
	renderRightActions,
	search,
	searchPlaceholder = 'Search campaigns or keywords',
}: {
	categories: string[];
	category: string;
	children: React.ReactNode;
	industry: string;
	onCategoryChange: (selectedCategory: string) => void;
	onIndustryChange: (selectedIndustry: Industry) => void;
	onSearchChange: (value: string) => void;
	renderRightActions?: React.ReactNode;
	search: string;
	searchPlaceholder?: string;
}) {
	const userSession = useUserSession();
	const industryOptions = getIndustries({ accountId: userSession.account.id }).map(_industry => {
		return {
			title: getIndustryName(_industry),
			value: _industry,
		};
	});
	const onRenderTriggerTitle = (selectedOption: ISelectBoxOption<Industry>): React.ReactNode => {
		if (selectedOption.title === Industry.Others) {
			return <span>Others</span>;
		}
		return <span>{selectedOption.title}</span>;
	};
	const [searchInputValue, setSearchInputValue] = React.useState('');
	React.useEffect(() => {
		const timeout = setTimeout(() => {
			onSearchChange(searchInputValue);
		}, SEARCH_DELAY);
		return () => clearTimeout(timeout);
	}, [searchInputValue, onSearchChange]);
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInputValue(event.target.value);
	};
	const handleClearSearch = () => {
		setSearchInputValue('');
		onSearchChange('');
	};
	const handleIndustryChange = (selectedIndustry: ISelectBoxOption<Industry>) => {
		onIndustryChange(selectedIndustry.value);
	};

	// This scolls to the selected category as soon as it is loaded
	React.useEffect(() => {
		if (categories.length > 0 && categories.indexOf(category) !== -1) {
			document.getElementById(category)?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
		}
	}, [category, categories]);
	return (
		<div className={`${css(styleSheet.containerComponent)} campaigns-browser`}>
			<FabContext appearance={{ hidden: true }} />
			<div className={css(styleSheet.searchSection)}>
				<div className={css(styleSheet.industrySection)}>
					<TextInput
						className={css(styleSheet.searchBox)}
						inputClassName={css(styleSheet.searchBoxInput)}
						inputId='campaign-browser-search'
						leftAccessory={<SearchIcon className={css(styleSheet.searchIcon)} />}
						onChange={handleSearchChange}
						placeholder={searchPlaceholder}
						type='text'
						value={searchInputValue}
					/>
					<div className={css(styleSheet.industryDropdown)}>
						<div className={css(styleSheet.browse)}>Browse Industry:</div>
						<SelectBox
							menuClassName={`${css(styleSheet.dropdownMenu)}`}
							onRenderSelectedOptionTitle={onRenderTriggerTitle}
							onSelectionChanged={handleIndustryChange}
							options={industryOptions}
							selectedOption={industryOptions.find(x => x.value === industry)}
							triggerClassName={`${css(styleSheet.dropdownTrigger)}`}
						/>
					</div>
				</div>
				{renderRightActions ? renderRightActions : <NewCampaignButtons />}
			</div>
			{search ? (
				<div className={css(styleSheet.tabNavigation)}>
					<div className={css(styleSheet.searchResults)}>
						<span>
							Showing results for
							<b className={css(baseStyleSheet.fontBold)}>{' ' + search + ' '}</b>
							campaigns
						</span>
						<div className={css(baseStyleSheet.brandLink, styleSheet.clearSearch)} onClick={handleClearSearch}>
							Clear search
						</div>
					</div>
				</div>
			) : (
				<CampaignBrowserTabs>
					{categories.map(c => {
						const onClick = () => onCategoryChange(c);
						if (c === KnownCategories.FinraReviewed) {
							return (
								<figure
									id={c}
									key={c}
									className={css([styleSheet.tab, category === c && styleSheet.tabSelected])}
									onClick={onClick}
								>
									<img src={FinraImage} height={10} />
									<span className={css(styleSheet.reviewed)}> Reviewed</span>
								</figure>
							);
						}
						return (
							<button
								id={c}
								key={c}
								className={css(
									styleSheet.tab,
									KnownCategories.HtmlNewsletters === c && styleSheet.htmlNewsletterTab,
									category === c && styleSheet.tabSelected
								)}
								onClick={onClick}
							>
								<div>{c}</div>
							</button>
						);
					})}
				</CampaignBrowserTabs>
			)}
			{children}
		</div>
	);
}

export * from './hooks';
