import { css } from 'aphrodite';
import * as React from 'react';
import { mergeRefs } from 'react-merge-refs';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	htmlContent?: string;
	cssContent?: string;
	disabled?: boolean;
	disableClicks?: boolean;
	scrolling?: 'no' | 'yes';
	fullHeight?: boolean;
}

export const HostedBlogPreview = React.forwardRef<HTMLIFrameElement, IProps>(function PreviewIframeComponent(
	{ cssContent, disabled, htmlContent, disableClicks, className, scrolling = 'yes', fullHeight },
	ref
) {
	const iframeRef = React.useRef<HTMLIFrameElement>(null);
	React.useEffect(() => {
		if (iframeRef.current) {
			const iframeDocument = iframeRef.current.contentDocument;

			if (iframeDocument) {
				iframeDocument.open();
				iframeDocument.write(htmlContent);
				iframeDocument.close();

				let styleTag = iframeDocument.getElementById('dynamic-styles');
				if (!styleTag) {
					styleTag = iframeDocument.createElement('style');
					styleTag.id = 'dynamic-styles';
					iframeDocument.head.appendChild(styleTag);
				}
				styleTag.innerHTML = cssContent;

				if (disableClicks) {
					let scriptTag = iframeDocument.getElementById('dynamic-script');
					if (!scriptTag) {
						scriptTag = iframeDocument.createElement('script');
						scriptTag.id = 'dynamic-script';
						scriptTag.innerHTML = `
							document.addEventListener('click', function(event) {
								event.preventDefault();
								event.stopPropagation();
							});
						`;
						iframeDocument.head.appendChild(scriptTag);
					}
				}
			}
		}
	}, [cssContent, htmlContent, disableClicks, fullHeight]);

	React.useEffect(() => {
		if (fullHeight) {
			iframeRef.current.height = `${iframeRef.current.contentWindow.document.body.scrollHeight}px`;
		}
	});

	return (
		<iframe
			className={`${css(styleSheet.previewIframe, disabled ? styleSheet.previewIframeDisabled : null)} ${className ?? []}`}
			ref={mergeRefs([ref, iframeRef])}
			title='Live Preview'
			scrolling={scrolling}
		/>
	);
});
