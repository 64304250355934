import {
	ContactFilterCriteriaProperty,
	ContactsViewModel,
	FilterOperator,
	IAutomationTemplate,
	IContactFilterCriteria,
	UserViewModel,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { AdvancedTagFilter, AdvancedTagFilterCollection } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { isTagSearchContactFilterCriteria, numberToCurrencyStringValue } from '../../../../models/UiUtils';
import { baseStyleSheet } from '../../../styles/styles';
import { EntityPropertySearchTag } from '../../entities/EntityPropertySearchTag';
import { styleSheet } from './styles';

const maxContactFiltersToDisplay = 50;

interface IProps extends IUserSessionComponentProps, IUserSessionComponentProps {
	advancedSearchButtonStyles?: StyleDeclarationValue[];
	automationTemplates?: Partial<IAutomationTemplate>[];
	className?: string;
	contacts?: ContactsViewModel;
	contactsTotal?: number;
	filterCriteria?: IContactFilterCriteria;
	hideAdvancedSearch?: boolean;
	hideLabel?: boolean;
	hideRemoveButtons?: boolean;
	onAdvancedTagSearchClicked?(): void;
	onRenderTag?(tagFilter: AdvancedTagFilter, index: number, total: number, onRemove: () => void): React.ReactNode;
	onSearchesChanged?(searches: IContactFilterCriteria[]): void;
	restrictTagsToDisplay?: number; // temporary fix until saved searches gets implemented and new ui is added
	searches?: IContactFilterCriteria[];
	searchUsers?: UserViewModel[];
	showOwnership?: boolean;
	styles?: StyleDeclarationValue[];
	tokenContainerStyles?: StyleDeclarationValue[];
	tokenPrefixStyles?: StyleDeclarationValue[];
	tokenStyles?: StyleDeclarationValue[];
}

const ContactsTableActiveSearchesListSfc: React.FC<IProps> = props => {
	const {
		advancedSearchButtonStyles,
		automationTemplates,
		className,
		contacts,
		contactsTotal,
		filterCriteria,
		hideAdvancedSearch,
		hideLabel,
		hideRemoveButtons,
		onAdvancedTagSearchClicked,
		onRenderTag,
		onSearchesChanged,
		restrictTagsToDisplay,
		searches,
		searchUsers,
		styles,
		tokenContainerStyles,
		tokenPrefixStyles,
		tokenStyles,
		userSession,
	} = props;

	const getQueryDescription = (value?: React.ReactNode): React.ReactNode => {
		let queryDescription = value;
		if (!queryDescription && filterCriteria.property !== null && filterCriteria.property !== undefined) {
			switch (filterCriteria.property) {
				case ContactFilterCriteriaProperty.All: {
					const inProgressCriteria = contacts?.filterRequest?.filter?.criteria?.find(
						x => x.property === ContactFilterCriteriaProperty.InProgressAutomations
					);
					if (inProgressCriteria) {
						const automationModel = inProgressCriteria.value
							? automationTemplates?.find(x => x.id === inProgressCriteria.value)
							: null;
						return (
							<span>
								contacts with in-progress automation
								{automationModel ? `: "${automationModel.name}"` : ''}
							</span>
						);
					}
					const completedCriteria = contacts?.filterRequest?.filter?.criteria?.find(
						x => x.property === ContactFilterCriteriaProperty.PreviousAutomations
					);
					if (completedCriteria) {
						const automationModel = completedCriteria.value
							? automationTemplates?.find(x => x.id === completedCriteria.value)
							: null;
						return (
							<span>
								contacts with completed automation
								{automationModel ? `: "${automationModel.name}"` : ''}
							</span>
						);
					}
					queryDescription = <span>all contacts</span>;
					break;
				}
				case ContactFilterCriteriaProperty.KeepInTouch: {
					queryDescription = <span>all contacts marked for &quot;Keep in Touch&quot;</span>;
					break;
				}
				case ContactFilterCriteriaProperty.OwnedBy: {
					if (userSession.user.id === filterCriteria.value) {
						queryDescription = <span>all contacts I own</span>;
						break;
					}
					queryDescription = (
						<span>
							all contacts owned by
							{searchUsers
								? searchUsers.reduce((result, user, i) => {
										return `${result}${!!result && i !== 0 ? `, ${user.name}` : user.name}`;
									}, ' ')
								: '...'}
						</span>
					);
					break;
				}
				case ContactFilterCriteriaProperty.Connections: {
					queryDescription = <span>all contact connections</span>;
					break;
				}
				case ContactFilterCriteriaProperty.PrivateContacts: {
					queryDescription = <span>all private contacts</span>;
					break;
				}
				case ContactFilterCriteriaProperty.TagAlert: {
					queryDescription = <span>all contacts with tag alerts due</span>;
					break;
				}
				case ContactFilterCriteriaProperty.WithoutEmailAddresses: {
					queryDescription = <span>all contacts without an email address</span>;
					break;
				}
				case ContactFilterCriteriaProperty.WithoutTags: {
					queryDescription = <span>all contacts without a tag</span>;
					break;
				}
				default: {
					queryDescription = value || '';
					break;
				}
			}
		}
		return queryDescription;
	};

	const renderDefault = () => {
		const search = (searches || []).find(x => !!x && !!x.value) || {};
		if (!search || !search.value) {
			return;
		}
		const queryDescription = getQueryDescription(search.value);
		return (
			<div className={css(styleSheet.defaultSearchText)} key={`${search.value}-0`}>
				{search.value ? '"' : ''}
				{queryDescription}
				{search.value ? '"' : ''}
			</div>
		);
	};

	const showingTagSearches = (searches || []).every(x => isTagSearchContactFilterCriteria(x));
	const filters = showingTagSearches ? AdvancedTagFilterCollection.instanceWithCriteria(searches || []) : null;
	if (filters) {
		filters.sort();
	}

	const removeTagSearch = (search: IContactFilterCriteria) => {
		if (!!onSearchesChanged && !!search.value) {
			const filter = filters.find(search.value, {
				caseInsensitiveSearch: true,
			});
			if (filter) {
				filters.remove(filter);
				onSearchesChanged(filters.toCriteriaCollection());
			}
		}
	};

	const renderTagCriteria = (x: AdvancedTagFilter, index: number) => {
		const tag = (onRenderTag
			? onRenderTag(x, index, filters.length, () => removeTagSearch(x.toContactFilterCriteria()))
			: null) || (
			<EntityPropertySearchTag
				className={css(styleSheet.tagToken, ...(tokenStyles || []))}
				onRemoveSearch={hideRemoveButtons ? () => removeTagSearch({ value: x.tag }) : undefined}
				op={x.type}
				search={{ value: x.tag }}
			/>
		);
		return (
			<React.Fragment key={x.id}>
				{index > 0 && (
					<span className={css(styleSheet.tagTokenOpPrefix, ...(tokenPrefixStyles || []))}>
						{x.type === FilterOperator.Or ? 'or' : x.type === FilterOperator.Not ? 'not' : 'and'}
					</span>
				)}
				{tag}
			</React.Fragment>
		);
	};

	const renderTagSearchTokens = () => {
		let tags: JSX.Element[] = [];

		const sortedFilters = [
			...filters.filters.filter(filter => filter.type === FilterOperator.Or),
			...filters.filters.filter(filter => filter.type === FilterOperator.And),
			...filters.filters.filter(filter => filter.type === FilterOperator.Not),
		];

		if (sortedFilters.length) {
			const tagsFiltered = sortedFilters.filter(
				x =>
					(x?.tag === 'Unsubscribe' && x?.type === FilterOperator.Not) ||
					(x?.tag === 'Email Bounced' && x?.type === FilterOperator.Not)
			);
			tags = sortedFilters
				.filter(
					x =>
						!(x?.tag === 'Unsubscribe' && x?.type === FilterOperator.Not) &&
						!(x?.tag === 'Email Bounced' && x?.type === FilterOperator.Not)
				)
				.filter(
					(_, i) => (typeof restrictTagsToDisplay === 'number' && i < restrictTagsToDisplay) || !restrictTagsToDisplay
				)
				.map((x, i) => renderTagCriteria(x, i));
			if (tags.length < sortedFilters.length - tagsFiltered.length) {
				tags.push(
					<span className={css(styleSheet.plusMoreTags)}>{`+ ${
						filters.filters.length - tags?.length - tagsFiltered.length
					} more`}</span>
				);
			}
		}

		return (
			<div className={css(styleSheet.tokens, ...(tokenContainerStyles || []))}>
				{tags}

				{!hideAdvancedSearch && filters.length > 0 && filters.length < maxContactFiltersToDisplay && (
					<button
						className={css(
							baseStyleSheet.brandLink,
							styleSheet.advancedTagSearchButton,
							...(advancedSearchButtonStyles || [])
						)}
						onClick={onAdvancedTagSearchClicked}
					>
						<span>Advanced Search</span>
					</button>
				)}
			</div>
		);
	};

	const searchCount = filters
		? filters.length
		: (searches || []).reduce((count, x) => {
				if (!!x && !!x.value) {
					return count + 1;
				}
				return count;
			}, 0);

	return (
		<div
			className={`${css(styleSheet.container, ...(styles || []))} contacts-table-active-searches-list ${
				className || ''
			}`}
		>
			{!hideLabel && !isNaN(contactsTotal) && (
				<div className={`${css(styleSheet.label)} truncate-text`}>
					Showing
					<span className={css(styleSheet.bold)}>{' ' + numberToCurrencyStringValue(contactsTotal) + ' '}</span>
					results for {searchCount > 0 ? ':' : getQueryDescription()}
				</div>
			)}
			{filters ? renderTagSearchTokens() : renderDefault()}
		</div>
	);
};
const ContactsTableActiveSearchesListAsObserver = observer(ContactsTableActiveSearchesListSfc);
const ContactsTableActiveSearchesListWithContext = inject(UserSessionViewModelKey)(
	ContactsTableActiveSearchesListAsObserver
);
export const ContactsTableActiveSearchesList = ContactsTableActiveSearchesListWithContext;
