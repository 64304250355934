import * as Api from '@ViewModels';
import { UseMutationResult } from '@tanstack/react-query';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { IModal, IModalContext, ModalChildComponentContextKey } from '../../../models';
import { useEventLogging } from '../../../models/Logging';
import { useErrorMessages, useUserSession } from '../../../models/hooks/appStateHooks';
import { baseStyleSheet } from '../../styles/styles';
import { LoadingSpinner } from '../LoadingSpinner';
import { asModalComponent } from '../Modal';
import { SvgIcon } from '../svgs/icons/SvgIcon';
import { styleSheet } from './styles';

interface IProps extends IModalContext {
	className?: string;
	promise: Promise<Api.ISystemJob>;
	subTitle?: React.ReactNode;
}
export const EXPORT_CONFIRMATION_LOGGING_KEY = 'ExportConfirmation';

const ExportConfirmationAsync = ({ promise, className, subTitle, parentModal }: IProps) => {
	const [systemJobModel, setSystemJobModel] = React.useState<Api.ISystemJob>();
	const { logApiError } = useEventLogging(EXPORT_CONFIRMATION_LOGGING_KEY);
	const errorMessages = useErrorMessages();
	React.useEffect(() => {
		let cancelled = false;
		const onRequestClose = (cancel = true) => {
			if (parentModal) {
				parentModal.onRequestClose(null, cancel);
			}
		};

		if (!promise) {
			return;
		}

		promise
			.then(sysJobModel => {
				setSystemJobModel(sysJobModel);
			})
			.catch(error => {
				logApiError('GetSystemJob-Error', error);
				if (!cancelled) {
					errorMessages?.pushApiError(error);
					onRequestClose(true);
				}
			});
		return () => {
			cancelled = true;
		};
	}, [promise, errorMessages, logApiError, parentModal]);
	return (
		<ExportConfirmationBody className={className} subTitle={subTitle}>
			{systemJobModel ? (
				<ExportConfirmationSystemJob systemJobModel={systemJobModel} parentModal={parentModal} />
			) : null}
		</ExportConfirmationBody>
	);
};

const ExportConfirmation = ({
	className,
	title,
	documentKind,
	subTitle,
	parentModal,
	mutationResult,
}: {
	className?: string;
	title?: string;
	documentKind?: string;
	subTitle?: React.ReactNode;
	parentModal?: IModal;
	mutationResult: UseMutationResult<Api.ISystemJob, Api.IOperationResultNoValue, any, unknown>;
}) => {
	return (
		<ExportConfirmationBody className={className} subTitle={subTitle} title={title} documentKind={documentKind}>
			{mutationResult.isSuccess ? (
				<ExportConfirmationSystemJob systemJobModel={mutationResult.data} parentModal={parentModal} />
			) : null}
		</ExportConfirmationBody>
	);
};

const ExportConfirmationBody = ({
	children,
	className,
	subTitle,
	title = 'Export to Spreadsheet (CSV)',
	documentKind = 'spreadsheet',
}: {
	title?: string;
	documentKind?: string;
	className?: string;
	children: React.ReactNode;
	subTitle?: React.ReactNode;
}) => {
	return (
		<div className={`${css(styleSheet.container)} export-confirmation ${className || ''}`}>
			<div className={css(styleSheet.header)}>
				<span>{title}</span>
			</div>
			<div className={css(styleSheet.body)}>
				<div className={css(styleSheet.bodyContent)}>
					<SvgIcon height={88} width={129}>
						<g fill='none' fillRule='evenodd' transform='translate(0 1)'>
							<g transform='translate(0 7)'>
								<path
									stroke='#00AAE8'
									strokeWidth='2'
									d='M4,1 C2.34314575,1 1,2.34314575 1,4 L1,76 C1,77.6568542 2.34314575,79 4,79 L108,79 C109.656854,79 111,77.6568542 111,76 L111,4 C111,2.34314575 109.656854,1 108,1 L4,1 Z'
								/>
								<polygon fill='#E8F3F9' points='4 4 40 4 40 74 4 74' />
								<rect width='34' height='70' x='5' y='5' stroke='#00AAE8' strokeWidth='2' />
								<rect width='34' height='70' x='39' y='5' stroke='#00AAE8' strokeWidth='2' />
								<rect width='34' height='70' x='73' y='5' stroke='#00AAE8' strokeWidth='2' />
								<path
									stroke='#00AAE8'
									strokeLinecap='square'
									strokeWidth='2'
									d='M5.5 15.5L106.53125 15.5M5.5 15.5L106.53125 15.5M5.5 25.5L106.53125 25.5M5.5 35.5L106.53125 35.5M5.5 45.5L106.53125 45.5M5.5 55.5L106.53125 55.5M5.5 65.5L106.53125 65.5'
								/>
							</g>
							<g transform='translate(88)'>
								<circle cx='19.986' cy='20.26' r='19.5' fill='#DBF2C3' stroke='#89C947' strokeWidth='2' />
								<circle cx='19.986' cy='20.26' r='16.5' fill='#FFF' />
							</g>
							<g fill='#89C947' transform='translate(100 14)'>
								<path d='M2,10 L2,2.943 L7.419,6.814 C7.593,6.938 7.796,7 8,7 C8.204,7 8.407,6.938 8.581,6.814 L14,2.943 L14.001,10 L2,10 Z M11.88,2 L8,4.771 L4.12,2 L11.88,2 Z M14,0 L2,0 C0.897,0 0,0.897 0,2 L0,10 C0,11.103 0.897,12 2,12 L14,12 C15.103,12 16,11.103 16,10 L16,2 C16,0.897 15.103,0 14,0 L14,0 Z' />
							</g>
						</g>
					</SvgIcon>
					<div className={css(styleSheet.title)}>
						<div>We are exporting the {documentKind}, and will</div>
						<div>email you when it&apos;s ready for download.</div>
					</div>
					{!!subTitle && <div className={css(styleSheet.subtitle)}>{subTitle}</div>}
					{children}
				</div>
			</div>
		</div>
	);
};

const ExportConfirmationSystemJob = ({
	systemJobModel,
	parentModal,
}: {
	systemJobModel: Api.ISystemJob;
	parentModal?: IModal;
}) => {
	const userSession = useUserSession();
	const { logApiError, logInput } = useEventLogging(EXPORT_CONFIRMATION_LOGGING_KEY);
	const [systemJob, setSystemJob] = React.useState<Api.SystemJobViewModel>();
	const [downloadURL, setDownloadURL] = React.useState<string>();
	React.useEffect(() => {
		if (!userSession) {
			return;
		}
		let cancelled = false;
		let progressTimeoutHandle: NodeJS.Timeout;
		const getProgress = (_systemJob: Api.SystemJobViewModel) => {
			return _systemJob
				.get()
				?.then(() => {
					if (!cancelled) {
						if (_systemJob.percentComplete >= 100) {
							progressTimeoutHandle = null;
							setDownloadURL(_systemJob.additionalFields?.ReportUrl);

							if (_systemJob.jobType === 'PdfGenerationJob') {
								setDownloadURL((_systemJob as Api.IExportTemplatePdfJob).pdf.publicUrl);
							}
						} else {
							progressTimeoutHandle = setTimeout(() => {
								getProgress(_systemJob);
							}, 2000);
						}
					}
				})
				?.catch((error: Api.IOperationResultNoValue) => {
					logApiError('GetSystemJobUpdate-Error', error);
				});
		};
		if (!cancelled) {
			const _systemJob = new Api.SystemJobViewModel(userSession, systemJobModel);
			setSystemJob(_systemJob);
			getProgress(_systemJob);
		}
		return () => {
			if (!userSession) {
				return;
			}
			cancelled = true;
			if (progressTimeoutHandle) {
				clearTimeout(progressTimeoutHandle);

				progressTimeoutHandle = null;
			}
		};
	}, [userSession, systemJobModel, logApiError]);
	const onRequestClose = (cancel = true) => {
		if (parentModal) {
			parentModal.onRequestClose(null, cancel);
		}
	};
	const onFinishButtonClicked = (e: React.MouseEvent<HTMLElement>) => {
		if (!downloadURL) {
			e.preventDefault();
			e.stopPropagation();
		}

		// @ts-ignore
		const model: Partial<ISystemJob> = systemJob
			? Api.selectKeysOf(systemJob.toJs(), ['totalRecords', 'recordsFailed', 'id'])
			: null;

		if (model) {
			logInput(downloadURL ? 'Download' : 'Close', 'Click', model);
		}
		onRequestClose();
	};

	return (
		<>
			<div className={css(styleSheet.footer)}>
				{systemJob ? (
					<a
						className={css(baseStyleSheet.ctaButton, styleSheet.finishButton)}
						onClick={onFinishButtonClicked}
						href={downloadURL}
					>
						<span>{downloadURL ? 'Download Now' : 'Close'}</span>
					</a>
				) : (
					<LoadingSpinner className={css(styleSheet.loading)} type='small' />
				)}
			</div>
		</>
	);
};

export const ExportConfirmationModal = asModalComponent(
	inject(ModalChildComponentContextKey)(ExportConfirmationAsync),
	{
		className: 'ExportConfirmationModal',
		useDefaultHeader: true,
	}
);

export const ExportConfirmationModalV2 = asModalComponent(inject(ModalChildComponentContextKey)(ExportConfirmation), {
	className: 'ExportConfirmationModalV2',
	useDefaultHeader: true,
});
