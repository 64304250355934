import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { IFabContext, ILocationState, IModalContext, ModalChildComponentContextKey } from '../../../models';
import { IEditHtmlNewsletterRequest } from '../../../models/AdminModels';
import { useEventLogging } from '../../../models/Logging';
import { useErrorMessages, useUserSession } from '../../../models/hooks/appStateHooks';
import { ImpersonationContextViewModel } from '../../../viewmodels/AdminViewModels';
import { HtmlNewsletterViewModel } from '../../../viewmodels/HtmlNewletterViewModels';
import { AppAutoUpdaterRefreshBlocker } from '../../components/AppAutoUpdaterRefreshBlocker';
import { FabContext } from '../../components/FabContext';
import { HtmlNewsletterEditor } from '../../components/HtmlNewsletterEditor';
import { HtmlNewsletterEditorHeaderHeight } from '../../components/HtmlNewsletterEditor/styles';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { INavigationItemProps } from '../MainContainer';
import { styleSheet } from './styles';

interface IProps extends INavigationItemProps, IModalContext {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const _EditHtmlNewsletterTemplate = observer(({ className, styles, routeContainerClassName }: IProps) => {
	const match = useRouteMatch<{ id?: string }>();
	const location = useLocation();
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const [templateLoadState, setTemplateLoadState] = React.useState({
		error: null,
		isLoaded: false,
	});
	const logger = useEventLogging('HtmlNewsletterTemplate');
	const newsletterRef = React.useRef<HtmlNewsletterViewModel>(null);
	const [createRequest, impersonationCtxVm] = React.useMemo(() => {
		const locationState: ILocationState<any, IEditHtmlNewsletterRequest> = location?.state ?? {};
		const request = locationState?.model?.template ? locationState?.model : null;
		const impCtx = new ImpersonationContextViewModel();
		impCtx.update(request?.impersonationContext);
		return [request, impCtx] as const;
	}, [location]);

	React.useEffect(() => {
		const PARAMS_ID = match?.params?.id;
		const isNew = PARAMS_ID === 'new';
		const template = createRequest?.template ?? { id: PARAMS_ID, name: isNew ? 'Untitled Newsletter' : undefined };

		newsletterRef.current = new HtmlNewsletterViewModel(
			userSession,
			isNew ? HtmlNewsletterViewModel.createDefaultBlankTemplateModel(template) : template
		).impersonate(impersonationCtxVm);
		if (newsletterRef.current.id && !isNew) {
			if (!newsletterRef.current.isLoaded) {
				newsletterRef.current
					?.load()
					?.then(() => {
						setTemplateLoadState({ error: null, isLoaded: true });
					})
					?.catch(error => {
						logger.logApiError('TemplateLoad-Error');
						errorMessages?.pushApiError(error);
						setTemplateLoadState({ error, isLoaded: false });
					});
			} else {
				setTemplateLoadState({ error: null, isLoaded: true });
			}
		} else {
			setTemplateLoadState({ error: null, isLoaded: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const pathComponentNameProvider = React.useCallback((pathComponent: string) => {
		if (pathComponent?.toLocaleLowerCase() === 'htmlnewsletter') {
			return 'HTML Newsletters';
		}
		return pathComponent
			? `${pathComponent.charAt(0).toLocaleUpperCase()}${pathComponent.substring(1)}`
			: pathComponent;
	}, []);
	const editorStyle = React.useRef([styleSheet.editor]).current;
	const editorBodyStyle = React.useRef<React.CSSProperties>({
		height: `calc(100% - ${HtmlNewsletterEditorHeaderHeight + 24}px)`,
		marginTop: 20,
	}).current;
	const fabContext = React.useRef<IFabContext>({
		appearance: {
			hidden: true,
		},
	});

	return (
		<div
			className={`${css(styleSheet.container, ...(styles || []))} edit-html-newsletter-template ${
				createRequest ? css(styleSheet.modalContainer) : ''
			} ${routeContainerClassName || ''} ${className || ''}`}
		>
			{!createRequest ? (
				<MultiContainerHeader>
					<NavigationBreadcrumbsBar
						currentLocationName={newsletterRef.current?.name || ''}
						pathComponentNameProvider={pathComponentNameProvider}
					/>
				</MultiContainerHeader>
			) : (
				<MultiContainerHeader
					fullscreenHeader={
						newsletterRef.current?.name !== 'Untitled Newsletter'
							? `Edit ${newsletterRef.current?.name}`
							: 'Create HTML Newsletter'
					}
					onFullscreenRequestClose={undefined}
				/>
			)}
			<FabContext {...fabContext.current} />
			{!!templateLoadState?.isLoaded && !templateLoadState.error ? (
				<HtmlNewsletterEditor
					newsletter={newsletterRef.current}
					styles={editorStyle}
					isEditModal={Boolean(createRequest)}
				>
					<HtmlNewsletterEditor.Header isEditModal={Boolean(createRequest)}>
						{!createRequest ? <HtmlNewsletterEditor.AutoSaveCheckbox /> : null}
						<HtmlNewsletterEditor.SaveButton />
						{newsletterRef.current?.name !== 'Untitled Newsletter' && !createRequest ? (
							<HtmlNewsletterEditor.SaveAsNewButton />
						) : null}

						{!createRequest ? <HtmlNewsletterEditor.DownloadPDF /> : null}

						{!createRequest ? <HtmlNewsletterEditor.SendButton /> : null}
					</HtmlNewsletterEditor.Header>
					<HtmlNewsletterEditor.SubjectInput />
					<HtmlNewsletterEditor.Body style={editorBodyStyle} />
				</HtmlNewsletterEditor>
			) : (
				<LoadingSpinner type='large' />
			)}
			<AppAutoUpdaterRefreshBlocker />
		</div>
	);
});

export const EditHtmlNewsletterTemplate = inject(ModalChildComponentContextKey)(_EditHtmlNewsletterTemplate);
