import { css, StyleSheet } from 'aphrodite';
import { forwardRef, ForwardRefExoticComponent, InputHTMLAttributes, RefAttributes, useRef } from 'react';
import { FieldError } from 'react-hook-form';
import { mergeRefs } from 'react-merge-refs';
import { v4 as uuid } from 'uuid';
import { background, charmGray, error as errorRed, shuffleGray } from '../../../web/styles/colors';
import { bs } from '../../../web/styles/styles';
import { InfoPopover } from '../InfoPopover/InfoPopover';
import { Column, Row } from '../Layout';

interface ILabeledInputProps extends InputHTMLAttributes<HTMLInputElement> {
	error?: FieldError;
	file?: File;
	label?: string;
	labelAccessory?: React.ReactNode;
	variant?: 'large' | 'small';
}

export const LabeledInput: ForwardRefExoticComponent<ILabeledInputProps & RefAttributes<HTMLInputElement>> = forwardRef(
	({ error, file, label, labelAccessory, variant = 'large', ...props }, ref) => {
		const id = useRef<string>(uuid());
		const inputRef = useRef<HTMLInputElement>(null);

		return (
			<Column className={css(bs.justifyCenter)}>
				{!!label && (
					<Row className={css(labeledInputStyles.labelRow)}>
						<label className={css(labeledInputStyles.label)} htmlFor={id.current}>
							{label}
						</label>
						{!!labelAccessory && labelAccessory}
						{!!error && !!label && <InfoPopover message={error.message} variant='error' />}
					</Row>
				)}
				<Row className={css(bs.itemsCenter)}>
					<input
						className={css(
							labeledInputStyles.textInput,
							labeledInputStyles[variant],
							error && label ? labeledInputStyles.textInputError : null,
							error && !label ? labeledInputStyles.textInputWithErrorAccessory : null
						)}
						id={id.current}
						ref={mergeRefs([ref, inputRef])}
						{...props}
					/>
					{!!error && !label && (
						<span className={css(labeledInputStyles.textInput, labeledInputStyles.inputErrorAccessory)}>
							<InfoPopover message={error?.message} variant='error' />
						</span>
					)}
				</Row>
			</Column>
		);
	}
);
LabeledInput.displayName = 'LabeledInput';

export const labeledInputStyles = StyleSheet.create({
	info: {
		fontSize: 12,
		color: charmGray,
		textAlign: 'right',
	},
	label: {
		fontSize: 14,
	},
	labelRow: {
		gap: 4,
		paddingBottom: 8,
	},
	large: {
		height: 42,
	},
	small: {
		height: 28,
	},
	textInput: {
		backgroundColor: background,
		border: `1px solid ${shuffleGray}`,
		borderRadius: 8,
		display: 'flex',
		height: 42,
		outline: 'none',
		textIndent: 12,
		width: '100%',
		':focus': {
			border: `1.5px solid ${charmGray}`,
		},
	},
	textInputError: {
		border: `2px solid ${errorRed}`,
		':focus': {
			border: `1.5px solid ${errorRed}`,
		},
	},
	textInputWithErrorAccessory: {
		border: `2px solid ${errorRed}`,
		borderRadius: '8px 0 0 8px',
		borderRight: 'none',
		width: 'calc(100% - 24px)',
		':focus': {
			border: `2px solid ${errorRed}`,
			borderRight: 'none',
		},
	},
	inputErrorAccessory: {
		backgroundColor: background,
		display: 'flex',
		height: 42,
		outline: 'none',
		textIndent: 12,
		border: `2px solid ${errorRed}`,
		borderRadius: '0 8px 8px 0',
		borderLeft: 'none',
		width: '24px',
	},
});
