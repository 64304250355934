/** This list of fonts was retrieved from unlayer official documentation
 *  it can be consulted at: https://docs.unlayer.com/builder/font-management/default-fonts
 */
const UNLAYER_EDITOR_FONTS = [
	{
		label: 'Andale Mono',
		value: 'andale mono,times',
	},
	{
		label: 'Arial',
		value: 'arial,helvetica,sans-serif',
	},
	{
		label: 'Arial Black',
		value: 'arial black,avant garde,arial',
	},
	{
		label: 'Book Antiqua',
		value: 'book antiqua,palatino',
	},
	{
		label: 'Comic Sans MS',
		value: 'comic sans ms,sans-serif',
	},
	{
		label: 'Courier New',
		value: 'courier new,courier',
	},
	{
		label: 'Georgia',
		value: 'georgia,palatino',
	},
	{
		label: 'Helvetica',
		value: 'helvetica,sans-serif',
	},
	{
		label: 'Impact',
		value: 'impact,chicago',
	},
	{
		label: 'Symbol',
		value: 'symbol',
	},
	{
		label: 'Tahoma',
		value: 'tahoma,arial,helvetica,sans-serif',
	},
	{
		label: 'Terminal',
		value: 'terminal,monaco',
	},
	{
		label: 'Times New Roman',
		value: 'times new roman,times',
	},
	{
		label: 'Trebuchet MS',
		value: 'trebuchet ms,geneva',
	},
	{
		label: 'Verdana',
		value: 'verdana,geneva',
	},
	{
		label: 'Lobster Two',
		value: "'Lobster Two',cursive",
		url: 'https://fonts.googleapis.com/css?family=Lobster+Two:400,700',
	},
	{
		label: 'Playfair Display',
		value: "'Playfair Display',serif",
		url: 'https://fonts.googleapis.com/css?family=Playfair+Display:400,700',
	},
	{
		label: 'Rubik',
		value: "'Rubik',sans-serif",
		url: 'https://fonts.googleapis.com/css?family=Rubik:400,700',
	},
	{
		label: 'Source Sans Pro',
		value: "'Source Sans Pro',sans-serif",
		url: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700',
	},
	{
		label: 'Open Sans',
		value: "'Open Sans',sans-serif",
		url: 'https://fonts.googleapis.com/css?family=Open+Sans:400,700',
	},
	{
		label: 'Crimson Text',
		value: "'Crimson Text',serif",
		url: 'https://fonts.googleapis.com/css?family=Crimson+Text:400,700',
	},
	{
		label: 'Montserrat',
		value: "'Montserrat',sans-serif",
		url: 'https://fonts.googleapis.com/css?family=Montserrat:400,700',
	},
	{
		label: 'Old Standard TT',
		value: "'Old Standard TT',serif",
		url: 'https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700',
	},
	{
		label: 'Lato',
		value: "'Lato',sans-serif",
		url: 'https://fonts.googleapis.com/css?family=Lato:400,700',
	},
	{
		label: 'Raleway',
		value: "'Raleway',sans-serif",
		url: 'https://fonts.googleapis.com/css?family=Raleway:400,700',
	},
	{
		label: 'Cabin',
		value: "'Cabin',sans-serif",
		url: 'https://fonts.googleapis.com/css?family=Cabin:400,700',
	},
	{
		label: 'Pacifico',
		value: "'Pacifico',cursive",
		url: 'https://fonts.googleapis.com/css?family=Pacifico',
	},
];

export const getEmailEditorFonts = ({ useExternal }: { useExternal: boolean } = { useExternal: false }) => {
	return UNLAYER_EDITOR_FONTS.filter(font => {
		if (useExternal) {
			return true;
		}

		return !font.url;
	});
};
