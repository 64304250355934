import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useAutomationTemplateIdStepOrderUpdateMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (value: string[]) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			id,
			order,
			impersonationContext,
		}: { id: string; order: string[]; impersonationContext?: Api.IImpersonationContext }) => {
			return userSession.webServiceHelper.callAsync<string[]>(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `automationTemplate/${id}/stepOrder`, impersonationContext }),
				'PUT',
				order
			);
		},
		onError,
		onSuccess,
	});
}
