import { FilterOperator } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { brandPrimaryHover, destructive } from '../../../styles/colors';
import { SvgIcon } from '../../svgs/icons/SvgIcon';
import { styleSheet } from './styles';

export interface IEntityPropertySearch {
	value?: string;
}

interface IProps {
	className?: string;
	onRemoveSearch?: () => void;
	op?: FilterOperator;
	removeButtonStyles?: StyleDeclarationValue[];
	search: IEntityPropertySearch;
	styles?: StyleDeclarationValue[];
}

export const EntityPropertySearchTag: React.FC<IProps> = props => {
	const { className, onRemoveSearch, search, styles, removeButtonStyles, op, children } = props;

	return (
		<div
			className={`${css(
				styleSheet.container,
				op === FilterOperator.Not ? styleSheet.excludeTag : null,
				...(styles || [])
			)} entity-property-search-tag ${className || ''}`}
		>
			<div className={css(styleSheet.tagText)}>{search.value}</div>
			{children}
			{onRemoveSearch ? (
				<button className={css(styleSheet.tagRemoveButton, ...(removeButtonStyles || []))} onClick={onRemoveSearch}>
					<SvgIcon height={8} width={8}>
						<polygon
							fill={op === FilterOperator.Not ? destructive : brandPrimaryHover}
							fillRule='evenodd'
							points='7.525 .74 6.925 .14 3.833 3.233 .74 .14 .14 .74 3.233 3.832 .14 6.925 .74 7.525 3.833 4.432 6.925 7.525 7.525 6.925 4.432 3.832'
						/>
					</SvgIcon>
				</button>
			) : null}
		</div>
	);
};
