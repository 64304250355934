import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ILocationState } from '../../../../models';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { useAutomationCardData } from '../AutomationCard/hooks';
import {
	AutomationProgressStats,
	AutomationTrigger,
	AutomationYoloModeIndicator,
} from '../AutomationCard/presentation';
import { AutomationSteps } from '../AutomationSteps';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, RouteComponentProps<any> {
	automationTemplate: Api.AutomationTemplateViewModel;
	automationTemplates?: Api.AutomationTemplatesViewModel;
	className?: string;
	maxStepsToDisplay?: number;

	styles?: StyleDeclarationValue[];
}

const _AutomationReportingCard = ({
	automationTemplate,
	className,
	history,
	match,
	logInput,
	styles,
	maxStepsToDisplay = 5,
}: IProps) => {
	const {
		automationId,
		completedContactString,
		inProgressContactString,
		trigger,
		triggerDescription,
		showCompletedButton,
		showInProgressButton,
	} = useAutomationCardData(automationTemplate);
	const locationState: ILocationState<Api.AutomationTemplateViewModel, any> = { viewModel: automationTemplate };
	const onClick = () => {
		logInput('ViewAutomationDetails', 'Click', { id: automationTemplate.id });
		if (trigger?.resourceSelector === Api.ResourceSelectorId.PolicyRenew) {
			history?.push({
				pathname: `/reporting/renewals/${automationTemplate.id}`,
				state: locationState,
			});
			return;
		}
		if (trigger?.resourceSelector === Api.ResourceSelectorId.HappyBirthday) {
			history?.push({
				pathname: `/reporting/birthday`,
				state: locationState,
			});
			return;
		}
		if (trigger?.resourceSelector === Api.ResourceSelectorId.Turning65) {
			history?.push({
				pathname: `/reporting/turning65/${automationTemplate.id}`,
				state: locationState,
			});
			return;
		}
		if (
			trigger?.resourceSelector === Api.ResourceSelectorId.NewLeadAutomationsOnHold ||
			trigger?._type === Api.AutomationTriggerType.NewLead
		) {
			history?.push({
				pathname: `/reporting/newleads/${automationTemplate.id}`,
				state: locationState,
			});
			return;
		}
		if (
			trigger?.resourceSelector === Api.ResourceSelectorId.NewClientAutomationsOnHold ||
			trigger?._type === Api.AutomationTriggerType.NewClient
		) {
			history?.push({
				pathname: `/reporting/newclients/${automationTemplate.id}`,
				state: locationState,
			});
			return;
		}
		if (
			trigger?.resourceSelector === Api.ResourceSelectorId.NewDonorAutomationsOnHold ||
			trigger?._type === Api.AutomationTriggerType.NewDonor
		) {
			history?.push({
				pathname: `/reporting/newdonors/${automationTemplate.id}`,
				state: locationState,
			});
			return;
		}
		history?.push({
			pathname: `${match.url}/${automationTemplate.id}`,
			search: `name=${encodeURIComponent(automationTemplate.name)}`,
		});
	};

	const AUTOMATION_STEPS_LENGTH = automationTemplate.publishedStepReferences?.length || 0;

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} automation-reporting-card ${className || ''}`}>
			<button className={css(baseStyleSheet.truncateText, styleSheet.name)} onClick={onClick}>
				<span>{automationTemplate.name}</span>
			</button>
			{automationTemplate?.scope !== Api.TemplateScope.Industry && (
				<div className={css(styleSheet.splitSection)}>
					<div className={css(styleSheet.sectionInner, styleSheet.sectionInnerReporting)}>
						<div className={css(styleSheet.sectionLabel, styleSheet.sectionLabelReporting)}>Creator:</div>
						<div
							className={css(
								baseStyleSheet.horizontalStack,
								baseStyleSheet.truncateText,
								styleSheet.statusSectionContent,
								styleSheet.statusSectionContentReporting
							)}
						>
							{Api.VmUtils.getDisplayName(automationTemplate?.creator, true)}
						</div>
					</div>
				</div>
			)}

			<AutomationTrigger trigger={trigger} triggerDescription={triggerDescription} />

			<div className={css(styleSheet.section)}>
				<div className={css(styleSheet.sectionLabel)}>
					{`${AUTOMATION_STEPS_LENGTH} Step${AUTOMATION_STEPS_LENGTH > 1 ? 's' : ''} - ${
						automationTemplate.runtimeDays
					}-Day Runtime`}
				</div>
				<div className={css(baseStyleSheet.horizontalStack)}>
					<AutomationSteps automationTemplate={automationTemplate} maxStepsToDisplay={maxStepsToDisplay} />
				</div>
			</div>
			{automationTemplate?.scope !== Api.TemplateScope.Industry && (
				<AutomationProgressStats
					automationId={automationId}
					showCompletedButton={showCompletedButton}
					showInProgressButton={showInProgressButton}
					completedContactString={completedContactString}
					inProgressContactString={inProgressContactString}
				/>
			)}
			<footer className={css(styleSheet.section, styleSheet.publishButtonContainer)}>
				<button className={css(baseStyleSheet.ctaButtonReverse, styleSheet.SeeReport)} onClick={onClick}>
					<span>See Report</span>
				</button>
			</footer>
			<AutomationYoloModeIndicator trigger={trigger} />

			{automationTemplate.isBusy ||
				(automationTemplate.cancelling && (
					<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />
				))}
		</div>
	);
};

const AutomationReportingCardAsObserver = observer(_AutomationReportingCard);
const AutomationReportingCardWithRouter = withRouter(AutomationReportingCardAsObserver);
export const AutomationReportingCard = withEventLogging(AutomationReportingCardWithRouter, 'AutomationReportingCard');
