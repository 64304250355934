import { StyleSheet } from 'aphrodite';
import { borderColor, brandPrimaryHoverTint, white } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';

const borderStyle = `1px solid ${borderColor}`;

export const styleSheet = StyleSheet.create({
	// Base table styles
	table: {
		alignItems: 'stretch',
		border: borderStyle,
		borderRadius: 10,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.1)',
		boxSizing: 'border-box',
		width: '100%',
		borderSpacing: 0,
	},

	// Header styles
	headerCell: {
		color: '#AAAAAA',
		fontSize: 11,
		letterSpacing: '1.1px',
		textTransform: 'uppercase',
		padding: 10,
	},

	headerCellSticky: {
		position: 'sticky',
		top: 0,
		left: 0,
		background: brandPrimaryHoverTint,
		whiteSpace: 'nowrap',
	},
	headerCellFirst: {
		borderTopLeftRadius: 10,
	},
	headerCellLast: {
		borderTopRightRadius: 10,
	},
	headerCellCentered: {
		textAlign: 'center',
		verticalAlign: 'middle',
	},
	row: {
		...CssSelectors.allChildren(
			{
				paddingLeft: 20,
			},
			':first-child'
		),
		...CssSelectors.allChildren(
			{
				paddingRight: 20,
			},
			':last-child'
		),
	},

	rowSticky: {
		position: 'sticky',
		top: 0,
		zIndex: 2,
		background: brandPrimaryHoverTint,
		boxShadow: '0 0 6px rgba(0,0,0,0.25)',
	},

	// Cell styles
	cell: {
		borderTop: borderStyle,
		padding: '16px 10px 16px 10px',
	},

	cellBorderless: {
		padding: '16px 10px 16px 10px',
	},

	cellSticky: {
		position: 'sticky',
		top: 0,
		left: 0,
		background: white,
		whiteSpace: 'nowrap',
		':before': {
			content: '""',
			position: 'absolute',
			top: 0,
			right: 0,
			width: 1,
			height: '100%',
			background: borderColor,
			boxShadow: '0 0 6px rgba(0,0,0,0.25)',
		},
		':after': {
			content: '""',
			position: 'absolute',
			top: 0,
			right: 0,
			width: 1,
			height: '100%',
			background: borderColor,
			boxShadow: '0 0 6px rgba(0,0,0,0.25)',
		},
	},

	cellCentered: {
		textAlign: 'center',
		verticalAlign: 'middle',
	},

	stackedCell: {
		display: 'flex',
		flexDirection: 'column',
	},

	// Additional utility styles
	fullHeight: {
		height: '100%',
	},
	headerRow: {
		background: brandPrimaryHoverTint,
	},
});
