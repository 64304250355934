import { StyleDeclarationValue } from 'aphrodite';
import * as React from 'react';

export interface IMainContainerBackgroundContext {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export interface IMainContainerBackgroundContextSetters {
	setClassName?(className?: string): void;
	setStyles?(styles?: StyleDeclarationValue[]): void;
}

export const MainContainerBackgroundContext = React.createContext<IMainContainerBackgroundContext>(null);
export const MainContainerBackgroundSettersContext = React.createContext<IMainContainerBackgroundContextSetters>(null);
