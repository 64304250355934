import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { ContactViewModel, TagAlertViewModel } from '../../../../../viewmodels/AppViewModels';
import { brandPrimaryHover, mention } from '../../../../styles/colors';
import { ClockAlertIcon } from '../../../svgs/icons/ClockAlertIcon';
import { TagCheckIcon } from '../../../svgs/icons/TagCheckIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	contactsWithKitsDue?: ContactViewModel[];
	count?: number;
	followUpCount?: number;
	hideCheckIcon?: boolean;
	isBirthday?: boolean;
	isFollowUp?: boolean;
	isSelected?: boolean;
	onClick?(): void;
	styles?: StyleDeclarationValue[];
	tagAlert?: TagAlertViewModel;
	value?: string;
}
export class EmailGuideTag extends React.PureComponent<IProps> {
	private get isAlertTag() {
		const { tagAlert, contactsWithKitsDue } = this.props;

		return !!tagAlert || contactsWithKitsDue?.length > 0;
	}

	private get containerClassName() {
		const { isSelected, isBirthday, styles, isFollowUp } = this.props;
		// note: order matters here
		const appliedStyles: StyleDeclarationValue[] = [styleSheet.container];
		if (isBirthday) {
			appliedStyles.push(styleSheet.birthday);
		} else if (this.isAlertTag) {
			appliedStyles.push(styleSheet.containerWithTagAlert);
		} else if (isFollowUp) {
			appliedStyles.push(styleSheet.containerFollowUp);
		}

		if (isSelected) {
			appliedStyles.push(styleSheet.containerSelected);
		}

		return css(...appliedStyles, ...(styles || []));
	}

	private get countClassName() {
		const { isSelected, isBirthday, isFollowUp } = this.props;
		const styles: StyleDeclarationValue[] = [styleSheet.count];
		if (isBirthday) {
			styles.push(styleSheet.birthdayCount);
		}
		if (isFollowUp) {
			styles.push(styleSheet.isFollowUp);
		}
		if (isSelected) {
			styles.push(styleSheet.countSelected);
		}
		return css(styles);
	}

	private get count() {
		const { count, contactsWithKitsDue } = this.props;

		return count !== null && count !== undefined
			? count
			: !!contactsWithKitsDue && contactsWithKitsDue.length > 0
				? contactsWithKitsDue.length
				: null;
	}

	private get followUpCount() {
		const { followUpCount } = this.props;

		return !isNaN(followUpCount) ? followUpCount : 0;
	}

	public render() {
		const { className, value, isSelected, onClick, children, hideCheckIcon, tagAlert, isFollowUp } = this.props;
		const count = this.count;
		const followUpCount = this.followUpCount;
		const tag = tagAlert?.tagValue || value;
		return (
			<div className={`${this.containerClassName} email-guide-tag ${className || ''}`} onClick={onClick}>
				{!hideCheckIcon && !isFollowUp && (
					<TagCheckIcon className={css(styleSheet.check)} checkFillColor={isSelected ? brandPrimaryHover : mention} />
				)}
				{!!tag && <div className={css(styleSheet.title)}>{tag}</div>}
				{!!count || (isFollowUp && <div className={this.countClassName}>{isFollowUp ? followUpCount : count}</div>)}
				{!!this.isAlertTag && !isSelected && <ClockAlertIcon className={css(styleSheet.clock)} />}
				{children}
			</div>
		);
	}
}
