import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { getSocialMediaPlatformLogoUrl } from '../../../../models/UiUtils';
import { useLambda } from '../../../../models/hooks/useLambda';
import { baseStyleSheet } from '../../../styles/styles';
import { PopoverType, TinyPopover } from '../../TinyPopover';
import { CancelIconFill } from '../../svgs/icons/CancelIconFill';
import { SuccessCheckIcon } from '../../svgs/icons/SuccessCheckIcon';
import { styleSheet } from './styles';

interface ISocialMediaStatusIndicatorsProps {
	postStatus?: Api.PostStatus;
	postTarget: Api.IPostTarget;
	showTargetProvider?: boolean;
}

export const SocialMediaPostTargetStatusIndicators: React.FC<ISocialMediaStatusIndicatorsProps> = props => {
	const { postTarget, showTargetProvider = false, postStatus } = props;
	const [showFailedPopover, , setShowFailedPopover] = useLambda(false);
	if (!postTarget) {
		return null;
	}

	if (
		postStatus === Api.PostStatus.Cancelled ||
		postStatus === Api.PostStatus.Draft ||
		postStatus === Api.PostStatus.Scheduled ||
		postStatus === Api.PostStatus.Pending
	) {
		return (
			<div className={css(styleSheet.basicContainer)}>
				{!showTargetProvider && (postStatus === Api.PostStatus.Cancelled || postStatus === Api.PostStatus.Draft) && (
					<img
						className={css(styleSheet.iconRow, styleSheet.iconRowBasic)}
						src={getSocialMediaPlatformLogoUrl(postTarget.provider)}
					/>
				)}
				{postStatus === Api.PostStatus.Cancelled ||
				postTarget.state?.lastConnectionState?.state !== Api.SocialMediaConnectionState.Connected ||
				!postTarget.state?.lastConnectionState?.postTargetId ? (
					<TinyPopover
						align='end'
						anchor={
							<div
								onMouseEnter={setShowFailedPopover(true)}
								onMouseLeave={setShowFailedPopover(false)}
								className={css(baseStyleSheet.flex)}
							>
								{!showTargetProvider &&
									(postStatus === Api.PostStatus.Cancelled || postStatus === Api.PostStatus.Draft) && (
										<span className={css(styleSheet.nameAdjustment)}>{postTarget?.provider}</span>
									)}
								<CancelIconFill />
							</div>
						}
						styles={[styleSheet.popoverMargin]}
						dismissOnOutsideAction={true}
						isOpen={showFailedPopover}
						placement={['right', 'top', 'left', 'bottom']}
						type={PopoverType.errorSecondary}
					>
						<div className={css(styleSheet.popover)}>
							{postTarget.state?.lastErrorMessage
								? postTarget.state.lastErrorMessage
								: postStatus === Api.PostStatus.Cancelled
									? 'Cancelled'
									: 'Disconnected'}{' '}
						</div>
					</TinyPopover>
				) : (
					<>
						{!showTargetProvider && <span className={css(styleSheet.nameAdjustment)}>{postTarget.provider}</span>}
						<SuccessCheckIcon fillColor='#89C947' />
					</>
				)}
			</div>
		);
	}

	const postTargetStatus = postTarget.state?.status;
	switch (postTargetStatus) {
		case Api.PostTargetStatus.Posted: {
			return (
				<div className={css(styleSheet.basicContainer)}>
					{!showTargetProvider && (
						<>
							<img
								className={css(styleSheet.iconRow, styleSheet.iconRowBasic)}
								src={getSocialMediaPlatformLogoUrl(postTarget.provider)}
							/>
							<span className={css(styleSheet.nameAdjustment)}>{postTarget.provider}</span>
						</>
					)}
					<SuccessCheckIcon fillColor='#89C947' />
				</div>
			);
		}
		case Api.PostTargetStatus.Failed: {
			const failMessage = postTarget.state?.lastErrorMessage?.replace(/:/g, ': ') || 'Failed to post';
			return (
				<div className={css(styleSheet.basicContainer)}>
					{!showTargetProvider && (
						<img
							className={css(styleSheet.iconRow, styleSheet.iconRowBasic)}
							src={getSocialMediaPlatformLogoUrl(postTarget.provider)}
						/>
					)}
					<TinyPopover
						align='end'
						anchor={
							<div
								onMouseEnter={setShowFailedPopover(true)}
								onMouseLeave={setShowFailedPopover(false)}
								className={css(baseStyleSheet.flex)}
							>
								{!showTargetProvider && <span className={css(styleSheet.nameAdjustment)}>{postTarget.provider}</span>}
								<CancelIconFill />
							</div>
						}
						styles={[styleSheet.popoverMargin]}
						dismissOnOutsideAction={true}
						isOpen={showFailedPopover}
						placement={['right', 'top', 'left', 'bottom']}
						type={PopoverType.errorSecondary}
					>
						<p className={css(styleSheet.popover)}>{failMessage} </p>
					</TinyPopover>
				</div>
			);
		}
		default: {
			return null as JSX.Element;
		}
	}
};
