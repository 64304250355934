import { css, StyleDeclarationValue } from 'aphrodite';
import * as React from 'react';
import { styleSheet } from './styles';

interface IDataGridProps {
	children: React.ReactNode;
	styles?: StyleDeclarationValue[];
}

export const DataGrid: React.FC<IDataGridProps> = ({ children, styles = [] }) => {
	return (
		<table className={css(styleSheet.table, styles)} role='data-grid'>
			{children}
		</table>
	);
};

interface IDataGridColumnProps {
	children?: React.ReactNode;
	styles?: StyleDeclarationValue[];
	title?: string;
	sticky?: boolean;
}

export const DataGridColumn: React.FC<IDataGridColumnProps> = ({ children, styles = [], title, sticky = false }) => {
	const thRef = React.useRef<HTMLTableHeaderCellElement>(null);
	const [isFirstColumn, setIsFirstColumn] = React.useState(false);
	const [isLastColumn, setIsLastColumn] = React.useState(false);

	React.useEffect(() => {
		if (thRef.current) {
			const parentRow = thRef.current.parentElement;
			if (parentRow) {
				const allColumns = Array.from(parentRow.children);
				setIsFirstColumn(allColumns[0] === thRef.current);
				setIsLastColumn(allColumns[allColumns.length - 1] === thRef.current);
			}
		}
	}, []);

	return (
		<th
			ref={thRef}
			className={css(
				styleSheet.headerCell,
				sticky ? styleSheet.headerCellSticky : null,
				isFirstColumn ? styleSheet.headerCellFirst : null,
				isLastColumn ? styleSheet.headerCellLast : null,
				styles
			)}
			role='data-grid-column'
			title={title}
		>
			{children}
		</th>
	);
};

interface IDataGridColumnsProps {
	children: React.ReactNode;
	styles?: StyleDeclarationValue[];
}

export const DataGridColumns: React.FC<IDataGridColumnsProps> = ({ children, styles = [] }) => {
	return (
		<thead className={css(styles)} role='data-grid-columns'>
			<tr className={css(styleSheet.headerRow, styleSheet.row)} role='data-grid-column'>
				{children}
			</tr>
		</thead>
	);
};

interface IDataGridRowProps {
	children: React.ReactNode;
	styles?: StyleDeclarationValue[];
}

export const DataGridRow: React.FC<IDataGridRowProps> = ({ children, styles = [] }) => {
	return (
		<tr className={css(styleSheet.row, styles)} role='data-grid-row'>
			{children}
		</tr>
	);
};

interface IDataGridRowItemProps {
	children?: React.ReactNode;
	styles?: StyleDeclarationValue[];
	colSpan?: number;
	fullColSpan?: boolean;
	noTopBorder?: boolean;
}

export const DataGridRowItem: React.FC<IDataGridRowItemProps> = ({
	children,
	styles = [],
	colSpan,
	fullColSpan,
	noTopBorder,
}) => {
	if (colSpan !== undefined && fullColSpan !== undefined)
		console.warn('Both colSpan and fullColSpan are defined, relying on colSpan');

	return (
		<td
			className={css(noTopBorder ? styleSheet.cellBorderless : styleSheet.cell, styles)}
			colSpan={colSpan ? colSpan : fullColSpan ? 100 : null}
			role='data-grid-row-item'
		>
			{children}
		</td>
	);
};

interface IDataGridRowsProps {
	children: React.ReactNode;
	styles?: StyleDeclarationValue[];
}

export const DataGridRows: React.FC<IDataGridRowsProps> = ({ children, styles = [] }) => {
	return (
		<tbody className={css(styles)} role='data-grid-rows'>
			{children}
		</tbody>
	);
};
