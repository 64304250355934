import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { bs } from '../../../../styles/styles';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../HoverCard';
import { Tag } from '../../../tags/Tag';
import { styleSheet } from './styles';

/**
 *
 * Accepts a contactFilter and returns a list of tag strings grouped by filter operator(And, Or, Not).
 */
const getTagGroupingFromContactFilterRequest = (filterRequest: Api.ContactFilter) => {
	const groupedTags = {
		[Api.FilterOperator.And]: [],
		[Api.FilterOperator.Or]: [],
		[Api.FilterOperator.Not]: [],
	} as Record<Api.FilterOperator, string[]>;
	for (const criteria of filterRequest.criteria) {
		if (criteria.criteria?.length) {
			for (const subCriteria of criteria.criteria) {
				if (subCriteria.property === Api.ContactFilterCriteriaProperty.Tag && subCriteria.value) {
					groupedTags[criteria.op ?? filterRequest.op].push(subCriteria.value);
				}
			}
		}
		if (criteria.property === Api.ContactFilterCriteriaProperty.Tag && criteria.value) {
			groupedTags[criteria.op ?? filterRequest.op ?? Api.FilterOperator.And].push(criteria.value);
		}
	}
	return groupedTags;
};

function tagWithOperator(tag: string, operator: Api.FilterOperator) {
	return { tag, operator };
}

export const ContactFilterRequestTagList = ({
	filterRequest,
	maxTagsToDisplayInline,
	renderTag: renderTagProp,
	renderOverflowTag: renderOverflowTagProp,
}: {
	filterRequest: Api.ContactFilter;
	maxTagsToDisplayInline?: number;
	renderTag?: (tag: string, operator: Api.FilterOperator, index: number) => React.ReactNode;
	renderOverflowTag?: (tag: string, operator: Api.FilterOperator, index: number) => React.ReactNode;
}) => {
	const operatorGroupedTags = getTagGroupingFromContactFilterRequest(filterRequest);
	operatorGroupedTags.Not = operatorGroupedTags.Not.filter(tag => !Api.DefaultBulkSendExcludedTags.has(tag));
	const allTags = [
		operatorGroupedTags.Or.map(tag => tagWithOperator(tag, Api.FilterOperator.Or)),
		operatorGroupedTags.And.map(tag => tagWithOperator(tag, Api.FilterOperator.And)),
		operatorGroupedTags.Not.map(tag => tagWithOperator(tag, Api.FilterOperator.Not)),
	].flat();
	let inlineTags = allTags;
	let overflowTags: typeof allTags = [];

	if (maxTagsToDisplayInline != null) {
		inlineTags = allTags.slice(0, maxTagsToDisplayInline);
		overflowTags = allTags.slice(maxTagsToDisplayInline);
	}

	const renderTag = (tag: string, op: Api.FilterOperator, index: number) => {
		if (renderTagProp) {
			return renderTagProp(tag, op, index);
		}
		return (
			<div className={css(styleSheet.tagContainer)}>
				{index > 0 && <span className={css(styleSheet.tagTokenOpPrefix)}>{op.toLowerCase()}</span>}
				<Tag
					styles={[
						bs.truncateText,
						styleSheet.tagToken,
						op === Api.FilterOperator.Not ? styleSheet.tagTokenExclude : null,
					]}
					tagValue={tag}
				/>
			</div>
		);
	};
	const renderOverflowTag = (tag: string, op: Api.FilterOperator, index: number) => {
		if (renderOverflowTagProp) {
			return renderOverflowTagProp(tag, op, index);
		}
		return (
			<div className={css(styleSheet.overflowTagContainer)}>
				{index > 0 && <span className={css(styleSheet.tagTokenOpPrefix)}>{op.toLowerCase()}</span>}
				<Tag
					styles={[
						bs.truncateText,
						styleSheet.tagToken,
						op === Api.FilterOperator.Not ? styleSheet.tagTokenExclude : null,
					]}
					tagValue={tag}
				/>
			</div>
		);
	};

	return (
		<div className={css(styleSheet.tags)}>
			{inlineTags.map((tagWithOp, i) => {
				return renderTag(tagWithOp.tag, tagWithOp.operator, i);
			})}
			{overflowTags.length > 0 ? (
				<HoverCard openDelay={0}>
					<HoverCardTrigger className={css(bs.flexCenter)}>
						<span className={css(bs.brandLink, styleSheet.moreTags)}>
							+ {allTags.length - maxTagsToDisplayInline} more
						</span>
						&nbsp;
					</HoverCardTrigger>
					<HoverCardContent styles={[styleSheet.extraTags]}>
						{overflowTags.map((tagWithOp, i) => {
							return renderOverflowTag(tagWithOp.tag, tagWithOp.operator, i + maxTagsToDisplayInline);
						})}
					</HoverCardContent>
				</HoverCard>
			) : null}
		</div>
	);
};
