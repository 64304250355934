import {
	AttachmentsToBeUploadedViewModel,
	EmailMessageViewModel,
	IOperationResultNoValue,
	IRichContentEditorState,
	ITemplate,
	TemplateScope,
	TemplateType,
	TemplatesViewModel,
	VmUtils,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../../models/Logging';
import { FirstNamePlaceholder, Token } from '../../../../../models/Token';
import { baseStyleSheet } from '../../../../styles/styles';
import { CompoundButton, CompoundButtonType } from '../../../CompoundButton';
import { Modal } from '../../../Modal';
import { SaveTemplate } from '../../../SaveTemplate';
import { styleSheet } from './styles';

export interface IEmailMessageTemplateSaveButtonProps
	extends IUserSessionComponentProps,
		IEventLoggingComponentProps,
		IErrorMessageComponentProps,
		IToasterComponentProps {
	canImpersonateOnTemplateSave?: boolean;
	className?: string;
	editorState?: IRichContentEditorState;
	emailMessage?: EmailMessageViewModel;
	onTemplateSaved?(template: ITemplate): void;
	styles?: StyleDeclarationValue[];
	subject?: string;
	template?: ITemplate;
	templates?: TemplatesViewModel;
}

interface IState {
	editorState?: IRichContentEditorState;
	saved?: boolean;
	savedTemplate?: ITemplate;
	saving?: boolean;
	showingSaveModal?: boolean;
	templates?: TemplatesViewModel;
	templateSaveCount?: number;
	templateToSave?: ITemplate;
}

class _EmailMessageTemplateSaveButton extends React.Component<IEmailMessageTemplateSaveButtonProps, IState> {
	public constructor(props: IEmailMessageTemplateSaveButtonProps) {
		super(props);
		const { userSession, editorState, template } = props;

		const templates = props.templates || new TemplatesViewModel(userSession);
		this.state = {
			editorState,
			savedTemplate: template,
			templates,
			templateSaveCount: 0,
		};
	}

	public static getDerivedStateFromProps(props: IEmailMessageTemplateSaveButtonProps, state: IState) {
		const nextState: IState = {};
		if (props.editorState !== state.editorState) {
			nextState.editorState = props.editorState;
			nextState.saved = false;
		}

		if (props.template !== state.savedTemplate) {
			if (!!state.savedTemplate && !!props.template) {
				nextState.templateSaveCount = state.savedTemplate.id !== props.template.id ? 0 : state.templateSaveCount + 1;
			}
			nextState.savedTemplate = props.template;
			nextState.saved = false;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	public render() {
		const { canImpersonateOnTemplateSave, className, styles } = this.props;
		const { templateToSave, showingSaveModal } = this.state;

		const saveAsButton = (
			<button
				className={css(baseStyleSheet.ctaButtonReverseSmall, styleSheet.saveAsButton)}
				onClick={this.onSaveAsButtonClicked}
				disabled={this.isSavingDisabled}
			>
				<span>Save as</span>
			</button>
		);
		return (
			<div
				className={`${css(styleSheet.container, ...(styles || []))} email-message-template-save-button ${
					className || ''
				}`}
			>
				{this.allowSaveOverwrite ? (
					<CompoundButton
						buttonTitle={<span>Save</span>}
						disabled={this.isSavingDisabled}
						kind={CompoundButtonType.CtaPrimary}
						onClick={this.onSaveButtonClicked}
						openDirection='down'
						styleDeclaration={styleSheet.saveButton}
					>
						{saveAsButton}
					</CompoundButton>
				) : (
					saveAsButton
				)}
				<Modal
					isOpen={showingSaveModal}
					onRequestClose={() => this.onSaveModalRequestClose(null, true)}
					useDefaultHeader
					className={css(styleSheet.saveTemplateModal)}
					shouldCloseOnOverlayClick={false}
				>
					{showingSaveModal ? (
						<SaveTemplate
							canImpersonateOnTemplateSave={canImpersonateOnTemplateSave}
							template={templateToSave}
							onCancel={() => this.onSaveModalRequestClose(null, true)}
							onSuccess={template => this.onSaveModalRequestClose(template, false)}
						/>
					) : null}
				</Modal>
			</div>
		);
	}

	@computed
	public get isSavingDisabled() {
		const { templates, saving, editorState } = this.state;

		return saving || !!templates.emailTemplates.isFetching || !editorState || !editorState.hasContent();
	}

	@computed
	public get allowSaveOverwrite() {
		const { userSession } = this.props;
		const { savedTemplate } = this.state;

		if (!savedTemplate) {
			return false;
		}

		const isUserAuthor = savedTemplate?.creator?.id === userSession?.user?.id;

		const isUserAdmin = userSession.userRole === 'admin' || userSession.userRole === 'superAdmin';
		const isValidScope = savedTemplate?.scope === TemplateScope.User || savedTemplate?.scope === TemplateScope.Account;

		return isValidScope && (isUserAuthor || isUserAdmin);
	}

	private onSaveButtonClicked = () => {
		const templateToSave = this.getNextTemplateToSave();
		if (!templateToSave) {
			return;
		}
		const { templates } = this.state;
		const { logInput, logEvent } = this.props;

		const promise = templates.update(templateToSave);
		if (promise) {
			logInput('SaveLatestChanges', 'Click');
			this.setState({
				saving: true,
			});
			promise
				.then(savedTemplate => {
					this.onSaveModalRequestClose(savedTemplate, false);
					this.setState({
						saving: false,
					});
				})
				.catch((error: IOperationResultNoValue) => {
					this.setState({
						saving: false,
					});

					logEvent('SaveLatestChanges-Error', { ...error });
				});
		}
	};

	private onSaveAsButtonClicked = () => {
		const { logInput, errorMessages } = this.props;
		const templateToSave = this.getNextTemplateToSave();

		templateToSave.schedule = {};
		if (!templateToSave) {
			return;
		}

		const content = templateToSave.content;

		if (!Token.isFirstNameTokenFormattedCorrectlyLoose(content)) {
			errorMessages.push({
				messages: [
					`The first name token does not appear to be formatted properly. It should be ${FirstNamePlaceholder.symbol}.`,
				],
			});
			return;
		}

		logInput('SaveAsTemplate', 'Click');

		this.setState({
			showingSaveModal: true,
			templateToSave,
		});
	};

	private onSaveModalRequestClose = async (savedTemplate: ITemplate, cancel?: boolean) => {
		const nextState: IState = {
			showingSaveModal: false,

			templateToSave: null,
		};

		if (cancel || !savedTemplate) {
			this.setState(nextState);
			return;
		}
		this.props.templates.emailTemplates.fetchResults.splice(0, 0, savedTemplate);
		const { onTemplateSaved, emailMessage, templates, userSession, toaster } = this.props;
		if (onTemplateSaved) {
			try {
				// Check for attachments to remove
				const attachmentsToRemove = savedTemplate?.attachments?.filter(alreadySaved => {
					const stillValid = emailMessage.savedAttachments?.find(current => alreadySaved.id === current.id);
					if (!stillValid) {
						return alreadySaved;
					}
				});

				if (attachmentsToRemove?.length > 0) {
					const result = await templates.removeAttachments(
						savedTemplate,

						attachmentsToRemove.map(x => x.id)
					);
					savedTemplate = result;

					emailMessage?.setSavedAttachments(result.attachments);
					nextState.saved = true;
				}

				// Check for attachments to add

				if (emailMessage?.attachments?.attachments?.length > 0) {
					const attachmentsToAdd = emailMessage?.attachments as AttachmentsToBeUploadedViewModel<File>;

					const result = await templates.addAttachment(savedTemplate, attachmentsToAdd);
					emailMessage?.setAttachments(
						new AttachmentsToBeUploadedViewModel<File>(
							null,

							VmUtils.getMaxAttachmentByteCountForEmailConfigurations(userSession?.account?.emailProviderConfiguration)
						)
					);

					emailMessage?.setSavedAttachments(result.attachments);
					savedTemplate = result;
					nextState.saved = true;
				}
				this.setState(nextState);
				onTemplateSaved(savedTemplate);

				toaster.push({
					message: `Template "${savedTemplate.name}" saved to "My Templates" section`,
					type: 'successMessage',
				});
			} catch (err) {
				const { errorMessages, logApiError } = this.props;

				logApiError('TemplateUpdateFailed', err);

				errorMessages.pushApiError(err);
				this.setState(nextState);
			}
		} else {
			nextState.savedTemplate = savedTemplate;
			if (!!savedTemplate && !!this.state.savedTemplate) {
				nextState.templateSaveCount =
					savedTemplate.id !== this.state.savedTemplate.id ? 0 : nextState.templateSaveCount + 1;
			}
			nextState.saved = true;
			this.setState(nextState);
		}
	};

	private getNextTemplateToSave = () => {
		const { subject } = this.props;
		const { savedTemplate, editorState } = this.state;
		if (!editorState) {
			return null;
		}
		const templateToSave: ITemplate = {
			...(savedTemplate || {}),
			content: editorState.getRawRichTextContent(),
			subject,
			templateType: TemplateType.Email,
		};
		return templateToSave;
	};
}

const EmailMessageTemplateSaveButtonAsObserver = observer(_EmailMessageTemplateSaveButton);
const EmailMessageTemplateSaveButtonWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ToasterViewModelKey
)(EmailMessageTemplateSaveButtonAsObserver);
export const EmailMessageTemplateSaveButton = withEventLogging(
	EmailMessageTemplateSaveButtonWithContext,
	'EmailMessageTemplateSaveButton'
);
