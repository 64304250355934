import {
	invalidateIntegrationsQuery,
	useGetExternalIntegrationsQuery,
	usePostExternalIntegrationEnableMutation,
} from '@Queries/index';
import { baseStyleSheet, bs } from '@Styles/styles';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAdminUserSession } from '../../../../models/hooks/adminStateHooks';
import { useErrorMessages, useToaster } from '../../../../models/hooks/appStateHooks';
import { AdminPermission, AdminUserSessionContext } from '../../../../viewmodels/AdminViewModels';
import microsoftDynamics365SalesLogo from '../../../assets/logo_microsoft_dynamics_sales_banner.png';
import practicePantherLogo from '../../../assets/practice-panther-logo-sm.png';
import { Button } from '../../Button';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { LoadingSpinner } from '../../LoadingSpinner';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { IntegrationCard } from '../IntegrationCard';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	impersonationContext?: Api.IImpersonationContext;
	styles?: StyleDeclarationValue[];
}

const AvailableExternalIntegrationWithIndustries = [
	{ provider: Api.IntegrationProvider.PracticePanther, industry: Api.Industry.Legal },
];

const accountCleanUpModalOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isDestructive: true,
		representedObject: true,
		title: 'Delete',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

const needAccountResetModalOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isCancel: true,
		representedObject: false,
		title: 'OK',
	},
];

export const ExternalIntegrations = ({ className, styles, impersonationContext }: IProps) => {
	const toaster = useToaster();
	const match = useRouteMatch();
	const userSession = useAdminUserSession();
	const errorMessages = useErrorMessages();

	const hasOnlyForDevelopersPermission = userSession.hasPermission(AdminPermission.OnlyForDevelopers);

	const [isAccountCleanUpModalOpen, setIsAccountCleanUpModalOpen] = React.useState(false);
	const [isNeedResetModalOpen, setIsNeedResetModalOpen] = React.useState(false);
	const [modalIntegrationProvider, setModalIntegrationProvider] = React.useState(undefined);

	const getExternalIntegrationsQuery = useGetExternalIntegrationsQuery({
		impersonationContext,
	});

	const externalIntegrations = React.useMemo(() => {
		const userIndustry = impersonationContext
			? impersonationContext.account?.additionalInfo?.industry
			: userSession.account?.additionalInfo?.industry;

		const alwaysAvailableExternalIntegrations = [];
		if (hasOnlyForDevelopersPermission) {
			alwaysAvailableExternalIntegrations.push({ provider: Api.IntegrationProvider.MicrosoftDynamics365Sales });
		}

		const availableIntegrations = [
			...AvailableExternalIntegrationWithIndustries.filter(
				availableIntegration => availableIntegration.industry === userIndustry
			),
			...alwaysAvailableExternalIntegrations,
		];

		return availableIntegrations.map(availableIntegration => {
			const existingIntegration = getExternalIntegrationsQuery.data?.find(
				i => i.provider === availableIntegration.provider
			);
			return {
				...existingIntegration,
				provider: availableIntegration.provider,
				enabled: existingIntegration ? existingIntegration.enabled : false,
			};
		});
	}, [getExternalIntegrationsQuery.data, userSession.account, impersonationContext, hasOnlyForDevelopersPermission]);

	const enableIntegrationMutation = usePostExternalIntegrationEnableMutation({
		impersonationContext,
		onSuccess: () => {
			toaster.push({
				message: 'Integration status updated successfully',
				type: 'successMessage',
			});
			invalidateIntegrationsQuery({
				impersonationContext,
			});
		},
	});

	const handleEnableToggle = async (integration: Api.IExternalIntegration) => {
		const enabled = !integration.enabled;

		if (enabled === true) {
			const enableAbility = await userSession.webServiceHelper.callAsync<string>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `account/integrations/${integration.provider}/enableAbility`,
				}),
				'GET'
			);

			if (enableAbility === Api.EnableAbility.CleanUpRequired) {
				const adminUserSession = userSession as AdminUserSessionContext;
				const hasResetAccountPermission = !!adminUserSession.hasPermission(AdminPermission.ResetAccount);
				if (hasResetAccountPermission) {
					setIsAccountCleanUpModalOpen(true);
					setModalIntegrationProvider(integration.provider);
				} else {
					setIsNeedResetModalOpen(true);
				}
			} else if (enableAbility === Api.EnableAbility.CannotEnable) {
				toaster.push({
					message: `There is already an enabled integration on this account.`,
					type: 'errorMessage',
				});
			} else {
				enableIntegrationMutation.mutate({
					integrationProvider: integration.provider,
					enabled,
				});
			}
		} else {
			enableIntegrationMutation.mutate({
				integrationProvider: integration.provider,
				enabled,
			});
		}
	};

	const onAccountCleanUpModalConfirmationDialogRequestClose = (
		result?: IConfirmationDialogOption<boolean>,
		canceled?: boolean
	) => {
		if (!!result?.isDestructive && !canceled) {
			userSession.webServiceHelper
				.callAsync<Api.IAccount>(`admin/account/${userSession.account.id}/reset?includeIntegrations=true`, 'POST')
				?.then(() => {
					enableIntegrationMutation.mutate({
						integrationProvider: modalIntegrationProvider,
						enabled: true,
					});
				})
				?.catch((error: Api.IOperationResultNoValue) => {
					errorMessages.pushApiError(error);
				});
		}

		setIsAccountCleanUpModalOpen(false);
		setModalIntegrationProvider(undefined);
	};

	const getIconUrl = (provider: Api.IntegrationProvider) => {
		switch (provider) {
			case Api.IntegrationProvider.PracticePanther:
				return practicePantherLogo;
			case Api.IntegrationProvider.MicrosoftDynamics365Sales:
				return microsoftDynamics365SalesLogo;
			default:
				return '';
		}
	};

	const getStatus = (integration: Api.IExternalIntegration) => {
		if (!integration?.configuration) {
			return 'Needs Configuration';
		}
		if (integration.enabled) {
			if (integration.configuration.needsConfiguration) {
				return 'Needs Configuration';
			}
			return 'Enabled';
		}
		return 'Disabled';
	};

	if (getExternalIntegrationsQuery.isLoading) {
		return <LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} />;
	}

	const isAdmin = impersonationContext;

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<div className={css(styleSheet.cardsGrid)}>
				{externalIntegrations?.map(integration => {
					const manageUrl = impersonationContext
						? `#/accounts/${impersonationContext.account.id}/integrations/${integration.provider}`
						: `/#${match.path}/${integration.provider}`;

					return (
						<IntegrationCard
							key={integration.provider}
							icon={
								<img
									src={getIconUrl(integration.provider)}
									alt={`${integration.provider} logo`}
									className={css(styleSheet.icon)}
								/>
							}
							enabled={integration.enabled}
							canManage
							onRenderCtaButton={() => (
								<div className={css(styleSheet.buttonContainer)}>
									{integration.enabled ? (
										<a href={manageUrl} className={css(bs.ctaButtonSmall, bs.noUnderline)}>
											Manage
										</a>
									) : null}
									{isAdmin ? (
										<Button
											kind='reverse'
											size='small'
											onClick={() => handleEnableToggle(integration)}
											disabled={enableIntegrationMutation.isLoading}
										>
											{integration.enabled ? 'Disable' : 'Enable'}
										</Button>
									) : null}
								</div>
							)}
							status={<div>{getStatus(integration)}</div>}
						/>
					);
				})}
			</div>
			<ConfirmationDialog
				icon={<WarningIcon />}
				modalProps={{
					isOpen: isAccountCleanUpModalOpen,
					onRequestClose: onAccountCleanUpModalConfirmationDialogRequestClose,
				}}
				options={accountCleanUpModalOptions}
				title='There were integrations previously enabled on this account.  To permanently delete all previous data including contacts, companies, tags, households, connection types, text messages, donations, and integration settings, click Delete.  Otherwise, click Cancel and contact Engineering for help.'
			/>
			<ConfirmationDialog
				icon={<WarningIcon />}
				modalProps={{
					isOpen: isNeedResetModalOpen,
					onRequestClose: () => setIsNeedResetModalOpen(false),
				}}
				options={needAccountResetModalOptions}
				title='There were integrations previously enabled on this account. Please contact Support or Engineering to request cleanup.'
			/>
		</div>
	);
};
