import { css } from 'aphrodite';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, MouseEventHandler } from 'react';
import { bs } from '../../../web/styles/styles';
import { styleSheet } from './styles';

export const Divider: FC = () => {
	return <div className={css(bs.flex, bs.wFull, styleSheet.borderBottom)} />;
};

export const ButtonBase: FC<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = ({
	children,
	onClick,
	...rest
}) => {
	const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
		e.preventDefault();
		e.stopPropagation();

		onClick?.(e);
	};
	return (
		<button onClick={handleClick} {...rest}>
			{children}
		</button>
	);
};
