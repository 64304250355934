import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { IContentCalendarSelectedSuggestion } from '../../../../../../models';
import { useUserSession } from '../../../../../../models/hooks/appStateHooks';
import { ISelectOption } from '../../../../../components/DeprecatedSelect';

export function useSocialMediaTargetOptions({
	user,
}: {
	user?: Api.IUser;
}) {
	const options = React.useMemo(() => {
		const facebookOptions: ISelectOption<Api.ISocialMediaConnection>[] = [];
		const instagramOptions: ISelectOption<Api.ISocialMediaConnection>[] = [];
		const linkedInOptions: ISelectOption<Api.ISocialMediaConnection>[] = [];

		user?.socialMediaConnectedAccounts?.forEach(connection => {
			const option: ISelectOption<Api.ISocialMediaConnection> = {
				text: connection.userName,
				id: uuid(),
				dataContext: connection,
			};
			switch (connection.type) {
				case Api.SocialMediaType.Facebook:
					facebookOptions.push(option);
					break;
				case Api.SocialMediaType.Instagram:
					instagramOptions.push(option);
					break;
				case Api.SocialMediaType.LinkedIn:
					linkedInOptions.push(option);
					break;
				default: {
					break;
				}
			}
		});

		return {
			facebookOptions,
			instagramOptions,
			linkedInOptions,
		};
	}, [user]);

	return options;
}

export type ScheduleSocialMediaSuggestionsParams = {
	forUserId?: string;
	impersonationContext?: Api.IImpersonationContext;
	sendWithCompliance?: boolean;
	suggestions: IContentCalendarSelectedSuggestion[];
	templates: Api.ITemplate[];
	designatedTargets: Api.IPostTarget[];
	sendWithComplianceEmail?: string;
};

export function useScheduleSocialMediaSuggestions({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (
		result: PromiseSettledResult<Api.ISocialMediaPost>[],
		params: ScheduleSocialMediaSuggestionsParams
	) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			designatedTargets,
			impersonationContext,
			forUserId,
			templates,
			sendWithCompliance,
			sendWithComplianceEmail,
			suggestions,
		}: ScheduleSocialMediaSuggestionsParams) => {
			return Promise.allSettled(
				suggestions.map(suggestion => {
					const template = templates.find(t => t.id === suggestion.templateReference.templateId);
					const request: Api.ICreateSocialMediaPostRequest = {
						sendWithComplianceEmail,
						designatedTargets,
						forUserId,
						name: template.name,
						content: template.content,
						templateReference: suggestion.templateReference,
						sendWithCompliance,
						attachments: template.attachments,
						dueDate: suggestion.schedule.startDate,
						overlayLogo: suggestion.overlayLogo || false,
					};

					return userSession.webServiceHelper.callAsync<Api.ISocialMediaPost>(
						Api.ImpersonationBroker.composeApiUrl({
							urlPath: 'social/post',
							queryParams: {
								contentCalendarSuggestionId: suggestion.id,
								forUserId,
							},
							impersonationContext,
						}),
						'POST',
						request
					);
				})
			);
		},
		onError,
		onSuccess,
	});
}
