import { css } from 'aphrodite';
import { FC, useEffect } from 'react';
import { CustomPageType } from '../../../extViewmodels';
import { useHostedBlogPagePreviewQuery } from '../../../queries';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { bs } from '../../../web/styles/styles';
import { HostedBlogPreview } from '../../containers/blogs/BlogEditor/HostedBlogPreview';
import { Column, Row } from '../Layout';
import { useAccountContext } from './AccountContext';
import { Divider } from './presentation';
import { styleSheet } from './styles';

interface HtmlPreviewFrameProps {
	type: CustomPageType;
	refreshPreview?: boolean;
}

export const HtmlPreviewFrame: FC<HtmlPreviewFrameProps> = ({ refreshPreview, type }) => {
	const { impersonationContext } = useAccountContext();
	const {
		data: html,
		isLoading,
		refetch,
	} = useHostedBlogPagePreviewQuery({
		page: type,
		impersonationContext,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (refreshPreview) {
			refetch();
		}
	});

	const { Footer, Header, BlogPost, BlogHome } = CustomPageType;

	let title: string;

	switch (type) {
		case BlogPost:
			title = 'Blog Post';
			break;
		case BlogHome:
			title = 'Home Page';
			break;
		case Header:
			title = 'Header';
			break;
		case Footer:
			title = 'Footer';
			break;

		default:
			title = 'Section';
			break;
	}

	return (
		<Column className={css(styleSheet.gap12)}>
			<h2 className={css(styleSheet.subtitleText)}>{`${title} Preview`}</h2>
			<Divider />
			{isLoading && (
				<Row className={css(bs.p7, bs.justifyCenter)}>
					<LoadingSpinner />
				</Row>
			)}
			{!!html && !isLoading && (
				<HostedBlogPreview
					className={css(styleSheet.previewFrame)}
					htmlContent={html}
					disableClicks
					scrolling='no'
					fullHeight
				/>
			)}
		</Column>
	);
};
