import { CampaignViewModel, EmailSendStatus, IEmailMessageFollowUpOptions, SendEmailFrom, VmUtils } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { useLocalNotificationService } from '../../../../models/LocalNotifications';
import { useEventLogging } from '../../../../models/Logging';
import {
	accountHasComplianceEnabled,
	accountHasComplianceRequired,
	getDefaultDateStringValue,
	isValidEmail,
} from '../../../../models/UiUtils';
import { useErrorMessages, useFullscreenModal, useToaster } from '../../../../models/hooks/appStateHooks';
import { SocialAccountCampaignViewModel } from '../../../../viewmodels/AdminViewModels';
import {
	ComposeEmailViewModel,
	IOperationResultNoValue,
	PostStatus,
	SocialMediaPostViewModel,
} from '../../../../viewmodels/AppViewModels';
import { brandPrimary } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { Button } from '../../Button';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { TextInput } from '../../TextInput';
import { TagEmailTagList } from '../../email/TagEmailTagList';
import { DoubleArrowRightIcon } from '../../svgs/icons/DoubleArrowRightIcon';
import { EmailIcon } from '../../svgs/icons/EmailIcon';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { ApprovalStatus, ScheduleBy, ScheduleDate } from './presentation';
import { styleSheet } from './styles';

export const lastUsedComplianceRecipientEmail = 'lastUsedComplianceRecipientEmail';
export const getCancelConfirmationOptions = (
	destructiveOptionText: string,
	cancelText = 'Cancel'
): IConfirmationDialogOption<boolean>[] => {
	return [
		{
			isDestructive: true,
			representedObject: true,
			title: destructiveOptionText,
		},
		{
			isCancel: true,
			representedObject: false,
			title: cancelText,
		},
	];
};
interface IProps extends IUserSessionComponentProps, IImpersonationContextComponentProps {
	campaign?: CampaignViewModel | SocialMediaPostViewModel;
	campaignGroup?: CampaignViewModel[];
	className?: string;
	disabled?: boolean;
	emailComposer?: ComposeEmailViewModel<IEmailMessageFollowUpOptions>;
	onApproveClicked?(): void;
	onApproveWithoutCompliance?(): void;
	onCancelClicked?(e?: React.MouseEvent<HTMLElement>): void;
	onChangeScheduleClicked?(): void;
	onEditRecipientsClicked?(): void;
	onViewCitationsClicked?(): void;
	onSendCompliance?(sendWithComplianceEmail?: string): void;
	readOnly?: boolean;
	styles?: StyleDeclarationValue[];
}

const CampaignDetailsSummaryFc: React.FC<IProps> = props => {
	const {
		campaign,
		campaignGroup,
		className,
		disabled,
		emailComposer,
		impersonationContext,
		onApproveClicked,
		onApproveWithoutCompliance,
		onCancelClicked,
		onChangeScheduleClicked,
		onEditRecipientsClicked,
		onSendCompliance,
		onViewCitationsClicked,
		readOnly,
		styles,
		userSession,
	} = props;

	const toaster = useToaster();
	const fullscreenModal = useFullscreenModal();
	const { logApiError, logEvent } = useEventLogging('CampaignDetailsSummary');
	const { postNotification } = useLocalNotificationService<SocialMediaPostViewModel>();
	const errorMessages = useErrorMessages();
	const [showingCancelConfirmation, setShowingCancelConfirmation] = React.useState<boolean>(false);
	const [isTextInputVisible, setIsTextInputVisible] = React.useState<boolean>(false);
	const [sendWithComplianceEmail, setSendWithComplianceEmail] = React.useState<string>(() => {
		return localStorage.getItem(lastUsedComplianceRecipientEmail) ?? '';
	});

	if (!campaign) {
		return null;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const scheduleDate = campaign instanceof CampaignViewModel ? campaign.schedule?.startDate : campaign?.dueDate;
	const renderSendOnBehalf = () => {
		let sendFrom: string;
		if (emailComposer) {
			sendFrom = !emailComposer?.emailMessage?.options?.sendEmailFrom
				? VmUtils.getDisplayName(userSession.user)
				: emailComposer?.emailMessage?.isSendFromOption(SendEmailFrom.SelectedUser) &&
						!!emailComposer.emailMessage.options?.sendEmailFromUser
					? VmUtils.getDisplayName(emailComposer.emailMessage.options.sendEmailFromUser)
					: null;
		} else if (campaign?.actor && campaign.actor.id !== campaign.creator.id) {
			sendFrom = campaign.actor.name;
		}

		if (!sendFrom) {
			return null;
		}
		return (
			<div className={css(styleSheet.card)}>
				<div className={css(styleSheet.cardTitle)}>Send From</div>
				<div>{sendFrom}</div>
			</div>
		);
	};

	const approveCampaign = async () => {
		if (onApproveClicked) {
			onApproveClicked();
			return;
		}
		if (campaign instanceof SocialMediaPostViewModel) {
			try {
				await campaign.approve();

				toaster.push({
					message: `Social media post approved`,
					type: 'successMessage',
				});
				postNotification({
					info: campaign,
					topic: Topics.EDIT_SOCIAL_POST,
				});
				fullscreenModal?.dismissModal();
			} catch (error) {
				logApiError('ApproveSocialMediaPost-Error', error);
				errorMessages.pushApiError(error);
			}
		}
	};

	const approveCampaignWithoutCompliance = async () => {
		if (onApproveWithoutCompliance) {
			onApproveWithoutCompliance();
			return;
		}

		if (campaign instanceof SocialMediaPostViewModel) {
			try {
				await campaign.update({ ...campaign.toJs(), sendWithCompliance: false });
				await campaign.approve();
				toaster.push({
					message: `Social media post approved without compliance`,
					type: 'successMessage',
				});
				postNotification({
					info: campaign,
					topic: Topics.EDIT_SOCIAL_POST,
				});
				fullscreenModal?.dismissModal();
			} catch (error) {
				logApiError('ApproveSocialMediaPost-Error', error);
				errorMessages.pushApiError(error);
			}
		}
	};

	const cancelClicked = (e?: React.MouseEvent<HTMLElement>) => {
		if (onCancelClicked) {
			onCancelClicked(e);
			return;
		}
		if (campaign instanceof SocialMediaPostViewModel) {
			setShowingCancelConfirmation(true);
		}
	};

	const checkComplianceEmail = () => {
		if (sendWithComplianceEmail.length > 0 && !isValidEmail(sendWithComplianceEmail)) {
			toaster.push({
				message: `Please enter a valid email address`,
				type: 'errorMessage',
			});
			return false;
		}
		return true;
	};

	const sendCompliance = async (isExternalSend = false) => {
		// We need to set this value to null if we are not sending to someone other than the logged in user
		// due to how the url is constructed in the underlying send methods
		const complianceEmail = isExternalSend ? sendWithComplianceEmail : null;
		if (!checkComplianceEmail()) {
			return;
		}
		if (onSendCompliance) {
			onSendCompliance(complianceEmail);
			return;
		}

		if (campaign instanceof SocialMediaPostViewModel) {
			const vm = new SocialAccountCampaignViewModel(userSession, campaign.toJs()).impersonate({
				account: impersonationContext?.account,
				user: campaign.creator,
			});
			try {
				const result = vm.sendWithCompliance
					? await vm.sendComplianceApprovalRequest(complianceEmail)
					: await vm.sendApprovalRequest();

				if (!result) {
					throw new Error("Couldn't send approval request");
				}

				toaster.push({
					message: `Copy sent to ${isExternalSend ? sendWithComplianceEmail : userSession?.user?.email}`,
					type: 'successMessage',
				});
			} catch (error) {
				logApiError('EmailNotificationPendingPostCopySend-Error', error);
				errorMessages.pushApiError(error);
			}
		}
	};

	const handleFirstButtonClick = () => {
		// Toggle the visibility of the text input
		setIsTextInputVisible(!isTextInputVisible);
	};

	const onCancelConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		if (!canceled && result?.representedObject) {
			cancelCampaign();
		}
		setShowingCancelConfirmation(false);
	};

	const cancelCampaign = async () => {
		if (campaign instanceof SocialMediaPostViewModel) {
			try {
				logEvent('CancelSocialCampaign', { id: campaign?.id });

				await campaign.cancel();

				toaster.push({
					message: campaign?.status === PostStatus.Pending ? `Pending post cancelled` : 'Post cancelled',
					type: 'successMessage',
				});
				postNotification({
					info: campaign,
					topic: Topics.EDIT_SOCIAL_POST,
				});
				fullscreenModal?.history?.goBack();
			} catch (error) {
				logApiError('CancelSocialPendingCampaign-Error', error);
				errorMessages.pushApiError(error);
			}
		}
	};

	const onRejectionConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		if (campaign instanceof SocialMediaPostViewModel) {
			if (!canceled && !!result?.representedObject) {
				const promise = campaign.reject();
				if (promise) {
					logEvent('RejectCampaign');
					promise
						.then(() => {
							toaster.push({
								message: `Campaign rejected`,
								type: 'successMessage',
							});
							postNotification({
								info: campaign,
								topic: Topics.EDIT_SOCIAL_POST,
							});
							fullscreenModal?.dismissModal();
						})
						.catch((error: IOperationResultNoValue) => {
							logApiError(`RejectCampaign-Error`);
							errorMessages.pushApiError(error);
						});
				}
			}
		}
		setShowingCancelConfirmation(false);
	};

	const renderCancelConfirmationDialog = () => (
		<ConfirmationDialog
			icon={<WarningIcon />}
			modalProps={{
				isOpen: showingCancelConfirmation,
				onRequestClose:
					!impersonationContext && campaign?.status === PostStatus.Pending
						? onRejectionConfirmationRequestClose
						: onCancelConfirmationRequestClose,
			}}
			options={getCancelConfirmationOptions(
				!impersonationContext && campaign?.status === PostStatus.Pending ? 'Reject' : 'Cancel Campaign',
				'Nevermind'
			)}
			title={`Are you sure you want to ${
				!impersonationContext && campaign?.status === PostStatus.Pending ? 'reject' : 'cancel'
			} this social media campaign?`}
		/>
	);

	const renderActions = () => {
		if (campaignGroup) {
			return (
				<div className={css(styleSheet.actions, styleSheet.cancelInstructions)}>
					Please go to the Email Reports section to cancel or notify client
				</div>
			);
		}
		if (
			(campaign instanceof CampaignViewModel && !campaign.isScheduledToSend) ||
			(campaign instanceof SocialMediaPostViewModel && !campaign?.hasEditableStatus)
		) {
			return null;
		}
		const showApproveButton =
			!(props.impersonationContext?.isValid || accountHasComplianceEnabled(userSession, impersonationContext)) &&
			(campaign instanceof CampaignViewModel ||
				(campaign instanceof SocialMediaPostViewModel && (campaign?.canEdit || campaign?.canEditAsAdmin)));
		return (
			<div
				className={css(
					campaign instanceof CampaignViewModel && campaign.status === EmailSendStatus.WaitingForApproval
						? styleSheet.actions
						: styleSheet.actionsVertical
				)}
			>
				{(campaign instanceof CampaignViewModel && campaign.status === EmailSendStatus.WaitingForApproval) ||
				(campaign instanceof SocialMediaPostViewModel &&
					campaign.status === PostStatus.Pending &&
					(campaign.canEdit || campaign.canEditAsAdmin || campaign.impersonationContext)) ? (
					<>
						{showApproveButton && (
							<Button
								className={css(baseStyleSheet.ctaButton)}
								disabled={disabled || (campaign.isLoaded && campaign.isBusy)}
								onClick={approveCampaign}
								label='Approve Campaign'
								isLoading={campaign.isLoaded && campaign.isBusy}
							/>
						)}
						<Button
							className={css(baseStyleSheet.linkDestructive)}
							disabled={campaign.isLoaded && campaign.isBusy}
							onClick={cancelClicked}
							kind='custom'
							label={props.impersonationContext?.isValid ? 'Cancel this Campaign' : 'Reject this Campaign'}
						/>
					</>
				) : (campaign instanceof SocialMediaPostViewModel && campaign.hasEditableStatus) ||
					(campaign instanceof CampaignViewModel && !readOnly) ? (
					<Button
						className={css(baseStyleSheet.linkDestructive)}
						disabled={campaign.isLoaded && campaign.isBusy}
						onClick={cancelClicked}
						label='Cancel Campaign'
						kind='custom'
					/>
				) : null}
			</div>
		);
	};

	const IsSendOnBehalfFromUser =
		campaign instanceof SocialMediaPostViewModel &&
		campaign?.actor &&
		campaign.actor.id !== campaign.creator.id &&
		!(campaign?.actor.isLevitateSupport && campaign?.approvalRequests);

	const emailComplianceActions =
		campaign instanceof CampaignViewModel &&
		campaign.status === EmailSendStatus.WaitingForApproval &&
		accountHasComplianceEnabled(userSession, impersonationContext) &&
		emailComposer.emailMessage.options?.sendWithCompliance === true;

	const socialComplianceActions =
		campaign instanceof SocialMediaPostViewModel &&
		campaign.status === PostStatus.Pending &&
		(campaign?.canEdit || campaign?.canEditAsAdmin || props.impersonationContext?.isValid) &&
		accountHasComplianceEnabled(userSession, impersonationContext);

	const complianceRequired = accountHasComplianceRequired(userSession, impersonationContext);

	return (
		<div
			className={`${css(
				campaign instanceof CampaignViewModel ? styleSheet.container : styleSheet.socialContainer,
				...(styles || [])
			)} campaign-details-summary ${className || ''}`}
		>
			{campaign instanceof CampaignViewModel ? (
				<>
					{renderSendOnBehalf()}
					<div className={css(styleSheet.card, styleSheet.recipientsCard)}>
						<div className={css(styleSheet.cardTitle)}>Recipients</div>
						<div className={css(styleSheet.cardActions)}>
							<div className={css(baseStyleSheet.truncateText)}>
								{`Approximately ${
									!campaignGroup ? campaign.totalCount : campaignGroup.reduce((x, camp) => x + camp.totalCount, 0)
								}`}
							</div>
							{!readOnly && !campaignGroup && (
								<button className={css(baseStyleSheet.brandLink)} onClick={onEditRecipientsClicked}>
									<span>{`Preview${!impersonationContext?.isValid && !emailComposer?.isFollowUp ? '/Edit' : ''}`}</span>
								</button>
							)}
						</div>
						<TagEmailTagList
							campaign={campaign}
							emailComposer={emailComposer}
							readOnly={true}
							styles={[styleSheet.tagList]}
						/>
					</div>
				</>
			) : null}
			{scheduleDate ? (
				<>
					<ScheduleDate
						title={campaign instanceof CampaignViewModel ? 'Send Time' : 'Posting Date'}
						scheduleDate={scheduleDate}
						showChangeButton={
							(campaign instanceof CampaignViewModel &&
								campaign?.isScheduledToSend &&
								!campaignGroup &&
								!emailComposer?.editModeIsActive) ||
							(campaign instanceof SocialMediaPostViewModel &&
								(campaign?.canEdit || (campaign?.impersonationContext && campaign?.hasEditableStatus)))
						}
						disabled={disabled}
						onClick={onChangeScheduleClicked}
					/>
				</>
			) : null}
			{IsSendOnBehalfFromUser ? <ScheduleBy name={campaign?.actor?.name} /> : null}
			{(emailComplianceActions || socialComplianceActions) && (
				<div className={css(styleSheet.card)}>
					<div className={css(styleSheet.cardTitle)}>Compliance Approval</div>
					<div className={css(styleSheet.cardActions)}>
						<div className={css(styleSheet.approvalStatusTitle)}>Pending Approval</div>
					</div>
					{((emailComplianceActions &&
						campaign.isScheduledToSend &&
						!campaignGroup &&
						!emailComposer.editModeIsActive) ||
						socialComplianceActions) && (
						<div className={css(styleSheet.complianceButtons)}>
							<button className={css(baseStyleSheet.brandLink)} disabled={disabled} onClick={handleFirstButtonClick}>
								<EmailIcon className={css(styleSheet.complianceIcon)} fillColor={brandPrimary} />
								<span>{`${'Email a PDF to Compliance'} `}</span>
							</button>
							{isTextInputVisible && (
								<div className={css(baseStyleSheet.horizontalStack)}>
									<TextInput
										inputId='user-emailAddress'
										placeholder='Please enter Compliance Email'
										type='text'
										onBlur={() => {
											if (!checkComplianceEmail()) {
												return;
											}
											localStorage.setItem(lastUsedComplianceRecipientEmail, sendWithComplianceEmail);
										}}
										onChange={ev => {
											setSendWithComplianceEmail(ev.target.value);
										}}
										className={css(styleSheet.complianceInput)}
										value={sendWithComplianceEmail}
									/>
									<button onClick={() => sendCompliance(true)} title='Click to Send'>
										<DoubleArrowRightIcon fillColor='#89C947' />
									</button>
								</div>
							)}
							<button
								className={css(baseStyleSheet.brandLink)}
								disabled={disabled}
								onClick={() => {
									sendCompliance();
								}}
							>
								<EmailIcon className={css(styleSheet.complianceIcon)} fillColor={brandPrimary} />
								<span>{`${props.impersonationContext?.isValid ? 'Email client a copy' : 'Email me a copy'} `}</span>
							</button>
						</div>
					)}
					{!props.impersonationContext?.isValid && (
						<div className={css(styleSheet.buttonActions)}>
							<div className={css(baseStyleSheet.horizontalStack)}>
								{emailComposer?.campaign?.emailBodyTemplate?.citation?.length > 0 && (
									<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onViewCitationsClicked}>
										<span>View Citations</span>
									</button>
								)}
								<button className={css(baseStyleSheet.ctaButton)} disabled={disabled} onClick={approveCampaign}>
									<span>Mark as Approved</span>
								</button>
								{complianceRequired || (socialComplianceActions && !campaign?.canEdit) ? null : (
									<button
										className={css(baseStyleSheet.ctaButtonReverse)}
										disabled={disabled}
										onClick={approveCampaignWithoutCompliance}
									>
										<span>{`${emailComplianceActions ? 'Send Anyway' : 'Post Anyway'} `}</span>
									</button>
								)}
							</div>
						</div>
					)}
				</div>
			)}
			{campaign instanceof SocialMediaPostViewModel &&
			campaign.status === PostStatus.Pending &&
			!socialComplianceActions &&
			campaign?.approvalRequests?.length > 0 ? (
				<ApprovalStatus title='Pending Approval' />
			) : null}
			{campaign instanceof CampaignViewModel &&
				campaign.status === EmailSendStatus.Queued &&
				accountHasComplianceEnabled(userSession, impersonationContext) &&
				campaign.approvalRequests?.length > 0 && (
					<div className={css(styleSheet.card)}>
						<div className={css(styleSheet.cardTitle)}>Compliance Approval</div>
						<div className={css(styleSheet.cardActions)}>
							{emailComposer.emailMessage.options?.sendWithCompliance ? (
								<div className={css(styleSheet.cardActions)}>
									<div className={css(styleSheet.approvedStatusTitle)}>Approved &nbsp;</div>
									<div className={css(styleSheet.approvedStatusDate)}>
										{getDefaultDateStringValue(campaign.approvalRequests[0].lastModifiedDate)}
									</div>
								</div>
							) : (
								<div className={css(styleSheet.approvedStatusTitle)}>
									{'Sent By ' + campaign.approvalRequests[0]?.approver?.firstName + ' Anyway'}
								</div>
							)}
						</div>
					</div>
				)}
			{campaign instanceof SocialMediaPostViewModel &&
				(campaign.status === PostStatus.Scheduled ||
					campaign.status === PostStatus.Succeeded ||
					campaign.status === PostStatus.PartiallySucceeded) &&
				accountHasComplianceEnabled(userSession, impersonationContext) && (
					<div className={css(styleSheet.card)}>
						<div className={css(styleSheet.cardTitle)}>Compliance Approval</div>
						<div className={css(styleSheet.cardActions)}>
							{campaign?.sendWithCompliance ? (
								<div className={css(styleSheet.cardActions)}>
									<div className={css(styleSheet.approvedStatusTitle)}>Approved &nbsp;</div>
									<div className={css(styleSheet.approvedStatusDate)}>
										{campaign?.approvalRequests &&
											getDefaultDateStringValue(campaign?.approvalRequests[0]?.lastModifiedDate)}
									</div>
								</div>
							) : campaign?.approvalRequests?.length > 0 ? (
								<div className={css(styleSheet.approvedStatusTitle)}>
									{'Scheduled By ' + campaign?.approvalRequests[0].approver?.firstName + ' Anyway'}
								</div>
							) : (
								<div className={css(styleSheet.approvedStatusTitle)}>Scheduled Without Compliance</div>
							)}
						</div>
					</div>
				)}

			{renderActions()}
			{renderCancelConfirmationDialog()}
		</div>
	);
};

const CampaignDetailsSummaryAsObserver = observer(CampaignDetailsSummaryFc);
export const CampaignDetailsSummary = inject(
	UserSessionViewModelKey,
	ImpersonationContextKey
)(CampaignDetailsSummaryAsObserver);
