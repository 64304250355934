import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useAutomationTemplateCreateMutation = ({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: Api.IAutomationTemplate) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			name,
			impersonationContext,
		}: { name: string; impersonationContext?: Api.IImpersonationContext }) => {
			return userSession.webServiceHelper.callAsync<Api.IAutomationTemplate>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'automationTemplate',
					impersonationContext,
					queryParams: {
						name,
					},
				}),
				'POST'
			);
		},
		onSuccess,
		onError,
	});
};
