import { StyleSheet } from 'aphrodite';
import { titles } from '../../../styles/colors';
import { CssSelectors, Layouts } from '../../../styles/styles';

const searchContainerHeight = 62;
const itemSpacer = 20;
export const styleSheet = StyleSheet.create({
	campaignSearch: {
		marginRight: 10,
		minWidth: 200,
		...CssSelectors.allChildren(
			{
				marginTop: 5,
			},
			'button'
		),
	},
	campaignSelector: {
		height: `calc(100% - ${searchContainerHeight + itemSpacer}px)`,
		marginTop: 10,
		alignItems: 'stretch',
		display: 'flex',
		flexDirection: 'column',
		padding: 0,
		position: 'relative',
	},
	cardContainer: {
		height: 'calc(100% - 114px)',
	},
	container: {
		...Layouts.verticalStack(itemSpacer),
	},
	newCampaignCta: {
		height: 40,
		marginLeft: 24,
	},
	searchContainer: {
		display: 'flex',
		height: searchContainerHeight,
		justifyContent: 'space-between',
		marginBottom: itemSpacer,
		paddingRight: 10,
	},
	searchContainerNoButton: {
		...CssSelectors.allChildren(
			{
				width: '100%',
			},
			'div'
		),
	},
	trigger: {
		height: '40px',
		padding: 0,
		paddingRight: 15,
	},
	triggerText: {
		color: titles,
		paddingLeft: 10,
		width: '80%',
	},
	select: {
		':not(:last-child)': {
			marginBottom: 10,
		},
		marginRight: 10,
		maxWidth: '100%',
		width: 'auto',
	},
});
