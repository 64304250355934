import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import {
	useErrorMessages,
	useFullscreenModal,
	useToaster,
	useUserSession,
} from '../../../../models/hooks/appStateHooks';
import { baseStyleSheet } from '../../../styles/styles';

import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { IImpersonationContextComponentProps, ILocationState, ImpersonationContextKey } from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import { AIContentGenerationStatus } from '../../../../models/Ai';
import { BlogPostRequest } from '../../../../models/Blogs';
import { accountHasComplianceEnabled, createRichContentEditorStateWithText } from '../../../../models/UiUtils';
import {
	invalidateBlogPostDrafts,
	useBlogGenerateFromUserMutation,
	useBlogSaveMutation,
	useBlogUpdateMutation,
	useDeleteBlogPostMutation,
} from '../../../../queries';
import {
	ConfirmationDialog,
	DefaultDeleteConfirmationOptions,
	IConfirmationDialogOption,
} from '../../../components/ConfirmationDialog';
import { AIContentGenerationWizard } from '../../../components/ai/AIContentGenerationWizard';
import { AIContentGenerationStatusContext } from '../../../components/ai/AIContentGenerationWizard/context';
import {
	AIContentGenerationWizardAssistantBlogPostMessages,
	AIContentGenerationWizardType,
} from '../../../components/ai/AIContentGenerationWizard/models';
import { ComplianceApprovalPrompt } from '../../../components/campaigns/ComplianceApprovalPrompt';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { BlogPostEditor } from './BlogPostEditor';
import {
	BlogPostEditorContext,
	ImageStage,
	REDUCER_ACTION_KEYS,
	useBlogPostEditingRequest,
	validateContent,
} from './hooks';
import { IBlogPostComponentProps } from './models';
import { BlogPostEditorLayout } from './presentation';
import { styleSheet } from './styles';

type CreateBlogPostProps = IImpersonationContextComponentProps & IBlogPostComponentProps;

function CreateBlogPostBase({ impersonationContext, onScheduleClicked }: CreateBlogPostProps) {
	const userSession = useUserSession();
	const location = useLocation<ILocationState<any, BlogPostRequest> | null>();
	const toaster = useToaster();
	const fullscreenModal = useFullscreenModal();
	const errorMessages = useErrorMessages();
	const requiresCompliance = accountHasComplianceEnabled(userSession, impersonationContext);

	const { postEditorContext, blogPost, setBlogPost, request } = useBlogPostEditingRequest({
		impersonationContext,
	});
	const { state, dispatch } = postEditorContext;

	const hasImageSelected = Boolean(state.selectedImage);

	const generateBlog = useBlogGenerateFromUserMutation({
		onError: error => {
			errorMessages.pushApiError(error);
		},
		onSuccess: ({ content: post }) => {
			dispatch({
				type: REDUCER_ACTION_KEYS.SET_STATE,
				payload: {
					title: post.title,
					content: createRichContentEditorStateWithText(post.content.document),
					selectedImage: undefined,
					blogId: '',
					blogGenerated: false,
					showingAiContentGenWizard: true,
					showScheduleAction: false,
					sendFromUser: (post.creator || location.state?.model?.sendFromUser || state.sendFromUser) as Api.IUser,
					status: post.status,
				},
			});
		},
	});

	React.useEffect(() => {
		if (!hasImageSelected && state.imageState === ImageStage.Loading) {
			dispatch({
				type: REDUCER_ACTION_KEYS.GENERATING_CONTENT,
				payload: { imageSuggestingLoading: false, imageState: ImageStage.Loaded },
			});
		}
	}, [hasImageSelected, state.imageState, dispatch]);

	const saveBlogMutation = useBlogSaveMutation({
		impersonationContext,
		onError: error => {
			errorMessages.pushApiError(error);
		},
		onSuccess: blog => {
			setBlogPost(blog);
			toaster.push({
				message: 'Blog post saved.',
				type: 'successMessage',
			});
			invalidateBlogPostDrafts({ userId: userSession.user.id });
		},
	});

	const updateBlogMutation = useBlogUpdateMutation({
		impersonationContext,
		onError: error => {
			errorMessages.pushApiError(error);
		},
		onSuccess: blog => {
			setBlogPost(blog);
			toaster.push({
				message: 'Blog post saved.',
				type: 'successMessage',
			});
			invalidateBlogPostDrafts({ userId: userSession.user.id });
		},
	});

	const deleteBlogPostMutation = useDeleteBlogPostMutation({
		impersonationContext,
		onSuccess: () => {
			fullscreenModal.dismissModal();
		},
		onError: error => {
			errorMessages.pushApiError(error);
		},
	});

	const contentGenerationStatusContext = React.useMemo(() => {
		const status = generateBlog.isLoading ? AIContentGenerationStatus.Generating : AIContentGenerationStatus.Idle;
		return { contentGenerationStatus: status };
	}, [generateBlog.isLoading]);

	const handleScheduleClick = (sendWithCompliance = false, sendWithComplianceEmail?: string) => {
		const errors = validateContent(state);
		if (errors.length !== 0) {
			errorMessages.push({
				messages: errors,
			});
			return;
		}

		const postToSave: Api.IBlogPost = {
			...blogPost,
			content: state.content.getRawRichTextContent(),
			title: state.title,
			mainImage: state.selectedImage,
			id: state.blogId,
			sendFromUserId: state.sendFromUser?.id,
			scheduledSendDate: blogPost?.scheduledSendDate || request?.schedule?.startDate,
			sendWithCompliance,
			sendWithComplianceEmail,
		};

		setBlogPost(postToSave); // context
		if (!onScheduleClicked?.(postToSave)) {
			fullscreenModal.history.push({
				pathname: '/blog/post/schedule',
			});
		}
	};

	const handleDeleteClick = () => {
		dispatch({ type: REDUCER_ACTION_KEYS.DELETE_BLOG_POST, payload: { value: true } });
	};

	const onDeleteConfirmationRequestClose = React.useCallback(
		(result?: IConfirmationDialogOption<boolean>, cancel?: boolean) => {
			if (result?.isDestructive && !cancel) {
				deleteBlogPostMutation.mutate({ id: state.blogId });
			}
			dispatch({ type: REDUCER_ACTION_KEYS.DELETE_BLOG_POST, payload: { value: false } });
		},
		[deleteBlogPostMutation, dispatch, state.blogId]
	);

	const onRetryClicked = (_: React.MouseEvent<HTMLElement, MouseEvent>, options: Api.IAIContentGenerationOptions) => {
		// options.writingInstructions = 'Retry writing the blog post content.';
		// TODO: add context id from previous attempt
		// TODO: use original content and title instead of previous attempt content and title
		generateBlog.mutateAsync({
			request: {
				...options,
				title: state.title,
				content: state.content.getRawRichTextContent(),
			},
			sendFromUserId: state.sendFromUser?.id,
			impersonationContext: impersonationContext?.toJs(),
		});
	};

	const generateBlogClicked = async (options: Api.IAIContentGenerationOptions) => {
		const value = await generateBlog.mutateAsync({
			request: {
				...options,
				title: state.title,
				content: state.content.getRawRichTextContent(),
			},
			sendFromUserId: state.sendFromUser?.id,
			impersonationContext: impersonationContext?.toJs(),
		});
		dispatch({
			type: REDUCER_ACTION_KEYS.GENERATING_CONTENT,
			payload: {
				imageSuggestingLoading: !hasImageSelected,
				imageState: hasImageSelected ? ImageStage.Loaded : ImageStage.Loading,
			},
		});
		return value;
	};

	const handleSaveClick = () => {
		if (blogPost?.id) {
			updateBlogMutation.mutate({
				post: {
					...blogPost,
					content: state.content.getRawRichTextContent(),
					title: state.title,
					mainImage: state.selectedImage,
					sendFromUserId: state.sendFromUser?.id,
				},
			});
			return;
		}
		saveBlogMutation.mutate({
			post: {
				content: state.content.getRawRichTextContent(),
				title: state.title,
				mainImage: state.selectedImage,
				id: undefined,
				sendFromUserId: state.sendFromUser?.id,
			},
		});
	};

	return (
		<BlogPostEditorContext.Provider value={postEditorContext}>
			<BlogPostEditorLayout
				status={state.status}
				header='Generate Blog'
				content={<BlogPostEditor showWizardButton={true} />}
				subHeader={
					<>
						<h1>New Blog</h1>
						<menu className={css(styleSheet.menu)}>
							{impersonationContext?.isValid ? null : (
								<li>
									<button
										type='button'
										className={css(baseStyleSheet.ctaButtonReverseSmall)}
										disabled={!(state.content && state.title)}
										onClick={handleSaveClick}
									>
										Save Blog Post
									</button>
								</li>
							)}
							{blogPost?.id ? (
								<li>
									<button
										type='button'
										className={css(baseStyleSheet.ctaButtonDestructiveSmall)}
										onClick={handleDeleteClick}
									>
										Delete
									</button>
								</li>
							) : null}
						</menu>
					</>
				}
				sidebar={
					<>
						{state.showingAiContentGenWizard ? (
							<>
								<AIContentGenerationStatusContext.Provider value={contentGenerationStatusContext}>
									<AIContentGenerationWizard<Api.IBlogPost, Api.IAIGeneratedContent<Api.IBlogPost>>
										cautionMessage='Please review content created with AI before posting.'
										onCloseClicked={() => {
											dispatch({ type: REDUCER_ACTION_KEYS.AI_WIZARD_CLOSED });
										}}
										onGenerateClicked={generateBlogClicked}
										onRetryClicked={onRetryClicked}
										type={AIContentGenerationWizardType.BlogPost}
										assistantMessageMap={AIContentGenerationWizardAssistantBlogPostMessages}
									/>
								</AIContentGenerationStatusContext.Provider>
							</>
						) : requiresCompliance ? (
							<ComplianceApprovalPrompt
								campaignType={CampaignType.Blog}
								onScheduleCompliance={(e: React.MouseEvent<HTMLElement>, sendWithComplianceEmail: string) => {
									e.preventDefault();
									handleScheduleClick(true, sendWithComplianceEmail);
								}}
								onScheduleWithoutCompliance={() => handleScheduleClick()}
							/>
						) : (
							<>
								<h2 className={css(styleSheet.question)}>What would you like to do with this blog?</h2>
								<button type='button' className={css(baseStyleSheet.ctaButton)} onClick={() => handleScheduleClick()}>
									Schedule
								</button>
							</>
						)}

						<ConfirmationDialog
							icon={<WarningIcon />}
							modalProps={{
								isOpen: state.showConfirmationModal,
								onRequestClose: onDeleteConfirmationRequestClose,
							}}
							options={DefaultDeleteConfirmationOptions}
							title='Are you sure you want to delete this blog post?'
						/>
					</>
				}
				sidebarStyles={[styleSheet.sidebarCenter]}
			/>
		</BlogPostEditorContext.Provider>
	);
}

export const CreateBlogPost = inject(ImpersonationContextKey)(observer(CreateBlogPostBase));
