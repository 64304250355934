import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { mergeRefs } from 'react-merge-refs';
import useMeasure from 'react-use-measure';
import { isIE11 } from '../../../models/Browser';
import { DisclosureIcon } from '../../components/svgs/icons/DisclosureIcon';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

interface IBrowseSectionProps
	extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	styles?: StyleDeclarationValue[];
	onClick: () => void;
}

export const BrowseAllInCategoryButton = ({
	children,
	onClick,
	className,
	styles,
	...restProps
}: IBrowseSectionProps) => {
	return (
		<button
			className={`${className} ${css(styleSheet.browseAllCampaigns, baseStyleSheet.brandLink, ...(styles ?? []))}`}
			onClick={onClick}
			{...restProps}
		>
			{children}
		</button>
	);
};

export const CampaignBrowserTabs = ({ children }: { children: React.ReactNode }) => {
	const [boundRef, { width }] = useMeasure({ offsetSize: true });
	const elementRef = React.useRef<HTMLDivElement>(null);
	const [scrollLeft, setScrollLeft] = React.useState(0);
	const scrollWidth = elementRef.current?.scrollWidth;
	const canScrollLeft = scrollLeft > 0;

	const canScrollRight = scrollLeft < scrollWidth - width;
	const onScrollLeft = () => {
		elementRef.current?.scroll({ behavior: 'smooth', left: 0 });
	};
	const onScrollRight = () => {
		elementRef.current?.scroll({ behavior: 'smooth', left: scrollWidth });
	};
	React.useEffect(() => {
		const handleScroll = () => {
			setScrollLeft(elementRef.current?.scrollLeft || 0);
		};
		const node = elementRef.current;
		if (node) {
			node.addEventListener('scroll', handleScroll);
		}
		return () => {
			if (node) {
				node.removeEventListener('scroll', handleScroll);
			}
		};
	}, [elementRef]);
	return (
		<div className={css(styleSheet.tabNavigation)}>
			{canScrollLeft && !isIE11() ? (
				<div className={css(styleSheet.arrowContainer, styleSheet.arrowLeftContainer)}>
					<div className={css(styleSheet.arrowLeft)} onClick={onScrollLeft}>
						<DisclosureIcon type='chevron' />
					</div>
				</div>
			) : null}
			<div className={css(styleSheet.tabSection)} ref={mergeRefs([boundRef, elementRef])}>
				{children}
			</div>
			{canScrollRight && !isIE11() ? (
				<div className={css(styleSheet.arrowContainer, styleSheet.arrowRightContainer)}>
					<div className={css(styleSheet.arrowRight)} onClick={onScrollRight}>
						{' '}
						<DisclosureIcon type='chevron' />{' '}
					</div>
				</div>
			) : null}
		</div>
	);
};
