import { StyleDeclarationValue, css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import DayPicker from 'react-day-picker';
import { bs } from '../../styles/styles';
import { TextInput } from '../TextInput';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	startDateRequired?: boolean;
	endDateRequired?: boolean;
	from?: Date | null;
	hasEndDateError?: boolean;
	hasStartDateError?: boolean;
	maxDate?: Date;
	minDate?: Date;
	numberOfMonths?: number;
	onChange?(from: Date, to: Date): void;
	resetDates?: () => void;
	showTextInput?: boolean;
	forceSmallText?: boolean;
	styles?: StyleDeclarationValue[];
	to?: Date | null;
	resetText?: string;
	disableScrollTo?: boolean;
	datePickerStyles?: StyleDeclarationValue[];
	additionalEndDateLabelText?: string;
	defaultOpen?: boolean;
	endDateTextLabel?: string;
}

export const CustomDateRange = ({
	className,
	startDateRequired = true,
	endDateRequired = false,
	from,
	hasEndDateError = false,
	hasStartDateError = false,
	maxDate,
	minDate,
	numberOfMonths = 1,
	onChange,
	resetDates,
	showTextInput = false,
	forceSmallText = false,
	styles = [],
	to,
	resetText = 'Reset dates',
	disableScrollTo = false,
	datePickerStyles = [],
	additionalEndDateLabelText = '',
	defaultOpen = true,
	endDateTextLabel = 'End Date',
}: IProps) => {
	// Allow the calendar to be hidden on initial load if hideCalendarOnInitialLoad is set to true else fallback to original behavior
	const [isShowingCalendar, setIsShowingCalendar] = useState(defaultOpen);
	const [dateFromTextInput, setDateFromTextInput] = useState('');
	const [state, setState] = useState<{ to: Date; from: Date }>({ from, to });
	const bottomDiv = React.useRef<HTMLDivElement>(null);

	const isSettingStartDate = React.useMemo(() => {
		return !state?.from || state?.from?.toLocaleDateString() !== state?.to?.toLocaleDateString();
	}, [state]);
	const isSettingEndDate = React.useMemo(() => {
		return state?.from && state?.from?.toLocaleDateString() === state?.to?.toLocaleDateString();
	}, [state]);

	React.useEffect(() => {
		if (isSettingEndDate === true) {
			setDateFromTextInput('');
		}
	}, [isSettingEndDate]);

	const handleDayUpdate = (day: Date) => {
		const selection = moment(day);
		const disabledFromMinDates = minDate ? moment(minDate) : null;
		const disabledFromMaxDates = maxDate ? moment(maxDate) : null;
		const fromDate = state.from ? moment(state.from) : null;
		const toDate = state.to ? moment(state.to) : null;

		/**
		 * @If selected date is less than min date
		 * @OR
		 * @If selected date is greater than max date
		 * @return early and do nothing
		 */

		if (selection.isBefore(disabledFromMinDates, 'day') || selection.isAfter(disabledFromMaxDates, 'day')) {
			return;
		}
		if (
			(fromDate?.isAfter(selection, 'day') && toDate?.isSame(fromDate, 'day')) ||
			selection.isBefore(fromDate, 'day') ||
			toDate?.isAfter(fromDate, 'day')
		) {
			setState({ from: day, to: day });
			onChange?.(day, day);
			return;
		}

		const newStartDate = fromDate ? (toDate?.isBefore(fromDate, 'day') ? day : fromDate.toDate()) : day;
		const newEndDate = day;
		setState({ from: newStartDate, to: newEndDate });
		onChange?.(newStartDate, newEndDate);

		if (endDateRequired && newEndDate && moment(newEndDate).isAfter(newStartDate)) {
			setIsShowingCalendar(false);
		}
	};

	const handleResetClick = () => {
		resetDates?.();

		setState({ from: undefined, to: undefined });
	};

	const scrollToCalendar = () => {
		if (disableScrollTo) {
			return;
		}
		requestAnimationFrame(() => {
			bottomDiv.current?.scrollIntoView({ behavior: 'smooth' });
		});
	};

	const showCalendar = () => {
		// Flip the state of isShowingCalendar.
		const newValue = !isShowingCalendar;
		setIsShowingCalendar(newValue);
		// One thing we should come back and deal with in some way is when multiple calendars are open on the page it will scroll to the last one on the page.
		if (newValue === true) scrollToCalendar();
	};

	const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const parsed = moment(e.target.value);
		const year1900 = moment('1900');
		const year2050 = moment('2050');
		setDateFromTextInput(e.target.value);

		if (parsed.isValid() && parsed.isAfter(year1900) && parsed.isBefore(year2050)) {
			handleDayUpdate(parsed.toDate());
			setDateFromTextInput('');
		}
	};

	const openCalendar = () => {
		if (!isShowingCalendar && state.from) {
			setDateFromTextInput(moment(state.from).format('YYYY-MM-DD'));
		}
		showCalendar();
	};

	const modifiers = { end: state.to, start: state.from };
	const hasSelectedDateError = hasStartDateError || hasEndDateError;
	return (
		<div className={`${css(styleSheet.dateRangeWrapper, ...styles)} dateRangeWrapper`}>
			<header className={css(styleSheet.header, endDateRequired ? styleSheet.resetHidden : null)}>
				{!endDateRequired ? (
					<button className={css(styleSheet.reset)} onClick={handleResetClick}>
						{resetText}
					</button>
				) : null}

				<div className={css(styleSheet.dateWrapper)} onClick={openCalendar}>
					<div className={css(styleSheet.label, startDateRequired ? styleSheet.labelRequired : null)}>Start Date</div>
					<div
						className={css(
							styleSheet.selectedDateDisplay,
							endDateRequired || forceSmallText ? styleSheet.smallText : null,
							isShowingCalendar && isSettingStartDate ? styleSheet.selectionHighlight : undefined
						)}
					>
						{!state?.from ? (
							<span className={css(styleSheet.placeholderText)}>Choose Date</span>
						) : (
							<time dateTime={moment(state.from).format('MMMM Do, YYYY')}>
								{moment(state.from).format('MMMM Do, YYYY')}
							</time>
						)}
					</div>
					{hasSelectedDateError ? (
						<p
							role='alert'
							className={css(styleSheet.formErrorLine, hasStartDateError ? styleSheet.formErrorLineVisible : null)}
						>
							Must select a start date
						</p>
					) : null}
				</div>

				<div className={css(styleSheet.dateWrapper)} onClick={openCalendar}>
					<div className={css(styleSheet.label, endDateRequired ? styleSheet.labelRequired : null)}>
						{endDateTextLabel}{' '}
						{endDateRequired ? null : <em className={css(styleSheet.placeholderText)}>{additionalEndDateLabelText}</em>}
					</div>
					<div
						className={css(
							bs.truncateText,
							styleSheet.selectedDateDisplay,
							endDateRequired || forceSmallText ? styleSheet.smallText : null,
							isSettingEndDate ? styleSheet.selectionHighlight : undefined
						)}
					>
						{isSettingEndDate ? (
							<span className={css(styleSheet.placeholderText)}>
								{endDateRequired ? 'Choose End Date' : 'Optional'}
							</span>
						) : state?.to ? (
							<time dateTime={moment(state.to).format('MMMM Do, YYYY')}>
								{moment(state.to).format('MMMM Do, YYYY')}
							</time>
						) : (
							<>&nbsp;</>
						)}
					</div>

					{hasSelectedDateError ? (
						<p
							role='alert'
							className={css(styleSheet.formErrorLine, hasEndDateError ? styleSheet.formErrorLineVisible : null)}
						>
							Must select an end date
						</p>
					) : null}
				</div>
			</header>

			{isShowingCalendar ? (
				<>
					{showTextInput ? (
						<>
							<div className={css(styleSheet.label)}>Type date or choose below</div>
							<TextInput
								className={css(styleSheet.dateRangeText)}
								inputId='calendar-date-input'
								onChange={onInputChanged}
								type='date'
								value={dateFromTextInput}
							/>
						</>
					) : null}
					<DayPicker
						className={`Selectable  ${className ?? ''} ${css(styleSheet.customDateRange, datePickerStyles)} ${
							showTextInput ? css(styleSheet.showingTextInput) : ''
						}`}
						disabledDays={{
							after: maxDate ?? undefined,
							before: minDate ?? undefined,
						}}
						initialMonth={state?.from ? new Date(state?.from) : undefined}
						numberOfMonths={numberOfMonths}
						selectedDays={[state.from, { from: state.from, to: state.to }]}
						modifiers={modifiers}
						onDayClick={handleDayUpdate}
					/>
				</>
			) : null}

			<div ref={bottomDiv} />
		</div>
	);
};
