import { css } from 'aphrodite';
import { UnregisterCallback } from 'history';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, NavLink as RouterLink, withRouter } from 'react-router-dom';
import { AppHashHistory } from '../../../models';
import {
	ITextMessagingComponentProps,
	IUserSessionComponentProps,
	TextMessagingViewModelKey,
	UserSessionViewModelKey,
} from '../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../models/Logging';
import { AutomationsOnHoldViewModel, IOperationResultNoValue } from '../../../viewmodels/AppViewModels';
import { brandPrimary } from '../../styles/colors';
import { INavBarLink, NavBarLink } from '../Happiness/NavBarLink';
import { CompactLogoIcon } from '../svgs/icons/CompactLogoIcon';
import { FlagIcon } from '../svgs/icons/FlagIcon';
import { LogoIcon } from '../svgs/icons/LogoIcon';
import { styleSheet } from './styles';

interface IProps
	extends IUserSessionComponentProps,
		RouteComponentProps<any>,
		IEventLoggingComponentProps,
		ITextMessagingComponentProps {
	className?: string;
	compactLayout?: boolean;
	header?: React.ReactNode;
	links?: INavBarLink[];
	onRenderLogo?(compactLayout?: boolean): React.ReactNode;
}

interface IState {
	automationsPending: boolean;
}

class _NavBar extends React.Component<IProps, IState> {
	@observable.ref private automationsOnHold: AutomationsOnHoldViewModel;
	private unregisterHandle: UnregisterCallback;
	constructor(props: IProps) {
		super(props);
		this.state = {
			automationsPending: false,
		};

		this.automationsOnHold = new AutomationsOnHoldViewModel(props.userSession);

		this.unregisterHandle = null;
	}

	public componentDidMount() {
		// Not sure why this has to be a function expression but it does not work to
		// just pass this.checkForAutomations
		this.unregisterHandle = AppHashHistory.listen(() => this.checkForAutomations());
		this.checkForAutomations();
	}

	public componentWillUnmount() {
		this.unregisterHandle();
	}

	private checkForAutomations = () => {
		const { userSession } = this.props;
		if (userSession?.account?.features?.automation?.enabled) {
			this.automationsOnHold
				.loadAutomationsOnHold(userSession.canSendOnBehalf)
				?.then(() => {
					this.setState({
						automationsPending: userSession.canSendOnBehalf
							? this.automationsOnHold?.allAutomationsPending?.length > 0
							: this.automationsOnHold?.myAutomationsPending?.length > 0,
					});
				})
				?.catch((err: IOperationResultNoValue) => {
					const { logApiError } = this.props;

					logApiError('LoadPendingAutomationsNavBar', err);
				});
		}
	};

	private getAccessory = (linkProps: INavBarLink): JSX.Element => {
		switch (linkProps.linkName) {
			case 'Automations': {
				const { automationsPending } = this.state;
				if (automationsPending) {
					return <FlagIcon />;
				}

				return null;
			}
			case 'Texting': {
				const { compactLayout, textMessaging } = this.props;
				if (textMessaging?.totalUnreadMessages) {
					return (
						<div
							className={css(
								compactLayout ? styleSheet.compactUnreadTextMessageCount : styleSheet.unreadTextMessageCount
							)}
						>
							{textMessaging.totalUnreadMessages}
						</div>
					);
				}

				return null;
			}
			default: {
				return null;
			}
		}
	};
	public render() {
		const { className, links, compactLayout, header, onRenderLogo } = this.props;
		return (
			<nav className={`nav-bar ${css(styleSheet.nav)} ${className || ''}`}>
				{header || (
					<div className={css(styleSheet.header)}>
						{onRenderLogo?.(compactLayout) || (
							<RouterLink to='/dashboard'>
								{compactLayout ? <CompactLogoIcon fill='#0066a5' /> : <LogoIcon fillColor={brandPrimary} />}
							</RouterLink>
						)}
					</div>
				)}
				<div className={css(styleSheet.links)}>
					<div>
						{!!links &&
							links.map(linkProps => {
								return (
									<NavBarLink
										{...linkProps}
										className={css(styleSheet.link)}
										accessory={this.getAccessory(linkProps)}
										compactLayout={compactLayout}
										key={typeof linkProps.path === 'object' ? linkProps.path[0] : linkProps.path}
									/>
								);
							})}
					</div>
				</div>
			</nav>
		);
	}
}

const NavBarAsObserver = observer(_NavBar);
const NavBarWithInjectedProps = inject(UserSessionViewModelKey, TextMessagingViewModelKey)(NavBarAsObserver);
const NavBarWithLogging = withEventLogging(NavBarWithInjectedProps);
export const NavBar = withRouter(NavBarWithLogging);
