import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, destructiveHover, error, header, inputBorderColor } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	customDateRange: {
		border: '1px solid #D3DBDE',
		borderRadius: 8,
		boxShadow: '0 2px 7px rgba(0, 0, 0, 0.08)',
		display: 'block',
		margin: '0 auto 16px',
		maxWidth: 368,

		...CssSelectors.allDescendants(
			{
				opacity: 0.8,
			},
			'.DayPicker-NavButton'
		),
		...CssSelectors.allDescendants(
			{
				backgroundImage: `url('../../assets/cal_left_arrow.svg')`,
				left: 40,
				marginRight: '1.5rem',
				right: 'auto',
			},
			'.DayPicker-NavButton--prev'
		),
		...CssSelectors.allDescendants(
			{
				backgroundImage: `url('../../assets/cal_right_arrow.svg')`,
				left: 'auto',
				right: 40,
			},
			'.DayPicker-NavButton--next'
		),
		...CssSelectors.allDescendants(
			{
				fontSize: 14,
				fontWeight: 500,
				textAlign: 'center',
			},
			'.DayPicker-Caption > div'
		),
		...CssSelectors.allDescendants(
			{
				boxSizing: 'border-box',
				fontSize: 14,
				height: '30px !important',
				outline: 'none',
				width: '30px !important',
			},
			'.DayPicker-Day'
		),
		...CssSelectors.allDescendants(
			{
				backgroundColor: '#D3DBDE',
				borderRadius: '50%',
				color: '#000',
			},
			'.DayPicker-Day--today'
		),
		...CssSelectors.allDescendants(
			{
				backgroundColor: '#0066FF',
				borderRadius: '100%',
				color: '#f0f8ff',
				position: 'relative',
			},
			'.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)'
		),
		...CssSelectors.allDescendants(
			{
				backgroundColor: '#51a0fa',
			},
			'.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover'
		),
		...CssSelectors.allDescendants(
			{
				backgroundColor: '#f0f8ff',
				borderRadius: '50%',
			},
			'.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover'
		),
		...CssSelectors.allDescendants(
			{
				backgroundColor: '#f0f8ff !important',
				borderRadius: '0 !important',
				color: '#4a90e2',
			},
			'.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(:disabled)'
		),
		...CssSelectors.allDescendants(
			{
				borderRadius: '50% !important',
			},
			'.DayPicker-Day--start'
		),
		...CssSelectors.allDescendants(
			{
				borderRadius: '50% !important',
			},
			'.DayPicker-Day--end'
		),
	},
	dateRangeText: {
		borderRadius: 6,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		borderBottom: 0,
	},
	dateRangeWrapper: {
		width: 340,
	},
	dateWrapper: {
		width: '50%',
	},
	formErrorLine: {
		color: error,
		fontSize: '0.75rem',
		marginTop: 0,
		visibility: 'hidden',
	},
	formErrorLineVisible: {
		visibility: 'visible',
	},
	header: {
		marginBottom: 12,
		paddingTop: 20,
		position: 'relative',
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren({
			marginLeft: 0,
			':last-child': {
				marginLeft: 8,
			},
		}),
	},
	label: {
		fontSize: 12,
		marginBottom: 8,
		...CssSelectors.allChildren(
			{
				fontSize: 10,
			},
			'em'
		),
	},
	labelRequired: {
		'::after': {
			color: destructiveHover,
			content: '"*"',
			display: 'inline',
			marginLeft: '0.25rem',
		},
	},
	placeholderText: {
		color: header,
	},
	reset: {
		color: brandPrimaryText,
		left: 'auto',
		position: 'absolute',
		right: 0,
		top: 0,
	},
	resetHidden: {
		paddingTop: 0,
	},
	showingTextInput: {
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
	},
	selectedDateDisplay: {
		border: `1px solid ${inputBorderColor}`,
		borderRadius: 8,
		boxSizing: 'border-box',
		fontSize: 14,
		padding: '8px 11px',
		overflow: 'hidden',
	},
	selectionHighlight: {
		border: `1px solid ${brandPrimaryText}`,
	},
	smallText: {
		fontSize: 12,
	},
});
