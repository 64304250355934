import * as Api from '@ViewModels';
import { computed, observable, runInAction } from 'mobx';
import { v4 as uuidgen } from 'uuid';
import { AutomationStepAction } from '../models/Automations';

export class EditAutomationTemplateStepsViewModel<
	TUserSession extends Api.UserSessionContext = Api.UserSessionContext,
> extends Api.ViewModel {
	@observable.ref public mSteps: Api.AutomationTemplateEditorStep[];
	public readonly automationTemplate: Api.AutomationTemplateViewModel<TUserSession>;
	public readonly children: Api.ObservableCollection<EditAutomationTemplateStepsViewModel<TUserSession>>;
	public readonly uuid: string;

	constructor(userSession: TUserSession, automationTemplate: Api.AutomationTemplateViewModel<TUserSession>) {
		super(userSession);
		this.load = this.load.bind(this);
		this.uuid = uuidgen();
		this.automationTemplate = automationTemplate;

		this.children = new Api.ObservableCollection<EditAutomationTemplateStepsViewModel<TUserSession>>(null, 'uuid');
	}

	@computed
	public get canReoderSteps() {
		return this.mSteps?.every(x => !!x.automationStep?.id);
	}

	@computed
	public get steps() {
		return this.mSteps;
	}

	@computed
	public get automationTemplateTrigger() {
		const root = this.automationTemplate?.rootTemplate;
		return root.draftTriggerReference || root.publishedTriggerReference;
	}

	public async load(reloadTemplate = true) {
		if (this.automationTemplate) {
			this.loading = true;
			if (reloadTemplate) {
				await this.automationTemplate.load();
			}

			const steps: Api.AutomationTemplateEditorStep[] =
				(this.automationTemplate.draftVersion || this.automationTemplate.publishedVersion)?.steps.map(x => {
					const step = new Api.AutomationTemplateEditorStep(x);
					if (this.automationTemplate.isImpersonating) {
						step.automationStep?.impersonate(this.automationTemplate.impersonationContext);
					}
					return step;
				}) || [];
			if (steps.length === 0) {
				steps.push(new Api.AutomationTemplateEditorStep());
			}

			runInAction(() => {
				this.loaded = true;
				this.loading = false;
				this.mSteps = steps;
			});
		}
	}

	public executeActionForStep = async (step: Api.AutomationTemplateEditorStep, action: AutomationStepAction) => {
		switch (action) {
			case AutomationStepAction.Copy: {
				if (step.automationStep?.id) {
					const index = this.mSteps.indexOf(step);
					const beforeStep = index + 1 < this.mSteps.length ? this.mSteps[index + 1] : null;

					// Note: we change to see if the default schedule criteria for the dup would differ given the new index, and we go with that value over the original value
					// Prevents creating a step with index > 0 with schedule criteria === Api.AutomationStepScheduleCriteria.Immediately if duplicating,
					// for example, an email step at index === 0
					const defaultSchedule = Api.VmUtils.Automations.steps.getDefaultScheduleForAutomationStep(
						step.automationStep.type,
						this.automationTemplateTrigger
					);
					const promise = this.automationTemplate.duplicateStep(
						step.automationStep,
						beforeStep?.automationStep?.id,
						defaultSchedule.criteria !== step.automationStep.schedule?.criteria
							? {
									...(step.automationStep?.schedule || {}),
									criteria: defaultSchedule.criteria,
								}
							: undefined
					);
					if (promise) {
						promise
							.then(duplicateStep => {
								const createdStep = new Api.AutomationTemplateEditorStep(duplicateStep);
								createdStep.automationStep?.impersonate(step.automationStep.impersonationContext);
								const onFinish = (error?: Api.IOperationResultNoValue) => {
									runInAction(() => {
										this.loading = false;
										if (!error) {
											const nextSteps = [...this.mSteps];
											nextSteps.splice(index + 1, 0, createdStep);
											this.mSteps = nextSteps;
										}
									});
									if (error) {
										throw error;
									}
								};
								if (!step?.automationStep?.schedule) {
									createdStep.automationStep
										.update({
											...createdStep.automationStep.toJs(),
											schedule: { numberOfDays: 0 },
										})
										.then(onFinish)
										.catch((error: Api.IOperationResultNoValue) => {
											onFinish(error);
										});
								} else {
									onFinish();
								}
							})
							.catch((error: Api.IOperationResultNoValue) => {
								this.loading = false;
								throw error;
							});
					}
				} else {
					// copy empty
					this.mSteps = [...this.mSteps, new Api.AutomationTemplateEditorStep()];
				}
				break;
			}
			case AutomationStepAction.Delete: {
				if (!step.automationStep) {
					const nextSteps = [...this.mSteps];
					nextSteps.splice(nextSteps.indexOf(step), 1);
					this.mSteps = nextSteps;
					return;
				}
				const promise = this.automationTemplate.removeStep(step.automationStep);
				if (promise) {
					promise
						.then(() => {
							const nextSteps = [...this.mSteps];
							nextSteps.splice(nextSteps.indexOf(step), 1);
							runInAction(() => {
								this.mSteps = nextSteps;
								this.loading = false;
							});
						})
						.catch((error: Api.IOperationResultNoValue) => {
							this.loading = false;
							throw error;
						});
				}
				break;
			}
			default: {
				break;
			}
		}
	};

	public addStep = (step?: Api.AutomationTemplateEditorStep) => {
		const nextStep = step || new Api.AutomationTemplateEditorStep();
		nextStep?.automationStep?.impersonate(this.automationTemplate?.impersonationContext);
		this.mSteps = [...(this.mSteps || []), nextStep];
		return Promise.resolve(this.mSteps);
	};

	public removeStep = async (step?: Api.AutomationTemplateEditorStep) => {
		const index = this.mSteps?.indexOf(step);
		if (index >= 0) {
			if (step?.automationStep?.id) {
				await this.automationTemplate.removeStep(step.automationStep);
			}
			const nextSteps = [...(this.mSteps || [])];
			nextSteps.splice(index, 1);
			this.mSteps = nextSteps;
		}

		return this.mSteps;
	};

	public addCaseToSwitchStep = async (
		switchStep: Api.AutomationTemplateEditorStep<Api.SwitchAutomationStepViewModel>,
		filter?: Api.IContactFilterCriteria
	) => {
		if (!this.isBusy) {
			this.busy = true;
			try {
				await this.mAddCaseToSwitchStep(switchStep, filter);
				this.busy = false;
			} catch (error) {
				this.busy = false;
				throw error;
			}
		}
	};

	public addSegment = async (replaceLastEmptyStep = false) => {
		if (!this.isBusy) {
			try {
				this.busy = true;
				let switchStep = this.mSteps?.find(
					x => x.automationStep?.type === Api.AutomationStepType.Switch
				) as Api.AutomationTemplateEditorStep<Api.SwitchAutomationStepViewModel>;
				if (switchStep) {
					// add empty case
					this.mAddCaseToSwitchStep(switchStep);
				} else {
					const switchStepViewModel = await this.automationTemplate.addStep<
						Api.ISwitchAutomationStep,
						Api.SwitchAutomationStepViewModel
					>({
						_type: 'SwitchAutomationStep',
					});
					switchStep = new Api.AutomationTemplateEditorStep<Api.SwitchAutomationStepViewModel>(switchStepViewModel);

					// add empty case and default case
					try {
						await this.mAddCaseToSwitchStep(switchStep, undefined);
						await this.mAddCaseToSwitchStep(switchStep, undefined, true);
						let lastEmptyIndex = -1;
						if (replaceLastEmptyStep) {
							lastEmptyIndex = (this.mSteps || []).reverse().findIndex(x => !x.automationStep?.id);
						}
						if (lastEmptyIndex >= 0) {
							const nextSteps = [...(this.mSteps || [])];
							nextSteps.splice(lastEmptyIndex, 1, switchStep);
							this.mSteps = nextSteps;
						} else {
							await this.addStep(switchStep);
						}
					} catch (error) {
						await this.automationTemplate.removeStep(switchStepViewModel);
						throw error;
					}
				}

				this.busy = false;
				return switchStep;
			} catch (error) {
				this.busy = false;
				throw error;
			}
		}
	};

	/** @returns Steps, in order, that have missing info */
	public getIncompleteSteps = () => {
		const steps: Api.AutomationTemplateEditorStep[] = [];
		const validSteps = this.mSteps?.filter(x => !!x.automationStep?.id);
		const validChildren = this.children.reduce<Record<string, Api.AutomationTemplateEditorStep[]>>((result, x) => {
			result[x.automationTemplate.id] = [...(x.steps || []).filter(step => !!step.automationStep?.id)];
			return result;
		}, {});
		validSteps
			.filter(x => !!x.automationStep)
			.forEach(x => {
				if (x.automationStep.type === Api.AutomationStepType.Switch) {
					const switchStep = x.automationStep as Api.SwitchAutomationStepViewModel;
					(switchStep.cases || [])
						.map(caseStatement => caseStatement.automationTemplateId)
						.forEach(id => {
							(validChildren[id] || []).forEach(step => {
								if (!step.automationStep.hasAllRequiredInfo) {
									steps.push(step);
								}
							});
						});
				} else {
					if (!x.automationStep.hasAllRequiredInfo) {
						steps.push(x);
					}
				}
			});

		return steps;
	};

	protected mAddCaseToSwitchStep = (
		switchStep: Api.AutomationTemplateEditorStep<Api.SwitchAutomationStepViewModel>,
		filter?: Api.IContactFilterCriteria,
		isDefault = false
	) => {
		return switchStep.automationStep.addCase({
			filter: !isDefault
				? filter || {
						criteria: [
							{
								property: Api.VmUtils.Automations.triggers.isResourceSelectorTrigger(
									this.automationTemplateTrigger,
									Api.ResourceSelectorId.PolicyRenew
								)
									? Api.ContactFilterCriteriaProperty.Policy
									: Api.ContactFilterCriteriaProperty.Tag,
							},
						],
					}
				: null,
			isDefault,
		});
	};
}
