import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext } from '../../../../models';
import { copyToClipboard } from '../../../../models/UiUtils';
import { useToaster } from '../../../../models/hooks/appStateHooks';
import { AccountOperationsViewModel } from '../../../../viewmodels/AdminViewModels';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { TextInput } from '../../../../web/components/TextInput';
import { DangerMessage } from '../../../../web/components/errorMessages/DangerMessage';
import { ClipboardIcon } from '../../../../web/components/svgs/icons/ClipboardIcon';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { styleSheet } from './styles';

interface IProps extends IModalContext {
	className?: string;
	accountOperations: AccountOperationsViewModel;
}

export interface ISubmittedBlogOptions {
	blogDomain: string;
	allowedOrigins?: string[];
	dudaSiteId?: string;
}

export const CreateBlogDomain = observer(({ accountOperations }: IProps) => {
	const blogFeature = accountOperations.account?.features?.blogFeature;
	const websiteFeature = accountOperations.account?.features?.websites;
	const showDudaSiteId = blogFeature?.dudaSiteId || accountOperations.account?.features?.websites?.enabled;
	const toaster = useToaster();
	const [blogDomain, setBlogDomain] = React.useState(blogFeature.domain || null);
	const [dudaSiteId, setDudaSiteId] = React.useState(blogFeature.dudaSiteId || websiteFeature?.dudaSiteId || null);

	const isDisabled = !blogDomain?.length || accountOperations.isBusy;

	const onDomainChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		setBlogDomain(value);
	};

	const onDomainBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		let value = e.target.value;
		if (value.endsWith('/')) {
			value = value.slice(0, -1);
		}

		setBlogDomain(value);
	};

	const handleDudaSiteIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDudaSiteId(e.target.value);
	};

	const handleSaveClick = async () => {
		if (blogDomain?.length > 0 && !/^(http|https)/.test(blogDomain)) {
			toaster.push({
				message: 'Make sure to include http or https in the URL',
				type: 'errorMessage',
			});
			return;
		}

		// This is more of a just in case scenario, but if the user adds a trailing slash to the URL, we should remove it
		let blogDomainValue = blogDomain;
		if (blogDomainValue.endsWith('/')) {
			blogDomainValue = blogDomainValue.slice(0, -1);
		}

		const submittedValues: ISubmittedBlogOptions = {
			blogDomain: blogDomainValue,
			dudaSiteId,
		};

		try {
			if (submittedValues?.blogDomain?.length > 0) {
				await accountOperations.updateBlogFeatures({
					domain: submittedValues.blogDomain,
					enabled: true,
					allowedOrigins: submittedValues.allowedOrigins,
					dudaSiteId: submittedValues.dudaSiteId,
				});

				toaster.push({
					message: `${blogDomain} added successfully!`,
					type: 'successMessage',
				});
			}
		} catch {
			toaster.push({
				message: 'Failed to add blog domain',
				type: 'errorMessage',
			});
			return;
		}
	};

	const copyValue = (value: string) => {
		if (!value) {
			return;
		}

		copyToClipboard(value);

		toaster.push({
			message: `${value} copied to clipboard`,
			type: 'successMessage',
		});
	};

	const renderDnsMessage = () => {
		if (!showDudaSiteId) {
			const blogDomainWithoutProtocol = blogDomain?.replace(/(^\w+:|^)\/\//, '');
			return (
				<>
					<DangerMessage>
						<span>
							<span className={css(baseStyleSheet.fontBold)}>{blogDomainWithoutProtocol || `The link above`}</span>
						</span>
						{` must be set as a CNAME record in the customer's DNS provider to `}
						<span className={css(baseStyleSheet.fontBold)}>blog-proxy.levitate.ai</span>
					</DangerMessage>

					<table className={css(styleSheet.table, styleSheet.container)}>
						<thead className={css(styleSheet.tableHeader)}>
							<tr className={css(styleSheet.tableHeaderRow)}>
								<th className={css(styleSheet.tableHeaderCell)}>CNAME</th>
								<th className={css(styleSheet.tableHeaderCell)}>value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className={css(styleSheet.cell)}>
									{
										<span
											className={css(styleSheet.cellContainer)}
											onClick={() => {
												copyValue(blogDomainWithoutProtocol);
											}}
										>
											{blogDomainWithoutProtocol || `---`} {blogDomainWithoutProtocol && <ClipboardIcon />}
										</span>
									}
								</td>
								<td className={css(styleSheet.cell)}>
									<span
										className={css(styleSheet.cellContainer)}
										onClick={() => {
											copyValue('blog-proxy.levitate.ai');
										}}
									>
										blog-proxy.levitate.ai <ClipboardIcon />
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</>
			);
		}
	};

	return (
		<article className={css(styleSheet.container)}>
			<h3 className={css(styleSheet.header)}>Add a Blog Link</h3>
			<div className={css(styleSheet.formControl)}>
				<p className={css(styleSheet.paragraph)}>
					This should be where the blogs are intended to be hosted.
					<br />
					Example: <strong>{showDudaSiteId ? 'https://www.levitate.ai/blog' : 'https://blog.levitate.ai'}</strong>
				</p>
				<label htmlFor='blogDomain' className={css(styleSheet.label)}>
					Blog link
				</label>
				<TextInput
					inputClassName=''
					inputId='blogDomain'
					onChange={onDomainChanged}
					onBlur={onDomainBlur}
					type='text'
					value={blogDomain || ''}
					placeholder='Add Link to Blog'
				/>
			</div>
			{renderDnsMessage()}
			<br />
			{showDudaSiteId ? (
				<div className={css(styleSheet.formControl)}>
					<p className={css(styleSheet.paragraph)}>
						The <strong>DudaSiteId</strong> is required for the blog to be hosted on Duda.
					</p>
					<label htmlFor='DudaSiteId' className={css(styleSheet.label)}>
						Duda Site Id
					</label>
					<TextInput
						inputClassName=''
						inputId='dudaSiteId'
						onChange={handleDudaSiteIdChange}
						type='text'
						value={dudaSiteId || ''}
						placeholder='Duda site id'
					/>
				</div>
			) : null}
			<footer className={css(styleSheet.footer, baseStyleSheet.horizontalStack)}>
				<button className={css(baseStyleSheet.ctaButton)} disabled={isDisabled} onClick={handleSaveClick}>
					{accountOperations.isBusy ? <LoadingSpinner type='tiny' /> : null}
					<span>Save</span>
				</button>
			</footer>
		</article>
	);
});
