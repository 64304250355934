import * as Api from '@ViewModels';
import * as React from 'react';
import { EditAutomationTemplateStepsViewModel } from '../viewmodels/AutomationViewModels';

export interface IEditAutomationTemplateContext {
	canEdit?: boolean;
	onDraftUpdated?(): void;
	parentAutomationTemplate?: Api.AutomationTemplateViewModel<Api.UserSessionContext>;
}

export const EditAutomationTemplateContext = React.createContext<IEditAutomationTemplateContext>(null);

export enum AutomationStepAction {
	Copy = 'copy',
	Delete = 'delete',
	MoveDown = 'moveDown',
	MoveUp = 'moveUp',
}

export interface IEditAutomationTemplateStepsContext {
	editorStepElementRefs?: Record<string, React.MutableRefObject<HTMLElement>>;
	editorSteps: EditAutomationTemplateStepsViewModel;
}

export const EditAutomationTemplateStepsContext = React.createContext<IEditAutomationTemplateStepsContext>(null);

export const EditAutomationTemplateItemIdPrefix = 'edit-automation-template-item-scroll-anchor';

export enum AutomationsReportingTableColumn {
	Communication = 'Communication',
	Policy = 'Policy',
	Recipient = 'Recipient',
	StatusAndActions = 'Status / Actions',
}
