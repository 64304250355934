import * as Api from '@ViewModels';
import { UseMutationResult } from '@tanstack/react-query';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { dedupeByProvider } from '../../../../extViewmodels/Utils';
import { SocialMediaPostStatusDetails } from '../../../../models';
import { accountHasComplianceRequired, getStatusGroupOptions } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { ImpersonationContextViewModel } from '../../../../viewmodels/AdminViewModels';
import { Collapsible } from '../../../components/Collapsible';
import { CompoundButton, CompoundButtonType } from '../../../components/CompoundButton';
import { TextInput } from '../../../components/TextInput';
import { PopoverType, TinyPopover } from '../../../components/TinyPopover';
import { Toggle } from '../../../components/Toggle';
import { AdvancedOptionsButton } from '../../../components/automation/AutomationStepCard/presentation';
import { CampaignDetailsSummary } from '../../../components/campaigns/CampaignDetailsSummary';
import { PreviewSocialMediaPost } from '../../../components/socialMedia/PreviewSocialMediaPost';
import { DangerIcon } from '../../../components/svgs/icons/DangerIcon';
import { ExpiryIcon } from '../../../components/svgs/icons/ExpiryIcon';
import { InfoIcon } from '../../../components/svgs/icons/InfoIcon';
import { charmGray, warningDark } from '../../../styles/colors';
import { bs } from '../../../styles/styles';
import { AIAssistantButton } from '../../AIAssistantButton';
import { IEditSocialMediaPostContext } from './context';
import { styleSheet } from './styles';

interface SocialMediaNavigationProps {
	creatingPostWithEmptyInitialContent: boolean;
	onAIContentGenerationAssistantButtonClicked: () => void;
	canSaveTemplate: boolean;
	updateTemplateMutation: UseMutationResult<
		unknown,
		Api.IOperationResultNoValue,
		{
			template: Api.ITemplate;
		},
		unknown
	>;
	handleSave: () => void;
	onSaveAsTemplateClicked: () => void;
	newPost: boolean;
	impersonationCtx: Api.IImpersonationContext;
	context: IEditSocialMediaPostContext;
	onSaveAsButtonClicked: () => void;
	source: Api.SocialMediaPostReportViewModel | Api.SocialMediaPostViewModel;
	onShowCancelConfirmation: () => void;
	onShowUpdateConfirmation: () => void;
}

export const SocialMediaNavigation = ({
	creatingPostWithEmptyInitialContent,
	onAIContentGenerationAssistantButtonClicked,
	canSaveTemplate,
	updateTemplateMutation,
	handleSave,
	onSaveAsTemplateClicked,
	newPost,
	impersonationCtx,
	context,
	onSaveAsButtonClicked,
	source,
	onShowCancelConfirmation,
	onShowUpdateConfirmation,
}: SocialMediaNavigationProps) => {
	const userSession = useUserSession();
	return (
		<div
			className={css(
				bs.flex,
				bs.boxBorder,
				bs.justifyBetween,
				bs.px4,
				bs.py2,
				bs.border0,
				bs.borderB,
				bs.borderSolid,
				bs.borderMentionDark,
				bs.itemsCenter
			)}
		>
			{userSession.account.features?.contentGeneration?.enabled ? (
				<AIAssistantButton onClick={onAIContentGenerationAssistantButtonClicked}>
					{creatingPostWithEmptyInitialContent
						? 'Create this post with AI'
						: 'Rewrite this post using our AI assistant'}
				</AIAssistantButton>
			) : null}
			<div className={css(bs.flex, bs.itemsCenter, bs.gap2, bs.flex1, bs.justifyEnd)}>
				{canSaveTemplate ? (
					<CompoundButton
						buttonTitle={<span>Save</span>}
						disabled={updateTemplateMutation.isLoading}
						kind={CompoundButtonType.CtaPrimary}
						onClick={handleSave}
						openDirection='down'
						styleDeclaration={bs.h7}
					>
						<button
							className={css(bs.ctaButtonReverseSmall)}
							onClick={onSaveAsTemplateClicked}
							disabled={!context.postContent?.length}
						>
							<span>Save as</span>
						</button>
					</CompoundButton>
				) : (
					<button
						className={css(bs.ctaButtonReverseSmall)}
						onClick={onSaveAsTemplateClicked}
						disabled={!context.postContent?.length}
					>
						<span>Save as</span>
					</button>
				)}
				{newPost && !impersonationCtx ? (
					<button
						className={css(bs.ctaButtonSmall)}
						onClick={onSaveAsButtonClicked}
						disabled={!context.postContent?.length || context?.selectedCreator?.id !== userSession?.user?.id}
					>
						<TinyPopover
							align='end'
							anchor={<InfoIcon />}
							autoCloseOtherPopoversOnHover
							anchorStyles={[
								context?.selectedCreator?.id !== userSession?.user?.id ? styleSheet.iconContainer : styleSheet.iconHide,
							]}
							dismissOnOutsideAction
							placement={['top', 'right']}
							toggleOnHover
							type={PopoverType.lightBlue}
						>
							<div className={css(styleSheet.popover)}>
								Draft posts can only be saved when selecting yourself as sender.
							</div>
						</TinyPopover>
						<span>Save as draft</span>
					</button>
				) : null}
				{source?.status === Api.PostStatus.Draft ? (
					<button className={css(bs.ctaButtonDestructiveSmall)} onClick={onShowCancelConfirmation}>
						<span>Delete</span>
					</button>
				) : null}
				{source ? (
					<button className={css(bs.ctaButtonSmall)} onClick={onShowUpdateConfirmation} disabled={!context?.hasChanges}>
						<span>Save</span>
					</button>
				) : null}
			</div>
		</div>
	);
};

interface StatusSpecificDetailsProps {
	postToEdit: Api.SocialMediaPostViewModel;
	scheduledPost: Api.SocialMediaPostViewModel;
	context: IEditSocialMediaPostContext;
	isComplianceFlow: boolean;
	sendWithComplianceEmail: string;
	setSendWithComplianceEmail: (value: string) => void;
	isSchedulingInProgress: boolean;
	impersonationCtx: Api.IImpersonationContext;
	handleScheduleForLater: () => void;
	handleScheduleWithoutCompliance: () => void;
	handlePostNow: () => void;
	checkComplianceEmail: () => boolean;
	lastUsedComplianceRecipientEmail: string;
	handleChangeDate: () => void;
	onViewCitationsClicked: () => void;
	source: Api.SocialMediaPostReportViewModel | Api.SocialMediaPostViewModel;
}

export const StatusSpecificDetails = observer(
	({
		postToEdit,
		scheduledPost,
		context,
		isComplianceFlow,
		sendWithComplianceEmail,
		setSendWithComplianceEmail,
		isSchedulingInProgress,
		impersonationCtx,
		handleScheduleForLater,
		handleScheduleWithoutCompliance,
		handlePostNow,
		checkComplianceEmail,
		lastUsedComplianceRecipientEmail,
		handleChangeDate,
		onViewCitationsClicked,
		source,
	}: StatusSpecificDetailsProps) => {
		const userSession = useUserSession();
		const statusDetails = getStatusGroupOptions(postToEdit || scheduledPost);
		const account = impersonationCtx?.account || userSession.account;
		const socialMediaEnabled = account?.features?.socialMedia?.enabled;
		const complianceRequired = accountHasComplianceRequired(
			userSession,
			new ImpersonationContextViewModel(impersonationCtx)
		);
		const alwaysOverlayLogo =
			account?.features?.socialMedia?.alwaysOverlayLogo || // Check if the overlay has been overlayed by the user to show the toggle to override the account setting
			postToEdit?.overlayLogo ||
			scheduledPost?.overlayLogo;

		const [advancedOptionsToggled, setAdvancedOptionsToggled] = React.useState(context.overlayLogo);

		const showAdvanceOptions =
			socialMediaEnabled &&
			alwaysOverlayLogo &&
			context.postImages.length > 0 &&
			!context.postImages.some(x => x.hasLogoOverlay) &&
			statusDetails !== SocialMediaPostStatusDetails.CancelledOrFailed;

		const renderAdvancedOptionsTrigger = React.useMemo(() => {
			const handleToggle = () => {
				context.setOverlayLogo(!context.overlayLogo);
				context.setHasChanges(true);
			};
			return (
				<div className={css(styleSheet.advancedOptions)}>
					<AdvancedOptionsButton
						isCollapsed={!advancedOptionsToggled}
						onClick={() => setAdvancedOptionsToggled(!advancedOptionsToggled)}
					/>
					<Collapsible isCollapsed={!advancedOptionsToggled}>
						{!context.postImages.some(x => x.hasLogoOverlay) ? (
							<div className={css(bs.flex, bs.flexCenter, bs.gap2)}>
								<Toggle
									className={css(styleSheet.toggle)}
									id='logo-toggle'
									isOn={context.overlayLogo}
									text={!context.overlayLogo ? 'Overlay Logo Off' : 'Overlay Logo On'}
									onToggleCheckChanged={handleToggle}
									uncheckedColor='#B1B4B6'
								/>
								<TinyPopover
									align='end'
									anchor={<InfoIcon fillColor={charmGray} />}
									autoCloseOtherPopoversOnHover
									dismissOnOutsideAction
									anchorStyles={[styleSheet.infoPopoverToggle]}
									placement={['top', 'right']}
									toggleOnHover
									type={PopoverType.lightBlue}
								>
									<div className={css(styleSheet.popover)}>
										This will overlay your account&apos;s logo on the post&apos;s media at the moment the post gets
										posted. Click on the image preview to see the overlay.
									</div>
								</TinyPopover>
							</div>
						) : null}
					</Collapsible>
				</div>
			);
		}, [advancedOptionsToggled, context]);

		switch (statusDetails) {
			case SocialMediaPostStatusDetails.CancelledOrFailed:
				return (
					<>
						<div className={css(styleSheet.card)}>
							<div className={css(styleSheet.cardTitle)}>Send Time</div>
							<div className={css(styleSheet.cardActions)}>
								{scheduledPost ? (
									<time dateTime={scheduledPost.dueDate}>
										{moment(scheduledPost.dueDate).format('dddd, MMMM Do, h:mm A')}
									</time>
								) : (
									<time dateTime={source?.dueDate}>{moment(source?.dueDate).format('dddd, MMMM Do, h:mm A')}</time>
								)}
							</div>
						</div>
						{source?.status === Api.PostStatus.Cancelled ? (
							<div className={css(styleSheet.card)}>
								<div className={css(styleSheet.cardActions)}>
									<span className={css(styleSheet.cardSubSection)}>
										<DangerIcon />
										{context?.postTargets?.find(x => x.state?.status === Api.PostTargetStatus.Posted)
											? `Part of this post was cancelled on ${moment(source?.lastModifiedDate).format('MMMM Do, h:mm A')}`
											: `This post was cancelled on ${moment(source?.lastModifiedDate).format('MMM Do, h:mm A')}`}
									</span>
								</div>
							</div>
						) : null}
					</>
				);
			case SocialMediaPostStatusDetails.NewOrDraft:
				return (
					<>
						{source ? (
							<>
								<div className={css(styleSheet.card)}>
									<div className={css(styleSheet.cardTitle)}>Last Updated</div>
									<div className={css(styleSheet.cardActions)}>
										<time dateTime={source?.lastModifiedDate}>
											{moment(source?.lastModifiedDate).format('dddd, MMMM Do, h:mm A')}
										</time>
									</div>
								</div>
								{source?.actor && !postToEdit.actor.isLevitateSupport ? (
									<div className={css(styleSheet.card)}>
										<div className={css(styleSheet.cardTitle)}>Created By</div>
										<div className={css(styleSheet.cardActions)}>
											<span className={css(styleSheet.approvalStatusTitle)}>{source?.actor?.name}</span>
										</div>
									</div>
								) : null}
							</>
						) : null}
						<div className={css(styleSheet.ctaContainer)}>
							<div className={css(styleSheet.ctaContainerButtons)}>
								{isComplianceFlow && (
									<TextInput
										inputId='user-emailAddress'
										placeholder='Please enter Compliance Email'
										type='text'
										onBlur={() => {
											if (!checkComplianceEmail()) {
												return;
											}
											localStorage.setItem(lastUsedComplianceRecipientEmail, sendWithComplianceEmail);
										}}
										onChange={ev => {
											setSendWithComplianceEmail(ev.target.value);
										}}
										className={css(styleSheet.complianceInput)}
										value={sendWithComplianceEmail}
									/>
								)}
								<button
									className={css(bs.ctaButton)}
									disabled={!context.postTargets?.length || !context.postContent?.length || isSchedulingInProgress}
									onClick={handleScheduleForLater}
									style={{ marginBottom: 5 }}
								>
									<span>{isComplianceFlow ? 'Schedule Post with Compliance Pending' : 'Schedule for later'}</span>
								</button>
								{!impersonationCtx ? (
									<>
										{isComplianceFlow && !complianceRequired ? (
											// Only show this button if we are in the compliance flow and it is not required.
											// It's intentional that isComplianceFlow and complianceRequired are evaluated separately.

											<button
												className={css(bs.ctaButtonReverse)}
												disabled={
													!context.postTargets?.length || !context.postContent?.length || isSchedulingInProgress
												}
												onClick={handleScheduleWithoutCompliance}
												style={{ marginBottom: 5 }}
											>
												<span>Schedule without Compliance</span>
											</button>
										) : null}
										{userSession?.user?.id === context.selectedCreator?.id ? (
											<button
												className={css(bs.ctaButtonReverse)}
												disabled={
													!context.postTargets?.length || !context.postContent?.length || isSchedulingInProgress
												}
												onClick={handlePostNow}
												style={{ marginBottom: 5 }}
											>
												<span>Post Now</span>
											</button>
										) : null}
									</>
								) : null}
							</div>
							{showAdvanceOptions ? renderAdvancedOptionsTrigger : null}
						</div>
					</>
				);
			case SocialMediaPostStatusDetails.NotCancelledFailedDraftOrUnknown:
				return (
					<>
						<CampaignDetailsSummary
							campaign={scheduledPost}
							onChangeScheduleClicked={handleChangeDate}
							disabled={context?.hasChanges}
							onViewCitationsClicked={onViewCitationsClicked}
						/>
						{source?.status === Api.PostStatus.PartiallySucceeded || source?.status === Api.PostStatus.Started ? (
							<div className={css(styleSheet.card)}>
								<div className={css(styleSheet.cardActions)}>
									<span className={css(styleSheet.cardSubSection)}>
										<DangerIcon />
										{`Some targets for this post ${
											source?.status === Api.PostStatus.PartiallySucceeded ? 'were rejected.' : 'are still in progress.'
										}`}
									</span>
								</div>
							</div>
						) : null}
						{showAdvanceOptions ? renderAdvancedOptionsTrigger : null}
					</>
				);
			default:
				return;
		}
	}
);

interface DefaultComposerContextProps {
	context: IEditSocialMediaPostContext;
	expirationDateInEffect: boolean;
	onRenderStatusSpecificDetails: () => JSX.Element;
	overlayLogo: boolean;
	impersonationCtx: Api.IImpersonationContext;
	hideWhiteBackground: boolean;
}

export const DefaultComposerContext = ({
	context,
	expirationDateInEffect,
	onRenderStatusSpecificDetails,
	overlayLogo,
	impersonationCtx,
	hideWhiteBackground,
}: DefaultComposerContextProps) => {
	return (
		<>
			<PreviewSocialMediaPost
				postTargets={dedupeByProvider(context.postTargets)}
				overlayLogo={overlayLogo}
				postContent={context.postContent}
				postImages={context.postImages}
				impersonationCtx={impersonationCtx}
				hideWhiteBackground={hideWhiteBackground}
			>
				<>
					{expirationDateInEffect ? (
						<div className={css(styleSheet.expirationDateContainer)}>
							<ExpiryIcon fill={warningDark} />
							<span>
								{`This post is available to send until ${moment(context.template.schedule.expirationDate).format(
									'MM/DD/YY'
								)}`}
							</span>
						</div>
					) : null}
				</>
			</PreviewSocialMediaPost>

			{onRenderStatusSpecificDetails()}
		</>
	);
};
