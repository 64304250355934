import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	ToasterViewModelKey,
} from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { CampaignsApprovalViewModel, ICampaign, IOperationResultNoValue } from '../../../../viewmodels/AppViewModels';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../../../components/LocalNotificationObserver/WithNotificationService';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { EditCampaign } from '../../templates/EditCampaign';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IToasterComponentProps,
		Partial<RouteComponentProps<any>>,
		IModalContext,
		IErrorMessageComponentProps,
		INotificationServiceComponentProps<ICampaign[]> {
	approval: CampaignsApprovalViewModel;
	className?: string;
	onFinish?(approved: boolean): void;
	styles?: StyleDeclarationValue[];
}

interface IState {
	loading?: boolean;
	showingRejectionConfirmation?: boolean;
}

const RejectionConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isDestructive: true,
		representedObject: true,
		title: 'Reject',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

class _BulkEmailApprovalComposer extends React.Component<IProps, IState> {
	private mMounted: boolean;
	constructor(props: IProps) {
		super(props);
		this.state = {
			loading: true,
		};
	}

	public componentDidMount() {
		this.mMounted = true;
		const { approval, logApiError, toaster, parentModal } = this.props;

		const contentPromise = approval.campaignComposer.loadForEditing(approval.activeCampaign);
		if (contentPromise) {
			contentPromise
				.then(() => {
					this.setState({
						loading: false,
					});
				})
				.catch((error: IOperationResultNoValue) => {
					logApiError('CampaignLoadContent-Error', error);
					if (this.mMounted) {
						toaster.push({
							message: 'Unable to load campaign content',
							type: 'errorMessage',
						});
						parentModal?.onRequestClose();
					}
				});
		}
	}

	public componentWillUnmount() {
		this.mMounted = false;
	}

	public render() {
		const { className, styles, approval } = this.props;
		const { showingRejectionConfirmation, loading } = this.state;
		return (
			<div
				className={`${css(styleSheet.composerContainer, ...(styles || []))} bulk-email-approval-composer ${
					className || ''
				}`}
			>
				<MultiContainerHeader
					fullscreenHeader='View Campaign'
					onFullscreenRequestBack={this.onFullscreenRequestBack}
					onFullscreenRequestClose={this.onComposerRequestClose}
				/>
				{!!loading || !approval || !!approval?.campaignComposer?.isLoadingDefaultMessageContent ? (
					<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />
				) : (
					<>
						{!!approval?.campaignComposer && (
							<EditCampaign
								emailComposer={approval.campaignComposer}
								onContextFinishedEditingClicked={this.onShowSendOptionsClicked}
								onRejectClicked={this.showRejectionConfirmation}
								styles={[styleSheet.childRoute]}
							/>
						)}
						<ConfirmationDialog
							icon={<WarningIcon />}
							modalProps={{
								isOpen: !!showingRejectionConfirmation,
								onRequestClose: this.onRejectionConfirmationRequestClose,
							}}
							options={RejectionConfirmationOptions}
							title='Are you sure you want to reject this email campaign?'
						/>
					</>
				)}
			</div>
		);
	}

	private onShowSendOptionsClicked = () => {
		this.props.onFinish?.(true);
	};

	private onRejectionConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		const { approval, logEvent, logApiError, errorMessages, postNotification } = this.props;
		if (!canceled && !!result?.representedObject) {
			const campaign = approval.activeCampaign;
			const promise = approval.rejectCampaign();
			if (promise) {
				logEvent('RejectCampaign');
				promise
					.then(() => {
						postNotification({
							info: [campaign.toJs()],
							topic: Topics.EDIT_CAMPAIGNS_ITEM,
						});
						this.onFinishComposing(false)();
					})
					.catch((error: IOperationResultNoValue) => {
						logApiError(`RejectCampaign-Error`);

						errorMessages.pushApiError(error);
					});
			}
		}
		this.setState({
			showingRejectionConfirmation: false,
		});
	};

	private showRejectionConfirmation = () => {
		this.setState({
			showingRejectionConfirmation: true,
		});
	};

	private onComposerRequestClose = (_?: any, canceled?: boolean) => {
		const { logEvent, parentModal } = this.props;
		if (canceled) {
			logEvent('Cancel');
		}
		parentModal?.onRequestClose(null, canceled);
	};

	private onFinishComposing = (approve: boolean) => (e?: React.MouseEvent<HTMLElement>) => {
		const { onFinish } = this.props;
		e?.preventDefault();
		if (onFinish) {
			onFinish(approve);
		}
	};

	private onFullscreenRequestBack = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
		this.props.parentModal?.onRequestClose();
	};
}

const BulkEmailApprovalComposerAsObserver = observer(_BulkEmailApprovalComposer);
const BulkEmailApprovalComposerWithContext = inject(
	ModalChildComponentContextKey,
	ToasterViewModelKey,
	ErrorMessagesViewModelKey
)(BulkEmailApprovalComposerAsObserver);
const BulkEmailApprovalComposerWithNotificationService = withNotificationService(BulkEmailApprovalComposerWithContext);
export const BulkEmailApprovalComposer = withEventLogging(
	BulkEmailApprovalComposerWithNotificationService,
	'BulkEmailApprovalComposer'
);
