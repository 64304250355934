import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { useErrorMessages } from '../../../../aida/hooks';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import { useEventLogging } from '../../../../models/Logging';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	EmailCampaignBrowserViewModel,
	IScheduledSend,
	ITemplate,
	Industry,
	TemplatesViewModel,
} from '../../../../viewmodels/AppViewModels';
import { CampaignSearchInputField } from '../CampaignSearchInputField';
import { CampaignsByCategorySelector, ICampaignsByCategorySelectorComponent } from '../CampaignsByCategorySelector';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps {
	onCampaignByCategorySelectorRef?(ref?: ICampaignsByCategorySelectorComponent): void;
	onCampaignTemplateClicked?(template: ITemplate, schedule?: IScheduledSend, referrer?: string): void;
	onTemplatesLoaded?(): void;
	retainIndustryOnClearSearch?: boolean;
	selectedTemplate?: ITemplate;
	styles?: StyleDeclarationValue[];
	templates?: TemplatesViewModel;
	useWholeCardAsCTA?: boolean;
}

export const _CampaignSearch: React.FC<IProps> = props => {
	const {
		onCampaignByCategorySelectorRef,
		onCampaignTemplateClicked,
		onTemplatesLoaded,
		retainIndustryOnClearSearch,
		selectedTemplate,
		styles,
		templates: propsTemplates,
		useWholeCardAsCTA,
		impersonationContext,
	} = props;
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const logger = useEventLogging('CampaignSearch');
	const account = impersonationContext?.isValid ? impersonationContext.account : userSession?.account;
	const [industry, setIndustry] = React.useState(account?.additionalInfo?.industry);
	const [searchQuery, setSearchQuery] = React.useState('');
	const [campaigns] = React.useState(new EmailCampaignBrowserViewModel(userSession).impersonate(impersonationContext));
	const [templates] = React.useState(
		propsTemplates || new TemplatesViewModel(userSession).impersonate(campaigns.impersonationContext)
	);

	const onTemplateClicked = async (templateId: string) => {
		try {
			const template = await templates.getByIdExpandedByLastUsedBy(templateId);
			onCampaignTemplateClicked?.(template);
		} catch (error) {
			errorMessages.pushApiError(error);

			logger.logApiError('TemplateLoad-Error', error);
		}
	};

	const onCampaignSearch = (query: string, newIndustry: Industry) => {
		setSearchQuery(query);
		campaigns.search(query, newIndustry)?.catch(error => {
			errorMessages.pushApiError(error);
			logger.logApiError('CampaignsSearch-Error', error);
		});
	};
	return (
		<div className={css(styleSheet.container, ...styles)}>
			<div className={css(styleSheet.searchContainer, styleSheet.searchContainerNoButton)}>
				<CampaignSearchInputField onChange={onCampaignSearch} industry={industry} />
			</div>
			<CampaignsByCategorySelector
				campaignBrowser={campaigns}
				supportedCampaignTypes={[CampaignType.Email]}
				cardContainerStyles={[styleSheet.cardContainer]}
				cardCtaText='Select'
				isSearch={!!searchQuery}
				onCampaignTemplateClicked={onTemplateClicked}
				onIndustrySelected={setIndustry}
				onRef={onCampaignByCategorySelectorRef}
				onTemplatesLoaded={onTemplatesLoaded}
				retainIndustryOnClearSearch={retainIndustryOnClearSearch}
				selectedTemplate={selectedTemplate}
				selectedTemplateType={CampaignType.Email}
				showIndustrySelector={true}
				styles={[styleSheet.campaignSelector]}
				templates={templates}
				useWholeCardAsCTA={useWholeCardAsCTA}
			/>
		</div>
	);
};

const CampaignSearchAsObserver = observer(_CampaignSearch);
export const CampaignSearch = inject(ImpersonationContextKey)(CampaignSearchAsObserver);
