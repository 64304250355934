import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';

const BASE_KEY = '/user/usersWithSocial';

export function useUsersWithSocialMediaConnection<T = Api.IUser>({
	transformer,
	userSession,
}: {
	transformer?: (users: Api.IUser[]) => T[];
	userSession: Api.UserSessionContext;
}) {
	return useQuery({
		queryFn: async () => {
			const users = await userSession.webServiceHelper.callAsync<Api.IUser[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `user/usersWithSocial`,
				}),
				'GET'
			);

			return transformer ? transformer(users) : (users as T[]);
		},
		queryKey: [BASE_KEY],
	});
}
