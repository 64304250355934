import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		width: 200,
		gap: 16,
	},
	container: {
		padding: '20px 0',
	},
	cardsGrid: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
		gap: '20px',
		padding: '20px 0',
	},
	icon: {
		width: '100%',
		height: '100%',
	},
});
