import * as React from 'react';
import { AttachmentsViewModel, IImpersonationContext } from '../../../extViewmodels';
import { IPixabayImage, IPixabayImageSearchOptions } from '../../../models/Pixabay';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export const useMediaChooserContextController = ({
	imageSearchOptions,
	impersonationContext,
}: {
	impersonationContext?: IImpersonationContext;
	imageSearchOptions?: IPixabayImageSearchOptions;
} = {}) => {
	const userSession = useUserSession();
	const [selectedImage, setSelectedImage] = React.useState<IPixabayImage>(null);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [showSuggestions, setShowSuggestions] = React.useState<boolean>(true);
	const [showSuggestionsNoResults, setShowSuggestionsNoResults] = React.useState<boolean>(false);

	const createFileAttachmentFromSelectedImageAsync = React.useCallback(
		async (fileName?: string) => {
			if (selectedImage && !isLoading) {
				const attachments = new AttachmentsViewModel(userSession).impersonate(impersonationContext);
				const name = fileName ?? attachments.getUpdateForSelectedImage(selectedImage);
				const url =
					Math.max(imageSearchOptions?.min_height || 0, imageSearchOptions?.min_width || 0) > 640 &&
					Boolean(selectedImage.largeImageURL)
						? selectedImage.largeImageURL
						: selectedImage.webformatURL;
				const opResult = await attachments.uploadImageByUrl(url, name ?? null);
				return opResult.value;
			}
		},
		[
			imageSearchOptions?.min_height,
			imageSearchOptions?.min_width,
			impersonationContext,
			isLoading,
			selectedImage,
			userSession,
		]
	);

	const context = React.useMemo(
		() => ({
			isLoading,
			createFileAttachmentFromSelectedImageAsync,
			selectedImage,
			setIsLoading,
			setSelectedImage,
			setShowSuggestions,
			setShowSuggestionsNoResults,
			showSuggestions,
			showSuggestionsNoResults,
			imageSearchOptions,
		}),
		[
			isLoading,
			imageSearchOptions,
			selectedImage,
			showSuggestionsNoResults,
			showSuggestions,
			createFileAttachmentFromSelectedImageAsync,
		]
	);

	return context;
};

export type MediaChooserContextType = ReturnType<typeof useMediaChooserContextController>;

export const MediaChooserContext = React.createContext<MediaChooserContextType>(null);

export const useMediaChooserContext = () => {
	const context = React.useContext(MediaChooserContext);
	if (!context) {
		throw new Error(`This component requires the use of "MediaChooserContext"`);
	}
	return context;
};
