import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
	AutomationStepAction,
	EditAutomationTemplateContext,
	EditAutomationTemplateStepsContext,
	IEditAutomationTemplateStepsContext,
} from '../../../../models/Automations';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../models/hooks/useLambda';
import { EditAutomationTemplateStepsViewModel } from '../../../../viewmodels/AutomationViewModels';
import { navigation } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { MoreMenu, MoreMenuItem } from '../../MoreMenu';
import { TextInputModal } from '../../TextInputModal';
import { AutomationTemplateStepIndicatorIcon } from '../../svgs/icons/AutomationTemplateStepIndicator';
import { EditItemPenIcon } from '../../svgs/icons/EditItemPenIcon';
import { RoundAddIcon } from '../../svgs/icons/RoundAddIcon';
import { EditAutomationTemplateStepCardHeader } from '../AutomationStepCard/presentation';
import { EditAutomationTemplateItemBumpers } from '../EditAutomationTemplateItemBumpers';
import { EditAutomationTemplateSegmentHeader } from '../EditAutomationTemplateSegmentHeader';
import { EditAutomationTemplateSteps } from '../EditAutomationTemplateSteps';
import { styleSheet } from './styles';

interface IEditAutomationTemplateSegmentProps {
	caseStatement: Api.IAutomationStepCaseStatement;
	switchStep: Api.AutomationTemplateEditorStep<Api.SwitchAutomationStepViewModel>;
}

export const EditAutomationTemplateSegment: React.FC<IEditAutomationTemplateSegmentProps> = observer(props => {
	const { switchStep, caseStatement } = props;
	const automationTemplate = switchStep?.automationStep?.getTemplateForCase(caseStatement);
	const { onDraftUpdated } = React.useContext(EditAutomationTemplateContext);
	const stepsContext = React.useContext(EditAutomationTemplateStepsContext);
	const { logApiError, logEvent } = useEventLogging('EditAutomationSegment');
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const index = caseStatement ? switchStep?.automationStep?.cases?.findIndex(x => x.id === caseStatement.id) : -1;

	// #region IEditAutomationTemplateStepsContext
	const [editorStepsVm, setEditorStepsVm] = React.useState<EditAutomationTemplateStepsViewModel>(
		automationTemplate
			? new EditAutomationTemplateStepsViewModel(userSession, automationTemplate).impersonate(
					automationTemplate?.impersonationContext
				)
			: null
	);

	// add ref to these steps to parent editor steps
	React.useEffect(() => {
		if (!stepsContext?.editorSteps?.children.has(editorStepsVm)) {
			stepsContext.editorSteps.children.add(editorStepsVm);
		}
		return () => {
			stepsContext?.editorSteps?.children.removeItems([editorStepsVm]);
		};
	}, [stepsContext?.editorSteps, editorStepsVm]);
	const [editorStepsContext, setEditorStepsContext] = React.useState<IEditAutomationTemplateStepsContext>({
		editorStepElementRefs: {},
		editorSteps: editorStepsVm,
	});
	React.useEffect(() => {
		const vm = new EditAutomationTemplateStepsViewModel(userSession, automationTemplate).impersonate(
			automationTemplate?.impersonationContext
		);
		unstable_batchedUpdates(() => {
			setEditorStepsVm(automationTemplate ? vm : null);
			setEditorStepsContext({ editorStepElementRefs: {}, editorSteps: vm });
		});
		logEvent('EditAutomationTemplateStepsLoad');
		vm?.load()?.catch(error => {
			logApiError('EditAutomationTemplateStepsLoad-Error', error);

			errorMessages.pushApiError(error);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [automationTemplate]);
	// #endregion

	const onAddStepClicked = React.useCallback(async () => {
		editorStepsVm.addStep();
	}, [editorStepsVm]);

	const onMenuItemClicked = React.useCallback(
		async (action: AutomationStepAction) => {
			switch (action) {
				case AutomationStepAction.Delete: {
					const nonDefaultSteps = switchStep.automationStep?.cases?.filter(x => !x.isDefault) || [];
					if (nonDefaultSteps.length === 1) {
						// bubble up and remove the switch step
						try {
							logEvent('RemoveSwitchStep');
							await stepsContext?.editorSteps?.executeActionForStep(switchStep, action);
							onDraftUpdated?.();
						} catch (error) {
							logApiError('RemoveSwitchStep-Error', error);

							errorMessages.pushApiError(error);
						}
					} else {
						// remove just the case/segment
						try {
							logEvent('RemoveSwitchStepCase', {
								caseStatementId: caseStatement.id,
							});
							await switchStep.automationStep.removeCase(caseStatement);
							onDraftUpdated?.();
						} catch (error) {
							logApiError('RemoveSwitchStepCase-Error', error);

							errorMessages.pushApiError(error);
						}
					}
					break;
				}
				default: {
					break;
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[stepsContext?.editorSteps, switchStep, caseStatement, onDraftUpdated]
	);
	// #endregion

	// #region rename modal
	const [renameModalProps, setRenameModalProps, setRenamModalLambda] = useLambda(
		{
			isOpen: false,
			onRequestClose: null,
		},
		[]
	);
	const renameModalPropsRef = React.useRef(renameModalProps);
	renameModalPropsRef.current = renameModalProps;

	const onRenameModalRequestClose = React.useCallback(
		async (nextName?: string, canceled?: boolean) => {
			const name = nextName?.trim();
			setRenameModalProps({
				...renameModalPropsRef.current,
				isOpen: false,
			});
			if (!canceled && !!name) {
				try {
					logEvent('RenameSegment', {
						caseStatementId: caseStatement?.id,
						nameLength: name.length,
					});

					await switchStep?.automationStep?.updateCase(caseStatement?.id, {
						...(caseStatement || {}),
						name,
					});
				} catch (error) {
					errorMessages.pushApiError(error);

					logApiError('RenameSegment-Error', error);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[caseStatement, switchStep?.automationStep]
	);
	React.useEffect(() => {
		setRenameModalProps({
			...renameModalPropsRef.current,

			onRequestClose: onRenameModalRequestClose,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onRenameModalRequestClose]);
	// #endregion

	const onRenderTitle = React.useCallback(() => {
		return (
			<div className={css(baseStyleSheet.horizontalStack)}>
				<div className={css(baseStyleSheet.truncateText, styleSheet.cardHeaderTitleName)}>
					{`Segment ${index + 1}: ${caseStatement?.name || 'Add name'}`}
				</div>
				<button
					className={css(styleSheet.segmentEditNameButton)}
					onClick={setRenamModalLambda({ ...renameModalProps, isOpen: true })}
				>
					<EditItemPenIcon />
				</button>
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [renameModalProps, caseStatement?.name, index]);

	const styles = React.useRef([styleSheet.segment]).current;

	const onRenderLeftBumper = React.useCallback(() => {
		return (
			<div className={css(styleSheet.indicator)}>
				<AutomationTemplateStepIndicatorIcon step={switchStep?.automationStep} styles={[styleSheet.indicatorIcon]} />
				<div className={css(styleSheet.indicatorHorizontalLine)} />
				<div className={css(styleSheet.indicatorVerticalLine, styleSheet.indicatorVerticalLineBottom)} />
				<div className={css(styleSheet.indicatorVerticalLine, styleSheet.indicatorVerticalLineTop)} />
			</div>
		);
	}, [switchStep]);

	const cardHeaderStyles = React.useRef<StyleDeclarationValue[]>([styleSheet.segmentCardHeader]).current;
	return (
		<EditAutomationTemplateItemBumpers
			onRenderLeftBumperContent={onRenderLeftBumper}
			scrollAnchorId={caseStatement?.id}
			styles={styles}
		>
			<EditAutomationTemplateStepCardHeader
				canEdit={automationTemplate?.canEdit}
				ActionMenu={
					caseStatement?.isDefault ? null : (
						<MoreMenu>
							<MoreMenuItem onClick={() => onMenuItemClicked(AutomationStepAction.Delete)}>Delete Segment</MoreMenuItem>
						</MoreMenu>
					)
				}
				onRenderTitle={onRenderTitle}
				styles={cardHeaderStyles}
			/>
			{editorStepsVm?.isLoaded ? (
				<EditAutomationTemplateStepsContext.Provider value={editorStepsContext}>
					<EditAutomationTemplateSegmentHeader
						automationTemplate={automationTemplate}
						caseStatement={caseStatement}
						switchStep={switchStep}
					/>
					<div className={css(styleSheet.segmentSteps)}>
						<EditAutomationTemplateSteps />
					</div>
					<div className={css(styleSheet.segmentStepsFooter)}>
						<button
							className={css(baseStyleSheet.horizontalStack, styleSheet.segmentAddStepButton)}
							onClick={onAddStepClicked}
						>
							<RoundAddIcon fillColor={navigation} />
							<span>Add another step for this segment</span>
						</button>
					</div>
				</EditAutomationTemplateStepsContext.Provider>
			) : (
				<LoadingSpinner type='small' />
			)}

			{index >= 0 && (
				<TextInputModal
					cta='Rename'
					initialValue={caseStatement?.name}
					modalProps={renameModalProps}
					placeholderText='Please enter a name...'
					title={`Rename Segment ${index + 1}`}
					type='text'
				/>
			)}
		</EditAutomationTemplateItemBumpers>
	);
});
