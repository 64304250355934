import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import { AIContentGenerationStatus } from '../../../../models/Ai';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { postNotification } from '../../../../models/LocalNotifications';
import { useEventLogging } from '../../../../models/Logging';
import { accountHasComplianceEnabled } from '../../../../models/UiUtils';
import {
	useErrorMessages,
	useFullscreenModal,
	useToaster,
	useUserSession,
} from '../../../../models/hooks/appStateHooks';
import {
	useBlogGenerationMutation,
	useBlogUpdateMutation,
	useDeleteBlogPostMutation,
	useGetSystemJobQuery,
} from '../../../../queries';
import {
	ConfirmationDialog,
	DefaultDeleteConfirmationOptions,
	IConfirmationDialogOption,
} from '../../../components/ConfirmationDialog';
import { AIContentGenerationWizard } from '../../../components/ai/AIContentGenerationWizard';
import { AIContentGenerationStatusContext } from '../../../components/ai/AIContentGenerationWizard/context';
import {
	AIContentGenerationWizardAssistantBlogPostMessages,
	AIContentGenerationWizardStep,
	AIContentGenerationWizardType,
} from '../../../components/ai/AIContentGenerationWizard/models';
import { ComplianceApprovalPrompt } from '../../../components/campaigns/ComplianceApprovalPrompt';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { BlogPostEditor } from './BlogPostEditor';
import { BlogPostEditorContext, REDUCER_ACTION_KEYS, useBlogPostEditingRequest, validateContent } from './hooks';
import { IBlogPostComponentProps } from './models';
import { BlogPostEditorLayout } from './presentation';
import { styleSheet } from './styles';

function CreateBlogPostFromTemplateBase({
	onScheduleClicked,
	impersonationContext,
}: IBlogPostComponentProps & IImpersonationContextComponentProps) {
	const fullscreenModal = useFullscreenModal();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('CreateBlogPostFromTemplate');
	const userSession = useUserSession();
	const requiresCompliance = accountHasComplianceEnabled(userSession, impersonationContext);

	const { postEditorContext, blogPost, setBlogPost, request } = useBlogPostEditingRequest({
		impersonationContext,
	});
	const templateCard = request?.templateCard || request?.template;
	const { state, dispatch } = postEditorContext;

	const updateBlogPostMutation = useBlogUpdateMutation({
		impersonationContext: impersonationContext?.toJs(),
		onSuccess: blog => {
			setBlogPost(blog);
			toaster.push({
				message: 'Blog post saved',
				type: 'successMessage',
			});
			postNotification({
				info: blogPost,
				topic: Topics.EDIT_BLOG_POST,
			});
		},
	});

	const deleteBlogPostMutation = useDeleteBlogPostMutation({
		impersonationContext: impersonationContext?.toJs(),
		onSuccess: () => {
			postNotification({
				info: blogPost,
				topic: Topics.EDIT_BLOG_POST,
			});
			fullscreenModal.dismissModal();
		},
		onError: error => {
			errorMessages.pushApiError(error);
		},
	});

	const blogGenerationMutation = useBlogGenerationMutation({
		impersonationContext: impersonationContext?.toJs(),
		onError: error => {
			errorMessages.pushApiError(error);
			toaster.push({
				message: 'Failed to generate blog',
				type: 'errorMessage',
			});
			logApiError('CreateBlogPost-Error', error);
		},
		onSuccess: (val: Api.IBlogGenerationSystemJob) => {
			if (val.generatedContent) {
				dispatch({
					type: REDUCER_ACTION_KEYS.UPDATE_WITH_BLOG_POST,
					payload: val.generatedContent.content,
				});
			} else {
				dispatch({ type: REDUCER_ACTION_KEYS.BLOG_GENERATION_START, payload: { id: val.id } });
			}
		},
	});

	const isSystemJobQueryEnabled = Boolean(state.blogGenerationJobId);
	// Note: Controller does not support impersonation
	useGetSystemJobQuery<Api.IBlogGenerationSystemJob>({
		enabled: isSystemJobQueryEnabled,
		jobId: state.blogGenerationJobId,
		onSuccess: data => {
			if (data?.generatedContent) {
				dispatch({
					type: REDUCER_ACTION_KEYS.SYSTEM_JOB_SUCCESS,
					payload: data.generatedContent.content,
				});
			}
			if (data?.percentComplete === 100 && !data?.generatedContent) {
				toaster.push({
					message: 'Blog generation failed',
					type: 'errorMessage',
				});
				dispatch({ type: REDUCER_ACTION_KEYS.BLOG_GENERATION_FAILED });
			}
		},
		refetchInterval: data => {
			if (!state.blogGenerationJobId) {
				return false;
			}
			return !data?.generatedContent ? 3000 : false;
		},
		refetchOnWindowFocus: false,
	});

	const contentGenerationStatusContext = React.useMemo(() => {
		const status =
			blogGenerationMutation?.isLoading || isSystemJobQueryEnabled
				? AIContentGenerationStatus.Generating
				: AIContentGenerationStatus.Idle;
		return { contentGenerationStatus: status };
	}, [blogGenerationMutation?.isLoading, isSystemJobQueryEnabled]);

	React.useEffect(() => {
		if (templateCard) {
			dispatch({ type: REDUCER_ACTION_KEYS.IMAGE_SELECTED, payload: { image: templateCard.attachments?.[0] } });
		}
	}, [dispatch, templateCard]);

	React.useEffect(() => {
		if (!blogPost) {
			blogGenerationMutation.mutate({
				template: {
					prompt: templateCard?.content,
					id: templateCard?.id,
				},
			});
			return;
		}

		dispatch({
			type: REDUCER_ACTION_KEYS.UPDATE_WITH_BLOG_POST,
			payload: blogPost,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleScheduleClick = (sendWithCompliance = false, sendWithComplianceEmail?: string) => {
		const errors = validateContent(state);
		if (errors.length !== 0) {
			errorMessages.push({
				messages: errors,
			});
			return;
		}

		const postToSave: Api.IBlogPost = {
			...blogPost,
			content: state.content.getRawRichTextContent(),
			title: state.title,
			mainImage: state.selectedImage,
			id: state.blogId,
			templateId: templateCard?.id,
			sendFromUserId: state.sendFromUser?.id,
			scheduledSendDate: blogPost?.scheduledSendDate || request?.schedule?.startDate,
			sendWithCompliance,
			sendWithComplianceEmail,
		};

		setBlogPost(postToSave); // context
		if (onScheduleClicked?.(postToSave)) {
			return;
		}
		fullscreenModal.history.push({
			pathname: '/blog/post/schedule',
		});
	};

	const onRetryClicked = React.useCallback(() => {
		dispatch({ type: REDUCER_ACTION_KEYS.RESET_BLOG_POST });
		blogGenerationMutation.mutate({
			template: {
				prompt: templateCard?.content,
				id: templateCard?.id,
			},
		});
	}, [blogGenerationMutation, dispatch, templateCard?.content, templateCard?.id]);

	const handleSaveClick = () => {
		const postToSave: Api.IBlogPost = {
			content: state.content.getRawRichTextContent(),
			title: state.title,
			mainImage: state.selectedImage,
			id: state.blogId,
			templateId: templateCard.id,
			sendFromUserId: state.sendFromUser?.id,
			status: state?.status,
		};

		updateBlogPostMutation.mutate({ post: postToSave });
	};

	const handleDeleteClick = () => {
		dispatch({ type: REDUCER_ACTION_KEYS.DELETE_BLOG_POST, payload: { value: true } });
	};
	const onDeleteConfirmationRequestClose = React.useCallback(
		(result?: IConfirmationDialogOption<boolean>, cancel?: boolean) => {
			if (result?.isDestructive && !cancel) {
				deleteBlogPostMutation.mutate({ id: state.blogId });
				dispatch({ type: REDUCER_ACTION_KEYS.DELETE_BLOG_POST, payload: { value: false } });
			}
		},
		[deleteBlogPostMutation, dispatch, state.blogId]
	);

	return (
		<BlogPostEditorContext.Provider value={postEditorContext}>
			<BlogPostEditorLayout
				status={state.status}
				header='Generate Blog'
				content={<BlogPostEditor />}
				subHeader={
					<>
						<h1>New Blog</h1>
						<menu className={css(styleSheet.menu)}>
							{impersonationContext?.isValid ? null : (
								<li>
									<button type='button' className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={handleSaveClick}>
										Save Blog Post
									</button>
								</li>
							)}
							{state.blogId ? (
								<li>
									<button
										type='button'
										className={css(baseStyleSheet.ctaButtonDestructiveSmall)}
										onClick={handleDeleteClick}
									>
										Delete
									</button>
								</li>
							) : null}
						</menu>
					</>
				}
				sidebar={
					<>
						{state.showingAiContentGenWizard ? (
							<>
								<AIContentGenerationStatusContext.Provider value={contentGenerationStatusContext}>
									<AIContentGenerationWizard<
										Api.IRawRichTextContentState,
										Api.IAIGeneratedContent<Api.IRawRichTextContentState>
									>
										cautionMessage='Please review content created with AI before posting.'
										onCloseClicked={() => {
											dispatch({ type: REDUCER_ACTION_KEYS.AI_WIZARD_CLOSED });
										}}
										onRetryClicked={onRetryClicked}
										type={AIContentGenerationWizardType.BlogPost}
										assistantMessageMap={AIContentGenerationWizardAssistantBlogPostMessages}
										setInitialStep={AIContentGenerationWizardStep.RetryPrompt}
									/>
								</AIContentGenerationStatusContext.Provider>
							</>
						) : null}
						{state.showScheduleAction ? (
							requiresCompliance ? (
								<ComplianceApprovalPrompt
									campaignType={CampaignType.Blog}
									onScheduleCompliance={(e: React.MouseEvent<HTMLElement>, sendWithComplianceEmail: string) => {
										e.preventDefault();
										handleScheduleClick(true, sendWithComplianceEmail);
									}}
									onScheduleWithoutCompliance={() => handleScheduleClick()}
								/>
							) : (
								<>
									<h2 className={css(styleSheet.question)}>What would you like to do with this blog?</h2>
									<button type='button' className={css(baseStyleSheet.ctaButton)} onClick={() => handleScheduleClick()}>
										Schedule
									</button>
								</>
							)
						) : null}
						<ConfirmationDialog
							icon={<WarningIcon />}
							modalProps={{
								isOpen: state.showConfirmationModal,
								onRequestClose: onDeleteConfirmationRequestClose,
							}}
							options={DefaultDeleteConfirmationOptions}
							title='Are you sure you want to delete this blog post?'
						/>
					</>
				}
				sidebarStyles={[state.showScheduleAction ? styleSheet.sidebarCenter : null]}
			/>
		</BlogPostEditorContext.Provider>
	);
}

export const CreateBlogPostFromTemplate = inject(ImpersonationContextKey)(observer(CreateBlogPostFromTemplateBase));
