import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import { getSocialMediaPlatformLogoUrl, isScheduledOrPendingOrDraftOrNew } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../models/hooks/useLambda';
import { useEditSocialMediaPostContext } from '../../../containers/socialMedia/EditSocialMediaPost/context';
import { baseStyleSheet } from '../../../styles/styles';
import { Checkbox } from '../../Checkbox';
import { PopoverType, TinyPopover } from '../../TinyPopover';
import { CancelIconFill } from '../../svgs/icons/CancelIconFill';
import { SocialMediaPostTargetStatusIndicators } from '../SocialMediaPostTargetStatusIndicators';
import { SocialMediaPlatformTableType } from './models';
import { styleSheet } from './styles';

interface ISocialMediaPlatformRowProps extends IImpersonationContextComponentProps {
	connection: Api.ISocialMediaConnection;
	onSelectedChanged(selected: boolean, connection: Api.ISocialMediaConnection): void;
	postSource?: Api.SocialMediaPostReportViewModel | Api.SocialMediaPostViewModel;
	postTarget?: Api.IPostTarget;
	selected: boolean;
	type: SocialMediaPlatformTableType;
}

const _SocialMediaPlatformRow: React.FC<ISocialMediaPlatformRowProps> = props => {
	const { connection, selected, onSelectedChanged, type, postTarget, postSource } = props;
	const [showDisconnectedPopover, , setDisconnectedPopover] = useLambda(false);
	const { logInput } = useEventLogging();
	const userSession = useUserSession();
	const { selectedCreator } = useEditSocialMediaPostContext();

	const onChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onSelectedChanged(e.target.checked, connection);
		},
		[connection, onSelectedChanged]
	);

	const onReconnectLinkClicked = React.useCallback(() => {
		const { origin, pathname } = window.location;
		window?.open(`${origin}${pathname}#/settings/social-media/social-media`, '_blank');
		logInput('ReconnectAttemptLink', 'Click');
	}, [logInput]);

	const scheduledOrPendingOrDraftOrNew = React.useMemo(() => {
		return isScheduledOrPendingOrDraftOrNew(postSource);
	}, [postSource]);

	if (!scheduledOrPendingOrDraftOrNew && !selected) {
		// return if post exists and is completed and connection is not selected
		return null;
	}

	return connection && connection?.userName !== 'Unknown' ? (
		<li className={css(styleSheet.listContainer)}>
			<div className={css(styleSheet.names)}>
				{postTarget && !scheduledOrPendingOrDraftOrNew ? (
					<SocialMediaPostTargetStatusIndicators
						postStatus={postSource?.status}
						postTarget={postTarget}
						showTargetProvider={false}
					/>
				) : (
					<div
						className={css(
							styleSheet.checkboxContainer,
							selected && styleSheet.checkboxContainerSelected,
							(connection?.state === Api.SocialMediaConnectionState.Disconnected ||
								connection?.state === Api.SocialMediaConnectionState.NoTarget ||
								connection?.state === Api.SocialMediaConnectionState.Unknown) &&
								styleSheet.disconnected
						)}
					>
						<Checkbox
							backgroundFillColor='white'
							checked={selected}
							childrenPosition='right'
							disabled={type === SocialMediaPlatformTableType.ReadOnly || (postSource && !postSource.canEdit)}
							id={`${connection?.postTargetId} - ${connection?.userId} - ${connection?.type}`}
							onChange={onChange}
						/>
						<img className={css(styleSheet.iconRow)} src={getSocialMediaPlatformLogoUrl(connection?.type)} />
						<span className={css(styleSheet.nameAdjustment)}>{connection?.type}</span>
					</div>
				)}
			</div>
			<div className={css(styleSheet.accountLogin, styleSheet.tableRowElement, baseStyleSheet.flex)}>
				<p>{connection?.userName}</p>
				{connection?.state !== Api.SocialMediaConnectionState.Connected && scheduledOrPendingOrDraftOrNew && (
					<>
						<TinyPopover
							align='end'
							anchor={
								<div
									onMouseEnter={setDisconnectedPopover(true)}
									onMouseLeave={setDisconnectedPopover(false)}
									className={css(styleSheet.cancelMargin)}
								>
									<CancelIconFill />
								</div>
							}
							styles={[styleSheet.popoverMargin]}
							dismissOnOutsideAction={true}
							isOpen={showDisconnectedPopover}
							placement={['bottom', 'right', 'top', 'left']}
							type={PopoverType.errorSecondary}
						>
							<p className={css(styleSheet.popover)}>Disconnected </p>
						</TinyPopover>

						{selected && !props.impersonationContext && selectedCreator.id === userSession.user.id ? (
							<button
								className={css(baseStyleSheet.ctaButtonSmall, styleSheet.reconnectButton)}
								onClick={onReconnectLinkClicked}
							>
								<span>Reconnect</span>
							</button>
						) : null}
					</>
				)}
			</div>
			<p className={css(styleSheet.postLocation, styleSheet.tableRowElement)}>
				{`@${connection?.postTargetDisplayName || connection?.userName}`}
			</p>
		</li>
	) : null;
};

export const SocialMediaPlatformRow = inject(ImpersonationContextKey)(_SocialMediaPlatformRow);
