import { StyleSheet } from 'aphrodite';
import {
	borderColor,
	brandPrimary,
	brandSecondary,
	brandSecondaryHover,
	destructive,
	errorLight,
	success,
	titles,
	warningDark,
} from '../../../../styles/colors';
import { baseStyleSheet, CssSelectors, truncateTextStyle } from '../../../../styles/styles';

const interItemSpacing = 40;

export const styleSheet = StyleSheet.create({
	action: {
		color: '#929292',
		fontSize: '11px',
		fontStyle: 'normal',
		fontWeight: 500,
		letterSpacing: '1.1px',
		lineHeight: '15px',
		textTransform: 'uppercase',
	},
	analytics: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		flex: 1,
		minWidth: 100,
		...truncateTextStyle,
	},
	analyticsContent: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		textAlign: 'center',
		width: '100%',
		...truncateTextStyle,
	},
	analyticsCountWrapper: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		...baseStyleSheet.gap5,
	},
	approvalStatusActions: {
		marginTop: 7,
	},
	approvalStatusTitle: {
		color: '#F89143',
		fontSize: 11,
		lineSpacing: '1.1px',
		textAlign: 'center',
		textTransform: 'uppercase',
		'@media only screen and (max-width: 1280px)': {
			whiteSpace: 'wrap',
		},
	},
	blue: {
		color: brandSecondaryHover,
	},
	cancelReject: {
		margin: '8px 0 0 20px',
		textAlign: 'left',
		width: '100%',
	},
	checkboxCol: {
		alignItems: 'center',
		display: 'flex',
		paddingRight: '10px',
	},
	contacts: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		flex: 1,
		minWidth: 100,
	},
	contactsCount: {
		paddingLeft: 10,
	},
	copyIdIcon: {
		':hover': {
			cursor: 'pointer',
		},
	},
	count: {
		color: success,
		fontSize: 22,
		lineHeight: '30px',
		textTransform: 'uppercase',
	},
	green: {
		color: success,
	},
	linkIcon: {
		padding: '5px 0 0 5px',
	},
	nameTags: {
		margin: 4,
		maxWidth: '80%',
	},
	nameTagsColumn: {
		alignItems: 'center',
		display: 'inline-flex',
		flexWrap: 'wrap',
	},
	orange: {
		color: warningDark,
	},
	placeholder: {
		display: 'flex',
		flex: '0.25',
		minWidth: 100,
		backgroundColor: 'transparent',
	},
	red: {
		color: destructive,
	},
	row: {
		borderBottom: '1px solid #D3DBDE77',
		borderTop: '1px solid #D3DBDE77',
		cursor: 'pointer',
		display: 'flex',
		minHeight: 100,
		paddingBottom: 10,
		paddingTop: 10,
	},
	schedule: {
		alignItems: 'center',
		display: 'flex',
		flex: 1,
		minWidth: 100,
		...CssSelectors.allChildren(
			{
				'@media only screen and (max-width: 800px)': {
					marginLeft: interItemSpacing / 2,
				},
				marginLeft: interItemSpacing,
			},
			':not(:first-child)'
		),
	},
	scheduleActionsWrapper: {
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	scheduleInfo: {
		minWidth: 55,
	},
	scheduleInfoCount: {
		color: brandSecondary,
		fontSize: 22,
		fontWeight: 500,
	},
	scheduleInfoDate: {
		color: '#929292',
		fontSize: 11,
		fontWeight: 500,
		letterSpacing: '1.1px',
		marginTop: 4,
	},
	scheduleInfoDateTitle: {
		textTransform: 'uppercase',
	},
	status: {
		marginTop: 6,
	},
	subject: {
		alignItems: 'center',
		color: brandPrimary,
		cursor: 'pointer',
		display: 'flex',
		fontSize: 14,
		fontWeight: 600,
		...truncateTextStyle,
	},
	subjectCell: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginBottom: 6,
		flex: 1,
		minWidth: '120px',
	},
	subjectTemplateTypeTag: {
		flexShrink: 0,
		marginRight: 10,
	},
	tags: {
		boxSizing: 'border-box',
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		minWidth: 100,
		alignItems: 'center',
		gap: 8,
	},
	moreTags: {
		fontSize: `0.75rem`,
		':hover': {
			textDecoration: 'none',
		},
	},
	tagContainer: {
		display: `flex`,
		alignItems: 'center',
		gap: 4,
		fontSize: `0.75rem`,
	},
	overflowTagContainer: {
		display: `flex`,
		alignItems: 'center',
		gap: 4,
		padding: `4px 8px`,
		fontSize: `0.75rem`,
	},
	extraTags: {
		padding: 0,
		':has(*) > :not(:last-child)': {
			borderBottom: `1px solid ${borderColor}`,
		},
	},
	tagToken: {
		maxWidth: 150,
	},
	tagTokenExclude: {
		background: errorLight,
		border: `1px solid ${destructive}`,
		color: titles,
	},
	tagTokenOpPrefix: {
		color: titles,
		fontSize: 12,
	},
});
