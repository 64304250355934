import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { IModalContext, ModalChildComponentContextKey } from '../../../models';
import { baseStyleSheet } from '../../styles/styles';
import { asModalComponent } from '../Modal';
import { TextInput } from '../TextInput';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IModalContext<string> {
	className?: string;
	cta?: ((props: { onClick: (e: React.MouseEvent<HTMLElement>) => void }) => React.ReactNode) | string;
	initialValue?: string;
	inputLabel?: React.ReactNode;
	isValid?(value: string): boolean;
	placeholderText?: string;
	styles?: StyleDeclarationValue[];
	title?: React.ReactNode;
	type?: 'password' | 'text' | 'email';
}

class _TextInputModal extends React.Component<IProps> {
	public static defaultProps: Partial<IProps> = {
		type: 'text',
	};
	@observable private mText: string;
	private mUuid = uuidgen();

	constructor(props: IProps) {
		super(props);

		this.mText = props.initialValue;
	}

	public render() {
		const { className, styles, title, children, type, cta, placeholderText, inputLabel } = this.props;
		const overrideCta = typeof cta === 'function' ? cta({ onClick: this.onCtaClicked }) : null;
		const ctaContent = !overrideCta && typeof cta === 'string' ? <span>{cta}</span> : null;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} text-input-modal ${className || ''}`}>
				<div className={css(baseStyleSheet.truncateText, styleSheet.header)}>{title}</div>
				<div className={css(baseStyleSheet.verticalStack)}>
					<div>
						{inputLabel}
						<TextInput
							inputId={`input-${this.mUuid}`}
							onChange={this.onTextChanged}
							placeholder={placeholderText}
							type={type}
							value={this.mText || ''}
						/>
					</div>
					{children}
				</div>
				<div className={css(baseStyleSheet.horizontalStack)}>
					{overrideCta || (
						<button className={css(baseStyleSheet.ctaButton)} onClick={this.onCtaClicked}>
							{ctaContent || <span>Ok</span>}
						</button>
					)}
				</div>
			</div>
		);
	}

	private onTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { isValid } = this.props;
		const v = e.target.value;
		if (isValid?.(v) || !isValid) {
			this.mText = v || '';
		}
	};

	private onCtaClicked = () => {
		const { parentModal } = this.props;

		parentModal.onRequestClose(this.mText, false);
	};
}

const TextInputModalAsObserver = observer(_TextInputModal);
const TextInputModalWithContext = inject(ModalChildComponentContextKey)(TextInputModalAsObserver);
const TextInputModalWithLogging = withEventLogging(TextInputModalWithContext, 'TextInputModal');
export const TextInputModal = asModalComponent(TextInputModalWithLogging, {
	shouldCloseOnOverlayClick: false,
	useDefaultHeader: true,
});
