import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { DraggableProvided, DraggableStateSnapshot, DroppableProvided } from 'react-beautiful-dnd';
import { CopyTemplateLinkModal } from '../../../../admin/containers/templates/CopyTemplateLinkModal';
import { TemplateActions } from '../../../../admin/containers/templates/TemplateCategory';
import {
	IImpersonationContextComponentProps,
	ImpersonationContextKey,
	InternalLevitateAccountIds,
} from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import { useEventLogging } from '../../../../models/Logging';
import { HiddenDefaultMessageTemplateKey } from '../../../../models/MessageTemplates';
import { storagePrefixExcludedFromClear } from '../../../../models/Storage';
import { canViewContentCalendarAndCampaigns, getIndustryName } from '../../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { usePersistChangeInBrowserStorage } from '../../../../models/hooks/usePersistChangeInBrowserStorage';
import { ITemplateCard, KnownCategories } from '../../../../viewmodels/AppViewModels';
import { ISelectOption } from '../../../components/DeprecatedSelect';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { IMoreMenuItem } from '../../../components/MoreMenu';
import { NewCampaignButtonsDropDown } from '../../../components/NewCampaignButtons';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/Select';
import { CampaignSearchInputField } from '../../../components/campaigns/CampaignSearchInputField';
import {
	BlogCampaignCalendarTemplateCard,
	CampaignCalendarTemplateCard,
	SocialCampaignCalendarTemplateCard,
} from '../../../components/campaigns/CampaignsByCategorySelector/presentation';
import { DraggableObserver, DroppableObserver } from '../../../components/helpers/dnd';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { useContentCalendarTemplateBrowserQueries } from './hooks';
import { styleSheet } from './styles';

export interface IContentCalendarTemplateBrowserComponent {
	getTemplateCards: () => ITemplateCard[];
}

interface IProps extends IImpersonationContextComponentProps {
	dragDropProps: {
		droppableId: string;
		type: string;
		isDragDisabled?: boolean;
		/**
		 * Styles to apply to the template card while dragging
		 */
		draggingStyles?: StyleDeclarationValue[];
	};
	selectedCampaignType?: CampaignType;
	hideCreateCampaignsButton?: boolean;
	onTemplateTypeSelected(templateType: CampaignType): void;
	onFilterCategories?(categories: string[]): string[];
	onCampaignTemplateClicked?(template: Api.ITemplate, schedule?: Api.IScheduledSend, referrer?: string): void;
	campaignTypes: CampaignType[];
}

const defaultCopyMenu = [
	{
		name: 'Copy Direct Link',
		representedObject: 'copy',
	},
];

const ContentCalendarTemplateBrowserBase = React.forwardRef<IContentCalendarTemplateBrowserComponent, IProps>(
	function ContentCalendarTemplateBrowserBase(
		{
			impersonationContext,
			onFilterCategories,
			onCampaignTemplateClicked,
			onTemplateTypeSelected,
			dragDropProps,
			selectedCampaignType = CampaignType.Email,
			hideCreateCampaignsButton,
			campaignTypes,
		},
		ref
	) {
		const { draggingStyles, ...restDragDropProps } = dragDropProps || {};
		const userSession = useUserSession();
		const errorMessages = useErrorMessages();
		const logger = useEventLogging('ContentCalendarTemplateBrowser');

		const account = impersonationContext?.isValid ? impersonationContext.account : userSession?.account;
		const isPersonalAccount = !canViewContentCalendarAndCampaigns(userSession, impersonationContext);
		const [industry, setIndustry] = usePersistChangeInBrowserStorage<Api.Industry>({
			storageKey: `${storagePrefixExcludedFromClear}-content-calendar-template-browser-industry`,
			storageType: 'localStorage',
			defaultIfEmpty: account.additionalInfo?.industry ?? Api.Industry.Insurance,
			skipPersistFn: () => impersonationContext?.isValid,
		});
		const [category, setCategory] = React.useState<string>(
			isPersonalAccount ? KnownCategories.MyTemplates : KnownCategories.Featured
		);
		const [searchQuery, setSearchQuery] = React.useState('');
		const [copyTemplate, setCopyTemplate] = React.useState<Api.ITemplate>(null);
		const [isCopyLinkModalOpen, setIsCopyLinkModalOpen] = React.useState<boolean>(false);

		const templateTypeOptions = React.useMemo<ISelectOption<CampaignType>[]>(() => {
			return campaignTypes.map(campaignType => {
				return {
					dataContext: campaignType,
					id: `template-type-option-${campaignType}`,
					text: campaignType.valueOf(),
				};
			});
		}, [campaignTypes]);

		const {
			isLoading,
			templatesByCategoryQuery,
			myTemplatesQuery,
			templatesQuery,
			templateSearchQuery,
			templateCategoriesQuery,
		} = useContentCalendarTemplateBrowserQueries({
			templateType: selectedCampaignType,
			searchQuery,
			category,
			industry,
			impersonationContext,
			onCategoriesSuccess: categories => {
				setCategory(
					categories.find(x => x === KnownCategories.Featured) ?? categories.find(x => x === KnownCategories.All)
				);
			},
		});

		const onCampaignSearch = (query: string, newIndustry: Api.Industry) => {
			setIndustry(newIndustry);
			setSearchQuery(query);
		};

		const onCampaignTemplateCardClicked = (card: ITemplateCard, socialIndicator?: boolean) => async () => {
			const templateId = socialIndicator
				? card.associatedTemplates?.find(x => x.templateType === Api.TemplateType.SocialMediaPost)?.id
				: card.id;
			try {
				const template = await userSession.webServiceHelper.callAsync(
					Api.ImpersonationBroker.composeApiUrl({
						impersonationContext,
						urlPath: `template/${templateId}`,
						queryParams: { expand: 'LastUsedByDate' },
					}),
					'GET'
				);
				onCampaignTemplateClicked?.(template);
			} catch (error) {
				logger.logApiError('LoadTemplate-Error', error);
				errorMessages.pushApiError(error);
			}
		};

		const onMenuItemClicked = (template: Api.ITemplate) => async (menuItem: IMoreMenuItem<TemplateActions>) => {
			switch (menuItem.representedObject) {
				case 'copy':
					setCopyTemplate(template);
					setIsCopyLinkModalOpen(true);
					break;
				default:
					break;
			}
		};

		const templateCards = React.useMemo(() => {
			const templateIdsToRemove = localStorage.getItem(HiddenDefaultMessageTemplateKey);
			if (!searchQuery) {
				if (category === KnownCategories.All && templatesByCategoryQuery) {
					const templatesByCategory = templatesByCategoryQuery.data || {};
					return Object.keys(templatesByCategory).reduce<ITemplateCard[]>((result, curr) => {
						const categoryTemplates = templatesByCategory[curr];
						categoryTemplates.forEach(x => {
							if (!result.find(y => y.id === x.id)) {
								result.push(x);
							}
						});
						return result;
					}, []);
				} else if (category === KnownCategories.MyTemplates && myTemplatesQuery) {
					return myTemplatesQuery.data?.filter(x => !templateIdsToRemove?.includes(x.id));
				} else {
					return templatesQuery.data?.filter(x => !templateIdsToRemove?.includes(x.id));
				}
			} else {
				return templateSearchQuery.data?.filter(x => !templateIdsToRemove?.includes(x.id));
			}
		}, [
			category,
			myTemplatesQuery,
			searchQuery,
			templateSearchQuery.data,
			templatesByCategoryQuery,
			templatesQuery.data,
		]);

		const templateCardsRef = React.useRef<ITemplateCard[]>(templateCards);
		templateCardsRef.current = templateCards;

		const categoryOptions = React.useMemo<string[]>(() => {
			let categories = templateCategoriesQuery.data || [];
			if (onFilterCategories) {
				categories = onFilterCategories(categories);
			} else if (
				impersonationContext?.isValid &&
				!impersonationContext?.account?.preferences?.csmViewMyCampaignsEnabled
			) {
				categories = categories?.filter(x => x !== KnownCategories.MyTemplates);
			}
			return categories.filter(x => x !== KnownCategories.HtmlNewsletters);
		}, [
			impersonationContext?.account?.preferences?.csmViewMyCampaignsEnabled,
			impersonationContext?.isValid,
			onFilterCategories,
			templateCategoriesQuery.data,
		]);

		React.useImperativeHandle(
			ref,
			() => {
				return {
					getTemplateCards: () => templateCardsRef.current || [],
				};
			},
			[]
		);

		const cardCtaText = `View ${selectedCampaignType === CampaignType.Blog ? 'Blog Post' : 'Campaign'}`;

		return (
			<div className={css(styleSheet.ContentCalendarTemplateBrowser)}>
				<div className={css(styleSheet.searchCampaignsContainer)}>
					{hideCreateCampaignsButton ? null : <NewCampaignButtonsDropDown supportedCampaignTypes={campaignTypes} />}
					<CampaignSearchInputField onChange={onCampaignSearch} industry={industry} styles={[bs.flex1]} />
				</div>

				<div className={css(styleSheet.filtersContainer)}>
					{!isPersonalAccount ? (
						<>
							{templateTypeOptions?.length > 1 ? (
								<>
									<label className={css(styleSheet.selectLabel)}>Content Type</label>
									<Select
										value={selectedCampaignType}
										onValueChange={(campaignType: CampaignType) => onTemplateTypeSelected(campaignType)}
									>
										<SelectTrigger styles={styleSheet.trigger}>
											<SelectValue />
										</SelectTrigger>
										<SelectContent>
											{campaignTypes.map(campaignType => {
												return (
													<SelectItem value={campaignType} key={campaignType}>
														{campaignType.valueOf()}
													</SelectItem>
												);
											})}
										</SelectContent>
									</Select>
								</>
							) : null}
							<label className={css(styleSheet.selectLabel)}>Industry</label>
							<Select value={industry} onValueChange={(i: Api.Industry) => setIndustry(i)}>
								<SelectTrigger styles={styleSheet.trigger}>
									<SelectValue />
								</SelectTrigger>
								<SelectContent>
									<SelectItem value={Api.Industry.Insurance}>{getIndustryName(Api.Industry.Insurance)}</SelectItem>
									<SelectItem value={Api.Industry.RealEstate}>{getIndustryName(Api.Industry.RealEstate)}</SelectItem>
									<SelectItem value={Api.Industry.Financial}>{getIndustryName(Api.Industry.Financial)}</SelectItem>
									<SelectItem value={Api.Industry.Mortgage}>{getIndustryName(Api.Industry.Mortgage)}</SelectItem>
									<SelectItem value={Api.Industry.Legal}>{getIndustryName(Api.Industry.Legal)}</SelectItem>
									<SelectItem value={Api.Industry.Accounting}>{getIndustryName(Api.Industry.Accounting)}</SelectItem>
									<SelectItem value={Api.Industry.NonProfit}>{getIndustryName(Api.Industry.NonProfit)}</SelectItem>
									<SelectItem value={Api.Industry.HomeMaintenance}>
										{getIndustryName(Api.Industry.HomeMaintenance)}
									</SelectItem>
									<SelectItem value={Api.Industry.Others}>{getIndustryName(Api.Industry.Others)}</SelectItem>
									{InternalLevitateAccountIds.has(account?.id) ? (
										<SelectItem value={Api.Industry.Levitate}>{getIndustryName(Api.Industry.Levitate)}</SelectItem>
									) : null}
								</SelectContent>
							</Select>
							<label className={css(styleSheet.selectLabel)}>Category</label>
							<Select value={category} onValueChange={setCategory}>
								<SelectTrigger styles={styleSheet.trigger}>
									<SelectValue />
								</SelectTrigger>
								<SelectContent>
									{categoryOptions.map(c => {
										return (
											<SelectItem value={c} key={`content-cal-browser-category-${c}`}>
												{c}
											</SelectItem>
										);
									})}
								</SelectContent>
							</Select>
						</>
					) : null}

					{/* @ts-ignore */}
					<DroppableObserver {...restDragDropProps}>
						{(droppableProvided: DroppableProvided) => {
							return (
								<div
									className={css(bs.boxBorder, bs.flex, bs.flexCol, bs.overflowAuto, bs.flex1, bs.gap2)}
									ref={droppableProvided.innerRef}
									{...droppableProvided.droppableProps}
								>
									{templateCards?.map((card, i) => {
										return (
											<DraggableObserver
												disableInteractiveElementBlocking={true}
												draggableId={card.id}
												index={i}
												isDragDisabled={dragDropProps.isDragDisabled}
												key={card.id}
												type={dragDropProps.type}
											>
												{(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => {
													return (
														<>
															<div
																ref={draggableProvided.innerRef}
																{...draggableProvided.draggableProps}
																{...draggableProvided.dragHandleProps}
															>
																{selectedCampaignType === CampaignType.Email ? (
																	<CampaignCalendarTemplateCard
																		card={card}
																		className={css(styleSheet.templateCardContainer)}
																		copyMenu={defaultCopyMenu}
																		ctaText={cardCtaText}
																		key={card.id}
																		isAccountTemplate={category === KnownCategories.MyTemplates}
																		onCtaClicked={onCampaignTemplateCardClicked(card)}
																		onSocialClicked={onCampaignTemplateCardClicked(card, true)}
																		onMenuItemClicked={onMenuItemClicked(card)}
																		styles={[
																			styleSheet.templateCard,
																			draggableSnapshot.isDragging ? styleSheet.templateCardDragging : null,
																			draggableSnapshot.isDragging && draggingStyles ? draggingStyles : null,
																		]}
																	/>
																) : null}
																{selectedCampaignType === CampaignType.Blog ? (
																	<BlogCampaignCalendarTemplateCard
																		card={card}
																		key={card.id}
																		isFeatured={categoryOptions.find(x => x === category) === 'Featured'}
																		onCtaClicked={onCampaignTemplateCardClicked(card)}
																	/>
																) : null}
																{selectedCampaignType === CampaignType.Social ? (
																	<SocialCampaignCalendarTemplateCard
																		card={card}
																		key={card.id}
																		isFeatured={categoryOptions.find(x => x === category) === 'Featured'}
																		onCtaClicked={onCampaignTemplateCardClicked(card)}
																	/>
																) : null}
															</div>
															{draggableSnapshot.isDragging && draggableProvided?.placeholder}
														</>
													);
												}}
											</DraggableObserver>
										);
									})}
									{isLoading ? <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' /> : null}
									<CopyTemplateLinkModal
										isOpen={isCopyLinkModalOpen}
										onRequestClose={() => {
											setIsCopyLinkModalOpen(false);
											setCopyTemplate(null);
										}}
										industry={industry}
										template={copyTemplate}
									/>
								</div>
							);
						}}
					</DroppableObserver>
				</div>
			</div>
		);
	}
);

const ContentCalendarTemplateBrowserAsObserver = observer(ContentCalendarTemplateBrowserBase);
export const ContentCalendarTemplateBrowser = inject(ImpersonationContextKey)(ContentCalendarTemplateBrowserAsObserver);
