import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { searchRequestToQueryStringParams } from '../../../../models/UiUtils';
import { baseStyleSheet } from '../../../styles/styles';
import { AutomationTriggerIcon } from '../../svgs/icons/AutomationTriggerIcon';
import { styleSheet } from './styles';

export const AutomationStatus = ({ icon, name }: { icon: React.ReactNode; name: string }) => {
	return (
		<>
			<div className={css(styleSheet.sectionInner)}>
				<div className={css(styleSheet.sectionLabel)}>Status</div>
				<div className={css(baseStyleSheet.horizontalStack, styleSheet.statusSectionContent)}>
					{icon}
					{!!name && <span>{name}</span>}
				</div>
			</div>
		</>
	);
};

export const AutomationTrigger = ({
	triggerDescription,
	trigger,
}: {
	triggerDescription: string;
	trigger: Api.IAutomationTrigger;
}) => {
	return (
		<div className={css(styleSheet.sectionHorizontal)}>
			<div className={css(styleSheet.sectionLabel)}>{trigger?._type != null ? 'Trigger' : 'Manually Triggered'}</div>
			<div className={css(baseStyleSheet.horizontalStack, styleSheet.trigger)}>
				{trigger?._type != null ? (
					<>
						<AutomationTriggerIcon styles={[styleSheet.triggerIcon]} trigger={trigger} />
						<div className={css(baseStyleSheet.truncateText)} title={triggerDescription}>
							{triggerDescription}
						</div>
					</>
				) : null}
			</div>
		</div>
	);
};

export const AutomationYoloModeIndicator = ({ trigger }: { trigger: Api.IAutomationTrigger }) => {
	let textByResourceSelectorType = '';

	// putOnHold must explicitly be set to false
	if (trigger && trigger._type === Api.AutomationTriggerType.ResourceSelector && trigger.putOnHold === false) {
		const t = trigger as Api.IResourceSelectorAutomationTrigger;
		switch (t.resourceSelector) {
			case Api.ResourceSelectorId.TurningXX:
			case Api.ResourceSelectorId.HappyBirthday:
			case Api.ResourceSelectorId.Turning65:
			case Api.ResourceSelectorId.Turning72:
			case Api.ResourceSelectorId.Turning73: {
				textByResourceSelectorType = `For all users: you will no longer see a birthday card on the dash; all birthday emails or texts are sent automatically.`;
				break;
			}
			case Api.ResourceSelectorId.PolicyRenew:
				textByResourceSelectorType = `For all users: you will no longer see a renewal card on the dash; all renewal emails or texts are sent automatically.`;
				break;
			case Api.ResourceSelectorId.HouseAnniversaries:
				textByResourceSelectorType = `For all users: anniversary emails or texts will now be sent automatically.`;
				break;
			case Api.ResourceSelectorId.FinancialReview:
				textByResourceSelectorType = `For all users: financial review emails or texts will now be sent automatically.`;
				break;
			default: {
				textByResourceSelectorType =
					'Users will no longer see a card on the dashboard prompting them to approve this automation; it will be kicked off automatically.';
				break;
			}
		}
		return (
			<div className={css(styleSheet.yoloModeIndicator)}>
				{!trigger?.putOnHold ? <p>{textByResourceSelectorType}</p> : null}
			</div>
		);
	}

	if (trigger && trigger.putOnHold === false) {
		return (
			<div className={css(styleSheet.yoloModeIndicator)}>
				<p>
					Users will no longer see a card on the dashboard prompting them to approve this automation; it will be kicked
					off automatically.
				</p>
			</div>
		);
	}

	return null;
};

export const AutomationProgressStats = ({
	automationId,
	completedContactString,
	inProgressContactString,
	showCompletedButton,
	showInProgressButton,
}: {
	automationId: string;
	completedContactString: string;
	inProgressContactString: string;
	showCompletedButton: boolean;
	showInProgressButton: boolean;
}) => {
	const history = useHistory();

	const navigateToPeopleSearch = (property: Api.ContactFilterCriteriaProperty) => () => {
		const filterRequest: Api.IContactsFilterRequest = {
			criteria: [
				{
					property: Api.ContactFilterCriteriaProperty.Name,
					value: '',
				},
				{
					property,
					value: automationId,
				},
			],
		};

		const filter: Api.IBulkContactsRequest = {
			filter: filterRequest,
		};

		const sort: Api.ISortDescriptor = {
			sort: 'asc',
			sortBy: 'handle',
		};

		const query = searchRequestToQueryStringParams<Api.IBulkContactsRequest>(filter, sort);

		history?.push(`/people/?search=${query.search}&sort=${query.sort}`);
	};
	return (
		<div className={css(styleSheet.splitSection)}>
			<div className={css(styleSheet.sectionInner)}>
				<div className={css(styleSheet.sectionLabel)}>In Progress</div>
				<div className={css(baseStyleSheet.horizontalStack, styleSheet.statusSectionContent)}>
					{showInProgressButton ? (
						<button
							className={css(baseStyleSheet.brandLink, styleSheet.activeCountLink)}
							onClick={navigateToPeopleSearch(Api.ContactFilterCriteriaProperty.InProgressAutomations)}
						>
							<span>{inProgressContactString}</span>
						</button>
					) : (
						inProgressContactString
					)}
				</div>
			</div>
			<div className={css(styleSheet.sectionInner)}>
				<div className={css(styleSheet.sectionLabel)}>Completed</div>
				<div
					className={css(baseStyleSheet.horizontalStack, baseStyleSheet.truncateText, styleSheet.statusSectionContent)}
				>
					{showCompletedButton ? (
						<button
							className={css(baseStyleSheet.brandLink, styleSheet.activeCountLink)}
							onClick={navigateToPeopleSearch(Api.ContactFilterCriteriaProperty.PreviousAutomations)}
						>
							<span>{completedContactString}</span>
						</button>
					) : (
						completedContactString
					)}
				</div>
			</div>
		</div>
	);
};
