import * as Api from '@ViewModels';
import { createContext, FC, useContext, useRef } from 'react';
import { useParams } from 'react-router';
import { useErrorMessages } from '../../../models/hooks/appStateHooks';
import { useAdminAccountQuery } from '../../../queries';
import { ImpersonationContextViewModel } from '../../../viewmodels/AdminViewModels';

interface IAccountContext {
	account: Api.IAccount;
	accountId: string;
	impersonationContext: Api.IImpersonationContext;
}

const AccountContext = createContext<IAccountContext>({
	account: null,
	accountId: null,
	impersonationContext: null,
});

export const AccountContextProvider: FC = ({ children }) => {
	const errorMessages = useErrorMessages();

	const { id: accountId } = useParams<{ id: string }>();

	const impersonationContextRef = useRef(
		new ImpersonationContextViewModel({
			account: { id: accountId },
		})
	);

	const { data: account } = useAdminAccountQuery({
		accountId,
		onError: (error: Api.IOperationResultNoValue) => {
			errorMessages.pushApiError(error);
		},
		onSuccess: (value: Api.IAccount) => {
			impersonationContextRef.current.update({
				account: value,
			});
		},
	});

	return (
		<AccountContext.Provider value={{ account, accountId, impersonationContext: impersonationContextRef.current }}>
			{children}
		</AccountContext.Provider>
	);
};

export const useAccountContext = () => useContext<IAccountContext>(AccountContext);
