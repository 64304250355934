import {
	DefaultRichContentRawCSS,
	IEditEmailTemplateComponent,
	IEditEmailTemplateContent,
	IImpersonationContextComponentProps,
	levPlaceholderPopoverCloseCommand,
	levPlaceholderPopoverCommand,
} from '@AppModels/.';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	ToolbarFull,
	convertRawRichTextContentStateToRichContentEditorState,
	getDefaultBulkMessagingBodyEditorState,
	isTemplateNew,
	replaceFontFamilyInCss,
} from '@AppModels/UiUtils';
import {
	AttachmentsToBeUploadedViewModel,
	FileAttachmentViewModel,
	IOperationResultNoValue,
	IRichContentEditorState,
	ITemplate,
	TemplateScope,
	TemplateType,
	TemplatesViewModel,
	VmUtils,
	excludeKeysOf,
} from '@ViewModels';
import { Checkbox } from '@WebComponents/Checkbox';
import { CompoundButton, CompoundButtonType } from '@WebComponents/CompoundButton';
import { InputFieldError } from '@WebComponents/InputFieldError';
import { LoadingSpinner } from '@WebComponents/LoadingSpinner';
import { TextInput } from '@WebComponents/TextInput';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '@WebComponents/richContent/RichContentDocumentEditor';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ImpersonationContextKey } from '../../../../models';
import { hideDefaultMessageTemplate } from '../../../../models/MessageTemplates';
import { FirstNamePlaceholder, Token } from '../../../../models/Token';
import { Attachments } from '../../../components/Attachments';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { DeprecatedPopover, PopoverType } from '../../../components/DeprecatedPopover';
import { FirstNamePlaceholderInfo } from '../../../components/richContent/RichContentDocumentEditor/FirstNamePlaceholderInfo';
import { TrashIcon } from '../../../components/svgs/icons/TrashIcon';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';
import './styles.less';

interface IProps
	extends IEventLoggingComponentProps,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IImpersonationContextComponentProps {
	attachments?: AttachmentsToBeUploadedViewModel<File>;
	className?: string;
	disableDelete?: boolean;
	disableSharing?: boolean;
	disableTemplateEdits?: boolean;
	innerRef?: (ref?: IEditEmailTemplateComponent) => void;
	onRenderFooter?(): React.ReactNode;
	onTemplateDeleted?(template: ITemplate): void;
	onTemplateSaved?(template: ITemplate): void;
	onTemplateSelected?(template?: ITemplate): void;
	selectOnly?: boolean;
	styles?: StyleDeclarationValue[];
	template?: ITemplate;
}

interface IState {
	bodyEditorState?: IRichContentEditorState;
	bodyHasText?: boolean;
	editorLoaded?: boolean;
	hasUnsavedChanges?: boolean;
	isDeleting?: boolean;
	levPlaceholderPopover?: { x: number; y: number };
	nameErrorMessage?: string;
	saving?: boolean;
	showDeleteConfirmation?: boolean;
	template?: ITemplate;
	templateName?: string;
	templateScope?: TemplateScope;
	templateSubject?: string;
}

class _EditEmailTemplate extends React.Component<IProps, IState> implements IEditEmailTemplateComponent {
	private static DefaultEditorConfig: IRichContentDocumentEditorConfig;
	private mTemplates: TemplatesViewModel;

	private mMounted: boolean;

	private filesInputRef: HTMLInputElement;

	public static getDerivedStateFromProps(props: IProps, state: IState) {
		const nextState: IState = {};
		if (props.template !== state.template) {
			nextState.template = props.template;
			nextState.bodyEditorState = props.template
				? convertRawRichTextContentStateToRichContentEditorState(props.template.content)
				: getDefaultBulkMessagingBodyEditorState();

			nextState.templateName = !!props.template && !!props.template.name ? props.template.name : null;

			nextState.templateSubject = props.template ? props.template.subject : null;
			nextState.templateScope = !!props.template && !!props.template.scope ? props.template.scope : TemplateScope.User;
			nextState.hasUnsavedChanges = !props.template || false;
		}
		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	constructor(props: IProps) {
		super(props);
		const { userSession } = this.props;

		this.mTemplates = new TemplatesViewModel(userSession);

		if (!_EditEmailTemplate.DefaultEditorConfig) {
			_EditEmailTemplate.DefaultEditorConfig = {
				autoresizeToFitContent: true,
				contentHorizontalPadding: 10,
				contentRawCss: replaceFontFamilyInCss('arial', DefaultRichContentRawCSS),
				minHeight: 100,
				plugins: 'placeholders table',
				toolbar: ToolbarFull,
				toolbarOverflow: 'floating',
				useDefaultEmailPlaceholders: true,
			};
		}

		this.state = {
			..._EditEmailTemplate.getDerivedStateFromProps(props, {}),
		};
	}

	public componentDidMount() {
		const { innerRef } = this.props;
		this.mMounted = true;
		if (innerRef) {
			innerRef(this);
		}
	}

	public componentWillUnmount() {
		this.mMounted = false;
		const { innerRef } = this.props;
		if (innerRef) {
			innerRef(null);
		}
	}

	public render() {
		const { className, styles, selectOnly, template, disableTemplateEdits, userSession, attachments } = this.props;
		const { bodyEditorState, levPlaceholderPopover, templateName, nameErrorMessage, saving, templateSubject } =
			this.state;

		const isNew = !!selectOnly && isTemplateNew(template);

		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} edit-email-template ${className || ''}`}>
				{selectOnly ? (
					<>
						<div className={css(styleSheet.readOnlyTemplateField)}>
							<div className={css(styleSheet.readOnlyTemplateFieldLabel)}>Template Name:</div>
							<div className={css(styleSheet.readOnlyTemplateFieldTitle)}>
								<div className={css(baseStyleSheet.truncateText)}>{templateName || ''}</div>
							</div>
						</div>
						{!!isNew && (
							<span className={css(styleSheet.new)}>
								&nbsp; &nbsp;
								<span>New!</span>
							</span>
						)}
						{!!templateSubject && (
							<div className={css(styleSheet.readOnlyTemplateField)}>
								<div className={css(styleSheet.readOnlyTemplateFieldLabel)}>Subject:</div>
								{templateSubject || ''}
							</div>
						)}
					</>
				) : (
					<>
						{!disableTemplateEdits && (
							<InputFieldError errorMessage={nameErrorMessage}>
								<TextInput
									autoComplete='off'
									inputId='edit-email-template-name-input'
									leftAccessory={
										<span className={css(styleSheet.inputLabel)}>
											Template Name:<span className='required'>*</span>
										</span>
									}
									onChange={this.onTemplateNameChanged}
									onFocus={this.clearInputErrorMessages}
									type='text'
									value={templateName || ''}
								/>
							</InputFieldError>
						)}
						<TextInput
							autoComplete='off'
							className={css(styleSheet.subjectInput)}
							inputId='edit-email-template-subject-input'
							leftAccessory={<span className={css(styleSheet.inputLabel)}>Subject:</span>}
							onChange={this.onTemplateSubjectChanged}
							onFocus={this.clearInputErrorMessages}
							type='text'
							value={templateSubject || ''}
						/>
					</>
				)}
				<div
					className={`edit-email-template-body ${css(styleSheet.body, selectOnly ? styleSheet.bodyReadOnly : null)}`}
				>
					<RichContentDocumentEditor
						className='edit-email-template-body-field-editor'
						config={_EditEmailTemplate.DefaultEditorConfig}
						contentState={bodyEditorState}
						onContentStateChanged={this.onBodyEditorStateChanged}
						onExecuteCommand={this.onEditorExecuteCommand}
						onFocus={this.clearInputErrorMessages}
						onLoad={this.onEditorLoaded}
						readOnly={!!saving || !!selectOnly}
						readonlyChildren={
							levPlaceholderPopover ? (
								<DeprecatedPopover
									anchor={
										<span
											style={{
												left: levPlaceholderPopover.x,
												position: 'absolute',
												top: levPlaceholderPopover.y,
											}}
										/>
									}
									dismissOnClickOutside={true}
									isOpen={true}
									preferredPlacement='right'
									onRequestClose={this.onDismissFirstNamePopover}
									type={PopoverType.white}
								>
									<FirstNamePlaceholderInfo onFinishClicked={this.onDismissFirstNamePopover} />
								</DeprecatedPopover>
							) : undefined
						}
					/>
				</div>
				<input
					id='edit-email-template-files-input'
					multiple={true}
					onChange={this.onFilesInputChanged}
					ref={this.onFilesInputRef}
					style={{
						height: 0,
						opacity: 0,
						pointerEvents: 'none',
						position: 'fixed',
						visibility: 'hidden',
						width: 0,
						zIndex: -1,
					}}
					type='file'
				/>

				{!!(template?.attachments?.length > 0 || attachments?.attachments?.length) && (
					<div className={css(styleSheet.attachmentContainer)}>
						<Attachments
							attachments={template?.attachments?.map(x => new FileAttachmentViewModel(userSession, '', x))}
							disableOnClick={true}
							newAttachments={attachments}
						/>
					</div>
				)}
				<div className={css(styleSheet.footer)}>
					{this.renderFooterButtons()}
					{(!!this.isEditingCoworkerTemplate || !!this.isEditingSystemTemplate) && !selectOnly && (
						<div className={css(styleSheet.footerMessage, styleSheet.footerMessageNotCreator)}>
							You cannot save over the original template since you are not the creator.
						</div>
					)}
				</div>
			</div>
		);
	}

	public onFilesInputRef = (ref?: HTMLInputElement) => {
		this.filesInputRef = ref;
	};

	private onFilesInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		const { attachments } = this.props;

		if (!!files && files.length > 0) {
			const fileAttachments = Array.from(files);

			attachments.add(fileAttachments);
		}
	};

	private renderFooterButtons() {
		const { userSession, selectOnly, onRenderFooter, disableTemplateEdits, disableSharing, disableDelete } = this.props;
		const { template, saving, editorLoaded, templateScope, showDeleteConfirmation, isDeleting, hasUnsavedChanges } =
			this.state;

		let saveButton: JSX.Element = null;
		if (selectOnly) {
			saveButton = (
				<>
					<button className={css(baseStyleSheet.ctaButton)} onClick={this.onTemplateSelected(template)}>
						<span>Choose this template</span>
					</button>
					<div className={css(styleSheet.footerMessage)}>You can edit the text on the next screen.</div>
				</>
			);
		} else if (!!template && !!template.id && template.scope !== TemplateScope.Industry) {
			saveButton = (
				<CompoundButton
					buttonTitle={<span>Save</span>}
					styleDeclaration={styleSheet.saveButton}
					disabled={!!saving || !editorLoaded || !hasUnsavedChanges}
					onClick={this.onSaveClicked(false)}
					openDirection='up'
					kind={CompoundButtonType.CtaPrimary}
				>
					<button
						className={css(baseStyleSheet.ctaButtonReverse, styleSheet.saveAsNewCompoundButton)}
						disabled={!!saving || !editorLoaded || !hasUnsavedChanges}
						onClick={this.onSaveClicked(true)}
					>
						<span>Save as new template</span>
					</button>
				</CompoundButton>
			);
		} else {
			const saveButtonText =
				!!this.isEditingSystemTemplate || !!this.isEditingCoworkerTemplate ? 'Save as new template' : 'Save';
			saveButton = (
				<button
					className={css(baseStyleSheet.ctaButton)}
					disabled={!!saving || !editorLoaded || !hasUnsavedChanges}
					onClick={this.onSaveClicked(!!this.isEditingSystemTemplate || !!this.isEditingCoworkerTemplate)}
				>
					<span>{saveButtonText}</span>
				</button>
			);
		}
		return (
			<div className={css(styleSheet.footerButtons)}>
				<div className={css(styleSheet.footerButtonGroup)}>
					{!disableTemplateEdits && saveButton}
					{onRenderFooter ? onRenderFooter() : null}
					{(!!saving || !!isDeleting) && <LoadingSpinner type='small' />}
				</div>
				<div className={css(styleSheet.footerButtonGroup, styleSheet.footerButtonGroupRight)}>
					{!!template &&
						!!template.creator &&
						template.creator.id !== userSession.user.id &&
						template.scope !== TemplateScope.Industry && (
							<span className={css(styleSheet.footerCreatorLabel)}>
								{`Shared by ${VmUtils.getDisplayName(template.creator, true)}`}
							</span>
						)}
					{!disableSharing &&
						(!template ||
							(!!template && !template.creator) ||
							(!!template.creator && template.creator.id === userSession.user.id)) &&
						template?.scope !== TemplateScope.Industry && (
							<Checkbox
								checked={templateScope === TemplateScope.Account}
								childrenPosition='right'
								className={css(styleSheet.shareCheckbox)}
								id='edit-email-template-checkbox'
								onChange={this.onShareCheckboxChanged}
								title='Share this template with coworkers'
							>
								<span>Shared</span>
							</Checkbox>
						)}
					{!disableDelete && !!template && (
						<button
							className={css(styleSheet.footerIconButton, styleSheet.footerDeleteButton)}
							onClick={this.toggleDeleteConfirmation(true)}
						>
							<TrashIcon />
							<span>Delete</span>
						</button>
					)}
				</div>
				<ConfirmationDialog
					icon={<WarningIcon />}
					modalProps={{
						isOpen: !!showDeleteConfirmation && !!template,
						onRequestClose: this.onDeleteNoteConfirmationDialogRequestClose,
					}}
					options={[
						{
							isDestructive: true,
							representedObject: true,
							title: 'Delete',
						},
						{
							isCancel: true,
							representedObject: false,
							title: 'Cancel',
						},
					]}
					title='Are you sure you want to delete this template?'
				/>
			</div>
		);
	}

	@computed
	private get isEditingCoworkerTemplate() {
		const { userSession } = this.props;
		const { template } = this.state;

		return !!template && !!template.creator && template.creator.id !== userSession.user.id;
	}

	@computed
	private get isEditingSystemTemplate() {
		const { template } = this.state;

		return !!template && !!template.scope && template.scope === TemplateScope.Industry;
	}

	private onEditorExecuteCommand = (
		e: React.ChangeEvent<any> & {
			command: string;
			type: string;
			ui?: any;
			value?: any;
		}
	) => {
		const target = e.value?.sourceEvent?.target as HTMLElement;
		if (!!target && e.command === levPlaceholderPopoverCommand) {
			this.setState({
				levPlaceholderPopover: {
					x: target.offsetLeft + target.getBoundingClientRect().width,
					y: target.offsetTop + target.getBoundingClientRect().height / 2.0,
				},
			});
		} else if (e.command === levPlaceholderPopoverCloseCommand) {
			this.onDismissFirstNamePopover();
		} else if (e.command === 'levAddFileAttachment') {
			this.filesInputRef?.click();
		}
	};

	private onDismissFirstNamePopover = () => {
		this.setState({
			levPlaceholderPopover: null,
		});
	};

	public getIsSaving = () => {
		return !!this.state.saving;
	};

	public hasUnsavedEdits = () => {
		const { hasUnsavedChanges } = this.state;
		return !!hasUnsavedChanges;
	};

	public getContent = (): IEditEmailTemplateContent => {
		const { bodyEditorState, templateSubject, template } = this.state;
		return {
			editorState: bodyEditorState,
			subject: templateSubject,
			template,
		};
	};

	private onTemplateSelected = (template?: ITemplate) => () => {
		const { onTemplateSelected } = this.props;
		if (onTemplateSelected) {
			onTemplateSelected(template);
		}
	};

	private toggleDeleteConfirmation = (showDeleteConfirmation: boolean) => () => {
		this.setState({
			showDeleteConfirmation,
		});
	};

	private onDeleteNoteConfirmationDialogRequestClose = (
		result?: IConfirmationDialogOption<boolean>,
		canceled?: boolean
	) => {
		const { template } = this.state;
		const { logApiError, logInput, errorMessages, onTemplateDeleted } = this.props;
		if (!canceled && !!result && result.representedObject === true && !!template) {
			const onSuccess = () => {
				this.setState(
					{
						isDeleting: false,
						templateScope: undefined,
					},
					() => {
						if (!!this.mMounted && !!onTemplateDeleted) {
							onTemplateDeleted(template);
						}
					}
				);
			};
			if (!!template.id && template.scope !== TemplateScope.Industry) {
				const promise = this.mTemplates.delete(template);
				if (promise) {
					logInput('DeleteTemplate', 'Click', { id: template.id });
					this.setState({
						isDeleting: true,
					});
					promise.then(onSuccess).catch((error: IOperationResultNoValue) => {
						logApiError('DeleteTemplate-Error', error);

						errorMessages.pushApiError(error);
						this.setState({
							isDeleting: false,
						});
					});
				}
			} else {
				hideDefaultMessageTemplate(template.id).then(() => {
					if (this.mMounted) {
						onSuccess();
					}
				});
			}
		}

		this.setState({
			showDeleteConfirmation: false,
		});
	};

	private clearInputErrorMessages = () => {
		if (this.state.nameErrorMessage) {
			this.setState({
				nameErrorMessage: null,
			});
		}
	};

	private onShareCheckboxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checked = (e.target as HTMLInputElement).checked;
		this.setState(
			{
				hasUnsavedChanges: true,

				nameErrorMessage: null,
				templateScope: checked ? TemplateScope.Account : TemplateScope.User,
			},
			() => {
				const { selectOnly } = this.props;
				if (selectOnly) {
					// in this mode, we don't show the save button, lets save for the user
					this.onSaveClicked(false)()?.catch(() => {
						this.setState({
							templateScope: checked ? TemplateScope.User : TemplateScope.Account,
						});
					});
				}
			}
		);
	};

	private onTemplateNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			hasUnsavedChanges: true,

			nameErrorMessage: null,
			templateName: (e.target as HTMLInputElement).value,
		});
	};

	private onTemplateSubjectChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			hasUnsavedChanges: true,

			nameErrorMessage: null,
			templateSubject: (e.target as HTMLInputElement).value,
		});
	};

	private onBodyEditorStateChanged = (bodyEditorState: IRichContentEditorState) => {
		this.setState({
			bodyEditorState,
			bodyHasText: bodyEditorState.hasContent(),
			hasUnsavedChanges: true,

			nameErrorMessage: null,
		});
	};

	private onEditorLoaded = () => {
		this.setState({
			editorLoaded: true,
		});
	};

	private onSaveClicked = (duplicate: boolean) => () => {
		const { logInput, logApiError, errorMessages } = this.props;
		const { templateName, template, templateScope, bodyEditorState, templateSubject } = this.state;
		let trimmedName = (templateName || '').trim();
		let scope = templateScope || TemplateScope.User;
		if (!trimmedName) {
			this.setState({
				nameErrorMessage: 'A name is required',
			});
			return;
		}

		if (duplicate) {
			trimmedName = `${trimmedName} - Copy`;
			if (scope === TemplateScope.Industry) {
				scope = TemplateScope.User;
			}
		}

		const content = bodyEditorState.getRawRichTextContent();
		if (!Token.isFirstNameTokenFormattedCorrectlyLoose(content)) {
			errorMessages.push({
				messages: [
					`The first name token does not appear to be formatted properly. It should be ${FirstNamePlaceholder.symbol}.`,
				],
			});
			return;
		}

		const templateToSave: ITemplate = {
			...excludeKeysOf(template || {}, ['creator', 'creationDate', 'industries', 'enabled', 'lastModifiedDate']),

			content: bodyEditorState.getRawRichTextContent(),
			name: trimmedName,
			scope,
			subject: templateSubject,
			templateType: TemplateType.Email,
		};

		if (duplicate) {
			templateToSave.id = null;
		}

		const promise = templateToSave.id ? this.mTemplates.update(templateToSave) : this.mTemplates.create(templateToSave);
		if (promise) {
			logInput('Save', 'Click', { id: templateToSave.id });
			this.setState({
				nameErrorMessage: null,
				saving: true,
			});
			promise
				.then((savedTemplate: ITemplate) => {
					if (this.mMounted) {
						this.setState(
							{
								saving: false,
							},
							() => {
								const { onTemplateSaved } = this.props;
								if (!!onTemplateSaved && !!this.mMounted) {
									onTemplateSaved(savedTemplate);
								}
							}
						);
					}
				})
				.catch((error: IOperationResultNoValue) => {
					logApiError('Save-Error', error);
					if (this.mMounted) {
						errorMessages.pushApiError(error);
						this.setState({
							saving: false,
						});
					}
				});
		}
		return promise;
	};
}

const EditEmailTemplateAsObserver = observer(_EditEmailTemplate);
const EditEmailTemplateWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey
)(EditEmailTemplateAsObserver);
const EditEmailTemplateWithCustomFields = inject(ImpersonationContextKey)(EditEmailTemplateWithContext);
export const EditEmailTemplate = withEventLogging(EditEmailTemplateWithCustomFields, 'EditEmailTemplate');
