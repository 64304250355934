import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedSelect, ISelectOption } from '../../DeprecatedSelect';
import { TextArea } from '../../TextArea';
import { AIContentGenerationWizardType } from '../AIContentGenerationWizard/models';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	instructionsPlaceholder?: string;
	isRewriting?: boolean;
	styleDeclarationValues?: StyleDeclarationValue[];
	type?: AIContentGenerationWizardType;
	generationOptions: Api.IAIContentGenerationOptions;
	onUpdateGenerationOptions: (optionsToMerge?: Partial<Api.IAIContentGenerationOptions>) => void;
	disabled: boolean;
}

const LengthOptions: ISelectOption<Api.AIContentLength>[] = [
	{
		dataContext: Api.AIContentLength.Short,
		id: Api.AIContentLength.Short,
		text: 'Short message',
	},
	{
		dataContext: Api.AIContentLength.Medium,
		id: Api.AIContentLength.Medium,
		text: 'Normal message',
	},
	{
		dataContext: Api.AIContentLength.Long,
		id: Api.AIContentLength.Long,
		text: 'Long message',
	},
];

const PersonalityOptions: ISelectOption<Api.AIPersonalityType>[] = [
	Api.AIPersonalityType.Formal,
	Api.AIPersonalityType.Informal,
	Api.AIPersonalityType.Humorous,
].map(p => ({
	dataContext: p,
	id: p,
	text: p,
}));

const MAX_CREATIVITY_FACTOR = 0.2;
const toInputValue = (creativity: number) => Math.floor((creativity / MAX_CREATIVITY_FACTOR) * 100.0);

export function AIContentGenerationOptions({
	className = '',
	instructionsPlaceholder,
	isRewriting,
	styleDeclarationValues,
	type,
	generationOptions,
	disabled,
	onUpdateGenerationOptions,
}: IProps) {
	const onCreativitySliderChanged = ({ target }: React.SyntheticEvent<HTMLInputElement>) => {
		onUpdateGenerationOptions({
			creativity: (parseInt((target as HTMLInputElement).value, 10) * MAX_CREATIVITY_FACTOR) / 100.0,
		});
	};

	const onContentLengthClicked = (option: ISelectOption<Api.AIContentLength>, wasSelected: boolean) => {
		if (!wasSelected) {
			return;
		}
		onUpdateGenerationOptions({ contentLength: option.dataContext });
	};

	const onSelectedPersonalityTypeClicked = (option: ISelectOption<Api.AIPersonalityType>, wasSelected: boolean) => {
		if (!wasSelected) {
			return;
		}
		onUpdateGenerationOptions({ personality: option.dataContext });
	};

	const onTopicBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
		onUpdateGenerationOptions({ writingInstructions: e.target.value });
	};

	const renderLengthInputField = () => (
		<div>
			<label className={css(styleSheet.inputLabel)}>Length</label>
			<DeprecatedSelect
				disabled={disabled}
				onOptionClick={onContentLengthClicked}
				onRenderPlaceholder={() => 'Select Length'}
				options={LengthOptions}
				selectedOption={LengthOptions.find(x => x.dataContext === generationOptions.contentLength)}
				styles={[styleSheet.lengthDropdownTrigger, disabled ? baseStyleSheet.disabled : null]}
			/>
		</div>
	);

	return (
		<div
			className={`${css(
				styleSheet.container,
				...(styleDeclarationValues || [])
			)} ai-content-generation-options ${className}`}
		>
			<label className={css(styleSheet.title)}>Let AI write the content for you</label>
			<div>
				<label className={css(styleSheet.inputLabel)}>Writing Instructions for the AI</label>
				<TextArea
					className={css(styleSheet.instructions)}
					defaultValue={generationOptions.writingInstructions}
					disabled={disabled}
					inputId='ai-content-generation-options-topic'
					onBlur={onTopicBlur}
					placeholder={
						instructionsPlaceholder
							? instructionsPlaceholder
							: isRewriting
								? `Rewrite this ${type === AIContentGenerationWizardType.Email ? 'email' : type === AIContentGenerationWizardType.BlogPost ? 'blog post' : 'post'}...`
								: type === AIContentGenerationWizardType.BlogPost
									? 'Example: Write a blog post about Home Insurance benefits'
									: 'Example: Write an email about Home Insurance benefits...'
					}
				/>
			</div>
			{isRewriting ? (
				renderLengthInputField()
			) : (
				<>
					<div className={css(styleSheet.selectorsContainer)}>
						{type !== AIContentGenerationWizardType.BlogPost ? (
							<div>
								<label className={css(styleSheet.inputLabel)}>Personality</label>
								<DeprecatedSelect
									disabled={disabled}
									onOptionClick={onSelectedPersonalityTypeClicked}
									onRenderPlaceholder={() => 'Select Personality'}
									options={PersonalityOptions}
									selectedOption={PersonalityOptions.find(x => x.dataContext === generationOptions.personality)}
									styles={disabled ? [baseStyleSheet.disabled] : null}
								/>
							</div>
						) : null}
						{renderLengthInputField()}
					</div>
					{type !== AIContentGenerationWizardType.BlogPost ? (
						<div>
							<div className={css(styleSheet.creativityHeader)}>
								<label>
									<span>Creative Freedom</span>
								</label>

								<div>{toInputValue(generationOptions.creativity)}</div>
							</div>
							<input
								className={css(styleSheet.creativitySlider)}
								defaultValue={generationOptions.creativity ? toInputValue(generationOptions.creativity) : 0}
								disabled={disabled}
								max={100}
								min={0}
								onKeyUp={onCreativitySliderChanged}
								onMouseUp={onCreativitySliderChanged}
								onTouchEnd={onCreativitySliderChanged}
								type='range'
							/>
						</div>
					) : null}
				</>
			)}
		</div>
	);
}
