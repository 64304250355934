import { ErrorMessagesViewModelKey, IErrorMessageComponentProps } from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { IDictionary, IImportContactFieldConfig, IOperationResultNoValue } from '@ViewModels';
import { LoadingSpinner } from '@WebComponents/LoadingSpinner';
import { MultiContainerHeader } from '@WebComponents/MultiContainerHeader';
import { ISelectBoxOption } from '@WebComponents/SelectBox';
import { SvgIcon } from '@WebComponents/svgs/icons/SvgIcon';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { AppImportContactsViewModel } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { Checkbox } from '../../Checkbox';
import { FabContext } from '../../FabContext';
import { ContactsSelfImportMappingHeaderItem } from '../ContactsSelfImportMappingHeaderItem';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IErrorMessageComponentProps {
	className?: string;
	importContacts: AppImportContactsViewModel;
	onCancelButtonClicked?(): void;
	onImportClicked?(): void;
	showMergeByNameCheckbox?: boolean;
}

interface IState {
	mergeByName: boolean;
}

class _ContactsSelfImportMapping extends React.Component<IProps, IState> {
	private mAllFieldOptions: ISelectBoxOption<IImportContactFieldConfig>[];

	constructor(props: IProps) {
		super(props);
		this.state = { mergeByName: true };
		if (!!props.importContacts && !!props.importContacts.preview && !!props.importContacts.preview.fields) {
			this.mAllFieldOptions = props.importContacts.preview.fields.map<ISelectBoxOption<IImportContactFieldConfig>>(
				(x, i) => {
					return {
						id: `${x}-${i}`,
						title: x.name,
						value: x,
					};
				}
			);
			this.mAllFieldOptions.splice(0, 0, {
				id: 'null',
				title: '--',

				value: null,
			});
		}
	}

	public componentDidMount() {
		const { logPageView } = this.props;

		logPageView('ContactsSelfImport/Map');
	}

	public render() {
		const { className, importContacts, onCancelButtonClicked, showMergeByNameCheckbox } = this.props;
		const { mergeByName } = this.state;
		return (
			<div className={`${css(styleSheet.container)} contacts-self-import-mapping ${className || ''}`}>
				<MultiContainerHeader fullscreenHeader='Please map the correct columns using the drop downs.' />
				<div className={`${css(styleSheet.content)} contacts-self-import-mapping-content`}>
					<div className={css(styleSheet.header)}>
						<p className={css(styleSheet.headerTitle)}>
							Start out by mapping the columns to what Levitate can support:
						</p>
						<div className={css(styleSheet.headerSubtitle)}>
							<SvgIcon className={css(styleSheet.headerArrow)} height={42} width={16}>
								<g fill='none' fillRule='evenodd' stroke='#89C947' strokeWidth='2' transform='matrix(1 0 0 -1 1 40)'>
									<path strokeDasharray='4' d='M5,5 C5,22.3394495 8.33333333,34.0061162 15,40' />
									<polyline points='0 4 5 0 10 4' />
								</g>
							</SvgIcon>
							<p className={css(styleSheet.tableSampleMessage)}>This is just a sample of your spreadsheet.</p>
						</div>
					</div>
					<div className={css(styleSheet.body)}>
						<div className={css(styleSheet.bodyInner)}>
							<table className={css(styleSheet.table)}>
								<thead>
									<tr className={css(styleSheet.tableHeadTr)}>
										{(importContacts.preview?.header || []).map((x, i) => {
											return (
												<ContactsSelfImportMappingHeaderItem
													header={x}
													index={i}
													fieldOptions={this.mAllFieldOptions}
													importVM={importContacts}
													selections={this.selections}
													key={`${x}-${i}`}
												/>
											);
										})}
									</tr>
								</thead>
								<tbody>
									{(importContacts.preview?.sample || []).map((row, k) => {
										return (
											<tr key={`${importContacts.preview.header[k]}-[${k}]`}>
												{row.map((col, j) => {
													return (
														<td
															className={css(styleSheet.tableBodyTrTd)}
															key={`${importContacts.preview.header[k]}-[${k}][${j}]`}
														>
															{col}
														</td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className={css(styleSheet.footer)}>
					{showMergeByNameCheckbox && (
						<div className={css(styleSheet.mergeByNameContainer)}>
							<Checkbox
								id='merge-by-name'
								onChange={this.onMergeByNameChange}
								checked={mergeByName}
								className={css(styleSheet.checkboxText)}
							>
								<div>Merge contacts with the same first & last name</div>
							</Checkbox>
						</div>
					)}
					<div className={css(styleSheet.ctaContainer)}>
						<button
							className={css(baseStyleSheet.ctaButton)}
							disabled={!!importContacts.isBusy}
							onClick={this.onImportClicked}
						>
							<span>Import</span>
						</button>
						<button
							className={css(baseStyleSheet.ctaButtonReverse)}
							disabled={!!importContacts.isBusy}
							onClick={onCancelButtonClicked}
						>
							<span>Cancel</span>
						</button>
					</div>
				</div>
				{!!importContacts.isBusy && <LoadingSpinner className='absolute-center' type='large' />}
				<FabContext appearance={{ hidden: true }} />
			</div>
		);
	}

	private onMergeByNameChange = () => {
		const { mergeByName } = this.state;
		this.setState({ mergeByName: !mergeByName });
	};

	@computed
	private get selections() {
		const { importContacts } = this.props;
		const headerNameToSelectBoxOption: IDictionary<ISelectBoxOption<IImportContactFieldConfig>> = {};

		(importContacts.preview.header || []).forEach((headerName, i) => {
			const field = importContacts.options.headerNameToFieldMap[headerName];
			if (field) {
				headerNameToSelectBoxOption[headerName] = {
					id: `${field.propertyName}-${i}`,
					title: field.name,
					value: field,
				};
			}
		});
		return headerNameToSelectBoxOption;
	}

	private onImportClicked = () => {
		const { errorMessages, logApiError, logInput, importContacts, onImportClicked } = this.props;
		const { mergeByName } = this.state;
		const selections = this.selections;
		const headerNames = Object.keys(selections);
		if (headerNames.length === 0) {
			errorMessages.push({
				messages: ['Please map at least one column'],
			});
			return;
		}

		let messages: string[] = null;

		// check for duplicates
		const propertyNames = new Set<string>();
		const dupFields = headerNames
			.map(x => selections[x].value)
			.filter(x => {
				const hasPropertyName = !!x && propertyNames.has(x.propertyName);
				if (!!x && !x.allowMultiple) {
					propertyNames.add(x.propertyName);
				}
				return hasPropertyName;
			});
		if (dupFields.length > 0) {
			messages = [`Cannot map "${dupFields[0].name}" to more than one column.`, 'Please revise and try again.'];
		}

		if (messages) {
			errorMessages.push({
				messages,
			});
			return;
		}

		const promise = importContacts.executeImport(mergeByName);
		if (promise) {
			logInput('Import', 'Click', { id: importContacts.preview.id });
			promise
				.then(() => {
					if (onImportClicked) {
						onImportClicked();
					}
				})
				.catch((error: IOperationResultNoValue) => {
					logApiError('Import-Error', error);

					errorMessages.pushApiError(error);
				});
		}
	};
}

const ContactsSelfImportMappingAsObserver = observer(_ContactsSelfImportMapping);
const ContactsSelfImportMappingWithContext = inject(ErrorMessagesViewModelKey)(ContactsSelfImportMappingAsObserver);
export const ContactsSelfImportMapping = withEventLogging(
	ContactsSelfImportMappingWithContext,
	'ContactsSelfImportMapping'
);
