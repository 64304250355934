import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { Modifier } from 'react-day-picker/types/common';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	accountHasComplianceEnabled,
	getComplianceDisabledDays,
	getScheduleTimeOptions,
	getSendOnBehalfDisabledDays,
	getSendOnBehalfWaitTime,
} from '../../../../models/UiUtils';
import { baseStyleSheet } from '../../../styles/styles';
import { CampaignDayPicker } from '../../CampaignDayPicker';
import { DefaultSelectBox, ISelectBoxOption } from '../../SelectBox';
import { styleSheet } from './styles';

export const CampaignDateTimePicker = inject(ImpersonationContextKey)(
	observer(function CampaignDateTimePicker({
		initialValue,
		onSave,
		onCancel,
		expirationDate,
		impersonationContext,
		sendFrom,
		sendFromUserId,
		ctaText,
		ignoreDisabledDays = false,
	}: {
		initialValue: Date;
		onSave(newValue: Date): void;
		onCancel(): void;
		expirationDate?: Date;
		sendFrom?: Api.ISendEmailFrom;
		sendFromUserId?: string;
		ctaText?: React.ReactNode;
		ignoreDisabledDays?: boolean;
	} & IImpersonationContextComponentProps) {
		const userSession = useUserSession();
		const [value, setValue] = React.useState<Date>(initialValue);
		const [selectedHour, setSelectedHour] = React.useState<number>(initialValue ? initialValue.getHours() : null);

		const requiresCompliance = accountHasComplianceEnabled(userSession, impersonationContext);

		const minStartDate = React.useMemo(() => {
			if (!sendFrom) {
				return new Date();
			}
			const currentUserId = impersonationContext?.isValid ? impersonationContext?.user?.id : userSession.user.id;
			const senderIsNotCurrentUser =
				Api.SendEmailFrom.ContactOwner === sendFrom ||
				(Api.SendEmailFrom.SelectedUser === sendFrom && sendFromUserId !== currentUserId);
			return requiresCompliance && senderIsNotCurrentUser
				? moment
						.max([
							moment(getComplianceDisabledDays(userSession, impersonationContext, false)),
							moment(getSendOnBehalfWaitTime(userSession, impersonationContext)),
						])
						.toDate()
				: requiresCompliance
					? getComplianceDisabledDays(userSession, impersonationContext, false)
					: senderIsNotCurrentUser
						? getSendOnBehalfDisabledDays(userSession, impersonationContext)
						: null;
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const onTimeChanged = (hour: number) => {
			setSelectedHour(hour);
			value.setHours(hour);
			setValue(value);
		};

		const timeOptions = getScheduleTimeOptions(0, userSession, undefined, moment(value));
		const NUM_WEEKS_SHOWN_IN_CAL = 6;

		const disabledDays = expirationDate
			? [{ after: expirationDate, before: minStartDate ? minStartDate : new Date() }]
			: [{ before: minStartDate ? minStartDate : new Date() }];

		const businessHours = userSession?.account?.features?.sendEmail;
		const outsideBusinessHourDays: Modifier[] = [];
		if (businessHours?.observeSendIntervals && ignoreDisabledDays === false) {
			if (!businessHours?.saturdayInterval?.startMinutes && !businessHours?.saturdayInterval?.endMinutes) {
				outsideBusinessHourDays.push({ daysOfWeek: [6] });
			}
			if (!businessHours?.sundayInterval?.startMinutes && !businessHours?.sundayInterval?.endMinutes) {
				outsideBusinessHourDays.push({ daysOfWeek: [0] });
			}
			if (!businessHours?.fridayInterval?.startMinutes && !businessHours?.fridayInterval?.endMinutes) {
				outsideBusinessHourDays.push({ daysOfWeek: [5] });
			}
		}

		const onDaySelected = (day: Date) => {
			day.setHours(value.getHours());
			setValue(day);
		};

		return (
			<div className={css(styleSheet.DateTimePicker)}>
				<div className={css(styleSheet.body)}>
					<div className={css(styleSheet.calendar)}>
						<CampaignDayPicker
							selectedDay={value}
							disabledDays={[...disabledDays, ...outsideBusinessHourDays]}
							numWeeksShown={NUM_WEEKS_SHOWN_IN_CAL}
							onDayClick={onDaySelected}
							modifiers={null}
						/>
					</div>

					<div className={css(styleSheet.separator)} />

					<div className={css(styleSheet.timeContainer)}>
						<DefaultSelectBox
							className={css(styleSheet.timeDropdown)}
							menuPopupPosition='top'
							onSelectionChanged={(option: ISelectBoxOption<number>) => {
								onTimeChanged(option.value);
							}}
							options={timeOptions}
							optionStyles={[baseStyleSheet.textSm]}
							placeholder={<span>Select a time...</span>}
							selectedOption={timeOptions.find(option => option.value === selectedHour)}
							triggerStyles={[baseStyleSheet.textSm]}
						/>
					</div>

					<div className={css(styleSheet.separator)} />
				</div>

				<div className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={() => onSave(value)}>
						{ctaText ? ctaText : <span>Schedule</span>}
					</button>

					<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={() => onCancel()}>
						<span>Cancel</span>
					</button>
				</div>
			</div>
		);
	})
);
