import { useEventLogging } from '../../../models/Logging';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import {
	useEmailAllCategoryTemplates,
	useEmailCategoryTemplates,
	useEmailSearchTemplates,
	useMyTemplatesQuery,
	useTemplateIndustryCategoriesQuery,
} from '../../../queries';
import { ITemplateCard, Industry, KnownCategories } from '../../../viewmodels/AppViewModels';
import { useCampaignParams } from '../CampaignBrowser';

export const useEmailCampaignBrowserData = (accountIndustry?: Industry) => {
	const { logApiError } = useEventLogging('EmailCampaignCards');
	const { category, industry, search, setCampaignBrowserParam, sortBy } = useCampaignParams();
	const userSession = useUserSession();
	const categoriesQuery = useTemplateIndustryCategoriesQuery({ industry });
	const allCategoryTemplatesQuery = useEmailAllCategoryTemplates({
		enabled: category === KnownCategories.All,
		industry,
		onError: error => {
			logApiError('LoadAllEmailCategoryTemplates-Error', error);
		},
	});
	const myTemplatesQuery = useMyTemplatesQuery<ITemplateCard>({
		enabled: category === KnownCategories.MyTemplates,
		industry,
		onError: error => {
			logApiError('LoadMyEmailTemplates-Error', error);
		},
		sort: sortBy === 'name' ? 'asc' : 'desc',
		sortBy,
	});
	const featuredQuery = useEmailCategoryTemplates({
		categoryName: KnownCategories.Featured,
		enabled: true,
		industry: accountIndustry || industry || userSession?.account?.additionalInfo?.industry,
		onError: error => {
			logApiError('LoadSocialFeaturedTemplates-Error', error);
		},
	});
	const categoryTemplatesQuery = useEmailCategoryTemplates({
		categoryName: category,
		enabled:
			industry != null &&
			category != null &&
			![KnownCategories.HtmlNewsletters, KnownCategories.All, KnownCategories.MyTemplates].includes(category),
		industry,
		onError: error => {
			if (error.systemCode === 404) {
				setCampaignBrowserParam({ category: KnownCategories.All });
			} else {
				logApiError('LoadEmailCategoryTemplates-Error', error);
			}
		},
		// We don't want to retry if the category doesn't exist
		retry: (_, error) => error.systemCode !== 404,
	});
	const searchTemplatesQuery = useEmailSearchTemplates({
		enabled: !!search,
		industry,
		onError: error => {
			logApiError('SearchEmailCampaign-Error', error);
		},
		query: search,
	});
	return {
		allCategoryTemplatesQuery,
		categoriesQuery,
		categoryTemplatesQuery,
		featuredQuery,
		myTemplatesQuery,
		searchTemplatesQuery,
	};
};
