import { css } from 'aphrodite';
import { FC, PropsWithChildren, useState } from 'react';
import { ArrayPath, FieldArray, Path, useFieldArray, useFormContext } from 'react-hook-form';
import { Collapsible } from '../../../web/components/Collapsible';
import { PlusIcon } from '../../../web/components/svgs/icons/PlusIcon';
import { RightChevron } from '../../../web/components/svgs/icons/RightChevron';
import { TrashIcon } from '../../../web/components/svgs/icons/TrashIcon';
import { brandPrimary, charmGray } from '../../../web/styles/colors';
import { bs } from '../../../web/styles/styles';
import { LabeledInput } from '../LabeledInput/LabeledInput';
import { Column, Row } from '../Layout';
import { ButtonBase } from './presentation';
import { styleSheet } from './styles';

export const InputFormTable: FC<{ title: string }> = ({ children, title }) => {
	return (
		<Column>
			<Row className={css(styleSheet.tableHead)}>
				<h1 className={css(styleSheet.tableTitle)}>{title}</h1>
			</Row>
			{children}
		</Column>
	);
};

interface ColumnProps {
	fieldName: string;
	placeholder?: string;
	disabled?: boolean;
}

interface FormTableRowProps<T extends Record<string, any>> {
	collectionName: Extract<keyof T, string>;
	columns?: ColumnProps[];
	onDeleteRow?: () => void;
	rowIndex: number;
}

export const InputFormTableRow = <T extends Record<string, any>>({
	children,
	collectionName,
	columns,
	onDeleteRow,
	rowIndex,
}: PropsWithChildren<FormTableRowProps<T>>) => {
	const {
		formState: { errors },
		register,
	} = useFormContext<T>();

	const [collapseChildren, setCollapseChildren] = useState<boolean>(false);

	return (
		<span className={css(!collapseChildren ? styleSheet.borderBottom : null)}>
			<Row className={css(bs.flex, styleSheet.tableParentRow, bs.itemsCenter, styleSheet.gap24)}>
				{columns?.map((column, index) => (
					<Column className={css(bs.flex1)} key={`${collectionName}-column-${index}`}>
						<LabeledInput
							disabled={column.disabled}
							error={(errors?.[`${collectionName}`] as any)?.[rowIndex]?.[`${column.fieldName}`]}
							placeholder={column?.placeholder}
							{...register(`${String(collectionName)}.${rowIndex}.${column.fieldName}` as Path<T>)}
						/>
					</Column>
				))}
				{!!children && (
					<Column className={css(bs.wAuto, bs.justifyCenter)}>
						<ButtonBase className={css(styleSheet.iconButton)} onClick={() => setCollapseChildren(prev => !prev)}>
							<RightChevron
								className={css(collapseChildren ? styleSheet.rotate90 : styleSheet.rotate270)}
								colorFill={charmGray}
								height={12}
								width={12}
							/>
						</ButtonBase>
					</Column>
				)}
				{columns.length < 3 && <Column className={css(bs.flex1)} />}
				<Column className={css(bs.wAuto, bs.justifyCenter)}>
					<ButtonBase className={css(styleSheet.iconButton)} onClick={onDeleteRow}>
						<TrashIcon fillColor={charmGray} />
					</ButtonBase>
				</Column>
			</Row>
			<Collapsible isCollapsed={collapseChildren}>{children}</Collapsible>
		</span>
	);
};

interface FormTableRowChildrenProps<T extends Partial<Record<string, U[]>>, U extends Partial<Record<string, any>>> {
	collectionName: Extract<keyof U, string>;
	columns?: ColumnProps[];
	defaultRowValue?: U;
	parentRowIndex: number;
	parentCollectionName: Extract<keyof T, string>;
}

export const InputFormTableRowChildren = <T extends Record<string, U[]>, U extends Partial<Record<string, any>>>({
	collectionName,
	columns,
	defaultRowValue,
	parentCollectionName,
	parentRowIndex,
}: PropsWithChildren<FormTableRowChildrenProps<T, U>>) => {
	const {
		formState: { errors },
		register,
	} = useFormContext();
	const {
		fields: rows,
		append,
		remove,
	} = useFieldArray<T>({
		name: `${String(parentCollectionName)}.${parentRowIndex}.${String(collectionName)}` as ArrayPath<T>,
	});

	return (
		<>
			{rows.map((row, rowIndex) => {
				return (
					<Row key={row.id} className={css(bs.flex, styleSheet.tableChildRow, bs.itemsCenter, styleSheet.gap24)}>
						{columns?.map((column, columnIndex) => (
							<Column className={css(bs.flex1)} key={`${row.id}-${columnIndex}`}>
								<LabeledInput
									error={
										(errors[`${parentCollectionName}`] as any)?.[parentRowIndex]?.[collectionName]?.[rowIndex]?.[
											`${column.fieldName}`
										]
									}
									placeholder={column?.placeholder}
									{...register(
										`${parentCollectionName}.${parentRowIndex}.${collectionName}.${rowIndex}.${column.fieldName}`
									)}
								/>
							</Column>
						))}
						{columns.length < 3 && <Column className={css(bs.flex1)} />}
						<ButtonBase className={css(styleSheet.iconButton)} onClick={() => remove(rowIndex)}>
							<TrashIcon fillColor={charmGray} />
						</ButtonBase>
					</Row>
				);
			})}
			{!!defaultRowValue && (
				<AppendInputRow isChildRow onAppendRow={() => append(defaultRowValue as FieldArray<T, ArrayPath<T>>)} />
			)}
		</>
	);
};

export const AppendInputRow: FC<{ isChildRow?: boolean; onAppendRow: () => void }> = ({ isChildRow, onAppendRow }) => {
	return (
		<Row
			className={css(
				bs.flex,
				isChildRow ? styleSheet.tableChildRow : styleSheet.tableParentRow,
				bs.itemsCenter,
				styleSheet.gap24
			)}
		>
			<ButtonBase onClick={onAppendRow} style={{ color: brandPrimary }}>
				<span className={css(bs.p2)}>
					<PlusIcon />
				</span>
				Add Item
			</ButtonBase>
		</Row>
	);
};
