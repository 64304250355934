import * as Api from '@ViewModels';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '@WebComponents/autocomplete/SimpleAutoCompleteSearchField';
import { StyleDeclarationValue, css } from 'aphrodite';
import equal from 'fast-deep-equal';
import * as React from 'react';
import { useEventLogging } from '../../../models/Logging';
import { getDisplayName } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useUserQuery } from '../../../queries';
import { useConnectionTypes } from '../../../queries/ConnectionType/useConnectionTypes';
import { baseStyleSheet } from '../../styles/styles';
import { Dropdown } from '../Dropdown';
import { Modal } from '../Modal';
import { DisclosureIcon } from '../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';

export interface ISendFromDropdownProps {
	styles?: StyleDeclarationValue[];
	sendFromOption?: Api.ISendFromOptions;
	sendFromUserId?: string;
	isForResourceSelector?: boolean;
	allowContactBasedOptions?: boolean;
	allowContactOwnerOption?: boolean;
	hideOptionsWithFilters?: boolean;
	sendOnBehalfOf: (option?: Api.ISendOnBehalfPermission, user?: Api.IUser) => void;
	disabled?: boolean;
	canSendOnBehalf?: boolean;
	onRenderSendFromOptions?(): React.ReactNode;
}

interface ISendOnBehalfOption {
	text: string;
	value: Api.ISendOnBehalfPermission;
}

const getSendOptions = (
	props: ISendFromDropdownProps,
	userSession: Api.UserSessionContext,
	connectionTypes: Api.IConnectionType[]
) => {
	const { firstName, lastName } = userSession!.user!;

	const allowedOptions = userSession.defaultSendOnBehalfPermissions;
	const sendOnBehalfOptions: ISendOnBehalfOption[] = [];

	const currentUserOption = allowedOptions.find(x => x.sender.mode === Api.SendEmailFrom.CurrentUser);
	if (currentUserOption) {
		sendOnBehalfOptions.push({
			text: `${firstName} ${lastName}`,
			value: currentUserOption,
		});
	}

	const contactOwnerOption = allowedOptions.find(x => x.sender.mode === Api.SendEmailFrom.ContactOwner);
	if (props.allowContactBasedOptions && contactOwnerOption) {
		if (props.isForResourceSelector || props.allowContactOwnerOption) {
			sendOnBehalfOptions.push({
				text: 'Owner of the recipient',
				value: contactOwnerOption,
			});
		}
	}

	if (props.allowContactBasedOptions) {
		if (allowedOptions.find(x => x.sender?.mode === Api.SendEmailFrom.ConnectionType) && connectionTypes?.length) {
			connectionTypes.forEach(connectionType => {
				sendOnBehalfOptions.push({
					text: `${connectionType.label} for the recipient`,
					value: { sender: { mode: Api.SendEmailFrom.ConnectionType, connectionType: connectionType.value } },
				});
			});
		}
	}

	const selectedUserOption = allowedOptions.find(x => x.sender?.mode === Api.SendEmailFrom.SelectedUser);
	/** @NOTE - Only allow 'select an employee' if the contact selection was not individually selected */
	if (selectedUserOption && !props.disabled) {
		sendOnBehalfOptions.push({
			text: 'Select an employee',
			value: selectedUserOption,
		});
	}

	if (props.allowContactBasedOptions) {
		userSession.customSendOnBehalfPermissions
			?.filter(x => !props.hideOptionsWithFilters || !x.requiredFilter)
			.forEach(x => {
				sendOnBehalfOptions.push({
					text: x.title,
					value: x,
				});
			});
	}
	return sendOnBehalfOptions;
};

const getSendOnBehalfAnchorText = (
	selectedOption: ISendOnBehalfOption,
	sendOptions: ISendOnBehalfOption[],
	connectionTypes: Api.IConnectionType[],
	selectedEmployee?: Api.IUser
) => {
	if (selectedOption?.value?.sender?.mode === Api.SendEmailFrom.ConnectionType) {
		return `${
			connectionTypes.find(connectionType => connectionType.value === selectedOption.value.sender.connectionType).label
		} for the recipient`;
	}

	if (
		selectedOption?.value?.sender &&
		selectedOption.value.sender.mode === Api.SendEmailFrom.SelectedUser &&
		selectedEmployee
	) {
		return getDisplayName(selectedEmployee);
	}

	if (selectedOption?.text) {
		return selectedOption.text;
	}
	return sendOptions.find(x => x.value.sender?.mode === Api.SendEmailFrom.CurrentUser)?.text;
};

export const SendFromDropdown = (props: ISendFromDropdownProps) => {
	const { logApiError } = useEventLogging('SendFromDropdown');
	const userSession = useUserSession();
	const connectionTypeQuery = useConnectionTypes({
		enabled: userSession.account.features.connectionTypes?.enabled ?? false,
		refetchOnWindowFocus: false,
	});
	const connectionTypes = connectionTypeQuery.data?.availableConnectionTypes;
	const sendOptions = getSendOptions(props, userSession, connectionTypes);
	const [selectedOption, setSelectedOption] = React.useState<ISendOnBehalfOption>(
		sendOptions.find(x => equal(x.value.sender, props.sendFromOption))
	);
	const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);
	const [selectAnEmployeeModalIsOpen, setSelectAnEmployeeModalIsOpen] = React.useState(false);
	const [tempSelectedEmployee, setTempSelectedEmployee] = React.useState<Api.IUser>(null);
	const [selectedEmployee, setSelectedEmployee] = React.useState<Api.IUser>(null);
	useUserQuery({
		enabled: Boolean(props.sendFromUserId),
		id: props.sendFromUserId ?? '',
		onSuccess: loadedUser => {
			setSelectedEmployee(loadedUser);
		},
		onError: error => {
			logApiError('UserLoadError', error);
		},
	});

	const getTitleText = () => {
		if (props.canSendOnBehalf) {
			if (selectedOption?.value?.sender?.mode === Api.SendEmailFrom.SelectedUser) {
				return selectedEmployee?.email;
			} else if (selectedOption?.value?.sender?.mode === Api.SendEmailFrom.CurrentUser) {
				return userSession?.user?.email;
			}
		}
		return null;
	};

	React.useEffect(() => {
		setSelectedOption(sendOptions.find(x => equal(x.value.sender, props.sendFromOption)));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.sendFromOption]);

	const sendOnBehalfSelectEmployee = () => {
		setDropdownIsOpen(false);
		setSelectAnEmployeeModalIsOpen(true);
	};

	const onSendFromOptionChanged = (option: ISendOnBehalfOption) => {
		setDropdownIsOpen(false);

		if (option === selectedOption) {
			return;
		}

		if (option.value.sender?.mode === Api.SendEmailFrom.SelectedUser) {
			sendOnBehalfSelectEmployee();
		} else {
			setSelectedOption(option);
			props.sendOnBehalfOf(option?.value);
		}
	};

	const onSelectAnEmployeeClose = () => {
		setSelectAnEmployeeModalIsOpen(false);

		setTempSelectedEmployee(null);
	};

	const clearSelectedEmployee = () => {
		setTempSelectedEmployee(null);
	};

	const onEmployeeSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<Api.IUser>) => {
		if (e.target) {
			e.target.setSearchQuery(Api.VmUtils.getDisplayName(e.selection));
		}

		setTempSelectedEmployee(e.selection);
	};

	const onSaveSelectAnEmployee = async () => {
		setSelectAnEmployeeModalIsOpen(false);
		if (tempSelectedEmployee) {
			const option = sendOptions.find(x => x.value.sender?.mode === Api.SendEmailFrom.SelectedUser);
			setSelectedOption(option);
			setSelectAnEmployeeModalIsOpen(false);
			setSelectedEmployee(tempSelectedEmployee);
			props.sendOnBehalfOf(option?.value, tempSelectedEmployee);
			setTempSelectedEmployee(null);
		}
	};

	const onSelectAnEmployeeChange = (e: ISimpleAutoCompleteSearchFieldEvent<React.KeyboardEvent<HTMLInputElement>>) => {
		if (e.sourceEvent && e.sourceEvent.keyCode !== 13) {
			clearSelectedEmployee();
		}
	};

	const onCreateAutoCompleteViewModel = (
		_: Api.ResourceAutoCompleteViewModelType,
		suggestedViewModel: Api.ResourceAutoCompleteViewModel
	) => {
		suggestedViewModel.addParam({ includeDeactivated: false });
		return suggestedViewModel;
	};

	if (props.onRenderSendFromOptions) {
		const options = props.onRenderSendFromOptions();

		if (options) {
			return (
				<div className={css(baseStyleSheet.horizontalStack, styleSheet.sendFromDropdownContainer, props.styles)}>
					<span>Send from:</span>
					{options}
				</div>
			);
		}
	}

	if (!props.canSendOnBehalf) {
		return null;
	}

	return (
		<div className={css(styleSheet.sendFromDropdownContainer, props.styles)}>
			<span>Send from:</span>
			<Dropdown
				anchor={
					<div className={css(baseStyleSheet.truncateText, styleSheet.dropdownAnchor)}>
						<span title={getTitleText()} className={css(baseStyleSheet.truncateText, styleSheet.dropdownAnchorText)}>
							{getSendOnBehalfAnchorText(selectedOption, sendOptions, connectionTypes, selectedEmployee)}
						</span>
						<DisclosureIcon className={css(styleSheet.dropdownIcon)} />
					</div>
				}
				disabled={props.disabled}
				className={css(styleSheet.dropdownInline, styleSheet.sendFromDropdown)}
				contentClassName={css(styleSheet.dropdownMenu)}
				contentPositionY='bottom'
				isOpen={dropdownIsOpen}
				onOpenChanged={setDropdownIsOpen}
				openOnClick={true}
			>
				<div>
					{Object.values(sendOptions).map((x, i) => {
						return (
							<div
								className={css(styleSheet.dropdownItem, baseStyleSheet.truncateText)}
								key={i}
								onClick={() => onSendFromOptionChanged(x)}
							>
								{x.text}
							</div>
						);
					})}
				</div>
			</Dropdown>
			{props.canSendOnBehalf && (
				<Modal isOpen={selectAnEmployeeModalIsOpen} onRequestClose={onSelectAnEmployeeClose} useDefaultHeader>
					<div className={css(styleSheet.selectAnEmployeeModal)}>
						<div className={css(styleSheet.title, baseStyleSheet.truncateText)}>Select an Employee</div>
						<div className={css(styleSheet.modalBodyText)}>
							Since you are the admin of this Levitate account, you can select another employee so this email will come
							from their email instead. They must be a Levitate user.
						</div>
						<SimpleAutoCompleteSearchField
							onClear={clearSelectedEmployee}
							// @ts-ignore
							onItemSelected={onEmployeeSelected}
							onKeyDown={onSelectAnEmployeeChange}
							placeholder='Search employee'
							style={styleSheet.searchEmployeeBox}
							type={Api.ResourceAutoCompleteViewModelType.User}
							onCreateAutoCompleteViewModel={onCreateAutoCompleteViewModel}
						/>
						<button
							className={css(baseStyleSheet.ctaButton, styleSheet.selectAnEmployeeCTA)}
							style={{ marginBottom: 5 }}
							onClick={onSaveSelectAnEmployee}
							disabled={!tempSelectedEmployee}
						>
							<span>Save</span>
						</button>
					</div>
				</Modal>
			)}
		</div>
	);
};
