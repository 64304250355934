import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { DayModifiers, Modifiers } from 'react-day-picker/types/common';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
	IDictionary,
	IUser,
	PostStatus,
	SocialMediaPostsReportingViewModel,
	TemplatesViewModel,
} from '../../../../extViewmodels';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import {
	EnvironmentKey,
	ErrorMessagesViewModelKey,
	IEnvironmentComponentProps,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import {
	accountHasComplianceEnabled,
	convertDurationFromString,
	getComplianceDisabledDays,
	getComplianceWaitTimeString,
	getDefaultDateStringValue,
	getDefaultTimeStringValue,
	getSendOnBehalfWaitTime,
	getSendOnBehalfWaitTimeString,
} from '../../../../models/UiUtils';
import { Button } from '../../../components/Button';
import { DayPicker } from '../../../components/DayPicker';
import { DeprecatedPopover, PopoverType } from '../../../components/DeprecatedPopover';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { DefaultSelectBox, ISelectBoxOption } from '../../../components/SelectBox';
import { DangerMessage } from '../../../components/errorMessages/DangerMessage';
import { CampaignList } from '../../../components/reporting/emails/CampaignList';
import { ExpiryIcon } from '../../../components/svgs/icons/ExpiryIcon';
import { SocialMediaIcon } from '../../../components/svgs/icons/SocialMediaIcon';
import { destructive } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		RouteComponentProps<any>,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IImpersonationContextComponentProps,
		IEnvironmentComponentProps {
	className?: string;
	hideHeader?: boolean;
	ignoreCompliance?: boolean;
	initialStartDate?: moment.MomentInput;
	onSubmitPost?(e?: React.MouseEvent<HTMLElement>, postMoment?: Moment, ignoreCompliance?: boolean): void;
	expirationDate?: string;
	selectedUser?: IUser;
	sendOnBehalf?: boolean;
	styles?: StyleDeclarationValue[];
	submitButtonText?: string;
	templateId?: string;
	isSchedulingInProgress: boolean;
}

interface IState {
	expirationDate?: string;
	modifiers?: IDictionary<Date[]>;
	selectedMoment?: moment.Moment;
	sendNow?: boolean;
	dayHover?: Date;
}

const ScheduleSelectBoxOptions: ISelectBoxOption<'now' | 'schedule'>[] = [
	{
		title: 'Send right away',
		value: 'now',
	},
	{
		title: 'Schedule for later',
		value: 'schedule',
	},
];

interface IEditSocialMediaSendOptionsDayPickerModifiers {
	disabled?: boolean;
	selected?: boolean;
	hasCampaign?: boolean;
	hasCompletedCampaign?: boolean;
	hasQueuedCampaign?: boolean;
}
class _EditSocialMediaSendOptions extends React.Component<IProps, IState> {
	private mDateRange: { startDate?: Date; endDate?: Date };

	private mSocialMediaVM: SocialMediaPostsReportingViewModel;

	constructor(props: IProps) {
		super(props);
		let startMoment = props.initialStartDate ? moment(props.initialStartDate) : null;

		if (startMoment.isBefore(this.minStartDate)) {
			startMoment = moment(this.minStartDate);
		}

		if (!startMoment) {
			startMoment = moment().set('minutes', 0);
		}

		if (startMoment.minutes() % 15 !== 0) {
			startMoment = startMoment.set('minutes', Math.ceil(startMoment.minutes() / 15) * 15);
		}

		this.state = {
			expirationDate: props.expirationDate,
			selectedMoment: startMoment,
			sendNow:
				!(this.isCompliance && !props.ignoreCompliance) &&
				!props?.sendOnBehalf &&
				!props.impersonationContext &&
				startMoment.isSame(moment(), 'day'),
		};
	}

	public async componentDidMount() {
		if (this.props.templateId && !this.props.expirationDate) {
			const templates = new TemplatesViewModel(this.props.userSession);
			await templates.getById(this.props.templateId).then(template => {
				this.setState({
					expirationDate: template?.schedule?.expirationDate,
				});
			});
		}

		await this.loadSocialMedia();
	}

	public render() {
		const {
			className,
			styles,
			submitButtonText,
			environment,
			impersonationContext,
			hideHeader,
			isSchedulingInProgress,
		} = this.props;
		const { selectedMoment, sendNow, modifiers, expirationDate } = this.state;
		let expirationWarning: React.ReactNode;

		const expirationDateAsDate = expirationDate ? new Date(expirationDate) : null;
		const disabledDays = [
			{
				after: expirationDateAsDate,

				before: new Date(this.minStartDate?.toLocaleString()) ?? new Date(),
			},
		];

		if (expirationDate) {
			expirationWarning = (
				<div className={css(styleSheet.expirationWarning)}>
					<ExpiryIcon fill={destructive} />
					<time
						dateTime={expirationDateAsDate.toLocaleDateString()}
					>{`This is a time-sensitive campaign. Posts will not be made after ${getDefaultDateStringValue(
						expirationDateAsDate
					)} at ${getDefaultTimeStringValue(expirationDateAsDate)}.`}</time>
				</div>
			);
		}

		return (
			<div
				className={`${css(styleSheet.container, ...(styles || []))} edit-social-media-send-options ${className || ''}`}
			>
				<div className={css(styleSheet.content)}>
					{!hideHeader ? (
						<div className={css(styleSheet.header)}>
							<SocialMediaIcon />
							<div className={css(styleSheet.headerText)}>
								<div>When do you want to post this?</div>
								{!this.disableSendNow && !impersonationContext && (
									<div className={css(styleSheet.headerDescription)}>Post now or choose a future date and time.</div>
								)}
							</div>
						</div>
					) : null}
					<div className={css(styleSheet.body)}>
						<DayPicker
							className={css(styleSheet.dayPicker)}
							disabledDays={[...disabledDays]}
							environment={environment}
							modifiers={modifiers}
							onDayClick={this.onDaySelected}
							onMonthChange={this.onMonthChanged}
							onWillChangeToNextMonth={this.onWillChangeMonth}
							onWillChangeToPreviousMonth={this.onWillChangeMonth}
							onRenderDay={this.onRenderDay}
							selectedDays={[selectedMoment.toDate()]}
						/>
						<div className={css(styleSheet.campaignListContainer)}>
							{!this.mSocialMediaVM || this.mSocialMediaVM.isLoading ? (
								<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />
							) : (
								<CampaignList campaigns={this.mSocialMediaVM} className={css(styleSheet.campaignList)} />
							)}
						</div>
					</div>
					{this.getWarningMessage()}

					<footer className={css(styleSheet.footer, expirationWarning ? styleSheet.footerWithWarning : null)}>
						{expirationWarning}

						<div className={css(styleSheet.footerInputs)}>
							<time dateTime={selectedMoment.toLocaleString()} className={css(styleSheet.selectedDayStringRep)}>
								{selectedMoment.format(`ddd[,] MMMM Do `)}
							</time>
							<div className={css(baseStyleSheet.verticalStack, styleSheet.footerInputsInner)}>
								<div className={css(baseStyleSheet.horizontalStack)}>
									{this.shouldShowScheduleTypeSelector && (
										<DefaultSelectBox
											className={css(styleSheet.scheduleTypeDropDown)}
											menuPopupPosition='top'
											onSelectionChanged={this.onTypeOptionSelected}
											options={this.scheduleSelectBoxOptions}
											optionStyles={[styleSheet.scheduleTypeDropDownOption]}
											selectedOption={this.scheduleSelectBoxOptions.find(
												x => x.value === (sendNow ? 'now' : 'schedule')
											)}
											triggerStyles={[styleSheet.scheduleTypeDropDownTrigger]}
										/>
									)}
									{!sendNow && this.timeOptions?.length > 0 && (
										<DefaultSelectBox
											className={css(styleSheet.timeDropdown)}
											menuPopupPosition='top'
											onSelectionChanged={this.onTimeOptionSelected}
											options={this.timeOptions}
											optionStyles={[styleSheet.timeDropdownOption]}
											placeholder={<span className={css(styleSheet.timeDropdownPlaceholder)}>Select a time...</span>}
											selectedOption={this.validSelectedHour}
											triggerStyles={[styleSheet.timeDropdownTrigger]}
										/>
									)}
									<Button
										className={css(baseStyleSheet.ctaButton, !sendNow && styleSheet.scheduleCta)}
										kind='custom'
										onClick={this.onScheduleCtaClicked}
										disabled={this.mSocialMediaVM?.isBusy || isSchedulingInProgress}
										label={<>{sendNow ? 'Send Now' : submitButtonText || 'Schedule'}</>}
										isLoading={this.mSocialMediaVM?.isBusy || isSchedulingInProgress}
									/>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</div>
		);
	}

	@computed
	private get validSelectedHour() {
		const { selectedMoment } = this.state;
		const hour = selectedMoment.get('hours');
		const minutes = selectedMoment.get('minutes');

		// Convert (hour + minutes/60) so 3:15 => 3.25, 3:30 => 3.5, etc.
		const decimalTime = hour + minutes / 60;

		return (
			this.timeOptions.find(x => x.value === decimalTime) ?? {
				isDefault: true,
				title: 'Select a time',
				value: -1,
			}
		);
	}

	private loadSocialMedia = async () => {
		const { impersonationContext, userSession, logApiError, sendOnBehalf, selectedUser } = this.props;

		let user: IUser = impersonationContext?.isValid ? impersonationContext.user : null;
		if (!impersonationContext?.isValid) {
			user = sendOnBehalf ? selectedUser : user;
		}

		this.mSocialMediaVM = new SocialMediaPostsReportingViewModel(userSession, selectedUser);
		this.mSocialMediaVM.pageSize = 100;

		this.mSocialMediaVM.setSelectedUserId(user?.role === 'user' || user?.role === 'limitedUser' ? user?.id : null);

		impersonationContext?.applyImpersonation(this.mSocialMediaVM);

		if (impersonationContext?.isValid && !impersonationContext.account.features.socialMedia?.enabled) {
			return;
		}

		if (!userSession.account.features.socialMedia?.enabled) {
			return;
		}
		this.mSocialMediaVM.dateRange = this.mDateRange || {};
		try {
			await this.mSocialMediaVM.load();
			if (this.mSocialMediaVM?.items?.length > 0) {
				const datesWithCompletedCampaigns =
					this.mSocialMediaVM.items
						.filter(
							x =>
								(x.status === PostStatus.Succeeded ||
									x.status === PostStatus.Started ||
									x.status === PostStatus.PartiallySucceeded) &&
								!!x?.dueDate
						)

						.map(x => new Date(x.dueDate)) || [];
				const datesWithQueuedCampaigns =
					this.mSocialMediaVM.items
						.filter(x => x.status === PostStatus.Scheduled && !!x?.dueDate)

						.map(x => new Date(x.dueDate)) || [];
				this.setState({
					modifiers: {
						hasCampaign: [...datesWithCompletedCampaigns, ...datesWithQueuedCampaigns],
						hasCompletedCampaign: datesWithCompletedCampaigns,
						hasQueuedCampaign: datesWithQueuedCampaigns,
					},
				});
			} else {
				this.setState({
					modifiers: null,
				});
			}
		} catch (error) {
			logApiError('SocialCampaignsLoad-Error', error);
			this.setState({
				modifiers: null,
			});
		}
	};

	private openPopover = (day: Date) => () => {
		this.setState({ dayHover: day });
	};

	private closePopover = () => {
		this.setState({ dayHover: null });
	};

	private onDisabledDayClick = (day: Date) => (e: React.MouseEvent<HTMLSpanElement>) => {
		const nowMoment = moment();

		e.stopPropagation();
		e.preventDefault();
		if (moment(day).isSame(nowMoment, 'day')) {
			this.onDaySelected(day, null, e);
		}
	};

	private onRenderDay = (
		day: Date,
		modifiers: Partial<Modifiers> & IEditSocialMediaSendOptionsDayPickerModifiers,
		props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
	): React.ReactNode => {
		const { dayHover } = this.state;
		const { expirationDate } = this.props;

		this.mDateRange = this.mDateRange ?? { endDate: day, startDate: day };

		if (!this.mDateRange?.startDate || day < this.mDateRange?.startDate) {
			this.mDateRange.startDate = day;
		}

		if (this.mDateRange?.endDate || day > this.mDateRange?.endDate) {
			this.mDateRange.endDate = day;
		}

		let styles: object[] = [];
		if (modifiers.hasCampaign) {
			styles = [
				styleSheet.dayPickerDateWithCampaign,

				modifiers.hasQueuedCampaign ? styleSheet.dayPickerDateHasQueuedCampaign : null,

				modifiers.hasCompletedCampaign ? styleSheet.dayPickerDateHasCompletedCampaign : null,

				modifiers.selected ? styleSheet.dayPickerDateSelected : null,
			];
		}

		if (modifiers.disabled) {
			if (modifiers?.selected) {
				styles.push(styleSheet.dayPickerDateSelected);
			}
			const anchor = (
				<span
					className={`${moment().isSame(day, 'day') ? props?.className || '' : ''} ${css(...styles)} ${css(
						styleSheet.dayPickerPopoverAnchor
					)}`}
					onMouseEnter={this.openPopover(day)}
					onMouseLeave={this.closePopover}
					onClick={this.onDisabledDayClick(day)}
				>
					{day.getDate()}
				</span>
			);

			return (
				<DeprecatedPopover
					anchor={anchor}
					isOpen={moment(dayHover).isSame(day, 'day') && !moment(day).isSameOrBefore(moment(), 'day')}
					className={css(styleSheet.dayPickerPopover)}
					preferredPlacement='right'
					type={PopoverType.white}
					tipSize={1}
				>
					{expirationDate && day > new Date(expirationDate) ? (
						<div className={css(styleSheet.dayPickerPopoverContent)}>
							You cannot schedule this post past it&apos;s expiration date. To bypass this, save the post as a draft and
							then schedule.
						</div>
					) : null}
				</DeprecatedPopover>
			);
		}

		if (modifiers?.hasCampaign) {
			return (
				<span className={`${props?.className || ''} ${css(...styles)}`}>
					{day.getDate()}
					<span className={css(styleSheet.dayPickerDateWithCampaignIndicator)} />
				</span>
			);
		}

		if (modifiers?.selected) {
			return (
				<span className={`${props?.className || ''} ${css(styleSheet.dayPickerDateSelected)}`}>{day.getDate()}</span>
			);
		}
		return null;
	};

	private renderComplianceMessage() {
		const { userSession, impersonationContext } = this.props;
		const complianceWarning =
			'You must schedule at least ' +
			getComplianceWaitTimeString(userSession, impersonationContext) +
			' ahead to allow compliance time to review and approve this campaign.';

		return <DangerMessage styles={[styleSheet.sendWaitTime]}> {complianceWarning} </DangerMessage>;
	}

	private renderSendOnBehalfWarning() {
		let sendOnBehalfWarning: string;

		if (this.props.sendOnBehalf) {
			sendOnBehalfWarning =
				'Since you are sending a post on behalf of another user, we require that you schedule the post at least ' +
				getSendOnBehalfWaitTimeString(this.props.userSession, this.props.impersonationContext) +
				' ahead, so we can give them proper notification.';
		}

		return sendOnBehalfWarning ? (
			<DangerMessage styles={[styleSheet.sendWaitTime]}>{sendOnBehalfWarning}</DangerMessage>
		) : null;
	}

	@computed
	private get isCompliance() {
		const { userSession, impersonationContext } = this.props;

		return accountHasComplianceEnabled(userSession, impersonationContext);
	}

	@computed get disableSendNow() {
		const { impersonationContext, ignoreCompliance, sendOnBehalf } = this.props;
		return impersonationContext?.isValid || (this.isCompliance && !ignoreCompliance) || sendOnBehalf;
	}

	private getWarningMessage() {
		const { userSession, impersonationContext, ignoreCompliance, sendOnBehalf } = this.props;
		if (this.isCompliance && sendOnBehalf) {
			return moment(getComplianceDisabledDays(userSession, impersonationContext, ignoreCompliance)).isAfter(
				moment(getSendOnBehalfWaitTime(userSession, impersonationContext))
			)
				? this.renderComplianceMessage()
				: this.renderSendOnBehalfWarning();
		}
		return this.isCompliance ? this.renderComplianceMessage() : sendOnBehalf ? this.renderSendOnBehalfWarning() : null;
	}
	@computed
	private get minStartDate() {
		const { userSession, impersonationContext, ignoreCompliance, sendOnBehalf } = this.props;
		return this.isCompliance && sendOnBehalf
			? moment
					.max([
						moment(getComplianceDisabledDays(userSession, impersonationContext, ignoreCompliance)),

						moment(getSendOnBehalfWaitTime(userSession, impersonationContext)),
					])
					.toDate()
			: this.isCompliance
				? getComplianceDisabledDays(userSession, impersonationContext, ignoreCompliance)
				: sendOnBehalf
					? getSendOnBehalfWaitTime(userSession, impersonationContext)
					: null;
	}
	private onMonthChanged = () => {
		// called after render of DayPicker
		this.loadSocialMedia();
	};

	private onWillChangeMonth = () => {
		this.mDateRange = null;
	};

	@computed
	private get timeOptions() {
		const { userSession, impersonationContext } = this.props;
		const { selectedMoment } = this.state;
		const nowMoment = moment();

		// Generate all possible time slots (24 hours × 4 quarters)
		const generateTimeSlots = () => {
			const slots: ISelectBoxOption<number>[] = [];
			for (let hour = 0; hour < 24; hour++) {
				for (let minutes = 0; minutes < 60; minutes += 15) {
					const value = hour + minutes / 60;
					const timeStr = moment().set({ hour, minute: minutes }).format('h:mm A');
					slots.push({
						title: timeStr,
						value,
					});
				}
			}
			return slots;
		};

		const allOptions = generateTimeSlots();
		const DEFAULT_DAYS = 2;
		const days = !impersonationContext?.isValid
			? convertDurationFromString(userSession?.account?.preferences?.sendOnBehalfWaitTime)?.days ?? DEFAULT_DAYS
			: convertDurationFromString(impersonationContext?.account?.preferences?.sendOnBehalfWaitTime)?.days ??
				DEFAULT_DAYS;

		// Handle send-on-behalf case
		if (
			this.props.sendOnBehalf &&
			selectedMoment?.isSame(
				getSendOnBehalfWaitTime(this.props.userSession, this.props?.impersonationContext),
				'day'
			) &&
			days !== DEFAULT_DAYS
		) {
			return allOptions.filter(({ value }) => {
				const hour = Math.floor(value);
				const minute = Math.floor((value % 1) * 60);
				const thenMoment = getSendOnBehalfWaitTime(this.props.userSession, this.props?.impersonationContext)
					.set('hours', hour)
					.set('minutes', minute);

				return thenMoment.isAfter(getSendOnBehalfWaitTime(this.props.userSession, this.props?.impersonationContext));
			});
		}

		// Filter options if it's today and we need future times only
		if (selectedMoment?.isSame(nowMoment, 'day')) {
			return allOptions.filter(({ value }) => {
				const hour = Math.floor(value);
				const minute = Math.floor((value % 1) * 60);
				const thenMoment = moment(nowMoment).set({ hours: hour, minutes: minute });
				return thenMoment.isAfter(nowMoment);
			});
		}

		return allOptions;
	}

	@computed
	private get shouldShowScheduleTypeSelector() {
		const { impersonationContext } = this.props;
		return (
			(!this.disableSendNow && !impersonationContext) ||
			(!impersonationContext && this.state.selectedMoment?.isSame(moment(), 'day') && this.timeOptions?.length > 0)
		);
	}

	private get scheduleSelectBoxOptions() {
		const { impersonationContext } = this.props;
		return this.disableSendNow || impersonationContext
			? ScheduleSelectBoxOptions.filter(x => x.value !== 'now')
			: ScheduleSelectBoxOptions;
	}

	private onTypeOptionSelected = (option: ISelectBoxOption<'now' | 'scheduled'>) => {
		const nextSelectedMoment = moment();

		nextSelectedMoment.set('minutes', 0);
		nextSelectedMoment.set('seconds', 0);
		this.onDaySelected(nextSelectedMoment.toDate(), null, null);
		this.setState({
			sendNow: option.value === 'now',
		});
	};

	private onTimeOptionSelected = (option: ISelectBoxOption<number>) => {
		const { selectedMoment } = this.state;

		if (selectedMoment) {
			const nextSelectedMoment = moment(selectedMoment);
			const hours = Math.floor(option.value);
			const minutes = Math.floor((option.value - hours) * 60);
			nextSelectedMoment.set({ hour: hours, minute: minutes, second: 0 });
			this.setState({
				selectedMoment: nextSelectedMoment,
			});
		}
	};

	private onDaySelected = (day: Date, _: DayModifiers, e: React.MouseEvent<HTMLElement>) => {
		const { impersonationContext } = this.props;

		if (
			moment(day).isBefore(moment().startOf('day')) ||
			(this.minStartDate && moment(day).isBefore(moment(this.minStartDate).startOf('day')))
		) {
			e.stopPropagation();
			e.preventDefault();
			return;
		}
		const { selectedMoment } = this.state;
		this.mDateRange = null;

		const nextMoment = moment(day);
		if (selectedMoment) {
			nextMoment.set('hours', selectedMoment.get('hours'));
			nextMoment.set('minutes', selectedMoment.get('minutes'));
		}
		const nextState: IState = {
			selectedMoment: nextMoment,
		};
		nextState.sendNow = !this.disableSendNow && !impersonationContext ? nextMoment.isSame(moment(), 'day') : false;

		this.setState(nextState);
	};

	private onScheduleCtaClicked = (e?: React.MouseEvent<HTMLElement>) => {
		const { onSubmitPost, ignoreCompliance } = this.props;
		const { selectedMoment, sendNow } = this.state;

		onSubmitPost(e, sendNow ? moment() : selectedMoment, ignoreCompliance);
	};
}

const EditSocialMediaSendOptionsAsObserver = observer(_EditSocialMediaSendOptions);
const EditSocialMediaSendOptionsWithContext = inject(
	EnvironmentKey,
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ImpersonationContextKey
)(EditSocialMediaSendOptionsAsObserver);
const EditSocialMediaSendOptionsWithRouter = withRouter(EditSocialMediaSendOptionsWithContext);
export const EditSocialMediaSendOptions = withEventLogging(
	EditSocialMediaSendOptionsWithRouter,
	'EditSocialMediaSendOptions'
);
