import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { autorun, computed, reaction } from 'mobx';
import { Provider, inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { Flipper } from 'react-flip-toolkit';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
	IEditAutomationTemplateRequest,
	IImpersonationContextComponentProps,
	ILocationState,
	IModalContext,
	ModalChildComponentContextKey,
} from '../../../models';
import * as AppState from '../../../models/AppState';
import {
	EditAutomationTemplateContext,
	EditAutomationTemplateItemIdPrefix,
	EditAutomationTemplateStepsContext,
	IEditAutomationTemplateContext,
	IEditAutomationTemplateStepsContext,
} from '../../../models/Automations';
import { ImpersonationContextViewModel } from '../../../viewmodels/AdminViewModels';
import { EditAutomationTemplateStepsViewModel } from '../../../viewmodels/AutomationViewModels';
import {
	ConfirmationDialog,
	DefaultDeleteConfirmationOptions,
	IConfirmationDialogOption,
} from '../../components/ConfirmationDialog';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { ScrollView } from '../../components/ScrollView';
import { TextInput } from '../../components/TextInput';
import { TextInputModal } from '../../components/TextInputModal';
import {
	AutomationDisableCancelModal,
	AutomationDisableCancelSelection,
} from '../../components/automation/AutomationDisableCancelModal';
import { AutomationTriggerCard } from '../../components/automation/AutomationTriggerCard';
import { EditAutomationTemplateItemBumpers } from '../../components/automation/EditAutomationTemplateItemBumpers';
import { EditAutomationTemplateSteps } from '../../components/automation/EditAutomationTemplateSteps';
import { AutomationTemplateStepIndicatorIcon } from '../../components/svgs/icons/AutomationTemplateStepIndicator';
import { DangerIcon } from '../../components/svgs/icons/DangerIcon';
import { LightningBoltIcon } from '../../components/svgs/icons/LightningBoltIcon';
import { PlusIcon } from '../../components/svgs/icons/PlusIcon';
import { TrashIcon } from '../../components/svgs/icons/TrashIcon';
import { WarningIcon } from '../../components/svgs/icons/WarningIcon';
import { baseStyleSheet } from '../../styles/styles';
import { INavigationItemProps } from '../MainContainer';
import { styleSheet } from './styles';

export interface IEditAutomationTemplateHeaderCallbacks {
	onDisableButtonClicked(): void;
	showDeleteConfirmation(): void;
}

interface IProps
	extends IEventLoggingComponentProps,
		AppState.IErrorMessageComponentProps,
		AppState.IToasterComponentProps,
		RouteComponentProps<any>,
		AppState.IUserSessionComponentProps,
		INavigationItemProps,
		IModalContext,
		AppState.IFabComponentProps {
	automationTemplate?: Api.AutomationTemplateViewModel;
	className?: string;
	hideDisableOption?: boolean;
	onDraftUpdated?(): void;
	onRenderHeader?(callbacks: IEditAutomationTemplateHeaderCallbacks): React.ReactNode;
	readonly?: boolean;
	styles?: StyleDeclarationValue[];
}

interface IState {
	automationTemplate?: Api.AutomationTemplateViewModel;
	editAutomationTemplateContext?: IEditAutomationTemplateContext;
	editAutomationTemplateRequest?: IEditAutomationTemplateRequest;
	editAutomationTemplateStepsContext?: IEditAutomationTemplateStepsContext;
	lastSaveMoment?: moment.Moment;
	loading?: boolean;
	name?: string;
	showDeleteConfirmation?: boolean;
	showDisableConfirmation?: boolean;
	showDuplicationNameInputModal?: boolean;
	showPublishConfirmation?: boolean;
}

const PublishConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isCta: true,
		representedObject: true,
		title: 'Okay & Publish',
	},
];

class _EditAutomationTemplate extends React.Component<IProps, IState> {
	private mFabContextDisposer: () => void;
	private mStepsContainerRef = React.createRef<Flipper>();
	private onChangeDisposer: () => void;
	private onCanEditChangeDisposer: () => void;
	private mImpersonationProviderProps: IImpersonationContextComponentProps = {
		impersonationContext: new ImpersonationContextViewModel(),
	};

	constructor(props: IProps) {
		super(props);
		this.state = {
			automationTemplate: props.automationTemplate,
			editAutomationTemplateContext: {
				canEdit: this.canEdit,
				onDraftUpdated: props.onDraftUpdated,
				parentAutomationTemplate: props.automationTemplate,
			},
			loading: true,
			showDisableConfirmation: false,
		};
	}

	public componentDidMount() {
		const { fab } = this.props;
		this.loadTemplate(this.state.automationTemplate);
		const disposer = fab.registerContext({
			appearance: {
				hidden: true,
			},
		});

		this.mFabContextDisposer = () => {
			disposer();
			this.mFabContextDisposer = null;
		};
	}

	public componentWillUnmount() {
		if (this.mFabContextDisposer) {
			this.mFabContextDisposer();
		}

		this.disposeOnChangeListeners();
	}

	// #region render
	public render() {
		const { className, styles, routeContainerClassName } = this.props;
		const {
			editAutomationTemplateStepsContext,
			editAutomationTemplateContext,
			name,
			loading,
			automationTemplate,
			showDisableConfirmation,
			showDeleteConfirmation,
			showPublishConfirmation,
		} = this.state;
		const locationName = loading
			? 'Loading...'
			: `${automationTemplate?.publishedStepReferences?.length > 0 ? 'Edit' : 'Create New'} Automation`;
		return (
			<Provider {...this.mImpersonationProviderProps}>
				<EditAutomationTemplateContext.Provider value={editAutomationTemplateContext}>
					<EditAutomationTemplateStepsContext.Provider value={editAutomationTemplateStepsContext}>
						<ScrollView
							className={`${css(styleSheet.scrollView, ...(styles || []))} edit-automation-template ${className || ''}`}
							contentClassName={routeContainerClassName || undefined}
							hasFixedHeader={true}
							header={this.renderHeader()}
						>
							<MultiContainerHeader
								appBarHeader={<NavigationBreadcrumbsBar currentLocationName={locationName} />}
								fullscreenHeader={locationName}
							/>
							<div className={css(styleSheet.body)}>
								<div className={css(styleSheet.bodyBackground)} />
								<div className={css(styleSheet.bodyContent)}>
									<article className={css(styleSheet.nameContainer)}>
										<h2 className={css(styleSheet.sectionTitle)}>
											Automation Name: &nbsp;
											<span className={css(baseStyleSheet.required)}>*</span>
										</h2>
										<TextInput
											className={css(styleSheet.nameInput)}
											disabled={loading || !this.canEdit}
											inputId='edit-automation-template-name-input'
											onBlur={this.onNameInputBlur}
											onChange={this.onNameInputChanged}
											type='text'
											value={name || ''}
										/>
										<div className={css(styleSheet.nameInputHint)}>
											Example: &quot;Leads Email Drip&quot;, or &quot;New Client To Do List&quot;
										</div>
									</article>
									<EditAutomationTemplateItemBumpers onRenderLeftBumperContent={this.onRenderTriggerIndicator}>
										<AutomationTriggerCard automationTemplate={automationTemplate} steps={this.steps} />
									</EditAutomationTemplateItemBumpers>
									<EditAutomationTemplateSteps ref={this.mStepsContainerRef}>
										{this.renderStepsFooter()}
									</EditAutomationTemplateSteps>
									{loading && <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />}
								</div>
							</div>
							{!!this.canEdit && (
								<>
									{!!showDeleteConfirmation && (
										<ConfirmationDialog
											icon={<WarningIcon />}
											modalProps={{
												isOpen: !!showDeleteConfirmation,
												onRequestClose: this.onDeleteConfirmationDialogRequestClose,
											}}
											options={DefaultDeleteConfirmationOptions}
											title='Are you sure you want to delete this automation template?'
										/>
									)}
									{!!showPublishConfirmation && (
										<ConfirmationDialog
											icon={<WarningIcon />}
											modalProps={{
												isOpen: !!showPublishConfirmation,
												onRequestClose: this.onPublishConfirmationDialogRequestClose,
											}}
											options={PublishConfirmationOptions}
											title={
												<span>
													Changes will apply to future
													<br />
													automations in progress.
												</span>
											}
										>
											<div style={{ textAlign: 'center' }}>
												Contacts that have existing automation in progress
												<br />
												will finish out the previous version.
											</div>
										</ConfirmationDialog>
									)}
									{!!showDisableConfirmation && (
										<AutomationDisableCancelModal
											automationTemplate={automationTemplate}
											isOpen={showDisableConfirmation}
											onRequestClose={this.onDisableModalClosed}
										/>
									)}
								</>
							)}
						</ScrollView>
					</EditAutomationTemplateStepsContext.Provider>
				</EditAutomationTemplateContext.Provider>
			</Provider>
		);
	}

	private renderStepsFooter() {
		if (!this.canEdit) {
			return null;
		}
		const addStepButton = (
			<button className={css(baseStyleSheet.horizontalStack, styleSheet.addStepButton)} onClick={this.onAddStepClicked}>
				<PlusIcon />
				<span>Add a Step</span>
			</button>
		);
		const renderAddSegementButton = (title?: string) => (
			<button
				className={css(baseStyleSheet.horizontalStack, styleSheet.addStepButton)}
				onClick={this.onAddSegmentClicked}
			>
				<PlusIcon />
				<span>{title || 'Add Segment'}</span>
			</button>
		);

		return (
			<div className={css(baseStyleSheet.horizontalStack, styleSheet.stepsFooter)}>
				{this.steps?.every(x => !!x.automationStep?.type && x.automationStep?.type !== Api.AutomationStepType.Switch) ||
				this.steps?.length === 0 ? (
					<>
						{addStepButton}
						{renderAddSegementButton()}
					</>
				) : this.steps?.length === 1 && !this.steps[0]?.automationStep?.id ? (
					renderAddSegementButton('Add Segment Instead')
				) : this.steps?.some(x => x.automationStep?.type === Api.AutomationStepType.Switch) ? (
					renderAddSegementButton()
				) : null}
			</div>
		);
	}

	private onRenderTriggerIndicator = () => {
		return (
			<div className={css(styleSheet.stepIndicator)}>
				<AutomationTemplateStepIndicatorIcon disableInnerCircle={true} className={css(styleSheet.triggerIndicator)}>
					<LightningBoltIcon className={css(styleSheet.triggerIndicatorIcon)} />
				</AutomationTemplateStepIndicatorIcon>
				<div className={css(styleSheet.triggerIndicatorHorizontalLine)} />
				<div className={css(styleSheet.triggerIndicatorVerticalLine)} />
			</div>
		);
	};

	private renderHeader() {
		const { hideDisableOption, onRenderHeader } = this.props;
		const { loading, automationTemplate } = this.state;
		return (
			<div className={css(styleSheet.headerFooter)}>
				{this.renderAutosaveInfo()}
				<div className={css(baseStyleSheet.horizontalStack)}>
					{!loading &&
						(onRenderHeader ? (
							onRenderHeader({
								onDisableButtonClicked: this.onDisableButtonClicked,
								showDeleteConfirmation: this.showDeleteConfirmation,
							})
						) : (
							<>
								{automationTemplate?.hasDraftVersion && (
									<div className={css(styleSheet.unpublished)}>
										<DangerIcon className={css(styleSheet.dangerIcon)} />
										unpublished changes
									</div>
								)}
								{this.renderPublishButton()}
								{!!this.canEdit && (
									<>
										{automationTemplate?.hasPublishedVersion &&
											automationTemplate?.status !== Api.TemplateStatus.Disabled &&
											!hideDisableOption && (
												<button
													className={css(baseStyleSheet.ctaButtonDestructiveSolid)}
													disabled={automationTemplate.isBusy || this.isUpdatingStep}
													onClick={this.onDisableButtonClicked}
												>
													<span>Disable Automation</span>
												</button>
											)}
										<button
											className={css(baseStyleSheet.horizontalStack, baseStyleSheet.ctaButtonDestructive)}
											disabled={automationTemplate.isBusy || this.isUpdatingStep}
											onClick={this.showDeleteConfirmation}
										>
											<TrashIcon />
											<span>Delete</span>
										</button>
									</>
								)}
							</>
						))}
				</div>
			</div>
		);
	}

	private renderPublishButton() {
		const { automationTemplate, showDuplicationNameInputModal, name } = this.state;
		const isDisabled =
			automationTemplate.isBusy ||
			this.isUpdatingStep ||
			this.steps?.some(x => !x.automationStep?.id) ||
			this.steps?.length === 0 ||
			!name;
		if (automationTemplate?.scope === Api.TemplateScope.Industry && !this.canEdit) {
			return (
				<>
					<button className={css(baseStyleSheet.ctaButton)} onClick={this.onSaveAsNewClicked}>
						<span>Save as New Template</span>
					</button>
					{!!showDuplicationNameInputModal && (
						<TextInputModal
							cta='Duplicate'
							modalProps={{
								isOpen: !!showDuplicationNameInputModal,
								onRequestClose: this.onDuplicateAutomationNameInputModalRequestClose,
							}}
							placeholderText='New Automation name'
							title={`Duplicate Automation: "${automationTemplate?.name}"`}
						/>
					)}
				</>
			);
		}
		if (this.canEdit) {
			return (
				<button
					className={css(automationTemplate?.hasPublishedVersion ? baseStyleSheet.ctaButton : styleSheet.enableButton)}
					disabled={isDisabled}
					onClick={!isDisabled ? this.onPublishButtonClicked : undefined}
				>
					<span>
						{automationTemplate?.hasPublishedVersion && automationTemplate?.status !== Api.TemplateStatus.Disabled
							? 'Publish Changes'
							: 'Enable Automation'}
					</span>
				</button>
			);
		}
	}

	private renderAutosaveInfo() {
		const { lastSaveMoment } = this.state;
		return (
			<div className={css(styleSheet.autosaveInfo)}>
				{!!lastSaveMoment && `Auto saved on ${lastSaveMoment.format('MM/DD/YYYY hh:mma')}`}
			</div>
		);
	}
	// #endregion

	private disposeOnChangeListeners = () => {
		if (this.onChangeDisposer) {
			this.onChangeDisposer();
			this.onChangeDisposer = null;
		}

		if (this.onCanEditChangeDisposer) {
			this.onCanEditChangeDisposer();
			this.onCanEditChangeDisposer = null;
		}
	};

	private initOnChangeListeners = () => {
		this.disposeOnChangeListeners();

		this.onCanEditChangeDisposer = autorun(() => {
			const { editAutomationTemplateContext } = this.state;
			const { onDraftUpdated: propsOnDraftUpdated } = this.props;
			if (this.canEdit !== editAutomationTemplateContext?.canEdit) {
				this.setState({
					editAutomationTemplateContext: {
						...(editAutomationTemplateContext || {}),
						canEdit: this.canEdit,
						onDraftUpdated: propsOnDraftUpdated,
					},
				});
			}
		});

		const { automationTemplate } = this.state;
		this.onChangeDisposer = reaction(
			() => {
				return [
					automationTemplate.draftVersion,
					automationTemplate.draftVersion?.steps,
					automationTemplate.draftVersion?.steps?.map(x => x.conditionalSteps)?.filter(x => !!x),
					automationTemplate.lastModifiedMoment,
					automationTemplate.publishedVersion,
				];
			},
			() => {
				this.props.onDraftUpdated?.();
				this.setState({
					lastSaveMoment: moment(),
				});
			}
		);
	};

	@computed
	private get steps() {
		const { editAutomationTemplateStepsContext } = this.state;
		return editAutomationTemplateStepsContext?.editorSteps?.steps;
	}

	@computed
	private get canEdit() {
		const { readonly } = this.props;
		return !readonly && !!this.state?.automationTemplate?.canEdit;
	}

	@computed
	private get isUpdatingStep() {
		return (this.steps || [])
			.map(x => x)
			.filter(x => !!x.automationStep)
			.some(x => x.automationStep.isBusy);
	}

	private onAddSegmentClicked = async () => {
		// check for empty first step... delete first if found
		const firstStep = this.steps?.[0];
		this.state.editAutomationTemplateStepsContext?.editorSteps?.addSegment(
			this.steps?.length === 1 && !firstStep.automationStep?.id
		);
	};

	private onAddStepClicked = () => {
		this.state.editAutomationTemplateStepsContext?.editorSteps?.addStep();
	};

	private loadTemplate = async (automationTemplate?: Api.AutomationTemplateViewModel) => {
		const { history, logApiError, logEvent, errorMessages, match, userSession } = this.props;
		const nextState: IState = {};
		if (!automationTemplate) {
			const locationState: ILocationState<any, IEditAutomationTemplateRequest> = history?.location?.state;
			if (locationState?.model) {
				automationTemplate = locationState.model.automationTemplate;
				nextState.editAutomationTemplateRequest = locationState.model;
			}
		}

		if (!automationTemplate) {
			const id = match.params.id;
			if (id) {
				automationTemplate = new Api.AutomationTemplateViewModel(userSession, {
					id,
					templateType: Api.TemplateType.Automation,
				});
			}
		}

		const promise = automationTemplate?.load();
		if (promise) {
			logEvent('AutomationTemplateLoad', { id: automationTemplate.id });
			this.mImpersonationProviderProps.impersonationContext.update(automationTemplate.impersonationContext);
			promise
				.then(async () => {
					const editorStepsVm = new EditAutomationTemplateStepsViewModel(userSession, automationTemplate).impersonate(
						automationTemplate.impersonationContext
					);

					try {
						logEvent('EditAutomationTemplateStepsLoad');
						// no need to reload template, hence the "false" param below
						await editorStepsVm.load(false);
					} catch (error) {
						logApiError('EditAutomationTemplateStepsLoad-Error', error);
						errorMessages.pushApiError(error);
					}

					this.setState(
						{
							automationTemplate,
							editAutomationTemplateContext: {
								...(this.state.editAutomationTemplateContext || {}),
								parentAutomationTemplate: automationTemplate,
							},
							editAutomationTemplateStepsContext: {
								editorStepElementRefs: {},
								editorSteps: editorStepsVm,
							},
							loading: false,
							name: automationTemplate.name,
						},
						() => {
							this.initOnChangeListeners();
						}
					);
				})
				.catch((error: Api.IOperationResultNoValue) => {
					logApiError('AutomationTemplateLoad-Error', error);
					errorMessages.pushApiError(error);
					this.setState({
						loading: false,
					});
				});
		} else {
			nextState.loading = false;
			errorMessages.push({
				messages: ['Unable to load. An automation template id is required.'],
			});
		}

		this.setState(nextState);
	};

	private onDuplicateAutomationNameInputModalRequestClose = (result?: string, cancel?: boolean) => {
		if (!cancel && !!result) {
			this.duplicateAutomation(result);
		}
		this.setState({
			showDuplicationNameInputModal: false,
		});
	};

	private duplicateAutomation = (name: string) => {
		const { logEvent, logApiError, errorMessages, history, match } = this.props;
		const { automationTemplate } = this.state;
		logEvent('DuplicateAutomation', {
			id: automationTemplate.id,
			nameLength: name.length,
		});
		automationTemplate
			?.duplicate(name)
			?.then(createdTemplate => {
				const locationState: ILocationState<any, IEditAutomationTemplateRequest> = {
					model: {
						automationTemplate: createdTemplate,
					},
				};
				history?.replace(`${match.path.replace(':id', createdTemplate.id)}`, locationState);
				this.loadTemplate(createdTemplate);
			})
			?.catch((error: Api.IOperationResultNoValue) => {
				logApiError('DuplicateAutomationTemplate-Error', error);
				errorMessages.pushApiError(error);
			});
	};

	private onSaveAsNewClicked = () => {
		this.setState({
			showDuplicationNameInputModal: true,
		});
	};

	private onPublishConfirmationDialogRequestClose = (result?: IConfirmationDialogOption<boolean>, cancel?: boolean) => {
		this.setState(
			{
				showPublishConfirmation: false,
			},
			() => {
				if (!!result?.representedObject && !cancel) {
					this.publish();
				}
			}
		);
	};

	private onDisableButtonClicked = () => {
		const { automationTemplate } = this.state;
		// In progress is handled in the modal
		if (automationTemplate?.stats?.inProgress >= 0) {
			this.setState({
				showDisableConfirmation: true,
			});
			return;
		}
	};

	private onDisableModalClosed = (result: AutomationDisableCancelSelection) => {
		const { history, match, parentModal } = this.props;
		this.setState({ showDisableConfirmation: false });

		if (result !== AutomationDisableCancelSelection.DoNothing) {
			if (parentModal?.name === 'adminFullscreenModal') {
				history.goBack();
				return;
			}
			history.replace(match.path.replace('/:id', ''));
		}
	};

	private onPublishButtonClicked = () => {
		this.setState({
			showPublishConfirmation: true,
		});
	};

	private publish = () => {
		const { logEvent, logApiError, errorMessages, history, match, toaster, parentModal } = this.props;
		const { automationTemplate, editAutomationTemplateRequest, editAutomationTemplateStepsContext } = this.state;

		// look for errors and scroll to them
		const firstIncompleteStep = (editAutomationTemplateStepsContext?.editorSteps?.getIncompleteSteps() || [])[0];
		if (!!firstIncompleteStep && !!this.mStepsContainerRef) {
			firstIncompleteStep.automationStep.showRequiredFieldError = true;
			const elementId = `${EditAutomationTemplateItemIdPrefix}-${firstIncompleteStep.automationStep.id}`;
			/* eslint-disable-next-line react/no-find-dom-node */
			(findDOMNode(this.mStepsContainerRef.current) as HTMLElement)
				?.querySelector(`#${elementId}`)
				?.scrollIntoView({ behavior: 'smooth' });
			return;
		}
		const promise = automationTemplate.commitDraft();
		if (promise) {
			this.setState({
				loading: true,
			});
			logEvent('CommitDraft', { id: automationTemplate.id });
			promise
				.then(async () => {
					toaster?.push({
						message: 'Automation template published.',
						type: 'successMessage',
					});
					let onFinishHandled = false;
					if (editAutomationTemplateRequest?.onFinish) {
						try {
							onFinishHandled = await editAutomationTemplateRequest.onFinish();
							this.setState({
								loading: false,
							});
						} catch {
							// do nothing
						}
					}
					if (!onFinishHandled) {
						if (parentModal?.name === 'fullscreenModal') {
							parentModal.onRequestClose(null, false);
							return;
						}
						history?.replace(match.path.replace('/:id', ''));
					}
				})
				.catch((error: Api.IOperationResultNoValue) => {
					logApiError('CommitDraft-Error', error);
					errorMessages.pushApiError(error);
					this.setState({
						loading: false,
					});
				});
		}
	};

	private showDeleteConfirmation = () => {
		this.setState({
			showDeleteConfirmation: true,
		});
	};

	private onDeleteConfirmationDialogRequestClose = (result?: IConfirmationDialogOption<boolean>, cancel?: boolean) => {
		this.setState(
			{
				showDeleteConfirmation: false,
			},
			() => {
				if (!!result?.isDestructive && !cancel) {
					const { logEvent, logApiError, errorMessages, history, match, toaster, parentModal } = this.props;
					const { automationTemplate, editAutomationTemplateRequest } = this.state;
					const promise = automationTemplate.delete();
					if (promise) {
						this.setState({
							loading: true,
						});
						logEvent('Delete', { id: automationTemplate.id });
						promise
							.then(async () => {
								toaster?.push({
									message: 'Automation template deleted.',
									type: 'successMessage',
								});
								let onFinishHandled = false;
								if (editAutomationTemplateRequest?.onFinish) {
									try {
										onFinishHandled = await editAutomationTemplateRequest.onFinish();
									} catch {
										// do nothing
									}
								}
								if (!onFinishHandled) {
									if (parentModal?.name === 'fullscreenModal') {
										parentModal.onRequestClose(null, false);
										return;
									}
									history?.replace(match.path.replace('/:id', ''));
								}
							})
							.catch((error: Api.IOperationResultNoValue) => {
								logApiError('Delete-Error', error);
								errorMessages.pushApiError(error);
								this.setState({
									loading: false,
								});
							});
					}
				}
			}
		);
	};

	private onStepSaved = () => {
		this.setState({
			lastSaveMoment: moment(),
		});
		this.props.onDraftUpdated?.();
	};

	private onNameInputBlur = () => {
		const { logApiError, logEvent, errorMessages } = this.props;
		const { automationTemplate, name } = this.state;
		if (!name) {
			return;
		}
		const promise = automationTemplate.setName(name);
		if (promise) {
			logEvent('AutomationTemplateSetName', { id: automationTemplate.id });
			promise
				.then(() => {
					this.onStepSaved();
				})
				.catch((error: Api.IOperationResultNoValue) => {
					logApiError('AutomationTemplateSetName-Error', error);
					errorMessages.pushApiError(error);
					this.setState({
						loading: false,
					});
				});
		}
	};

	private onNameInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			name: e.target.value,
		});
	};
}

const EditAutomationTemplateAsObserver = observer(_EditAutomationTemplate);
const EditAutomationTemplateWithContext = inject(
	AppState.ErrorMessagesViewModelKey,
	AppState.FabViewModelKey,
	ModalChildComponentContextKey,
	AppState.ToasterViewModelKey,
	AppState.UserSessionViewModelKey
)(EditAutomationTemplateAsObserver);
export const EditAutomationTemplate = withRouter(
	withEventLogging(EditAutomationTemplateWithContext, 'EditAutomationTemplate')
);
