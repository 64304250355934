import { StyleSheet } from 'aphrodite';
import { darkGrayFontColor, titles } from '../../styles/colors';

export const s = StyleSheet.create({
	breadcrumbList: {
		display: `flex`,
		flexWrap: `wrap`,
		alignItems: `center`,
		gap: 6,
		overflowWrap: 'break-word',
		fontSize: '1.125rem',
		padding: 0,
		color: darkGrayFontColor,
		'@media (min-width: 640px)': {
			gap: 10,
		},
	},
	breadcrumbItem: {
		display: `inline-flex`,
		alignItems: `center`,
		gap: 12,
	},
	breadcrumbLink: {
		transitionProperty: 'color, background-color, border-color, text-decoration-color, fill, stroke',
		transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
		transitionDuration: '150ms',
		':hover': {
			color: titles,
		},
		color: darkGrayFontColor,
		textDecoration: 'none',
	},
	breadcrumbPage: {
		fontWeight: 400,
		color: titles,
	},
	breadcrumbSeparator: {
		':has(*) > svg': {
			width: 14,
			height: 14,
			display: `block`,
		},
		color: darkGrayFontColor,
	},
	breadcrumbEllipsis: {
		display: `flex`,
		width: 36,
		height: 36,
		alignItems: `center`,
		justifyContent: `center`,
	},
	ellipsisIcon: {
		width: 32,
		height: 32,
		display: `block`,
	},
});
