import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEventLogging } from '../../../../../models/Logging';
import { convertRawRichTextContentStateToRichContentEditorState } from '../../../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../../../models/hooks/appStateHooks';
import {
	useAutomationTemplateStepDeleteMutation,
	useAutomationTemplateStepEmailAttachmentCreateMutation,
	useAutomationTemplateStepEmailOutcomeCreateMutation,
	useAutomationTemplateStepHwcUpdateMutation,
	useTemplate,
} from '../../../../../queries';
import { HwcFrontBackPreview } from '../../../../containers/PostcardComposer/HwcFrontBackPreview';
import { HwcTogglePreviewValues } from '../../../../containers/PostcardComposer/HwcPreviewToggle';
import { convertHtmlValueToPostcardText } from '../../../../containers/PostcardComposer/hooks/usePostcardTextEditor';
import { bs } from '../../../../styles/styles';
import { Checkbox } from '../../../Checkbox';
import { DeprecatedSelect } from '../../../DeprecatedSelect';
import { HandwrittenCardTemplateSearchModal } from '../../../HandwrittenCards/HandwrittenCardTemplateSearchModal';
import { InputFieldError } from '../../../InputFieldError';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { Modal } from '../../../Modal';
import { TextInput } from '../../../TextInput';
import { EditAutomationStepEmailComposerModal } from '../../EditAutomationStepEmailComposerModal';
import {
	AutomationStepScheduleAnchorQualifierSelectOption,
	EScheduleAnchorOptions,
	getTriggerDescription,
} from '../presentation';
import { styleSheet } from '../styles';
import { HwcAutomationStepEditor } from './HwcAutomationStepEditor';

const QualifierSelectOptions: AutomationStepScheduleAnchorQualifierSelectOption[] = [
	{
		dataContext: EScheduleAnchorOptions.Before,
		id: 'before',
		text: 'before',
	},
	{
		dataContext: EScheduleAnchorOptions.SameDay,
		id: 'sameDay',
		text: 'same day as',
	},
	{
		dataContext: EScheduleAnchorOptions.After,
		id: 'after',
		text: 'after',
	},
];

interface Props {
	templateId: string;
	canEdit: boolean;
	step: Api.AutomationTemplateEditorStep<Api.AutomationStepViewModel<Api.UserSessionContext, Api.IAutomationStep>>;
	trigger: Api.IAutomationTrigger;
}

function _HwcAutomationStepCardContent({ templateId, canEdit, step, trigger }: Props) {
	const userSession = useUserSession();
	const { logApiError, logEvent } = useEventLogging('HwcAutomationStepCardContent');
	const errorMessages = useErrorMessages();
	const hwcAutomationStep = step.automationStep.toJs() as Api.HandwrittenCardAutomationStep;
	const [name, setName] = React.useState(hwcAutomationStep.name);
	const [isHwcTemplateChooserModalOpen, setIsHwcTemplateChooserModalOpen] = React.useState(false);
	const [isPreviewModalOpen, setIsPreviewModalOpen] = React.useState(false);
	const [selectedHwcTemplateId, setSelectedHwcTemplateId] = React.useState<string | null>(
		hwcAutomationStep.templateReference?.templateId ?? null
	);
	const [activeTogglePreviewValue, setActiveTogglePreviewValue] = React.useState<HwcTogglePreviewValues>('image');
	const [contentEditorState, setContentEditorState] = React.useState<Api.IRichContentEditorState>(
		convertRawRichTextContentStateToRichContentEditorState(hwcAutomationStep.content)
	);
	const contentEditorStateText = contentEditorState.getPlainTextPreview();
	const selectedHwcTemplateQuery = useTemplate<Api.IHandwrittenCardTemplate>({
		enabled: Boolean(selectedHwcTemplateId),
		templateId: selectedHwcTemplateId,
	});
	const schedule = step.automationStep.schedule;
	const stepHwcUpdateMutation = useAutomationTemplateStepHwcUpdateMutation({
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('SaveAutomationStep-Error', error);
		},
		onSuccess: data => {
			step.automationStep.mSetModel(data);
			logEvent('SaveAutomationStep', {
				id: data.id,
				type: data._type,
			});
		},
	});
	const [dayInputValue, setDayInputValue] = React.useState<string>(String(Math.abs(schedule.numberOfDays)));
	const [selectedQualifierOption, setSelectedQualifierOption] =
		React.useState<AutomationStepScheduleAnchorQualifierSelectOption>(() => {
			if (schedule.numberOfDays < 0) {
				return QualifierSelectOptions.find(x => x.dataContext === EScheduleAnchorOptions.Before);
			} else if (schedule.numberOfDays > 0) {
				return QualifierSelectOptions.find(x => x.dataContext === EScheduleAnchorOptions.After);
			}
			return QualifierSelectOptions.find(x => x.dataContext === EScheduleAnchorOptions.SameDay);
		});
	const fallbackEmailAutomationStep = step?.automationStep?.conditionalSteps?.[
		Api.AutomationStepOutcome.Error
	] as Api.EmailAutomationStepViewModel;
	const [hasFallbackEmailChecked, setHasFallbackEmailChecked] = React.useState<boolean>(
		Boolean(fallbackEmailAutomationStep)
	);
	const attachmentCreateMutation = useAutomationTemplateStepEmailAttachmentCreateMutation({
		onError: error => {
			logApiError('SaveEamilAutomationStepAttachments-Error', Api.asApiError(error));
			errorMessages.pushApiError(Api.asApiError(error));
			setShowingEmailFallbackComposer(false);
		},
	});
	const emailOutcomeCreateMutation = useAutomationTemplateStepEmailOutcomeCreateMutation({
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('SaveAutomationStepOutcome-Error', error);
			setShowingEmailFallbackComposer(false);
		},
		onSuccess: data => {
			step.automationStep.mSetModel(data);
		},
	});
	const stepDeleteMutation = useAutomationTemplateStepDeleteMutation({
		onError: (error, variables) => {
			logApiError(`${variables.stepType}-Error`, error);
			errorMessages.pushApiError(error);
		},
		onSuccess: () => {
			const { Error, ...rest } = step.automationStep.model.conditionalSteps;
			step.automationStep.mSetModel({
				...step.automationStep.model,
				conditionalSteps: rest,
			});
		},
	});
	const handleHasFallbackEmailCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.checked) {
			if (fallbackEmailAutomationStep) {
				stepDeleteMutation.mutate({
					templateId,
					stepType: Api.AutomationStepType.Email,
					impersonationContext: step.automationStep?.impersonationContext,
					stepId: fallbackEmailAutomationStep.id,
				});
			}
			setHasFallbackEmailChecked(false);
		} else {
			let contentHtml = Api.getContentHtmlStringValueFromRawRichTextContentState(
				contentEditorState.getRawRichTextContent()
			);
			if (selectedHwcTemplateQuery.data?.thumbnail?.publicUrl) {
				contentHtml = `<p><img src="${selectedHwcTemplateQuery.data.thumbnail.publicUrl}" /></p>${contentHtml}`;
			}
			const newContent = Api.createRawRichTextContentStateWithHtmlStringValue(contentHtml);
			emailOutcomeCreateMutation.mutate({
				impersonationContext: step.automationStep.impersonationContext,
				templateId,
				stepId: step.automationStep.id,
				outcome: Api.AutomationStepOutcome.Error,
				step: {
					_type: 'EmailAutomationStep',
					content: newContent,
					name: 'Email Fallback Step',
					options: {},
					conditionalSchedule: {
						criteria: Api.AutomationStepScheduleCriteria.RelativeToAnchor,
						numberOfDays: 0,
						numberOfHours: 9,
						numberOfMinutes: 0,
					},
					schedule: {
						criteria: Api.AutomationStepScheduleCriteria.Conditional,
					},
				} as Api.IEmailAutomationStep,
			});
			setHasFallbackEmailChecked(true);
		}
	};
	const onEmailFallbackComposerModalRequestClose = async (data?: Api.IEmailAutomationStep) => {
		if (data) {
			fallbackEmailAutomationStep.mSetModel(data);
		}
		setShowingEmailFallbackComposer(false);
	};
	const onDayBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
		if (!ev.target.value) {
			return;
		}
		const value = parseInt(ev.target.value);
		if (isNaN(value)) {
			return;
		}
		const newSchedule = {
			...schedule,
			numberOfDays: selectedQualifierOption.dataContext === EScheduleAnchorOptions.Before ? -value : value,
		};
		stepHwcUpdateMutation.mutate({
			impersonationContext: step.automationStep.impersonationContext,
			templateId,
			stepId: step.automationStep.id,
			step: {
				...step.automationStep.toJs(),
				schedule: newSchedule,
			},
		});
	};
	const onQualifierOptionSelected = (option: AutomationStepScheduleAnchorQualifierSelectOption) => {
		if (option.dataContext === selectedQualifierOption.dataContext) {
			return;
		}
		setSelectedQualifierOption(option);
		let newNumDays: number;
		switch (option.dataContext) {
			case EScheduleAnchorOptions.Before:
				newNumDays = schedule.numberOfDays === 0 ? -1 : -Math.abs(schedule.numberOfDays);
				break;
			case EScheduleAnchorOptions.After:
				newNumDays = schedule.numberOfDays === 0 ? 1 : Math.abs(schedule.numberOfDays);
				break;
			default:
				newNumDays = 0;
				break;
		}
		const newSchedule = { ...schedule };
		newSchedule.numberOfDays = newNumDays;
		setDayInputValue(String(Math.abs(newNumDays)));
		stepHwcUpdateMutation.mutate({
			impersonationContext: step.automationStep.impersonationContext,
			templateId,
			stepId: step.automationStep.id,
			step: {
				...step.automationStep.toJs(),
				schedule: newSchedule,
			},
		});
	};
	const handleNameBlur = () => {
		clearErrorMessages();
		stepHwcUpdateMutation.mutate({
			impersonationContext: step.automationStep.impersonationContext,
			templateId,
			stepId: step.automationStep.id,
			step: {
				...hwcAutomationStep,
				name,
			},
		});
	};
	const handleEditChangeSave = () => {
		clearErrorMessages();
		stepHwcUpdateMutation.mutate({
			impersonationContext: step.automationStep.impersonationContext,
			templateId,
			stepId: step.automationStep.id,
			step: {
				...step.automationStep.toJs(),
				content: contentEditorState.getRawRichTextContent(),
			},
		});
	};
	const handleTemplateChosen = (template: Api.IHandwrittenCardTemplate) => {
		setSelectedHwcTemplateId(template.id);
		const templateContent = convertRawRichTextContentStateToRichContentEditorState(template.content);
		const preview = contentEditorState.getPlainTextPreview();
		const shouldOverwriteContent = !preview || preview.length === 0;
		if (shouldOverwriteContent) {
			setContentEditorState(templateContent);
		}
		stepHwcUpdateMutation.mutate({
			impersonationContext: step.automationStep.impersonationContext,
			templateId,
			stepId: step.automationStep.id,
			step: {
				...step.automationStep.toJs(),
				content: shouldOverwriteContent
					? templateContent.getRawRichTextContent()
					: contentEditorState.getRawRichTextContent(),
				templateReference: {
					templateId: template.id,
					isCustomized: false,
					isSystemTemplate: false,
				},
			},
		});
		setIsHwcTemplateChooserModalOpen(false);
		clearErrorMessages();
	};
	const [showingEmailFallbackComposer, setShowingEmailFallbackComposer] = React.useState<boolean>(false);
	const onShowEmailFallbackComposerClicked = () => {
		setShowingEmailFallbackComposer(true);
	};
	const activeHandwritingStyle =
		(step.automationStep.model as Api.HandwrittenCardAutomationStep).style ??
		userSession.user.userPreferences?.handwrittenCardPreferences?.handwritingStyle ??
		Api.HandwritingStyle.Aladdin;
	const activeSignature =
		(step.automationStep.model as Api.HandwrittenCardAutomationStep).signature ??
		userSession.user.userPreferences?.handwrittenCardPreferences?.signature ??
		'';
	const showDayInput = selectedQualifierOption.dataContext !== EScheduleAnchorOptions.SameDay;
	const triggerDescription = getTriggerDescription(trigger);
	const clearErrorMessages = () => {
		step.automationStep.showRequiredFieldError = false;
	};
	return (
		<div className={css(bs.boxBorder, bs.p5, bs.flex, bs.flexCol, bs.gap4, bs.itemsStart)}>
			{step.automationStep.showRequiredFieldError ? (
				<div className={css(bs.wFull)}>
					<InputFieldError errorMessage='Please make sure all required fields are filled' />
				</div>
			) : null}
			<div
				className={css(
					bs.flex,
					bs.itemsCenter,
					bs.gap1,
					bs.pb4,
					bs.border0,
					bs.borderB,
					bs.borderSolid,
					bs.borderGray100,
					bs.wFull
				)}
			>
				Mail the card
				{showDayInput ? (
					<>
						<TextInput
							disabled={!canEdit}
							inputClassName={css(bs.textCenter, bs.w8)}
							inputId={`dayof-automation-input-${step.uuid}`}
							min={1}
							onBlur={onDayBlur}
							type='number'
							value={dayInputValue}
							onChange={ev => setDayInputValue(ev.target.value)}
						/>
						day(s)
					</>
				) : null}
				<DeprecatedSelect
					disabled={!canEdit}
					onOptionClick={onQualifierOptionSelected}
					options={QualifierSelectOptions}
					selectedOption={selectedQualifierOption}
					styles={[bs.flexShrink0, bs.w36]}
				/>
				{triggerDescription ? (
					<span className={css(bs.flexShrink0)}>
						{' '}
						<span className={css(bs.flexShrink0)}>{`the ${triggerDescription}`}</span>
					</span>
				) : null}
			</div>
			<div className={css(bs.flex, bs.flexCol, bs.gap2, bs.wFull)}>
				<label>
					Card title: &nbsp;
					<span className={css(bs.required)}>*</span>
					<TextInput
						className={css(bs.bgWhite)}
						disabled={!canEdit}
						onBlur={handleNameBlur}
						onChange={ev => setName(ev.target.value)}
						onFocus={() => (step.automationStep.showRequiredFieldError = false)}
						placeholder='Enter a brief description of this card'
						type='text'
						value={name || ''}
					/>
				</label>
			</div>

			<div className={css(bs.flex, bs.gap3, bs.itemsStart)}>
				<HwcChooseCardPreviewButton
					onClick={() => setIsHwcTemplateChooserModalOpen(true)}
					templateId={selectedHwcTemplateId}
				/>
			</div>
			<div className={css(bs.flex, bs.flexCol, bs.gap1, bs.wFull)}>
				<span className={css(bs.textSm)}>
					Card content:<span className={css(bs.required)}>*</span>
				</span>
				<HwcAutomationStepEditor
					contentState={contentEditorState}
					onBlur={handleEditChangeSave}
					onContentStateChanged={setContentEditorState}
				/>
			</div>
			{selectedHwcTemplateId && contentEditorStateText ? (
				<button className={css(bs.ctaButton)} onClick={() => setIsPreviewModalOpen(true)}>
					Preview Card
				</button>
			) : null}
			<div className={css(bs.flex, bs.itemsCenter, bs.gap2)}>
				<Checkbox
					checked={hasFallbackEmailChecked}
					onChange={handleHasFallbackEmailCheckChange}
					disabled={
						(!fallbackEmailAutomationStep && (!contentEditorStateText || !selectedHwcTemplateId)) ||
						emailOutcomeCreateMutation.isLoading ||
						attachmentCreateMutation.isLoading ||
						stepDeleteMutation.isLoading
					}
				>
					<span>If can&apos;t send card, send email instead</span>
				</Checkbox>
				{emailOutcomeCreateMutation.isLoading || attachmentCreateMutation.isLoading || stepDeleteMutation.isLoading ? (
					<LoadingSpinner type='extra-small' />
				) : null}
			</div>
			{fallbackEmailAutomationStep ? (
				<div className={css(bs.flex, bs.flexCol, bs.textLabel, bs.textSm, bs.wFull)}>
					<div>Enter backup email content:</div>
					<button
						className={css(
							bs.flex,
							bs.itemsCenter,
							bs.textSm,
							bs.justifyCenter,
							bs.wFull,
							bs.h15,
							bs.textBrandPrimary,
							!fallbackEmailAutomationStep?.options?.subject ? styleSheet.emailCreateButton : styleSheet.emailEditButton
						)}
						disabled={!fallbackEmailAutomationStep || stepDeleteMutation.isLoading}
						onClick={onShowEmailFallbackComposerClicked}
					>
						<span>{`${fallbackEmailAutomationStep?.options?.subject ? 'Preview / Edit' : 'Create'} Email`}</span>
					</button>
				</div>
			) : null}
			{showingEmailFallbackComposer ? (
				<EditAutomationStepEmailComposerModal
					automationTemplateId={templateId}
					step={fallbackEmailAutomationStep.model}
					onAttachmentRemoved={data => fallbackEmailAutomationStep.mSetModel(data)}
					readonly={!fallbackEmailAutomationStep.canEdit}
					syncSubjectWithStep
					onRequestClose={onEmailFallbackComposerModalRequestClose}
					impersonationContext={step.automationStep.impersonationContext}
				/>
			) : null}
			<HandwrittenCardTemplateSearchModal
				isOpen={isHwcTemplateChooserModalOpen}
				onClose={() => setIsHwcTemplateChooserModalOpen(false)}
				onTemplateClicked={handleTemplateChosen}
			/>
			<Modal isOpen={isPreviewModalOpen} onRequestClose={() => setIsPreviewModalOpen(false)} useDefaultHeader>
				{isPreviewModalOpen && selectedHwcTemplateId ? (
					<div className={css(bs.p4)}>
						<HwcFrontBackPreview
							templateId={selectedHwcTemplateId}
							value={activeTogglePreviewValue}
							handwritingStyle={activeHandwritingStyle}
							signature={activeSignature}
							onValueChange={setActiveTogglePreviewValue}
							postcardText={convertHtmlValueToPostcardText(contentEditorState.getRawRichTextContent().document)}
						/>
					</div>
				) : null}
			</Modal>
		</div>
	);
}

function HwcChooseCardPreviewButton({ templateId, onClick }: { templateId?: string; onClick: () => void }) {
	const templateQuery = useTemplate<Api.IHandwrittenCardTemplate>({ templateId, enabled: Boolean(templateId) });
	if (templateId) {
		if (templateQuery.isSuccess) {
			return (
				<div className={css(bs.h28, bs.w44, bs.rounded, bs.cursorPointer)} onClick={onClick}>
					<img
						src={templateQuery.data.image.publicUrl}
						className={css(bs.block, bs.hFull, bs.wFull, bs.objectCover)}
						alt='Hwc template image preview'
					/>
				</div>
			);
		}
		return (
			<div className={css(bs.h28, bs.w44, bs.flex, bs.itemsCenter, bs.justifyCenter)} onClick={onClick}>
				<LoadingSpinner type='small' />
			</div>
		);
	}
	return (
		<div
			className={css(
				bs.border,
				bs.borderDashed,
				bs.borderAlternateTitle,
				bs.rounded,
				bs.h28,
				bs.w44,
				bs.textAlternateTitle,
				bs.flex,
				bs.itemsCenter,
				bs.justifyCenter,
				bs.cursorPointer
			)}
			onClick={onClick}
		>
			<span>Choose Card</span>
		</div>
	);
}

export const HwcAutomationStepCardContent = observer(_HwcAutomationStepCardContent);
