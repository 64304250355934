import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useAutomationTemplateTriggerQuery({
	impersonationContext,
}: {
	impersonationContext?: Api.IImpersonationContext;
} = {}) {
	const userSession = useUserSession();
	return useQuery({
		queryKey: [`automationTemplate/trigger`],
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IAvailableAutomationTrigger[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'automationTemplate/trigger',
					impersonationContext,
				}),
				'GET'
			);
		},
	});
}
