import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useAdminAutomationTemplateTriggerQuery() {
	const userSession = useUserSession();
	return useQuery({
		queryKey: [`admin/automationTemplate/trigger`],
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IAvailableAutomationTrigger[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'admin/automationTemplate/trigger',
				}),
				'GET'
			);
		},
	});
}
