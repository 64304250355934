import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { LoadingSpinner } from '@WebComponents/LoadingSpinner';
import { MultiContainerHeader } from '@WebComponents/MultiContainerHeader';
import { ContactsImportProcessingIcon } from '@WebComponents/svgs/icons/ContactsImportProcessingIcon';
import { SuccessCheckIcon } from '@WebComponents/svgs/icons/SuccessCheckIcon';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { AppImportContactsViewModel } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps {
	className?: string;
	importContacts: AppImportContactsViewModel;
	bySupportUser?: boolean;
	onRequestClose?(contactsSearchRequestFilter?: Api.IBulkContactsRequest): void;
}

class _ContactsSelfImportProcessing extends React.Component<IProps> {
	public componentDidMount() {
		const { importContacts, logPageView } = this.props;
		if (importContacts.systemJob) {
			importContacts.systemJob.startWatching(this.onSystemJobUpdate);
		}

		logPageView('ContactsSelfImport/Processing');
	}

	public componentWillUnmount() {
		const { importContacts } = this.props;
		if (importContacts.systemJob) {
			importContacts.systemJob.stopWatching();
		}
	}

	public render() {
		const { className, importContacts, bySupportUser } = this.props;
		const processed = importContacts.systemJob ? importContacts.systemJob.recordsProcessed : 0;
		return (
			<div className={`${css(styleSheet.container)} contacts-self-import-processing ${className || ''}`}>
				<MultiContainerHeader fullscreenHeader={this.isJobComplete ? 'Done!' : 'Importing...'} />
				<div className={css(styleSheet.icon)}>
					<ContactsImportProcessingIcon />
					{!!this.isJobComplete && <SuccessCheckIcon className={css(styleSheet.iconSpinner, styleSheet.iconSuccess)} />}
					{this.isInProgress && <LoadingSpinner className={css(styleSheet.iconSpinner)} type='small' />}
				</div>
				<div className={css(styleSheet.message)}>
					{this.isJobComplete
						? 'All done!'
						: !this.isCompleteAndFailed
							? `${
									importContacts.systemJob
										? `Processed ${processed} contact${processed > 1 || processed === 0 ? 's' : ''}`
										: ''
								}`
							: 'Import failed.'}
				</div>
				<div className={css(styleSheet.detail)}>
					{this.isJobComplete
						? `We finished importing ${bySupportUser ? 'the' : 'your'} contacts.`
						: !this.isCompleteAndFailed
							? 'We will email you when we are done. You can close this window now.'
							: 'Failed to import all contacts: ' + importContacts.systemJob.status}
				</div>
				<button
					className={css(baseStyleSheet.ctaButton, styleSheet.closeButton)}
					disabled={!!importContacts.isBusy}
					onClick={this.onRequestClose}
				>
					<span>{!!this.isJobComplete && !bySupportUser ? 'Take a look!' : 'Close Window'}</span>
				</button>
			</div>
		);
	}

	@computed
	private get isJobComplete() {
		const { importContacts } = this.props;
		return !!importContacts.systemJob && importContacts.systemJob.percentComplete >= 100;
	}

	@computed
	private get isCompleteAndFailed() {
		const { importContacts } = this.props;
		return !!importContacts.systemJob && importContacts.systemJob.percentComplete === -1;
	}

	@computed
	private get isInProgress() {
		const { importContacts } = this.props;
		return !!importContacts.systemJob && !this.isJobComplete && !this.isCompleteAndFailed;
	}

	private onSystemJobUpdate = (opResult: Api.IOperationResult<Api.ISystemJob>) => {
		if (opResult.value.percentComplete < 100) {
			return true;
		}
		return false;
	};

	private onRequestClose = () => {
		const { onRequestClose, importContacts } = this.props;
		if (onRequestClose) {
			const contactsSearchRequestFilter: Api.IBulkContactsRequest = this.isJobComplete
				? {
						filter: {
							criteria: [
								{ property: Api.ContactFilterCriteriaProperty.Name, value: '' },
								...(importContacts.options.tags || [])
									.filter(x => !!x)
									.map<Api.IContactFilterCriteria>(x => {
										return {
											property: Api.ContactFilterCriteriaProperty.Tag,
											value: x,
										};
									}),
							],
						},
					}
				: null;
			onRequestClose(contactsSearchRequestFilter);
		}
	};
}

const ContactsSelfImportProcessingAsObserver = observer(_ContactsSelfImportProcessing);
const ContactsSelfImportProcessingWithContext = inject()(ContactsSelfImportProcessingAsObserver);
export const ContactsSelfImportProcessing = withEventLogging(
	ContactsSelfImportProcessingWithContext,
	'ContactsSelfImportProcessing'
);
