import { css } from 'aphrodite';
import { FC, HTMLAttributes } from 'react';
import { bs } from '../../../web/styles/styles';

export const Column: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => {
	return <div className={`${className || []} ${css(bs.flex, bs.flexCol, bs.wFull, bs.hFull)}`}>{children}</div>;
};

export const Row: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => {
	return <div className={`${className || []} ${css(bs.flex, bs.flexRow, bs.wFull, bs.hFull)}`}>{children}</div>;
};
