import { StyleSheet } from 'aphrodite';
import {
	brandPrimary,
	brandPrimaryHover,
	brandPrimaryHoverTint,
	header,
	inputBorderColor,
	white,
} from '../../../../web/styles/colors';
import { baseStyles, CssSelectors } from '../../../../web/styles/styles';

const tabItemHeight = 48;
const tabListPadding = 16;

export const styleSheet = StyleSheet.create({
	container: { height: '100%' },
	body: {
		height: `calc(100% - ${tabItemHeight + tabListPadding}px)`,
		display: 'flex',
	},
	editorContainer: {
		height: '100%',
	},
	editorContainerCollapsed: {
		width: '5%',
	},
	previewContainer: {
		flexGrow: 1,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	previewIframe: {
		flexGrow: 1,
	},
	previewIframeDisabled: {
		pointerEvents: 'none',
	},
	saveButton: {
		alignSelf: 'auto',
		height: 40,
	},
	splitContainer: {
		...CssSelectors.allDescendants(
			{
				':hover': {
					backgroundColor: brandPrimaryHoverTint,
				},
				':active': {
					backgroundColor: brandPrimaryHover,
				},
				transition: 'inset 0.2s',
			},
			'.mosaic-split'
		),
		...CssSelectors.allDescendants(
			{
				transition: 'inset 0.2s',
			},
			'.mosaic-tile'
		),
	},
	splitContainerDisabledTransitons: {
		...CssSelectors.allDescendants(
			{
				transition: 'none',
			},
			'.mosaic-split'
		),
		...CssSelectors.allDescendants(
			{
				transition: 'none',
			},
			'.mosaic-tile'
		),
	},
	splitContainerShadowRight: {
		'::after': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			pointerEvents: 'none',
			boxShadow: '-6vw 0 20px 0px rgba(0, 0, 0, 0.2) inset',
		},
	},
	splitContainerShadowLeft: {
		'::after': {
			pointerEvents: 'none',
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			boxShadow: '6vw 0 20px 0px rgba(0, 0, 0, 0.2) inset',
		},
	},
	splitViewWindow: {
		...CssSelectors.allDescendants(
			{
				display: 'none',
			},
			'.mosaic-window-title'
		),
		...CssSelectors.allDescendants(
			{
				justifyContent: 'flex-start',
				display: 'flex',
				height: 40,
				boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
				boxSizing: 'border-box',
				borderBottom: `1px solid ${inputBorderColor}`,
				padding: 5,
			},
			'.mosaic-window-toolbar'
		),
		...CssSelectors.allDescendants(
			{
				flex: 1,
			},
			'.mosaic-window-controls'
		),
	},
	tabView: {
		height: '100%',
	},
	tabViewTablist: {
		background: 'white',
		borderBottom: `1px solid #d3dbde`,
		boxSizing: 'border-box',
		display: 'flex',
		height: tabItemHeight + tabListPadding,
		justifyContent: 'space-between',
		paddingTop: tabListPadding,
	},
	tabViewItem: {
		':focus-visible': {
			background: white,
			border: `1px solid ${brandPrimary}`,
			borderBottom: 'none',
		},
		':hover': {
			background: '#ddd5d533',
		},
		':not(:first-child)': {
			borderLeft: 'none',
			borderTopLeftRadius: 0,
		},
		':last-child': {
			borderTopRightRadius: 8,
		},
		alignItems: 'center',
		background: '#ddd5d552',
		border: `1px solid #d3dbde`,
		borderTopLeftRadius: 8,
		color: header,
		display: 'inline-flex',
		flexBasis: 'auto',
		flexGrow: 0,
		fontSize: 14,
		height: tabItemHeight,
		marginLeft: 1,
		paddingLeft: 20,
		paddingRight: 20,
		position: 'relative',
		textAlign: 'center',
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
	},
	tabViewSelectedItem: {
		':hover': {
			background: white,
		},
		background: white,
		borderBottom: '1px solid white',
		boxSizing: 'border-box',
		overflow: 'visible',
		position: 'relative',
	},
	toolbarButton: {
		width: 30,
		aspectRatio: '1 / 1',
		...baseStyles.flexCenter,
	},
	rotated: {
		transform: 'rotate(180deg)',
	},
	toolbarLeft: {
		flex: 1,
		justifyContent: 'space-between',
		display: 'flex',
		alignItems: 'center',
	},
	toolbarRight: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
	},
});
