import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { Keyframes, animated } from 'react-spring-legacy/renderprops';
import Waypoint from 'react-waypoint';
import { MediaType } from '.';
import { IFileAttachment } from '../../../extViewmodels';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../models';
import { useEventLogging } from '../../../models/Logging';
import { IPixabayImage } from '../../../models/Pixabay';
import { useErrorMessages } from '../../../models/hooks/appStateHooks';
import { brandSecondary } from '../../styles/colors';
import { baseStyleSheet } from '../../styles/styles';
import { LoadingSpinner } from '../LoadingSpinner';
import { TextInput } from '../TextInput';
import { RefreshIcon } from '../svgs/icons/RefreshIcon';
import { SearchIcon } from '../svgs/icons/SearchIcon';
import { MediaSuggestionResults } from './MediaSuggestionResults';
import { useMediaChooserContext } from './context';
import { useMediaChooserSearch } from './hooks/useMediaChooserSearch';
import { useMediaChooserSuggestions } from './hooks/useMediaChooserSuggestions';
import { ImageContainerHeight, styleSheet } from './styles';

const ImageContainer: React.ReactNode = Keyframes.Spring(async (next: any) => {
	await next({
		config: { friction: 30, tension: 400 },
		from: { height: 0, opacity: 0 },
		height: ImageContainerHeight,
		opacity: 0,
	});
	await next({
		config: { friction: 50, tension: 200 },
		from: { opacity: 0 },
		opacity: 1,
	});
});

interface IMediaChooserSearchProps extends IImpersonationContextComponentProps {
	mediaType?: MediaType;
	optionalFileName?: string;
	onSave?: (img: IFileAttachment) => void;
}

export const MediaChooserSearch: React.FC<IMediaChooserSearchProps> = inject(ImpersonationContextKey)(
	observer(props => {
		const { onSave, mediaType, optionalFileName } = props;
		const { selectedImage, setSelectedImage, createFileAttachmentFromSelectedImageAsync } = useMediaChooserContext();
		const { pushApiError } = useErrorMessages();
		const { logApiError } = useEventLogging('MediaChooserSearch');

		const {
			getMoreImages,
			inputRef,
			isLoading,
			onClearClicked,
			onCtaClicked,
			onInputKeyDown,
			onSearchTermInputChanged,
			pendingSearchTerm,
			searchedImages,
			searchTerm,
		} = useMediaChooserSearch();
		const [isUploading, setIsUploading] = useState(false);

		const onImageClicked = (e: React.MouseEvent<HTMLElement>, selectedUserImage: IPixabayImage) => {
			e.preventDefault();
			setSelectedImage(selectedUserImage);
		};

		const onCancel = () => {
			setSelectedImage(null);
		};
		const handleChooseClick = async () => {
			setIsUploading(true);
			try {
				await onChooseClicked();
			} finally {
				setIsUploading(false);
			}
		};

		const onChooseClicked = async () => {
			try {
				const attachment = await createFileAttachmentFromSelectedImageAsync(optionalFileName);

				onSave(attachment);
			} catch (error) {
				// @ts-ignore
				logApiError(error);
				pushApiError(error);
			}
		};

		const renderSearch = () => {
			return (
				<div className={css(baseStyleSheet.verticalStack, styleSheet.search)}>
					<div>Search for royalty free images</div>
					<div className={css(baseStyleSheet.horizontalStack)}>
						<TextInput
							autoComplete='off'
							className={css(styleSheet.searchInput)}
							inputId='freeimage-search-input'
							inputRef={inputRef}
							onChange={onSearchTermInputChanged}
							onKeyDown={onInputKeyDown}
							placeholder='Search for an image'
							type='text'
							value={pendingSearchTerm || ''}
						/>
						<button
							className={searchedImages?.pages?.length > 0 ? undefined : css(styleSheet.hide)}
							onClick={onClearClicked}
						>
							<span className={css(styleSheet.clearSearch)}>Clear Search</span>
						</button>
					</div>
				</div>
			);
		};

		const renderResults = () => {
			if (selectedImage) {
				return null;
			}

			return mediaType === MediaType.Image && searchedImages?.pages[0].totalHits > 0 ? (
				// @ts-ignore
				<ImageContainer>
					{(style: React.CSSProperties) => {
						return (
							<animated.div style={style}>
								<div className={css(styleSheet.imageGrid)}>
									{searchedImages?.pages?.map(page => {
										return page?.hits?.map(y => {
											return <PixabayImageTile key={y.id} image={y} onClick={onImageClicked} />;
										});
									})}
									<Waypoint bottomOffset='-200px' onEnter={() => getMoreImages()} />{' '}
								</div>
							</animated.div>
						);
					}}
				</ImageContainer>
			) : (
				<figure className={css(styleSheet.noSuggestions, styleSheet.noSearchResults)}>
					<SearchIcon className={css(styleSheet.searchIcon)} fillColor={brandSecondary} />
					<span className={css(styleSheet.noSuggestionsTitle)}>No Images Found</span>
					<span className={css(styleSheet.noSuggestionsText)}>Please try a different search term.</span>
				</figure>
			);
		};
		return (
			<>
				{!selectedImage ? <div>{renderSearch()}</div> : null}
				{selectedImage ? <PixabayImageTile image={selectedImage} styles={[styleSheet.imageGridTileSelected]} /> : null}
				{searchTerm && !isLoading ? renderResults() : null}
				<footer className={css(baseStyleSheet.horizontalStack, styleSheet.footer)}>
					{!searchedImages?.pages?.length && !selectedImage ? (
						<button className={css(baseStyleSheet.ctaButton)} onClick={onCtaClicked}>
							<span> Search</span>
						</button>
					) : selectedImage ? (
						<>
							<button className={css(baseStyleSheet.ctaButton)} onClick={handleChooseClick} disabled={isUploading}>
								<span>Add Image</span>
							</button>
							<button className={css(baseStyleSheet.ctaButton)} onClick={onCancel}>
								<span>Cancel</span>
							</button>
						</>
					) : null}
				</footer>
			</>
		);
	})
);

interface IMediaChooserSuggestionsProps extends IImpersonationContextComponentProps {
	content?: string;
	mediaType?: MediaType;
}

export const MediaChooserSuggestions: React.FC<IMediaChooserSuggestionsProps> = inject(ImpersonationContextKey)(
	observer(props => {
		const { mediaType, content } = props;
		const { showSuggestionsNoResults, showSuggestions, selectedImage, setSelectedImage } = useMediaChooserContext();

		const { onReloadClicked, keywords } = useMediaChooserSuggestions({
			content,
		});

		const onImageClicked = (selectedUserImage: IPixabayImage) => {
			setSelectedImage(selectedUserImage);
		};

		const noSelection = !selectedImage;

		const renderSuggestions = () => {
			const keywordsLabels: string[] = keywords;
			return (
				<section className={css(baseStyleSheet.verticalStack)}>
					<h4 className={css(styleSheet.suggestionSubHeader)}>
						Suggested images are based on keywords from your post content
					</h4>
					<div className={css(baseStyleSheet.horizontalStack, styleSheet.keywordsSection)}>
						<div>{'Keywords: '} </div>
						{keywordsLabels?.map(keyword => {
							return (
								<div className={css(styleSheet.keywordLabel)} key={keyword}>
									{keyword}
								</div>
							);
						})}
						{keywords?.length > 0 ? (
							<button onClick={onReloadClicked}>
								<RefreshIcon height={20} width={20} fillColor={brandSecondary} />
							</button>
						) : null}
					</div>
					{keywords?.length > 0 ? (
						<MediaSuggestionResults keywords={keywords} onImageClicked={onImageClicked} mediaType={mediaType} />
					) : (
						<LoadingSpinner />
					)}
				</section>
			);
		};

		return (
			<section
				className={css(
					showSuggestions && noSelection ? styleSheet.suggestionContainer : styleSheet.suggestionsContainerHidden
				)}
			>
				<h2 className={css(styleSheet.suggestionHeader)}>AI Suggested Images</h2>

				{!showSuggestionsNoResults && content?.length > 0 ? renderSuggestions() : null}
				{showSuggestionsNoResults || !content ? (
					<figure className={css(styleSheet.noSuggestions)}>
						<SearchIcon className={css(styleSheet.searchIcon)} fillColor={brandSecondary} />
						<span className={css(styleSheet.noSuggestionsTitle)}>No Suggestions Available</span>
						<span className={css(styleSheet.noSuggestionsText)}>
							To see suggested images, please provide more content to your post.
						</span>
					</figure>
				) : null}
			</section>
		);
	})
);

export interface IPixabayImageTileProps {
	image: IPixabayImage;
	onClick?(e: React.MouseEvent<HTMLElement>, image: IPixabayImage): void;
	styles?: StyleDeclarationValue[];
}

export const PixabayImageTile: React.FC<IPixabayImageTileProps> = ({ image, onClick, styles = [] }) => {
	const click = React.useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			onClick?.(e, image);
		},
		[onClick, image]
	);
	return (
		<button className={css(styleSheet.imageGridTile, ...styles)} onClick={click}>
			<img src={image.webformatURL} />
		</button>
	);
};
