import * as Api from '@ViewModels';
import * as React from 'react';

/**
 * Returns an impersonation context for the given account and user.
 * If both accountId and userId are provided, the impersonation context will be for the user in the account.
 * If accountId is provided and userId is not provided, the impersonation context will be for the account only.
 * If neither is provided, the impersonation context will be undefined. Same as no impersonation.
 *
 * Memoized impersonation context that can be a dependency for effects without render stability issues.
 * Composable alternative to managing impersonation state by context.
 *
 * @param {string} props.accountId - The ID of the account to impersonate.
 * @param {string} props.userId - The ID of the user to impersonate.
 **/
export function useImpersonationContext({ userId, accountId }: { accountId?: string; userId?: string }) {
	return React.useMemo(() => {
		if (!accountId) {
			return undefined;
		}
		return { account: { id: accountId }, user: userId ? { id: userId } : undefined } as Api.IImpersonationContext;
	}, [accountId, userId]);
}
