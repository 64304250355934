import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { DragDropContext, DropResult, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { IModalContext, ModalChildComponentContextKey } from '../../../models';
import { useToaster } from '../../../models/hooks/appStateHooks';
import { DeprecatedCloseButton } from '../../components/DeprecatedCloseButton';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { asModalComponent } from '../../components/Modal';
import { CloudUploadIcon } from '../../components/svgs/icons/CloudUploadIcon';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

interface IProps extends IModalContext {
	className?: string;
	acceptedFileTypes?: string;
	title?: string;
}

export const ImageFileChooserBase = ({
	title,
	className,
	acceptedFileTypes = 'image/png, image/jpeg, image/jpg',
	parentModal,
}: IProps) => {
	const [isDraggingOver, setIsDraggingOver] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);
	const toaster = useToaster();
	const inputRef = React.useRef<HTMLInputElement>(null);
	const inputAccept = acceptedFileTypes;
	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDraggingOver(true);
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDraggingOver(false);
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDraggingOver(false);

		const file = e.dataTransfer.files?.[0];

		if (file) {
			onFileChange(file);
		}
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}
	};

	const onFileChange = async (file: File) => {
		if (!file) {
			return; // If no file is selected, return early
		}
		setIsLoading(true);

		const mimeType = file.type;
		if (!mimeType.startsWith('image/')) {
			toaster.push({
				message: 'Please select a valid image file.',
				type: 'errorMessage',
			});
			setIsLoading(false);
			return;
		}
		if (mimeType.startsWith('image/')) {
			// Handle image upload
			setUploadedFile(file);
			setIsLoading(false);
		}

		// Clear the input field
		if (inputRef.current) {
			inputRef.current.value = '';
		}
	};

	const handleSave = () => {
		if (!uploadedFile) {
			return;
		}
		URL.revokeObjectURL(uploadedFile.name);
		parentModal?.onRequestClose(uploadedFile, false);
	};
	return (
		<section className={`${className ?? ''}`}>
			<h2 className={css(styleSheet.title)}>{title || 'Add Media'}</h2>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='image-file-upload'>
					{(droppableProvided: DroppableProvided) => {
						return (
							<div>
								{!uploadedFile && !isLoading && (
									<>
										<div
											onDragEnter={handleDragEnter}
											onDragOver={handleDragOver}
											onDragLeave={handleDragLeave}
											onClick={() => {
												inputRef.current?.click();
											}}
											onDrop={handleDrop}
											className={isDraggingOver ? css(styleSheet.dragContainerHover) : css(styleSheet.dragContainer)}
											ref={droppableProvided ? droppableProvided.innerRef : undefined}
										>
											<input
												ref={inputRef}
												type='file'
												accept={inputAccept}
												style={{ display: 'none' }}
												onChange={ev => {
													if (ev.target.files.length > 0) {
														onFileChange(ev.target.files[0]);
													}
												}}
											/>
											<div className={css(styleSheet.dragInfo)}>
												<CloudUploadIcon />
												<div className={css(styleSheet.dragText)}>Drag and drop image to upload</div>
												<div className={css(styleSheet.dragFooter)}>Click to browse device</div>
											</div>
										</div>
									</>
								)}
								{uploadedFile && (
									<ModalImagePreview
										name={uploadedFile.name}
										onRemoveClick={() => setUploadedFile(null)}
										size={uploadedFile.size}
										url={URL.createObjectURL(uploadedFile)}
									/>
								)}
								{isLoading && <LoadingSpinner type='tiny' />}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>
			<footer>
				<button className={css(baseStyleSheet.ctaButtonSmall)} disabled={!uploadedFile} onClick={handleSave}>
					Save
				</button>
			</footer>
		</section>
	);
};

const ImageFileChooserBaseModalAsObserver = observer(ImageFileChooserBase);
const ImageFileChooserModalWithContext = inject(ModalChildComponentContextKey)(ImageFileChooserBaseModalAsObserver);

export const ImageFileChooserModal = asModalComponent(ImageFileChooserModalWithContext, {
	className: `dropZone-modal `,
	shouldCloseOnOverlayClick: true,
	useDefaultHeader: true,
});

const ModalImagePreview = ({
	name,
	onRemoveClick,
	size,
	url,
}: {
	name: string;
	onRemoveClick: () => void;
	size: number;
	url: string;
}) => {
	return (
		<>
			<div className={css(styleSheet.previewImageWrap)}>
				<img src={url} alt='preview' className={css(styleSheet.previewImage)} />
				<div className={css(styleSheet.previewImageDescriptionWrap)}>
					<p className={css(baseStyleSheet.truncateText, styleSheet.noMargin, styleSheet.previewImageFileName)}>
						{name}
					</p>
					<div>
						<p className={css(styleSheet.noMargin, styleSheet.previewImageDescriptionTxt)}>
							File size: {Math.round(size / 1024)} KB
						</p>
						<p className={css(styleSheet.noMargin, styleSheet.previewImageDescriptionTxt)}>Source: Upload</p>
					</div>
				</div>
				<DeprecatedCloseButton
					type='button'
					onClick={onRemoveClick}
					className={css(styleSheet.logoRemove, styleSheet.previewRemove)}
				/>
			</div>
		</>
	);
};
