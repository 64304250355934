import * as React from 'react';
import { AIContentGenerationStatus } from '../../../../models/Ai';
import { useContextGuard } from '../../../../models/hooks/useContextGuard';

export interface IAIContentGenerationStatusContext {
	clearContentGenerationStatus?(): void;
	contentGenerationStatus: AIContentGenerationStatus;
}

export const AIContentGenerationStatusContext = React.createContext<IAIContentGenerationStatusContext>(null);

export const useAIContentGenerationStatusContext = () =>
	useContextGuard(AIContentGenerationStatusContext, 'AIContentGenerationStatusContext');
