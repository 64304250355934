import { CampaignViewModel, EmailSendStatus, FilterOperator, IContactFilterCriteria, VmUtils } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { ComposeEmailViewModel } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { OverflowAccordion } from '../../OverflowAccordion';
import { ContactsTableActiveSearchesList } from '../../contacts/ContactsTableActiveSearchesList';
import { EntityPropertySearchTag } from '../../entities/EntityPropertySearchTag';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';

interface IProps {
	campaign?: CampaignViewModel;
	className?: string;
	emailComposer?: ComposeEmailViewModel;
	onSearchesChanged?(searches: IContactFilterCriteria[]): void;
	readOnly?: boolean;
	styles?: StyleDeclarationValue[];
}

export const TagEmailTagList: React.FC<IProps> = props => {
	const { className, readOnly, styles, emailComposer, campaign, onSearchesChanged } = props;
	const canEdit =
		!readOnly &&
		campaign?.status !== EmailSendStatus.WaitingForApproval &&
		emailComposer?.campaign?.status !== EmailSendStatus.WaitingForApproval;
	const tagSearchCriteria = VmUtils.sortContactFilterCriteria(
		emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria
	).searches.filter(x => VmUtils.isTagSearchContactFilterCriteria(x));
	const tagSearchCriteriaNotOr = tagSearchCriteria?.filter(x => x.op !== FilterOperator.Or);

	const renderSelectedReachOutTags = () => {
		let reachOutTags = emailComposer.selectedReachOutTags.map(tag => {
			return (
				<EntityPropertySearchTag
					key={tag}
					search={{ value: tag }}
					styles={canEdit ? [styleSheet.tag, styleSheet.reachOutTag] : [styleSheet.tag]}
				>
					{!canEdit && tagSearchCriteriaNotOr.length > 0
						? tagSearchCriteriaNotOr[0].value
						: emailComposer.selectedReachOutTags.includes(ComposeEmailViewModel.KIT_TAG) && (
								<span className={css(styleSheet.reachOutTagCount)}>
									&nbsp;
									{emailComposer.emailMessage.contactsToAdd.length}
								</span>
							)}
				</EntityPropertySearchTag>
			);
		});

		if (emailComposer?.reachOutInfo?.contactsWithKeepInTouchesDue?.length) {
			reachOutTags = [
				...reachOutTags,
				<EntityPropertySearchTag
					key='keep-in-touches'
					search={{ value: 'Keep In Touches' }}
					styles={canEdit ? [styleSheet.tag, styleSheet.reachOutTag] : [styleSheet.tag]}
				/>,
			];
		}

		return (
			<div className={css(styleSheet.tagSearchesContainer)}>
				<div className={css(styleSheet.tagTokenContainer)}>{reachOutTags}</div>
			</div>
		);
	};

	const renderOverflowTrigger = (isExpanded: boolean) => {
		return (
			<>
				<span className={css(styleSheet.triggerText)}>{`${isExpanded ? 'Hide' : 'Show all'} tags`}</span>
				<DisclosureIcon className={css(isExpanded ? styleSheet.pointUp : styleSheet.pointDown)} />
			</>
		);
	};

	return (
		<div className={`${css(baseStyleSheet.horizontalStack, ...(styles || []))} tag-email-tag-list ${className || ''}`}>
			{emailComposer?.selectedReachOutTags.length ||
			emailComposer?.reachOutInfo?.contactsWithKeepInTouchesDue?.length ? (
				<OverflowAccordion threshold={29}>
					<div className={css(styleSheet.tagSearches)}>{renderSelectedReachOutTags()}</div>
				</OverflowAccordion>
			) : (
				tagSearchCriteria?.length > 0 && (
					<div className={css(styleSheet.tagSearchesContainer)}>
						<OverflowAccordion threshold={65} onRenderTrigger={renderOverflowTrigger}>
							<ContactsTableActiveSearchesList
								filterCriteria={emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest}
								hideAdvancedSearch={true}
								hideLabel={true}
								hideRemoveButtons={!canEdit}
								onSearchesChanged={onSearchesChanged}
								searches={tagSearchCriteria}
								tokenContainerStyles={[styleSheet.tagTokenContainer]}
								styles={[styleSheet.tagSearches]}
							/>
						</OverflowAccordion>
					</div>
				)
			)}
		</div>
	);
};
