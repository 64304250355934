import * as Api from '@ViewModels';
import moment from 'moment';
import { useMemo, useState } from 'react';

const findMatchingCreationDateFilter = (criterion: Api.IContactFilterCriteria) => {
	return criterion.criteria?.find(subCriterion => {
		return subCriterion.property === Api.ContactFilterCriteriaProperty.CreationDate;
	});
};

const getInitialValues = (criteria: Api.IContactFilterCriteria[]) => {
	const createDateCriteria = criteria?.find(criterion => findMatchingCreationDateFilter(criterion));

	const startDateCriteria = createDateCriteria?.criteria.find(criterion => {
		return criterion.op === Api.FilterOperator.Gte;
	});
	const startDate = startDateCriteria?.value ? moment(startDateCriteria.value).toDate() : null;

	const endDateCriteria = createDateCriteria?.criteria.find(criterion => {
		return criterion.op === Api.FilterOperator.Lte;
	});
	const endDate = endDateCriteria?.value ? moment(endDateCriteria.value).toDate() : null;
	return {
		startDate,
		endDate,
	};
};

export const useCreateDateFilter = (initialFilterCriteria: Api.IContactFilterCriteria[] = []) => {
	const [creationStartDate, setCreationStartDate] = useState<Date | null>(
		getInitialValues(initialFilterCriteria)?.startDate
	);
	const [creationEndDate, setCreationEndDate] = useState<Date | null>(getInitialValues(initialFilterCriteria)?.endDate);

	const startDateCriteria: Api.IContactFilterCriteria = useMemo(() => {
		if (!creationStartDate) {
			return;
		}

		return {
			op: Api.FilterOperator.Gte,
			property: Api.ContactFilterCriteriaProperty.CreationDate,
			value: moment(creationStartDate).format('YYYY-MM-DD'),
		};
	}, [creationStartDate]);

	const endDateCriteria: Api.IContactFilterCriteria = useMemo(() => {
		if (!creationEndDate) {
			return;
		}
		return {
			op: Api.FilterOperator.Lte,
			property: Api.ContactFilterCriteriaProperty.CreationDate,
			value: moment(creationEndDate).format('YYYY-MM-DD'),
		};
	}, [creationEndDate]);

	const createDateCriteria = useMemo(() => {
		const criteria = [];
		if (startDateCriteria) {
			criteria.push(startDateCriteria);
		}

		if (endDateCriteria) {
			criteria.push(endDateCriteria);
		}

		return {
			criteria,
			op: Api.FilterOperator.And,
		} as Api.IContactFilterCriteria;
	}, [startDateCriteria, endDateCriteria]);

	function getCreationDateCriteria() {
		const newCreationDateCriteria: PartiallyRequired<Api.IContactFilterCriteria, 'criteria'> = JSON.parse(
			JSON.stringify(createDateCriteria)
		);
		return newCreationDateCriteria;
	}

	function cancelCreationDateChanges() {
		setCreationStartDate(null);
		setCreationEndDate(null);
	}

	const refreshCreationDateFilter = (newKeyFactCriteria: Api.IContactFilterCriteria[]) => {
		const { startDate, endDate } = getInitialValues(newKeyFactCriteria);
		setCreationStartDate(startDate);
		setCreationEndDate(endDate);
	};

	return {
		cancelCreationDateChanges,
		creationStartDate,
		creationEndDate,
		findMatchingCreationDateFilter,
		setCreationStartDate,
		setCreationEndDate,
		getCreationDateCriteria,
		refreshCreationDateFilter,
	};
};
