import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ErrorMessagesViewModelKey, IErrorMessageComponentProps } from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { postNotification } from '../../../../models/LocalNotifications';
import { PostStatus, SocialMediaPostViewModel } from '../../../../viewmodels/AppViewModels';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../../../components/LocalNotificationObserver/WithNotificationService';
import { SocialMediaIcon } from '../../../components/svgs/icons/SocialMediaIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { EditSocialMediaSendOptions } from '../../templates/EditSocialMediaSendOptions';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		RouteComponentProps<any>,
		IErrorMessageComponentProps,
		INotificationServiceComponentProps<SocialMediaPostViewModel> {
	className?: string;
	sendOnBehalf?: boolean;
	postVm?: SocialMediaPostViewModel;
	expirationDate?: string;
	scheduleCtaText?: string;
	styles?: StyleDeclarationValue[];
}

interface IState {
	initialSendDate?: string;
}

class _SocialMediaApprovalSetSchedule extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			initialSendDate: this.props.postVm?.dueDate,
		};
	}

	public render() {
		const { className, styles, scheduleCtaText, sendOnBehalf, expirationDate, postVm } = this.props;
		const { initialSendDate } = this.state;
		return (
			<div
				className={`${css(styleSheet.container, ...(styles || []))} social-media-approval-set-schedule ${
					className || ''
				}`}
			>
				<div className={css(styleSheet.header)}>
					<div className={css(styleSheet.headerContent)}>
						<div>
							<SocialMediaIcon className={css(styleSheet.headerIcon)} />
						</div>
						<div className={css(styleSheet.headerMessage)}>
							<div className={css(styleSheet.headerMessageTitle)}>Schedule Send</div>
							{this.renderScheduleSendMessage()}
						</div>
					</div>
				</div>
				<EditSocialMediaSendOptions
					ignoreCompliance={!postVm?.sendWithCompliance}
					sendOnBehalf={sendOnBehalf}
					initialStartDate={moment(initialSendDate)}
					expirationDate={expirationDate}
					onSubmitPost={this.onChangeDate}
					submitButtonText={scheduleCtaText || 'Approve'}
					styles={[styleSheet.sendOptions]}
					hideHeader={true}
					templateId={postVm?.templateReference?.templateId}
					isSchedulingInProgress={postVm?.isBusy}
				/>
			</div>
		);
	}

	private onChangeDate = async (_: React.MouseEvent<HTMLElement>, newMoment: moment.Moment) => {
		const { postVm, history, logApiError, errorMessages } = this.props;
		if (postVm) {
			try {
				await postVm.update({ ...postVm.toJs(), dueDate: newMoment.toISOString() });

				postNotification({
					info: postVm,
					topic: Topics.EDIT_SOCIAL_POST,
				});
				history.goBack();
			} catch (error) {
				logApiError('UpdateSocialMediaPostDueDate-Error', error);

				errorMessages.pushApiError(error);
			}
		}
	};

	private renderScheduleSendMessage() {
		const { initialSendDate } = this.state;
		const { postVm } = this.props;
		if (!initialSendDate) {
			return null;
		}
		const dateStringValue = moment(initialSendDate).format('MMMM Do');
		const datePassed = moment(initialSendDate).isBefore(moment(), 'day');
		return postVm?.status === PostStatus.Pending ? (
			datePassed ? (
				<span>
					Please pick a new date to send this campaign. The date suggested by your Success Manager has passed &nbsp;
					<span>(</span>
					<span className={css(baseStyleSheet.fontBold)}>{dateStringValue}</span>
					<span>)</span>
				</span>
			) : (
				<span>
					Your success manager has recommended &nbsp;
					<span className={css(baseStyleSheet.fontBold)}>{dateStringValue}</span>
					&nbsp; to send the campaign; you can accept or choose a different date.
				</span>
			)
		) : (
			<span>
				{`This post was previously scheduled for `}
				<span className={css(baseStyleSheet.fontBold)}>{dateStringValue}</span>.
			</span>
		);
	}
}

const SocialMediaApprovalSetScheduleAsObserver = observer(_SocialMediaApprovalSetSchedule);
const SocialMediaApprovalSetScheduleWithContext = inject(ErrorMessagesViewModelKey)(
	SocialMediaApprovalSetScheduleAsObserver
);
const SocialMediaApprovalSetScheduleWithLocalNotifications = withNotificationService(
	SocialMediaApprovalSetScheduleWithContext
);
export const SocialMediaApprovalSetSchedule = withEventLogging(
	withRouter(SocialMediaApprovalSetScheduleWithLocalNotifications),
	'SocialMediaApprovalSetSchedule'
);
