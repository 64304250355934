import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import * as React from 'react';
import { DayModifiers, Modifier } from 'react-day-picker/types/common';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { EmailMailerType, IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { IEmailComposerContext } from '../../../../models/Email';
import {
	calcScheduleCampaignCalendarDateRange,
	convertDurationFromString,
	getComplianceDisabledDays,
	getComplianceWaitTimeString,
	getDefaultDateStringValue,
	getDefaultTimeStringValue,
	getScheduleTimeOptions,
	getSendOnBehalfDisabledDays,
	getSendOnBehalfWaitTime,
	getSendOnBehalfWaitTimeString,
	scheduleSendOverlapsWithExistingWorkload,
	senderIsNotCurrentUser,
} from '../../../../models/UiUtils';
import {
	CampaignViewModel,
	ComposeEmailViewModel,
	ComposeResourceEmailViewModel,
	ContentSourceFormat,
	EmailCategories,
	ISendEmailFeatures,
} from '../../../../viewmodels/AppViewModels';
import { Button } from '../../../components/Button';
import { CampaignDayPicker } from '../../../components/CampaignDayPicker';
import { DeprecatedPopover, PopoverType } from '../../../components/DeprecatedPopover';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { DefaultSelectBox, ISelectBoxOption } from '../../../components/SelectBox';
import { CampaignDateTimePicker } from '../../../components/campaigns/CampaignDateTimePicker';
import { DangerMessage } from '../../../components/errorMessages/DangerMessage';
import { CampaignList } from '../../../components/reporting/emails/CampaignList';
import { DisclosureIcon } from '../../../components/svgs/icons/DisclosureIcon';
import { ExpiryIcon } from '../../../components/svgs/icons/ExpiryIcon';
import { SendNowIcon } from '../../../components/svgs/icons/SendNowIcon';
import { brandPrimary, destructive } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { EditSendOptionsCompleteBy, SendOptionCompleteByOption } from './presentation';
import { styleSheet } from './styles';

export const EditSendOptionsAdvancedOptions = ({
	expirationDate,
	onExpirationDateChanged,
}: {
	expirationDate: Date;
	onExpirationDateChanged(newValue: Date | null): void;
}) => {
	const [isCalendarShowing, setIsCalendarShowing] = React.useState(false);
	const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = React.useState(false);

	const onClick = () => {
		setIsAdvancedOptionsOpen(!isAdvancedOptionsOpen);
	};

	const onSave = (newValue: Date) => {
		setIsCalendarShowing(false);
		onExpirationDateChanged(newValue);
	};

	const onCancel = () => {
		setIsCalendarShowing(false);
		onExpirationDateChanged(null);
	};
	const anchor = !expirationDate ? (
		<button onClick={() => setIsCalendarShowing(true)} className={css(baseStyleSheet.ctaButton)}>
			Set End Date
		</button>
	) : (
		<button onClick={onCancel} className={css(baseStyleSheet.ctaButton)}>
			Clear End Date
		</button>
	);

	return (
		<div className={css(styleSheet.advancedOptionsContainer)}>
			<button className={css(baseStyleSheet.brandLink, styleSheet.advancedOptionsButton)} onClick={onClick}>
				<span>Advanced Options</span>
				<span className={css(styleSheet.advancedOptionsChevron)}>
					<DisclosureIcon
						type='chevron'
						className={css(
							styleSheet.advancedOptionsChevronIcon,
							isAdvancedOptionsOpen && styleSheet.advancedOptionsChevronIconFlipped
						)}
						fillColor={brandPrimary}
					/>
				</span>
			</button>
			{isAdvancedOptionsOpen ? (
				<div className={css(styleSheet.advancedOptionsBody)}>
					{expirationDate ? (
						<span>
							This email will stop sending {getDefaultDateStringValue(expirationDate)} at{' '}
							{getDefaultTimeStringValue(expirationDate)}
						</span>
					) : null}

					<DeprecatedPopover
						anchor={anchor}
						dismissOnClickOutside
						isOpen={isCalendarShowing}
						onRequestClose={() => setIsCalendarShowing(false)}
						preferredPlacement='above'
						type={PopoverType.white}
					>
						<CampaignDateTimePicker
							initialValue={expirationDate ?? new Date()}
							onSave={onSave}
							onCancel={onCancel}
							ctaText='Select'
							ignoreDisabledDays
						/>
					</DeprecatedPopover>
				</div>
			) : null}
		</div>
	);
};

interface IProps
	extends IEventLoggingComponentProps,
		RouteComponentProps<any>,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IImpersonationContextComponentProps {
	campaign?: CampaignViewModel;
	className?: string;
	disableSendNow?: boolean;
	emailComposer: ComposeEmailViewModel;
	emailWorkload?: Api.EmailWorkloadViewModel;
	hideCampaignsList?: boolean;
	hideHeader?: boolean;
	initialStartDate?: moment.MomentInput;
	onScheduleCtaClicked?(e?: React.MouseEvent<HTMLElement>): void;
	onShowOmitConfirmation?(
		e?: React.MouseEvent<HTMLElement>,
		estimate?: Partial<Api.IEmailSendEstimate>,
		selectedMoment?: moment.Moment,
		sendNow?: boolean,
		minStartDate?: Date
	): void;
	requireEstimateToSend?: boolean;
	styles?: StyleDeclarationValue[];
	submitButtonText?: string;
	sendEmail?: ISendEmailFeatures;
}

interface IState {
	completeBySendOption?: SendOptionCompleteByOption;
	scheduledExpirationDate?: Date;
	composerContext?: IEmailComposerContext;
	dayHover?: Date;
	estimate?: Partial<Api.IEmailSendEstimate>;
	estimatedSchduleSendOverlapsWithExistingWorkload?: boolean;
	mailerType?: EmailMailerType;
	selectedMoment?: moment.Moment;
	sendNow?: boolean;
	sendOnBehalfWarning?: string;
	mostOwnerEmailCount?: number;
	datesWithCompletedCampaigns: Date[];
	datesWithQueuedCampaigns: Date[];
}

const ScheduleSelectBoxOptions: ISelectBoxOption<'now' | 'schedule'>[] = [
	{
		title: 'Send right away',
		value: 'now',
	},
	{
		title: 'Schedule for later',
		value: 'schedule',
	},
];

const NUM_WEEKS_SHOWN_IN_CAL: 4 | 6 = 6;

class _EditSendOptions extends React.Component<IProps, IState> {
	private mCampaigns: Api.CampaignsReportingViewModel;
	private mDateRange: { startDate?: Date; endDate?: Date } | null = null;
	private mEmailWorkloadVm: Api.EmailWorkloadViewModel;
	private mEstimatePromise: Promise<any>;
	private mMinutes = 0;
	public static defaultProps: Partial<IProps> = {
		requireEstimateToSend: true,
	};

	constructor(props: IProps) {
		super(props);
		const { isSendFromOption, options } = props.emailComposer?.emailMessage ?? {};
		const startDate = options?.scheduledSend?.startDate;

		let startMoment = props.initialStartDate ? moment(props.initialStartDate) : startDate ? moment(startDate) : null;
		const minStartMoment = this.minStartDate ? moment(this.minStartDate) : moment().set('minutes', this.mMinutes);
		if (minStartMoment && (!startMoment || minStartMoment.isAfter(startMoment))) {
			startMoment = minStartMoment;
		}
		this.mDateRange = calcScheduleCampaignCalendarDateRange({
			selectedDate: startMoment ?? new Date(),
			numWeeks: NUM_WEEKS_SHOWN_IN_CAL,
		});

		this.state = {
			selectedMoment: startMoment,
			sendNow:
				!props.disableSendNow &&
				startMoment.isSame(moment(), 'day') &&
				!props.campaign &&
				isSendFromOption?.(Api.SendEmailFrom.CurrentUser),
			datesWithCompletedCampaigns: [],
			datesWithQueuedCampaigns: [],
		};

		this.mEmailWorkloadVm = props.emailWorkload || new Api.EmailWorkloadViewModel(props.userSession);
		props.impersonationContext?.applyImpersonation(this.mEmailWorkloadVm);
	}

	public async componentDidMount() {
		const { emailComposer, logApiError, impersonationContext } = this.props;
		const { selectedMoment } = this.state;
		if (selectedMoment && selectedMoment !== this.state?.selectedMoment) {
			this.loadSendEstimate();
		}
		try {
			const skipEmailMailerTypeCall = impersonationContext?.account && !impersonationContext?.user?.id;
			if (!skipEmailMailerTypeCall) {
				const emailMailerType = (await emailComposer.getMailerType(this.isForHtmlNewsletter)).value;
				this.setState({
					mailerType: emailMailerType,
				});
			}
		} catch (err) {
			logApiError('GetEmailMailerType-Error', Api.asApiError(err));
		}
		this.getCampaigns();
	}

	public componentDidUpdate(_: IProps, prevState: IState) {
		const { selectedMoment } = this.state;
		if (selectedMoment && selectedMoment !== prevState?.selectedMoment) {
			this.loadSendEstimate();
		}
	}

	public render() {
		const {
			className,
			styles,
			hideHeader,
			submitButtonText,
			emailComposer,
			userSession,
			disableSendNow,
			hideCampaignsList,
			onShowOmitConfirmation,
		} = this.props;
		const { selectedMoment, sendNow, mostOwnerEmailCount } = this.state;

		const formattedExpirationDate = new Date(emailComposer?.emailMessage?.options?.scheduledSend?.expirationDate);

		const scheduleInfo = this.renderScheduleInfo();
		const timingWarning = this.renderTimingWarning();

		const disabledDays = formattedExpirationDate
			? [{ after: formattedExpirationDate, before: this.minStartDate ? this.minStartDate : new Date() }]
			: [{ before: this.minStartDate ? this.minStartDate : new Date() }];

		const businessHours = userSession?.account?.features?.sendEmail;
		const outsideBusinessHourDays: Modifier[] = [];
		if (businessHours?.observeSendIntervals) {
			if (!businessHours?.saturdayInterval?.startMinutes && !businessHours?.saturdayInterval?.endMinutes) {
				outsideBusinessHourDays.push({ daysOfWeek: [6] });
			}
			if (!businessHours?.sundayInterval?.startMinutes && !businessHours?.sundayInterval?.endMinutes) {
				outsideBusinessHourDays.push({ daysOfWeek: [0] });
			}
			if (!businessHours?.fridayInterval?.startMinutes && !businessHours?.fridayInterval?.endMinutes) {
				outsideBusinessHourDays.push({ daysOfWeek: [5] });
			}
		}

		const disableSendButton =
			emailComposer?.emailMessage?.didScheduleOrSendSuccessFully ||
			emailComposer?.emailMessage?.isBusy ||
			emailComposer?.isBusy ||
			(!sendNow && this.validSelectedHour.isDefault) ||
			(emailComposer?.emailMessage.options?.scheduledSend?.expirationDate &&
				moment(emailComposer?.emailMessage?.options?.scheduledSend?.expirationDate) < selectedMoment);

		const warningMessage = this.renderWarningMessage();
		const expirationWarningMessage = this.renderExpirationWarning();

		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} edit-send-options ${className || ''}`}>
				<div className={css(styleSheet.content)}>
					{!hideHeader && (
						<div className={css(styleSheet.header)}>
							<SendNowIcon className={css(styleSheet.headerIcon)} />
							<div className={css(styleSheet.headerText)}>
								<div>When do you want to send this email?</div>
								{!disableSendNow && (
									<div className={css(styleSheet.headerDescription)}>Send it now or choose a future date and time.</div>
								)}
							</div>
						</div>
					)}
					<div className={css(styleSheet.body)}>
						<CampaignDayPicker
							selectedDay={selectedMoment?.toDate()}
							disabledDays={[...disabledDays, ...outsideBusinessHourDays]}
							onDisableDayClick={this.onDisabledDayClick}
							numWeeksShown={NUM_WEEKS_SHOWN_IN_CAL}
							modifiers={{
								hasCampaign: [...this.state.datesWithCompletedCampaigns, ...this.state.datesWithQueuedCampaigns],
								hasCompletedCampaign: this.state.datesWithCompletedCampaigns,
								hasQueuedCampaign: this.state.datesWithQueuedCampaigns,
							}}
							onDayClick={this.onDaySelected}
							onDateRangeChange={dateRange => {
								this.mDateRange = dateRange;
								this.loadCampaignsInDateRange();
							}}
						/>
						{!hideCampaignsList && (
							<div className={css(styleSheet.campaignListContainer)}>
								{!this.mCampaigns || this.mCampaigns.isLoading ? (
									<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />
								) : (
									<CampaignList campaigns={this.mCampaigns} className={css(styleSheet.campaignList)} />
								)}
							</div>
						)}
					</div>
					{warningMessage}
					<footer
						className={css(
							styleSheet.footer,
							timingWarning || formattedExpirationDate ? styleSheet.footerWithWarning : null,
							warningMessage ? styleSheet.footerWithDanger : null
						)}
					>
						{expirationWarningMessage}
						{timingWarning ? (
							<div className={css(styleSheet.warningMessage, scheduleInfo && styleSheet.wideWarning)}>
								{timingWarning}
							</div>
						) : null}
						<div className={css(styleSheet.footerInputs)}>
							<time dateTime={selectedMoment.toLocaleString()} className={css(styleSheet.selectedDayStringRep)}>
								{selectedMoment.format(`ddd[,] MMMM Do `)}
							</time>
							<div className={css(baseStyleSheet.verticalStack, styleSheet.footerInputsInner)}>
								<div className={css(baseStyleSheet.horizontalStack)}>
									{this.shouldShowScheduleTypeSelector && (
										<DefaultSelectBox
											className={css(styleSheet.scheduleTypeDropDown)}
											menuPopupPosition='top'
											onSelectionChanged={this.onTypeOptionSelected}
											options={this.scheduleSelectBoxOptions}
											optionStyles={[styleSheet.scheduleTypeDropDownOption]}
											selectedOption={this.scheduleSelectBoxOptions.find(
												x => x.value === (sendNow ? 'now' : 'schedule')
											)}
											triggerStyles={[styleSheet.scheduleTypeDropDownTrigger]}
										/>
									)}
									{!sendNow && this.timeOptions?.length > 0 && (
										<DefaultSelectBox
											className={css(styleSheet.timeDropdown)}
											menuPopupPosition='top'
											onSelectionChanged={this.onTimeOptionSelected}
											options={this.timeOptions}
											optionStyles={[styleSheet.timeDropdownOption]}
											placeholder={<span className={css(styleSheet.timeDropdownPlaceholder)}>Select a time...</span>}
											selectedOption={this.validSelectedHour}
											triggerStyles={[styleSheet.timeDropdownTrigger]}
										/>
									)}
									<Button
										className={css(!sendNow ? styleSheet.scheduleCta : undefined)}
										disabled={disableSendButton}
										onClick={onShowOmitConfirmation ? this.onShowOmitConfirmation : this.onScheduleCtaClicked}
										isLoading={emailComposer?.emailMessage?.isBusy || emailComposer?.isBusy}
										label={<>{sendNow ? 'Send Now' : submitButtonText || 'Schedule'}</>}
									/>
								</div>
								{this.recipientCount > 0 && (
									<EditSendOptionsCompleteBy
										maxEmailsPerDay={this.maxEmailsPerDay}
										onOptionChange={this.onCompleteByOptionChange}
										onSendOptionCompleteByChange={this.onSendOptionCompleteByChange}
										recipients={
											mostOwnerEmailCount ??
											this.recipientCount ??
											emailComposer?.followUpSource?.totalCount -
												emailComposer.emailMessage?.options?.followUp?.excludeContactIds?.length
										}
									/>
								)}
								<EditSendOptionsAdvancedOptions
									expirationDate={this.state.scheduledExpirationDate}
									onExpirationDateChanged={this.onExpirationDateChanged}
								/>
							</div>
						</div>
					</footer>
				</div>
			</div>
		);
	}

	@computed
	private get isForHtmlNewsletter() {
		const { emailComposer } = this.props;
		return (
			emailComposer.campaign?.categories?.includes(EmailCategories.HtmlNewsletter) ||
			emailComposer.emailMessage?.content?.sourceFormat === ContentSourceFormat.UnlayerHtmlNewsletter
		);
	}

	@computed
	private get recipientCount() {
		const { emailComposer } = this.props;
		const contactEmailMessage = emailComposer!.emailMessage;
		const contactEmailApproximation = contactEmailMessage?.contactEmailApproximation;
		const contactsToAdd = contactEmailMessage?.contactsToAdd;
		let recipientsCount =
			emailComposer instanceof ComposeResourceEmailViewModel
				? (emailComposer as ComposeResourceEmailViewModel)?.approximation?.hasEmail
				: contactEmailApproximation?.hasEmail;
		if (emailComposer.followUpSource) {
			recipientsCount =
				emailComposer.followUpCount - emailComposer.emailMessage?.options?.followUp?.excludeContactIds?.length;
		}
		/**
		 * If we have no approximation, we can assume that the user has added contacts manually
		 * and we should use the length of the contactsToAdd array as the recipient count
		 * this is a side effect of the way we handle the approximation in the email composer
		 * where the waypoint is not updated when the user adds contacts manually and
		 * the screensize is too small to show the contact list
		 */
		if (!recipientsCount && !contactEmailApproximation?.hasEmail && contactsToAdd?.length > 0) {
			recipientsCount = contactsToAdd.length;
		}
		return recipientsCount;
	}

	@computed
	private get maxEmailsPerDay() {
		if (this.dailySendLimit?.count) {
			return this.dailySendLimit?.count;
		}
		return this.defaultEmailConfigDailyLimit;
	}

	@computed
	private get getEstimatedDurationInDays() {
		const { estimate } = this.state;
		const { userSession, emailComposer } = this.props;
		if (estimate) {
			const { estimate: est } = estimate;
			const startDate = est?.startDate ? moment(est.startDate) : null;
			const endDate = est?.endDate ? moment(est.endDate) : null;

			if (startDate && endDate && !this.isForHtmlNewsletter) {
				return Math.ceil(endDate.diff(startDate, 'days', true));
			} else if (
				this.isForHtmlNewsletter &&
				!userSession.account.features?.htmlNewsletter?.useClientEmailProvider &&
				!emailComposer?.emailMessage?.options?.sendWithCompliance
			) {
				return Math.ceil(this.recipientCount / 5000);
			} else {
				this.props.logEvent('NoEstimateForSendDuration', { estimatedDurationInDays: 0 });
				return 0;
			}
		}
		return null;
	}

	@computed
	private get defaultEmailConfigDailyLimit() {
		const { userSession, impersonationContext } = this.props;
		const account = impersonationContext?.account || userSession.account?.toJs();
		return account
			? account.emailProviderConfigurations.reduce<number>((currentValue, x) => {
					const dailyLimit = Api.VmUtils.getEmailSendLimitFromProviderConfig(x, 1440);
					if (!dailyLimit) {
						if (!currentValue) {
							return 100;
						}
						return currentValue;
					}
					if (currentValue === null) {
						return dailyLimit.count;
					}
					return Math.min(currentValue, dailyLimit.count);
				}, null)
			: 100;
	}

	private onCompleteByOptionChange = (option: SendOptionCompleteByOption) => {
		this.setState({ completeBySendOption: option });
	};

	private onExpirationDateChanged = (newValue: Date | null) => {
		this.setState({ scheduledExpirationDate: newValue });
		this.props.emailComposer.emailMessage.setScheduleExpirationDate(newValue);
	};

	private onSendOptionCompleteByChange = (minimumDays?: number) => {
		this.props.emailComposer.emailMessage.setMinimumDurationInDays(minimumDays);
	};

	@computed
	private get validSelectedHour() {
		const { selectedMoment } = this.state;
		return (
			this.timeOptions.find(x => x.value === selectedMoment.get('hours')) ?? {
				isDefault: true,
				title: 'Select a time',
				value: -1,
			}
		);
	}

	private getCampaigns = async () => {
		const { emailComposer, userSession, impersonationContext } = this.props;
		const { isSendFromOption } = emailComposer.emailMessage;

		let user: Api.IUser = impersonationContext?.isValid ? impersonationContext.user : null;
		if (!impersonationContext?.isValid) {
			user = userSession.user;
			if (isSendFromOption(Api.SendEmailFrom.SelectedUser)) {
				user = emailComposer?.emailMessage?.options?.sendEmailFromUser;
			} else if (isSendFromOption(Api.SendEmailFrom.ContactOwner)) {
				try {
					const contactOwners = await emailComposer.getContactOwners();

					if (contactOwners?.length) {
						const owner = contactOwners.reduce(
							(prev, curr) => (prev?.contactsCount < curr?.contactsCount ? curr : prev),
							contactOwners[0]
						);
						this.setState({ mostOwnerEmailCount: owner.contactsCount });
						user = owner.user;
					}
				} catch (err) {
					const { logApiError } = this.props;
					logApiError('LoadSendOnBehalfError', err);
				}
			}
		}

		this.mCampaigns = new Api.CampaignsReportingViewModel(userSession, user);
		this.mCampaigns.pageSize = 50;
		impersonationContext?.applyImpersonation(this.mCampaigns);

		this.loadCampaignsInDateRange();
		this.loadSendEstimate();
	};

	private senderIsNotCurrentUser = () => {
		const { emailComposer } = this.props;
		const emailMessage = emailComposer?.emailMessage;
		if (!emailMessage) {
			return false;
		}
		return senderIsNotCurrentUser(emailMessage);
	};

	private renderSendOnBehalfWarning() {
		const { emailComposer } = this.props;
		const emailMessage = emailComposer?.emailMessage;

		let sendOnBehalfWarning: string;
		if (emailMessage) {
			const { isSendFromOption } = emailComposer.emailMessage;

			if (this.senderIsNotCurrentUser()) {
				sendOnBehalfWarning =
					'Since you are sending an email on behalf of another user, we require that you schedule the email at least ' +
					getSendOnBehalfWaitTimeString(this.props.userSession, this.props.impersonationContext) +
					' ahead, so we can give them proper notification.';

				if (isSendFromOption(Api.SendEmailFrom.ContactOwner)) {
					try {
						const contactOwnersStr = emailComposer.ownersWithFirstNames
							.map(
								(owner, i) =>
									`${
										i === emailComposer.ownersWithFirstNames.length - 1 && emailComposer.ownersWithFirstNames.length > 1
											? 'and '
											: ''
									}${owner?.user?.firstName} (${owner?.contactsCount})`
							)
							.join(emailComposer.ownersWithFirstNames.length > 2 ? ', ' : ' ');

						sendOnBehalfWarning = `${sendOnBehalfWarning} This email will come from ${contactOwnersStr}.`;
					} catch (err) {
						const { logApiError } = this.props;
						logApiError('LoadSendOnBehalfError', err);
					}
				}
			}
		}

		return sendOnBehalfWarning ? (
			<DangerMessage styles={[styleSheet.sendOnBehalfWarning]}>{sendOnBehalfWarning}</DangerMessage>
		) : null;
	}

	private renderComplianceMessage() {
		const { userSession, impersonationContext } = this.props;
		const complianceWarning =
			'You must schedule at least ' +
			getComplianceWaitTimeString(userSession, impersonationContext) +
			' ahead to allow compliance time to review and approve this campaign.';

		return <DangerMessage styles={[styleSheet.sendOnBehalfWarning]}> {complianceWarning} </DangerMessage>;
	}

	private renderScheduleInfo() {
		const { estimate } = this.state;
		const { emailComposer } = this.props;
		let numberOfEmailsToSend = emailComposer?.estimatedRecipientsTotal || 1;
		if (emailComposer.followUpSource) {
			numberOfEmailsToSend = emailComposer.followUpCount;
		}
		const estimatedDaysToComplete = this.numberOfDaysToFinish;

		if (estimate && estimatedDaysToComplete > 0) {
			const dailyLimit = this.dailySendLimit;
			return (
				<span>
					{dailyLimit && <span>{` Your email server has a daily limit of ${dailyLimit.count} emails per day. `}</span>}
					{`Levitate will send the ${numberOfEmailsToSend} email${
						numberOfEmailsToSend > 1 ? 's' : ''
					} over ${estimatedDaysToComplete} day${estimatedDaysToComplete > 1 ? 's' : ''}.`}
				</span>
			);
		}
		return null;
	}

	private renderTimingWarning() {
		const { selectedMoment, estimatedSchduleSendOverlapsWithExistingWorkload } = this.state;
		const { userSession, emailComposer } = this.props;

		const scheduleInfo = this.renderScheduleInfo();
		const estimatedDurationInDays = this.getEstimatedDurationInDays;

		if (selectedMoment) {
			if (estimatedSchduleSendOverlapsWithExistingWorkload && !this.isForHtmlNewsletter) {
				return (
					<>
						<span>
							It looks like you have other emails going out over the selected day(s). We recommend starting sooner.
						</span>
						{scheduleInfo ? scheduleInfo : null}
					</>
				);
			} else if (estimatedDurationInDays > 1 && !this.isForHtmlNewsletter) {
				return (
					<span>
						Since your email server has a daily send limit, this campaign will take approximately{' '}
						{estimatedDurationInDays} day
						{estimatedDurationInDays > 1 ? 's' : ''}.
					</span>
				);
			} else if (
				estimatedDurationInDays > 1 &&
				this.isForHtmlNewsletter &&
				!userSession.account.features?.htmlNewsletter?.useClientEmailProvider &&
				!emailComposer?.emailMessage?.options?.sendWithCompliance
			) {
				return (
					<span>
						Given the daily send limit, this campaign will take approximately {estimatedDurationInDays} day
						{estimatedDurationInDays > 1 ? 's' : ''}.
					</span>
				);
			} else if (this.datePassed) {
				return (
					<span>
						The selected date has already passed.
						<br />
						Please select a new date in the future.
					</span>
				);
			}
			return null;
		}
	}

	private renderExpirationWarning() {
		const { emailComposer } = this.props;
		const expirationDate = emailComposer?.emailMessage?.options?.scheduledSend?.expirationDate;
		if (expirationDate) {
			const formattedExpirationDate = new Date(expirationDate);
			return (
				<div className={css(styleSheet.warningMessage)}>
					<div className={css(styleSheet.expirationWarning)}>
						<ExpiryIcon fill={destructive} />
						<time
							dateTime={formattedExpirationDate.toLocaleDateString()}
						>{`This is a time-sensitive campaign. Emails will not be sent after ${getDefaultDateStringValue(
							formattedExpirationDate
						)} at ${getDefaultTimeStringValue(formattedExpirationDate)}.`}</time>
					</div>
				</div>
			);
		}
		return null;
	}

	private get dailySendLimit() {
		const { estimate } = this.state;
		return estimate ? estimate?.emailWorkload?.limits?.find(x => x.intervalInMinutes === 1440) : null;
	}

	private get numberOfDaysToFinish() {
		const { emailComposer, userSession } = this.props;
		const { completeBySendOption, estimate, sendNow } = this.state;

		if (
			this.isForHtmlNewsletter &&
			!emailComposer?.emailMessage?.options?.sendWithCompliance &&
			!userSession.account.features.htmlNewsletter.useClientEmailProvider &&
			!sendNow &&
			completeBySendOption === SendOptionCompleteByOption.SPREAD
		) {
			return Math.ceil((emailComposer?.estimatedRecipientsTotal || 1) / 5000);
		} else {
			return emailComposer.emailMessage.sendLimits &&
				!sendNow &&
				completeBySendOption === SendOptionCompleteByOption.SPREAD
				? Math.ceil((emailComposer?.estimatedRecipientsTotal || 1) / emailComposer.emailMessage.sendLimits[0]?.count)
				: estimate && estimate.estimate
					? Math.ceil(moment(estimate.estimate.endDate).diff(moment(estimate.estimate.startDate), 'days', true))
					: 0;
		}
	}

	private onDisabledDayClick = (day: Date) => {
		const nowMoment = moment();
		const isSendFromOption = this.props.emailComposer?.emailMessage?.isSendFromOption;
		if (moment(day).isSame(nowMoment, 'day') && isSendFromOption(Api.SendEmailFrom.CurrentUser)) {
			// TODO: This is not passing in any modifiers nor events as expected

			// @ts-ignore
			this.onDaySelected(day);
		}
	};

	@computed
	private get datePassed() {
		const todayMoment = moment().startOf('day');
		return !this.state.selectedMoment?.isSame(todayMoment, 'day') && this.state.selectedMoment?.isBefore(todayMoment);
	}
	@computed
	private get isCompliance() {
		const { emailComposer } = this.props;
		return !!emailComposer.emailMessage.options?.sendWithCompliance;
	}

	@computed
	private get timeOptions() {
		const { sendEmail, userSession, impersonationContext } = this.props;
		const { selectedMoment } = this.state;

		const allOptions = getScheduleTimeOptions(this.mMinutes, userSession, sendEmail, selectedMoment);
		const nowMoment = moment();
		const days = !impersonationContext?.isValid
			? convertDurationFromString(userSession?.account?.preferences?.sendOnBehalfWaitTime)?.days ?? 2
			: convertDurationFromString(impersonationContext?.account?.preferences?.sendOnBehalfWaitTime)?.days ?? 2;

		if (
			this.senderIsNotCurrentUser() &&
			selectedMoment?.isSame(
				getSendOnBehalfWaitTime(this.props.userSession, this.props?.impersonationContext),
				'day'
			) &&
			days !== 2
		) {
			const options = allOptions.filter(({ value }) => {
				const thenMoment = getSendOnBehalfWaitTime(this.props.userSession, this.props?.impersonationContext).set(
					'hours',
					value
				);
				return thenMoment.isAfter(getSendOnBehalfWaitTime(this.props.userSession, this.props?.impersonationContext));
			});
			return options;
		}
		if (selectedMoment?.isSame(nowMoment, 'day')) {
			const options = allOptions.filter(({ value }) => {
				const thenMoment = moment(nowMoment).set('hours', value).set('minutes', this.mMinutes);
				return thenMoment.isAfter(nowMoment);
			});
			return options;
		}

		return allOptions;
	}

	@computed
	private get shouldShowScheduleTypeSelector() {
		const { disableSendNow } = this.props;
		return !disableSendNow && this.state.selectedMoment?.isSame(moment(), 'day') && this.timeOptions?.length > 0;
	}

	private get scheduleSelectBoxOptions() {
		const { disableSendNow } = this.props;
		return disableSendNow ? ScheduleSelectBoxOptions.filter(x => x.value !== 'now') : ScheduleSelectBoxOptions;
	}

	private loadSendEstimate = async () => {
		const { logEvent, emailComposer, logApiError, impersonationContext } = this.props;
		const { selectedMoment, estimate: prevEstimate, mostOwnerEmailCount, scheduledExpirationDate } = this.state;
		if (
			!this.mEstimatePromise &&
			(!impersonationContext?.isValid || (impersonationContext?.isValid && impersonationContext?.user?.id))
		) {
			const selectedDate = selectedMoment?.toDate();
			const schedule: Api.IScheduledSend = {
				criteria: Api.ScheduleCriteria.StartAfter,
				expirationDate:
					scheduledExpirationDate?.toISOString() || emailComposer?.emailMessage?.options?.scheduledSend?.expirationDate,
				startDate: selectedDate.toISOString(),
			};

			const composerWithResource = emailComposer as ComposeResourceEmailViewModel;
			const approximation = composerWithResource?.resourceSelector
				? composerWithResource.approximation
				: emailComposer.emailMessage?.contactEmailApproximation;

			// Fall back to our FE "calculation" of recipients if there is no approximation available
			const numberOfEmailsToSend =
				mostOwnerEmailCount ?? approximation?.hasEmail ?? emailComposer.recipients?.length ?? 1;
			logEvent('GetEstimateScheduleSend', {
				...schedule,
				numberOfEmailsToSend,
			});

			const email = emailComposer.emailMessage.toJs();
			email.options.scheduledSend = schedule;
			const promise = this.mEmailWorkloadVm.getBulkScheduledSendEstimate(
				email,
				numberOfEmailsToSend,
				momentTz.tz.guess()
			);
			const onFinish = (opResult: Api.IOperationResult<Api.IEmailSendEstimate>) => {
				if (promise === this.mEstimatePromise) {
					this.mEstimatePromise = null;
					const estimate = opResult.success ? opResult.value : prevEstimate || null;
					this.setState({
						estimate,
						estimatedSchduleSendOverlapsWithExistingWorkload: scheduleSendOverlapsWithExistingWorkload(
							estimate,
							selectedDate,
							numberOfEmailsToSend
						),
					});
				}

				if (!opResult.success) {
					logApiError('GetEstimateScheduleSend-Error', opResult);
				}
			};
			promise.then(onFinish).catch(onFinish);
			this.mEstimatePromise = promise;
		}
		return this.mEstimatePromise;
	};

	private onTypeOptionSelected = (option: ISelectBoxOption<'now' | 'scheduled'>) => {
		this.setState({
			sendNow: option.value === 'now',
		});
	};

	private onTimeOptionSelected = (option: ISelectBoxOption<number>) => {
		const { selectedMoment } = this.state;
		if (selectedMoment) {
			const nextSelectedMoment = moment(selectedMoment);
			nextSelectedMoment.set('hours', option.value);
			nextSelectedMoment.set('minutes', 0);
			this.setState({
				selectedMoment: nextSelectedMoment,
			});
		}
	};

	private onDaySelected = (day: Date, _: DayModifiers, e: React.MouseEvent<HTMLElement>) => {
		const { disableSendNow } = this.props;
		if (
			moment(day).isBefore(moment().startOf('day')) ||
			(this.minStartDate && moment(day).isBefore(moment(this.minStartDate).startOf('day')))
		) {
			e.stopPropagation();
			e.preventDefault();
			return;
		}
		const { selectedMoment } = this.state;
		const nextMoment = moment(day);
		if (selectedMoment) {
			nextMoment.set('hours', selectedMoment.get('hours'));
			nextMoment.set('minutes', selectedMoment.get('minutes'));
		}
		this.setState({
			selectedMoment: nextMoment,
			sendNow: !disableSendNow ? nextMoment.isSame(moment(), 'day') : false,
		});
	};

	private loadCampaignsInDateRange = () => {
		const { logApiError, logEvent } = this.props;
		this.mCampaigns.dateRange = this.mDateRange || {};
		const promise = this.mCampaigns.load();
		if (promise) {
			logEvent('CampaignsLoad', {
				dateRange: {
					endDate: this.mCampaigns.dateRange?.endDate?.toISOString(),
					startDate: this.mCampaigns.dateRange?.startDate?.toISOString(),
				},
			});
			promise
				.then(() => {
					const datesWithCompletedCampaigns =
						this.mCampaigns.items
							?.filter(x => x.status === Api.EmailSendStatus.Complete && x.schedule?.startDate)
							?.map(x => new Date(x.schedule.startDate)) || [];
					const datesWithQueuedCampaigns =
						this.mCampaigns.items
							?.filter(x => x.status === Api.EmailSendStatus.Queued && x.schedule?.startDate)
							?.map(x => new Date(x.schedule.startDate)) || [];
					this.setState({
						datesWithCompletedCampaigns,
						datesWithQueuedCampaigns,
					});
				})
				.catch((error: Api.IOperationResultNoValue) => {
					logApiError('CampaignsLoad-Error', error);
					this.setState({
						datesWithCompletedCampaigns: [],
						datesWithQueuedCampaigns: [],
					});
				});
		}
	};
	private onShowOmitConfirmation = () => {
		const { onShowOmitConfirmation } = this.props;
		const { estimate, selectedMoment, sendNow } = this.state;
		if (onShowOmitConfirmation) {
			onShowOmitConfirmation(undefined, estimate, selectedMoment, sendNow, this.minStartDate);
		}
	};

	private onScheduleCtaClicked = (e?: React.MouseEvent<HTMLElement>) => {
		const { emailComposer, onScheduleCtaClicked, requireEstimateToSend, impersonationContext } = this.props;
		const { estimate, sendNow, selectedMoment, mailerType } = this.state;
		const skipEmailMailerTypeCall = impersonationContext?.account && !impersonationContext?.user?.id;
		if (!skipEmailMailerTypeCall && requireEstimateToSend && !estimate && mailerType !== EmailMailerType.Levitate) {
			return;
		}
		emailComposer.sendEstimate = estimate;
		emailComposer.emailMessage.options.scheduledSend = {
			...(emailComposer.emailMessage.options.scheduledSend || {}),
			criteria: sendNow ? Api.ScheduleCriteria.Immediately : Api.ScheduleCriteria.StartAfter,
			startDate: selectedMoment?.toISOString(),
		};
		onScheduleCtaClicked?.(e);
	};

	private renderWarningMessage() {
		const { userSession, impersonationContext } = this.props;
		if (this.isCompliance && this.senderIsNotCurrentUser()) {
			return moment(getComplianceDisabledDays(userSession, impersonationContext, false)).isAfter(
				moment(getSendOnBehalfWaitTime(userSession, impersonationContext))
			)
				? this.renderComplianceMessage()
				: this.renderSendOnBehalfWarning();
		}
		return this.isCompliance
			? this.renderComplianceMessage()
			: this.senderIsNotCurrentUser()
				? this.renderSendOnBehalfWarning()
				: null;
	}

	@computed
	private get minStartDate() {
		const { userSession, impersonationContext } = this.props;
		return this.isCompliance && this.senderIsNotCurrentUser()
			? moment
					.max([
						moment(getComplianceDisabledDays(userSession, impersonationContext, false)),
						moment(getSendOnBehalfWaitTime(userSession, impersonationContext)),
					])
					.toDate()
			: this.isCompliance
				? getComplianceDisabledDays(userSession, impersonationContext, false)
				: this.senderIsNotCurrentUser()
					? getSendOnBehalfDisabledDays(userSession, impersonationContext)
					: null;
	}
}

const EditSendOptionsAsObserver = observer(_EditSendOptions);
const EditSendOptionsWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ImpersonationContextKey
)(EditSendOptionsAsObserver);
const EditSendOptionsWithRouter = withRouter(EditSendOptionsWithContext);
export const EditSendOptions = withEventLogging(EditSendOptionsWithRouter, 'EditSendOptions');
