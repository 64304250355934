import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandPrimaryHover, charmGray, header, label } from '../../../../web/styles/colors';
import { CssSelectors } from '../../../../web/styles/styles';

export const styleSheet = StyleSheet.create({
	addMore: {
		color: brandPrimaryHover,
		display: 'inline-flex',
		gap: 8,
	},
	container: {
		width: 557,
	},
	dropdownSelect: {
		maxWidth: 120,
	},
	footer: {
		marginBottom: 24,
		display: 'flex',
	},
	formControl: {
		margin: '0 0 16px',
		...CssSelectors.allChildren(
			{
				color: header,
				fontSize: 12,
			},
			'small'
		),
	},
	formControlInputWrap: {
		display: 'flex',
		width: '100%',
		...CssSelectors.allChildren(
			{
				marginRight: 10,
				width: 'calc(100% - 22px)',
			},
			'.text-input'
		),
	},
	header: {
		color: brandPrimary,
		fontSize: 20,
		margin: '0 0 16px',
	},
	paragraph: {
		color: label,
		display: 'block',
		fontSize: 14,
		margin: '0 0 12px',
	},
	label: {
		color: charmGray,
		display: 'block',
		fontSize: 12,
		margin: '0 0 8px',
		textTransform: 'uppercase',
	},
	table: {
		marginTop: 20,
	},
	tableHeader: {
		background: '#fff',
	},
	tableHeaderRow: {},
	tableHeaderCell: {
		borderBottom: '1px solid #D8D8D8',
		color: '#AAAAAA',
		fontSize: 11,
		letterSpacing: '1.1px',
		paddingBottom: 20,
		textTransform: 'uppercase',
	},
	cell: {
		borderBottom: '1px solid #D8D8D8',
		padding: '20px 10px 20px 0',
	},
	cellContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: 8,
		cursor: 'pointer',
	},
});
