import * as Api from '@ViewModels';
import { StyleDeclarationValue } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { IImpersonationContextComponentProps, IModalContext, ImpersonationContextKey } from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useToaster } from '../../../../models/hooks/appStateHooks';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { WarningIcon } from '../../svgs/icons/WarningIcon';

interface IProps extends IModalContext, IImpersonationContextComponentProps {
	automationTemplate: Api.AutomationTemplateViewModel;
	className?: string;
	isOpen?: boolean;
	onRequestClose?(result: AutomationDisableCancelSelection): void;
	styles?: StyleDeclarationValue[];
}

export enum AutomationDisableCancelSelection {
	Disable,
	DisableAndCancelAllInProgress,
	DoNothing,
}

const AutomationDisableCancelModalBase: React.FC<IProps> = props => {
	const { automationTemplate, isOpen, onRequestClose } = props;
	const toaster = useToaster();
	const { logEvent, logApiError } = useEventLogging('AutomationDisableCancelModal');
	const errorMessages = useErrorMessages();

	const options = React.useMemo(() => {
		if (automationTemplate) {
			const disableConfirmationOptions: IConfirmationDialogOption<AutomationDisableCancelSelection>[] =
				automationTemplate.stats?.inProgress > 0
					? [
							{
								isDestructive: true,
								representedObject: AutomationDisableCancelSelection.DisableAndCancelAllInProgress,
								title: 'Disable and cancel all in progress',
							},
							{
								isCta: true,
								representedObject: AutomationDisableCancelSelection.Disable,
								title: 'Disable automation only',
							},
							{
								isCancel: true,
								representedObject: AutomationDisableCancelSelection.DoNothing,
								title: 'Do not disable',
							},
						]
					: [
							{
								isCta: true,
								representedObject: AutomationDisableCancelSelection.Disable,
								title: 'Yes, disable automation',
							},
							{
								isCancel: true,
								representedObject: AutomationDisableCancelSelection.DoNothing,
								title: 'Do not disable',
							},
						];
			return disableConfirmationOptions;
		}
		return [];
	}, [automationTemplate]);

	const onDisableConfirmationDialogRequestClose = React.useCallback(
		async (result?: IConfirmationDialogOption<AutomationDisableCancelSelection>) => {
			if (
				result?.representedObject === AutomationDisableCancelSelection.Disable ||
				result?.representedObject === AutomationDisableCancelSelection.DisableAndCancelAllInProgress
			) {
				const currentImpersonationContext = automationTemplate.impersonationContext;
				if (
					automationTemplate.impersonationContext?.account?.id &&
					!automationTemplate.impersonationContext?.user?.id
				) {
					automationTemplate.impersonate({
						account: automationTemplate.impersonationContext.account,
						user: automationTemplate.creator,
					});
				}

				try {
					if (result.representedObject === AutomationDisableCancelSelection.DisableAndCancelAllInProgress) {
						logEvent('CancelAllInProgress');
						await automationTemplate.cancelAllInProgress();
						await automationTemplate.load();
					}

					logEvent('DisableAutomation', { templateId: automationTemplate.id });
					await automationTemplate.setStatus(Api.TemplateStatus.Disabled);

					const message = result.isDestructive
						? 'Automation template disabled and in progress cancelled.'
						: 'Automation template disabled.';
					toaster?.push({
						message: `${message}`,
						type: 'successMessage',
					});
				} catch (error) {
					logApiError('DisableAutomation-Error', error);

					errorMessages.pushApiError(error);
				}

				automationTemplate.impersonate(currentImpersonationContext);
			}

			onRequestClose?.(result?.representedObject);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onRequestClose, automationTemplate]
	);

	return (
		<ConfirmationDialog
			icon={<WarningIcon />}
			modalProps={{
				isOpen,
				onRequestClose: onDisableConfirmationDialogRequestClose,
			}}
			options={options}
			stackButtonsVertically={true}
			title={<span>Disabling automation</span>}
		>
			{automationTemplate.stats.inProgress > 0 && (
				<div style={{ textAlign: 'center' }}>
					Contacts that have existing automation in progress
					<br />
					can be cancelled or left to run.
				</div>
			)}
		</ConfirmationDialog>
	);
};

export const AutomationDisableCancelModal = inject(ImpersonationContextKey)(AutomationDisableCancelModalBase);
