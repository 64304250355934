// import { DashboardFeedViewModel, DashboardViewModel, IOperationResultNoValue, ITemplate } from '@ViewModels';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages } from '../../../../models/hooks/appStateHooks';
import {
	CampaignViewModel,
	ComposeEmailViewModel,
	FollowUpType,
	IEmailMessageFollowUpOptions,
	ITemplate,
} from '../../../../viewmodels/AppViewModels';
import { DeprecatedSelect, ISelectOption } from '../../../components/DeprecatedSelect';
import { EmailTemplateBrowserDialog } from '../../../components/email/guide/EmailTemplateBrowserDialog';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	emailComposer: ComposeEmailViewModel<IEmailMessageFollowUpOptions, CampaignViewModel>;
	onFollowUpContentSelected?: (followUpType: FollowUpType, template?: ITemplate) => void;
	styles?: StyleDeclarationValue[];
}

const FollowUpContentSelectorBase: React.FC<IProps> = props => {
	const { emailComposer, onFollowUpContentSelected } = props;
	const [showingTemplateSelectorModal, setShowingTemplateSelectorModal] = React.useState(false);
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('FollowUpContentSelector');
	const originalCampaign = emailComposer.followUpSource;
	const isHtmlNewsletter = !!originalCampaign?.categories?.find(x => x === 'HtmlNewsletter');

	const FollowUpTypeOptions: ISelectOption<FollowUpType>[] = React.useMemo(() => {
		return isHtmlNewsletter
			? [
					{
						dataContext: FollowUpType.FromTemplate,
						id: 'from-template-selector',
						text: 'Choose From Template Library',
					},
					{
						dataContext: FollowUpType.New,
						id: 'new',
						text: 'New',
					},
				]
			: [
					{
						dataContext: FollowUpType.OriginalContent,
						id: 'original-content',
						text: `(Resend) ${originalCampaign.subject || 'Original Email Content'}`,
					},
					{
						dataContext: FollowUpType.FromTemplate,
						id: 'from-template-selector',
						text: 'Choose From Template Library',
					},
					{
						dataContext: FollowUpType.New,
						id: 'new',
						text: 'New',
					},
				];
	}, [originalCampaign?.subject, isHtmlNewsletter]);

	const pastOptionType = emailComposer.emailMessage.options.followUp.type;
	const optionSelection = FollowUpTypeOptions.find(o => o.dataContext === pastOptionType);
	const defaultSelection = FollowUpTypeOptions.find(o => o.dataContext === Api.FollowUpType.New);
	const [selectedOption, setSelectedOption] = React.useState(
		pastOptionType === undefined ? defaultSelection : optionSelection
	);

	const onSelectedDropdown = React.useCallback(
		async (options: ISelectOption<FollowUpType>) => {
			if (options.dataContext === Api.FollowUpType.OriginalContent) {
				// load it if we need it
				if (!originalCampaign?.defaultMessageContent) {
					try {
						await originalCampaign.loadDefaultMessageContent();
					} catch (error) {
						logApiError('Error-Loading-Original-Campaign', Api.asApiError(error));

						errorMessages.pushApiError(error);
					}
				}

				emailComposer.emailMessage.options.followUp.type = Api.FollowUpType.OriginalContent;
				setSelectedOption(FollowUpTypeOptions.find(o => o.dataContext === FollowUpType.OriginalContent));

				onFollowUpContentSelected?.(Api.FollowUpType.OriginalContent, {
					content: originalCampaign.defaultMessageContent,
					subject: originalCampaign.subject,
				});
			}
			if (options.dataContext === Api.FollowUpType.New) {
				setSelectedOption(FollowUpTypeOptions.find(o => o.dataContext === Api.FollowUpType.New));

				onFollowUpContentSelected?.(Api.FollowUpType.New, null);

				emailComposer.emailMessage.options.followUp.type = Api.FollowUpType.New;
			}

			if (options.dataContext === Api.FollowUpType.FromTemplate) {
				setShowingTemplateSelectorModal(true);

				emailComposer.emailMessage.options.followUp.type = Api.FollowUpType.FromTemplate;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[emailComposer, originalCampaign]
	);

	const onTemplateSelectorModalRequestClose = (result: ITemplate, cancelled: boolean) => {
		if (!cancelled) {
			setSelectedOption(FollowUpTypeOptions.find(o => o.dataContext === Api.FollowUpType.FromTemplate));
			onFollowUpContentSelected?.(Api.FollowUpType.FromTemplate, result);
		}

		emailComposer.emailMessage.options.followUp.type = Api.FollowUpType.FromTemplate;
		setShowingTemplateSelectorModal(false);
	};

	return (
		<div className={css(styleSheet.sendFromDropdownContainer)}>
			<span>Select template:</span>
			<DeprecatedSelect
				onOptionClick={onSelectedDropdown}
				options={FollowUpTypeOptions}
				selectedOption={selectedOption}
				styles={[styleSheet.dropdownInline, styleSheet.followUpDropdown]}
			/>
			<EmailTemplateBrowserDialog
				modalProps={{
					isOpen: showingTemplateSelectorModal,
					onRequestClose: onTemplateSelectorModalRequestClose,
					useDefaultHeader: true,
				}}
				title='Use Existing Email Template'
			/>
		</div>
	);
};

export const FollowUpContentSelector = observer(FollowUpContentSelectorBase);
