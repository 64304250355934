import {
	IEditEmailTemplateComponent,
	IImpersonationContextComponentProps,
	IModalContext,
	ImpersonationContextKey,
	ModalChildComponentContextKey,
} from '@AppModels/.';
import {
	ErrorMessagesViewModelKey,
	FabViewModelKey,
	FullScreenModalViewModelKey,
	IErrorMessageComponentProps,
	IFabComponentProps,
	IFullscreenModalComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import {
	AttachmentsToBeUploadedViewModel,
	IRichContentEditorState,
	ITemplate,
	Industry,
	TemplateScope,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { hideDefaultMessageTemplate } from '../../../../models/MessageTemplates';
import { IScheduledSend, ITemplateCard, MangeEmailTemplatesViewModel } from '../../../../viewmodels/AppViewModels';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { FabContext } from '../../../components/FabContext';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { CampaignSearch } from '../../../components/campaigns/CampaignSearch';
import { ICampaignsByCategorySelectorComponent } from '../../../components/campaigns/CampaignsByCategorySelector';
import { NewMessageIcon } from '../../../components/svgs/icons/NewMessageIcon';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { EditEmailTemplate } from '../EditEmailTemplate';
import { styleSheet } from './styles';

interface IProps
	extends IUserSessionComponentProps,
		Partial<RouteComponentProps<any>>,
		IModalContext,
		IEventLoggingComponentProps,
		IFabComponentProps,
		IFullscreenModalComponentProps,
		IErrorMessageComponentProps,
		IImpersonationContextComponentProps {
	attachments?: AttachmentsToBeUploadedViewModel<File>;
	className?: string;
	defaultTemplatesIndustry?: Industry;
	disableDelete?: boolean;
	disableSharing?: boolean;
	disableTemplateEdits?: boolean;
	newContentCtaText?: string;
	onInnerEditorRef?(ref?: IEditEmailTemplateComponent): void;
	onCampaignByCategorySelectorRef?(ref?: ICampaignsByCategorySelectorComponent): void;
	onlySystemTemplates?: boolean;
	onCampaignTemplateClicked?(template?: ITemplate): void;
	onOpenCampaignComposer?(template: ITemplate, schedule?: IScheduledSend, referrer?: string): boolean;
	onRenderFooterAction?(
		template?: ITemplate,
		editorState?: IRichContentEditorState,
		templateSubject?: string
	): React.ReactNode;
	onRenderFullscreenHeader?(onCloseClicked: (e?: React.MouseEvent<HTMLElement>) => void): React.ReactNode;
	onTemplateSelected?(template?: ITemplate): void;
	selectOnly?: boolean;
	styles?: StyleDeclarationValue[];
}

interface IState {
	editingNewTemplate?: boolean;
	onDiscardChanges?(): void;
	selectedTemplate?: ITemplate;
	showDiscardChangesConfirmation?: boolean;
	showingCloseConfirmation?: boolean;
}

class _ManageEmailTemplates extends React.Component<IProps, IState> {
	private mTemplates?: MangeEmailTemplatesViewModel;

	private mTemplateListRef: ICampaignsByCategorySelectorComponent;
	private mEditTemplateRef?: IEditEmailTemplateComponent;

	constructor(props: IProps) {
		super(props);
		this.state = {};

		this.mTemplates = new MangeEmailTemplatesViewModel(props.userSession).impersonate(props.impersonationContext);
	}

	public render() {
		const {
			attachments,
			className,
			disableDelete,
			disableSharing,
			disableTemplateEdits,
			newContentCtaText,
			onRenderFooterAction,
			onRenderFullscreenHeader,
			onTemplateSelected,
			selectOnly,
			styles,
		} = this.props;
		const { selectedTemplate, showingCloseConfirmation, editingNewTemplate, showDiscardChangesConfirmation } =
			this.state;
		return (
			<div className={`${css(...(styles || []), styleSheet.container)} manage-email-templates ${className || ''}`}>
				<FabContext appearance={{ hidden: true }} />
				{onRenderFullscreenHeader ? (
					onRenderFullscreenHeader(this.onCloseClicked)
				) : (
					<MultiContainerHeader fullscreenHeader='Manage Templates' onFullscreenRequestClose={this.onCloseClicked} />
				)}
				<div className={css(styleSheet.splitViewMaster)}>
					<div className={css(styleSheet.splitViewMasterHeader)}>
						<button
							className={css(styleSheet.createNewTemplateButton)}
							onClick={selectOnly ? this.onNewEmailClicked : this.onCreateNewTemplate()}
						>
							<NewMessageIcon className={css(styleSheet.createNewTemplateIcon)} showGreenPlus={true} />
							<span>{newContentCtaText || (selectOnly ? 'New Email' : 'Create new email template')}</span>
						</button>
					</div>
					<CampaignSearch
						onCampaignByCategorySelectorRef={this.onTemplateListRef}
						onCampaignTemplateClicked={this.onCampaignTemplateClicked}
						onTemplatesLoaded={this.onTemplatesLoaded}
						selectedTemplate={selectedTemplate}
						styles={[styleSheet.campaignSearch]}
						templates={this.mTemplates}
						useWholeCardAsCTA={true}
					/>
				</div>
				<div className={css(styleSheet.splitViewDetail)}>
					{this.mTemplates.isLoadingTemplate ? (
						<LoadingSpinner type='large' />
					) : (
						<>
							{(selectedTemplate || editingNewTemplate) && (
								<EditEmailTemplate
									attachments={attachments}
									className={css(styleSheet.templateEditor)}
									disableDelete={disableDelete}
									disableSharing={disableSharing}
									disableTemplateEdits={disableTemplateEdits}
									innerRef={this.onEditTemplateRef}
									key={(selectedTemplate ? selectedTemplate.id : 'new-template-editor') || 'template-editor'} // important
									onRenderFooter={
										onRenderFooterAction ? onRenderFooterAction : !selectOnly && this.onRenderFooterButtons
									}
									onTemplateDeleted={this.onTemplateDeleted}
									onTemplateSaved={this.onTemplateSaved}
									onTemplateSelected={onTemplateSelected}
									selectOnly={selectOnly}
									template={selectedTemplate}
								/>
							)}
						</>
					)}
				</div>
				{!selectOnly && (
					<ConfirmationDialog
						icon={<WarningIcon />}
						modalProps={{
							isOpen: showingCloseConfirmation || showDiscardChangesConfirmation,
							onRequestClose: this.onConfirmationDialogRequestClose,
						}}
						options={[
							{
								isDestructive: true,
								representedObject: true,
								title: 'Discard',
							},
							{
								isCancel: true,
								representedObject: false,
								title: 'Cancel',
							},
						]}
						title={`Are you sure you want to discard changes to this template${
							showingCloseConfirmation ? ' and close' : ''
						}?`}
					/>
				)}
			</div>
		);
	}

	private loadTemplate = async (indexOrCard?: number | ITemplateCard) => {
		const { errorMessages, logApiError } = this.props;
		if (this.mTemplateListRef) {
			try {
				const template = await this.mTemplateListRef.loadTemplate(indexOrCard);
				this.setState({ selectedTemplate: undefined });
				this.onTemplateSelected(true)(template);
			} catch (error) {
				logApiError('TemplatesLoad-Error', error);

				errorMessages.pushApiError(error);
			}
		}
	};

	private onTemplatesLoaded = () => {
		this.loadTemplate(0);
	};

	private onCampaignTemplateClicked = (template: ITemplate) => {
		if (template?.id !== this.state.selectedTemplate?.id) {
			this.setState({ selectedTemplate: template });
			this.onTemplateSelected(false)(template);
		}
	};

	private onRenderFooterButtons = () => {
		const disabled = this.mEditTemplateRef
			? this.mEditTemplateRef.hasUnsavedEdits() || this.mEditTemplateRef.getIsSaving()
			: false;
		return (
			<>
				<button className={css(baseStyleSheet.ctaButton)} disabled={disabled} onClick={this.onSendClicked}>
					<span>Send</span>
				</button>
			</>
		);
	};

	private onSendClicked = () => {
		const { onTemplateSelected } = this.props;
		const { selectedTemplate } = this.state;
		onTemplateSelected?.(selectedTemplate);
	};

	private onNewEmailClicked = () => {
		const { onTemplateSelected } = this.props;
		if (onTemplateSelected) {
			onTemplateSelected();
		}
	};

	private onTemplateListRef = (ref?: ICampaignsByCategorySelectorComponent) => {
		this.mTemplateListRef = ref;
	};

	private onEditTemplateRef = (ref?: IEditEmailTemplateComponent) => {
		const { onInnerEditorRef } = this.props;
		this.mEditTemplateRef = ref;
		if (onInnerEditorRef) {
			onInnerEditorRef(ref);
		}
	};

	private onTemplateDeleted = async (template: ITemplate) => {
		const { userSession, logApiError, errorMessages } = this.props;
		// if shared and not created by you, you may hide it locally
		if (
			template.scope === TemplateScope.Industry ||
			(template?.creator?.id !== userSession?.user?.id && template.scope === TemplateScope.Account)
		) {
			hideDefaultMessageTemplate(template.id);
		}

		try {
			await this.mTemplateListRef.loadTemplates();
		} catch (error) {
			logApiError('TemplatesLoad-Error', error);

			errorMessages.pushApiError(error);
		}
	};

	private onCreateNewTemplate =
		(discardUnsavedEdits = false) =>
		() => {
			const { editingNewTemplate } = this.state;
			if (!editingNewTemplate) {
				if (!discardUnsavedEdits && this.mEditTemplateRef && this.mEditTemplateRef.hasUnsavedEdits()) {
					this.setState({
						onDiscardChanges: () => {
							this.onCreateNewTemplate(true)();
						},
						showDiscardChangesConfirmation: true,
					});
					return;
				}
				this.setState({
					editingNewTemplate: true,

					selectedTemplate: null,
				});
			}
		};

	private onConfirmationDialogRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		const { onDiscardChanges, showingCloseConfirmation } = this.state;
		this.setState(
			{
				showDiscardChangesConfirmation: false,
				showingCloseConfirmation: false,
			},
			() => {
				if (result && result.isDestructive && !canceled) {
					if (showingCloseConfirmation) {
						this.cancel();
					} else {
						if (onDiscardChanges) {
							this.setState(
								{
									onDiscardChanges: null,
								},
								() => {
									onDiscardChanges();
								}
							);
						}
					}
				}
			}
		);
	};

	private onCloseClicked = (e?: React.MouseEvent<HTMLElement>) => {
		e?.preventDefault();

		if (this.mEditTemplateRef && this.mEditTemplateRef.hasUnsavedEdits()) {
			this.setState({
				showingCloseConfirmation: true,
			});
			return;
		}

		this.cancel();
	};

	private cancel = () => {
		const { parentModal } = this.props;
		if (parentModal) {
			parentModal.onRequestClose(null, true);
		}
	};

	private onTemplateSelected =
		(discardUnsavedEdits = false) =>
		(template: ITemplate) => {
			const { selectedTemplate } = this.state;

			if (!discardUnsavedEdits && this.mEditTemplateRef && this.mEditTemplateRef.hasUnsavedEdits()) {
				this.setState({
					onDiscardChanges: () => {
						this.onTemplateSelected(true)(template);
					},
					showDiscardChangesConfirmation: true,
				});
				return;
			}
			if (template !== selectedTemplate) {
				this.setState({
					editingNewTemplate: false,
					selectedTemplate: template,
				});
			}
		};

	private onTemplateSaved = async (template: ITemplate) => {
		await this.mTemplateListRef.loadTemplates();
		this.onTemplateSelected(true)(template);
	};
}

const ManageEmailTemplatesAsObserver = observer(_ManageEmailTemplates);
const ManageEmailTemplatesWithContext = inject(
	UserSessionViewModelKey,
	ModalChildComponentContextKey,
	FabViewModelKey,
	FullScreenModalViewModelKey,
	ErrorMessagesViewModelKey,
	ImpersonationContextKey
)(ManageEmailTemplatesAsObserver);
export const ManageEmailTemplates = withEventLogging(ManageEmailTemplatesWithContext, 'ManageEmailTemplates');
