import * as Api from '@ViewModels';
import { Moment } from 'moment';
import * as React from 'react';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { useLocalNotificationService } from '../../../../models/LocalNotifications';
import {
	convertHtmlStringValueToPlainText,
	createContentStateWithHtmlStringValue,
	getShortenedString,
} from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';

export interface IEditSocialMediaPostContext {
	aiReference?: Api.IAIReference;
	/** Internally set to true any time content or images change */
	hasChanges?: boolean;
	hasVideo?: boolean;
	postContent?: string;
	postImages?: Api.IFileAttachment[];
	postTargets?: Api.IPostTarget[];
	campaignName?: string;
	schedulePost?(
		selectedMoment?: Moment,
		suggestionId?: string,
		impersonationContext?: Api.IImpersonationContext,
		bypassCompliance?: boolean,
		sendWithComplianceEmail?: string
	): Promise<readonly [Api.SocialMediaPostViewModel, Api.IOperationResultNoValue]>;
	selectedCreator?: Api.IUser;
	setAIReference?(aiReference: Api.IAIReference | ((currentValue: Api.IAIReference) => Api.IAIReference)): void;
	setContent?(postContent: string | ((currentValue: string) => string)): void;
	setCampaignName?(campaignName: string | ((currentValue: string) => string)): void;
	setHasChanges?(hasChanges: boolean | ((currentValue: boolean) => boolean)): void;
	setImages?(
		postImages?: Api.IFileAttachment[] | ((currentValue: Api.IFileAttachment[]) => Api.IFileAttachment[])
	): void;
	setPostTargets?(postTargets?: Api.IPostTarget[] | ((currentValue: Api.IPostTarget[]) => Api.IPostTarget[])): void;
	setSelectedCreator?(selectedCreator: Api.IUser | ((currentValue: Api.IUser) => Api.IUser)): void;
	setTemplate?(template?: Api.ITemplate | ((currentValue: Api.ITemplate) => Api.ITemplate)): void;
	template?: Api.ITemplate;
	updatePost?(
		// targets: Api.IPostTarget[],
		postOrId: string | Api.SocialMediaPostViewModel,
		selectedMoment?: Moment,
		bypassCompliance?: boolean
	): Promise<readonly [Api.IOperationResult<Api.ISocialMediaPost>, Api.IOperationResultNoValue]>;
	getPostBase?: () => Api.ISocialMediaPostBase;
	overlayLogo?: boolean;
	setOverlayLogo?(overlayLogo: boolean | ((currentValue: boolean) => boolean)): void;
	hideWhiteBackground?: boolean;
	setHideWhiteBackground?(hideWhiteBackground: boolean | ((currentValue: boolean) => boolean)): void;
}

export const EditSocialMediaPostContext = React.createContext<IEditSocialMediaPostContext>(null);

export const useEditSocialMediaPostContext = () => {
	const context = React.useContext(EditSocialMediaPostContext);
	if (!context) {
		throw new Error(`This component requires the use of "EditSocialMediaPostContext"`);
	}
	return context;
};

export const EditSocialMediaPostContextProvider: React.FC<{
	contextRef?: React.MutableRefObject<IEditSocialMediaPostContext>;
}> = ({ children, contextRef }) => {
	const [context] = useCreateEditSocialMediaContext();
	if (contextRef) {
		contextRef.current = context;
	}

	return <EditSocialMediaPostContext.Provider value={context}>{children}</EditSocialMediaPostContext.Provider>;
};

export const useCreateEditSocialMediaContext = () => {
	const userSession = useUserSession();
	const setHasChangesRef = React.useRef<(hasChanges?: boolean) => void>(null);
	const setContentRef = React.useRef<(content?: string) => void>(null);
	const setImagesRef = React.useRef<(images?: Api.IFileAttachment[]) => void>(null);
	const setPostTargetsRef = React.useRef<(targets?: Api.IPostTarget[]) => void>(null);
	const setSelectedCreatorRef = React.useRef<(selectedCreator?: Api.IUser) => void>(null);
	const setTemplateRef = React.useRef<(template?: Api.ITemplate) => void>(null);
	const { postNotification } = useLocalNotificationService<Api.SocialMediaPostViewModel>();
	const setAIReferenceRef = React.useRef<(aiReference?: Api.IAIReference) => void>(null);
	const setCampaignNameRef = React.useRef<(campaignName?: string) => void>(null);
	const setOverlayLogoRef = React.useRef<(overlayLogo?: boolean) => void>(null);
	const setHideWhiteBackgroundRef = React.useRef<(hideWhiteBackground?: boolean) => void>(null);

	const schedulePostRef =
		React.useRef<
			(
				selectedMoment?: Moment,
				suggestionId?: string,
				impersonationContext?: Api.IImpersonationContext,
				bypassCompliance?: boolean,
				sendWithComplianceEmail?: string
			) => Promise<readonly [Api.SocialMediaPostViewModel, Api.IOperationResultNoValue]>
		>(null);
	const updatePostRef =
		React.useRef<
			(
				postOrId: string | Api.SocialMediaPostViewModel,
				selectedMoment?: Moment,
				bypassCompliance?: boolean
			) => Promise<readonly [Api.IOperationResult<Api.ISocialMediaPost>, Api.IOperationResultNoValue]>
		>(null);
	const getPostBase = React.useRef(() => {
		const { aiReference, campaignName, template, postContent, postImages } = contextRef.current;
		const unwrappedContent = convertHtmlStringValueToPlainText(template?.content.document);
		const isCustomized = unwrappedContent !== postContent || template?.attachments !== postImages;
		const wrappedContent = createContentStateWithHtmlStringValue(postContent);
		const post: Api.ISocialMediaPostBase = {
			aiReference,
			attachments: postImages,
			content: {
				document: wrappedContent.getRawRichTextContent().document,
			},
			name:
				campaignName ||
				(template?.id ? template.name : postContent ? getShortenedString(postContent, 25, ' ') : 'Custom Post'),
			templateReference: template?.id
				? {
						isCustomized,
						isSystemTemplate: template.scope
							? !![Api.TemplateScope.Industry, Api.TemplateScope.System].find(x => x === template.scope)
							: false,
						name: template.name,
						templateId: template.id,
					}
				: null,
			designatedTargets: contextRef.current?.postTargets,
			overlayLogo: contextRef.current.overlayLogo,
		};
		return post;
	}).current;

	const contextRef = React.useRef<IEditSocialMediaPostContext>(null);
	const [context, setContext] = React.useState<IEditSocialMediaPostContext>(() => {
		const ctx: Partial<IEditSocialMediaPostContext> = {
			campaignName: '',
			hasChanges: false,
			hasVideo: false,
			postContent: '',
			postImages: [],
			postTargets: [],
			selectedCreator: null,
			template: null,
			overlayLogo: false,
		};
		ctx.setAIReference = aiReferenceProvider => {
			setAIReferenceRef.current?.(
				typeof aiReferenceProvider === 'function'
					? aiReferenceProvider(contextRef.current.aiReference)
					: aiReferenceProvider
			);
		};

		ctx.setHasChanges = booleanProvider => {
			setHasChangesRef.current?.(
				typeof booleanProvider === 'function' ? booleanProvider(contextRef.current.hasChanges) : booleanProvider
			);
		};

		ctx.setImages = imageProvider => {
			setImagesRef.current?.(
				typeof imageProvider === 'function' ? imageProvider(contextRef.current.postImages) : imageProvider
			);
		};

		ctx.setContent = contentProvider => {
			setContentRef.current?.(
				typeof contentProvider === 'function' ? contentProvider(contextRef.current.postContent) : contentProvider
			);
		};

		ctx.setSelectedCreator = creatorProvider => {
			setSelectedCreatorRef.current?.(
				typeof creatorProvider === 'function' ? creatorProvider(contextRef.current.selectedCreator) : creatorProvider
			);
		};

		ctx.setPostTargets = targetsProvider => {
			setPostTargetsRef.current?.(
				typeof targetsProvider === 'function' ? targetsProvider(contextRef.current.postTargets) : targetsProvider
			);
		};

		ctx.setTemplate = contentProvider => {
			setTemplateRef.current?.(
				typeof contentProvider === 'function' ? contentProvider(contextRef.current.template) : contentProvider
			);
		};

		ctx.setCampaignName = contentProvider => {
			setCampaignNameRef.current?.(
				typeof contentProvider === 'function' ? contentProvider(contextRef.current.campaignName) : contentProvider
			);
		};

		ctx.setOverlayLogo = overlayLogoProvider => {
			setOverlayLogoRef.current?.(
				typeof overlayLogoProvider === 'function'
					? overlayLogoProvider(contextRef.current.overlayLogo)
					: overlayLogoProvider
			);
		};
		ctx.setHideWhiteBackground = hideWhiteBackgroundProvider => {
			setHideWhiteBackgroundRef.current?.(
				typeof hideWhiteBackgroundProvider === 'function'
					? hideWhiteBackgroundProvider(contextRef.current.overlayLogo)
					: hideWhiteBackgroundProvider
			);
		};

		ctx.schedulePost = (
			selectedMoment,
			suggestionId,
			impersonationContext,
			bypassCompliance,
			sendWithComplianceEmail
		) =>
			schedulePostRef.current?.(
				selectedMoment,
				suggestionId,
				impersonationContext,
				bypassCompliance,
				sendWithComplianceEmail
			);

		ctx.getPostBase = getPostBase;
		ctx.updatePost = (postOrId, selectedMoment, bypassCompliance) =>
			updatePostRef.current?.(postOrId, selectedMoment, bypassCompliance);

		contextRef.current = ctx;
		return ctx;
	});
	React.useEffect(() => {
		contextRef.current = context;
	});

	if (!setHasChangesRef.current) {
		setHasChangesRef.current = (value?: boolean) => {
			setContext(currentContext => ({
				...currentContext,
				hasChanges: value,
			}));
		};
	}

	if (!setAIReferenceRef.current) {
		setAIReferenceRef.current = (aiReference?: Api.IAIReference) => {
			setContext(currentContext => ({
				...currentContext,
				aiReference,
				hasChanges: true,
			}));
		};
	}

	if (!setContentRef.current) {
		setContentRef.current = (content?: string) => {
			setContext(currentContext => ({
				...currentContext,
				hasChanges: true,
				postContent: content || '',
			}));
		};
	}

	if (!setContentRef.current) {
		setContentRef.current = (content?: string) => {
			setContext(currentContext => ({
				...currentContext,
				hasChanges: true,
				postContent: content || '',
			}));
		};
	}
	if (!setImagesRef.current) {
		setImagesRef.current = (images?: Api.IFileAttachment[]) => {
			setContext(currentContext => ({
				...currentContext,
				hasChanges: true,
				hasVideo: images?.some(x => x?.mimeType?.toLocaleLowerCase().startsWith('video/')),
				postImages: images || [],
			}));
		};
	}
	if (!setSelectedCreatorRef.current) {
		setSelectedCreatorRef.current = (creator?: Api.IUser) => {
			setContext(currentContext => ({
				...currentContext,
				hasChanges: false,
				selectedCreator: creator || null,
			}));
		};
	}
	if (!setTemplateRef.current) {
		setTemplateRef.current = (template?: Api.ITemplate) =>
			setContext(currentContext => ({
				...currentContext,
				template,
			}));
	}

	if (!setPostTargetsRef.current) {
		setPostTargetsRef.current = (targets?: Api.IPostTarget[]) => {
			setContext(currentContext => ({
				...currentContext,
				hasChanges: false,
				postTargets: targets || [],
			}));
		};
	}

	if (!setCampaignNameRef.current) {
		setCampaignNameRef.current = (name?: string) => {
			setContext(currentContext => ({
				...currentContext,
				campaignName: name,
				hasChanges: false,
			}));
		};
	}

	if (!setOverlayLogoRef.current) {
		setOverlayLogoRef.current = (overlayLogo?: boolean) => {
			setContext(currentContext => ({
				...currentContext,
				overlayLogo,
			}));
		};
	}
	if (!setHideWhiteBackgroundRef.current) {
		setHideWhiteBackgroundRef.current = (hideWhiteBackground?: boolean) => {
			setContext(currentContext => ({
				...currentContext,
				hideWhiteBackground,
			}));
		};
	}

	if (!schedulePostRef.current) {
		schedulePostRef.current = async (
			selectedMoment?: Moment,
			suggestionId?: string,
			impersonationContext?: Api.IImpersonationContext,
			bypasssCompliance?: boolean,
			sendWithComplianceEmail?: string
		) => {
			const basePost = getPostBase();
			const post: Api.ICreateSocialMediaPostRequest = {
				...basePost,
				dueDate: selectedMoment?.toISOString(),
				forUserId: contextRef.current?.selectedCreator?.id,
				sendWithCompliance:
					impersonationContext?.account?.preferences?.complianceSettings?.enabled ||
					userSession?.account?.preferences?.complianceSettings?.enabled
						? !bypasssCompliance
						: undefined,
				targets: undefined,
				sendWithComplianceEmail,
			};

			let error: Api.IOperationResultNoValue = null;
			let result: Api.SocialMediaPostViewModel = null;
			try {
				result = await Api.SocialMediaPostViewModel.schedulePost(userSession, post, suggestionId, impersonationContext);
				postNotification({
					info: result,
					topic: Topics.CREATE_SOCIAL_POST,
				});
			} catch (err) {
				error = err;
			}
			return [result, error] as const;
		};
	}

	if (!updatePostRef.current) {
		updatePostRef.current = async (
			postOrId: string | Api.SocialMediaPostViewModel,
			selectedMoment?: Moment,
			bypassCompliance?: boolean
		) => {
			const viewModel =
				typeof postOrId === 'string' ? new Api.SocialMediaPostViewModel(userSession, { id: postOrId }) : postOrId;
			const basePost = getPostBase();
			const updatedPost: Api.ISocialMediaPost = {
				...basePost,
				creator: viewModel.creator,
				dueDate: selectedMoment?.toISOString(),
				id: viewModel.id,
				name: contextRef.current.campaignName || viewModel.name || basePost.name,
				sendWithCompliance: viewModel.sendWithCompliance ? !bypassCompliance : null,
				status: viewModel.status,
				targets: [],
			};

			let error: Api.IOperationResultNoValue = null;
			let result: Api.IOperationResult<Api.ISocialMediaPost> = null;
			try {
				result = await viewModel.update(updatedPost);
				postNotification({
					info: viewModel,
					topic: Topics.EDIT_SOCIAL_POST,
				});
			} catch (err) {
				error = err;
			}
			return [result, error] as const;
		};
	}

	return [context, setContext] as const;
};
