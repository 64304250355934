import { IEventLoggingComponentProps } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { IImpersonationContextComponentProps } from '../../../../models';
import { IErrorMessageComponentProps, IUserSessionComponentProps } from '../../../../models/AppState';
import { ComposeFollowUpEmailViewModel, IEmailMessageComposeOptions } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { ISelectOption } from '../../DeprecatedSelect';
import { RadioButton } from '../../RadioButton';
import { EmailGuideTag } from '../../email/guide/EmailGuideTag';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IErrorMessageComponentProps,
		IUserSessionComponentProps,
		IImpersonationContextComponentProps,
		IEmailMessageComposeOptions {
	className?: string;
	emailComposer?: ComposeFollowUpEmailViewModel<Api.IEmailMessageFollowUpOptions>;
	isPriorFollowUp?: boolean;
	onPreviewClicked?(): void;
	styles?: StyleDeclarationValue[];
}

const AllOptions: ISelectOption<Api.EmailTransactionStatus[]>[] = [
	{
		dataContext: [
			Api.EmailTransactionStatus.Queued,
			Api.EmailTransactionStatus.Failed,
			Api.EmailTransactionStatus.Sent,
			Api.EmailTransactionStatus.Opened,
			Api.EmailTransactionStatus.Replied,
			Api.EmailTransactionStatus.Bounced,
			Api.EmailTransactionStatus.FailedNoEmail,
			Api.EmailTransactionStatus.MachineOpened,
		],
		id: 'All-recipeints',
		text: 'All recipients',
	},
	{
		dataContext: [
			Api.EmailTransactionStatus.Sent,
			Api.EmailTransactionStatus.Opened,
			Api.EmailTransactionStatus.Replied,
			Api.EmailTransactionStatus.MachineOpened,
		],
		id: 'All-received',
		text: 'All who received an email',
	},
	{
		dataContext: [Api.EmailTransactionStatus.Queued],
		id: 'Queued',
		text: 'Queued',
	},
	{
		dataContext: [Api.EmailTransactionStatus.Failed, Api.EmailTransactionStatus.FailedNoEmail],
		id: 'Failed',
		text: 'Failed',
	},
	{
		dataContext: [Api.EmailTransactionStatus.Replied],
		id: 'All-replied',
		text: 'All who replied',
	},
	{
		dataContext: [
			Api.EmailTransactionStatus.Opened,
			Api.EmailTransactionStatus.MachineOpened,
			Api.EmailTransactionStatus.Sent,
		],
		id: 'All-no-reply',
		text: 'All who did not reply (viewed or not)',
	},
	{
		dataContext: [Api.EmailTransactionStatus.Clicked],
		id: 'All-clicked',
		text: 'All who clicked',
	},
	{
		dataContext: [Api.EmailTransactionStatus.NotClicked],
		id: 'All-no-click',
		text: 'All who did not click (viewed or not)',
	},
	{
		dataContext: [Api.EmailTransactionStatus.Opened, Api.EmailTransactionStatus.MachineOpened],
		id: 'Viewed-no-reply',
		text: 'Who viewed but did not reply',
	},
	{
		dataContext: [Api.EmailTransactionStatus.Sent],
		id: 'No-view',
		text: 'Who did not view',
	},
];

const getFollowUpCountforId = (emailComposer: ComposeFollowUpEmailViewModel, id: string) => {
	let followUpCount = 0;
	if (emailComposer.followUpSource) {
		switch (id) {
			case 'All-recipeints': {
				followUpCount = emailComposer.followUpSource.totalCount;
				break;
			}
			case 'All-received': {
				followUpCount = emailComposer.followUpSource.sentCount;
				break;
			}
			case 'Queued': {
				followUpCount = emailComposer.followUpSource.queuedCount;
				break;
			}
			case 'Failed': {
				followUpCount = emailComposer.followUpSource.failedCount + emailComposer.followUpSource.noEmailAddressCount;
				break;
			}
			case 'All-replied': {
				followUpCount = emailComposer.followUpSource.repliedCount;
				break;
			}
			case 'All-no-reply': {
				followUpCount = emailComposer.followUpSource.sentCount - emailComposer.followUpSource.repliedCount;
				break;
			}
			case `All-clicked`: {
				followUpCount = emailComposer.followUpSource.distinctClickCount;
				break;
			}
			case `All-no-click`: {
				if (emailComposer.followUpSource.hasClickTargets) {
					followUpCount =
						emailComposer.followUpSource.sentCount - (emailComposer.followUpSource.distinctClickCount || 0);
				}
				break;
			}
			case 'Viewed-no-reply': {
				followUpCount = emailComposer.followUpSource.openCount - emailComposer.followUpSource.repliedCount;
				break;
			}
			case 'No-view': {
				followUpCount = emailComposer.followUpSource.sentCount - emailComposer.followUpSource.openCount;
				break;
			}
			default: {
				break;
			}
		}
	}
	return followUpCount;
};

/**
 * Utility method for converting EmailTransactionStatus[] to a string
 *
 * @param statusList List of transactions statuses
 * @returns A single string, for example: [Api.EmailTransactionStatus.Opened, Api.EmailTransactionStatus.Sent] ->
 *   'Opened-Sent'
 */
const statusListToKey = (followUp: Api.IEmailMessageFollowUpOptions) => {
	const keyFromStatusList = followUp?.statuses?.reduce(
		(value, x, i) => `${value}${i > 0 ? '-' : ''}${x.replace(' ', '')}`,
		''
	);
	const keyFromClickStatus = followUp?.clickStatus?.replace(' ', '');
	return keyFromStatusList || keyFromClickStatus || '';
};

export const FollowUpTagsList = observer((props: IProps) => {
	const { className, styles, emailComposer, isPriorFollowUp, onPreviewClicked } = props;
	const selectedOptionKey = statusListToKey(emailComposer?.emailMessage?.options?.followUp);
	const match = useRouteMatch();
	const history = useHistory();

	const onShowRecipients = () => {
		onPreviewClicked();
		history.push(match.url.replace('/follow-up', ''));
		return;
	};

	const onOptionClicked = React.useCallback(
		(option: ISelectOption<Api.EmailTransactionStatus[]>) => {
			return () => {
				if (emailComposer) {
					const followUpCount = getFollowUpCountforId(emailComposer, option.id);
					let clickStatus;
					if (option.dataContext.includes(Api.EmailTransactionStatus.Clicked)) {
						clickStatus = Api.ClickStatus.Clicked;
					}

					if (option.dataContext.includes(Api.EmailTransactionStatus.NotClicked)) {
						clickStatus = Api.ClickStatus.NotClicked;
					}

					// Remove the Clicked and NotClicked statuses from the statuses array as they are not valid statuses in the Enum on the backend.
					const filteredStatuses = option.dataContext.filter(
						x => x !== Api.EmailTransactionStatus.Clicked && x !== Api.EmailTransactionStatus.NotClicked
					);

					const options: Api.IEmailMessageFollowUpOptions = {
						...(emailComposer.emailMessage.options.followUp || {}),
						_type: 'EmailFollowUpOptions',
						excludeContactIds: emailComposer.emailMessage.contactsToOmit.map(x => x.id) || [],
						statuses: filteredStatuses,
						clickStatus,
					};
					emailComposer.emailMessage.options.followUp = options;
					emailComposer.followUpCount = followUpCount;
				}
			};
		},
		[emailComposer]
	);

	return (
		<>
			{!isPriorFollowUp && (
				<>
					<ul className={`${css(styleSheet.container, ...(styles || []))} follow-up-tags-list ${className || ''}`}>
						{AllOptions.map((x, i) => {
							const followUp = {
								statuses: x.dataContext,
							} as Api.IEmailMessageFollowUpOptions;
							const optionKey = statusListToKey(followUp);
							const selected = !!selectedOptionKey && optionKey === selectedOptionKey;

							const followUpCount = getFollowUpCountforId(emailComposer, x.id);

							return (
								<div key={`${x}-${i}`}>
									<li key={`${x}-${i}`} className={css(styleSheet.tagRow)}>
										<div className={css(styleSheet.followUpContainer)}>
											<RadioButton
												className={css(styleSheet.tag)}
												checked={selected}
												disabled={followUpCount <= 0}
												id={`filter-${optionKey}`}
												name={`filter-${optionKey}`}
												onClick={onOptionClicked(x)}
												readOnly={true}
											/>
											<EmailGuideTag
												className={css(
													styleSheet.tag,
													styleSheet.tagContainer,
													followUpCount <= 0 && baseStyleSheet.disabled
												)}
												isFollowUp={true}
												isSelected={selected}
												onClick={followUpCount > 0 ? onOptionClicked(x) : null}
												followUpCount={followUpCount}
												value={x.text}
											/>
										</div>
									</li>
								</div>
							);
						})}
					</ul>

					<div className={css(styleSheet.recipientsFooter)}>
						<button className={css(baseStyleSheet.ctaButton)} onClick={onShowRecipients}>
							<span>Next: Preview Recipients</span>
						</button>
					</div>
				</>
			)}
		</>
	);
});
