import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { useEmailComposerContext } from '../../../../../models/Email';
import { useGetSurveyById } from '../../../../../queries';
import { ComposeEventRegistrationSurveyFollowUpEmailViewModel } from '../../../../../viewmodels/AppViewModels';
import { grayIconFill } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { PopoverType, TinyPopover } from '../../../TinyPopover';
import { InfoIcon } from '../../../svgs/icons/InfoIcon';
import {
	FollowUpRecipientDiscriminator,
	IFollowUpRecipientDiscriminatorOption,
} from '../FollowUpRecipientDiscriminator';
import { styleSheet } from './styles';

interface IProps {
	styles?: StyleDeclarationValue[];
}

export function EventRegistrationSurveyFollowUpOptions({ styles }: IProps) {
	const { emailComposer } = useEmailComposerContext<
		Api.ISurveyFollowUpOptions,
		Api.EventSurveyReportViewModel,
		ComposeEventRegistrationSurveyFollowUpEmailViewModel
	>();

	const surveyId = emailComposer.followUpSource.survey.id;
	const { data, isLoading } = useGetSurveyById<Api.IEventRegistrationSurvey>({
		surveyId,
	});

	const options = React.useMemo(() => {
		if (data?.stats) {
			const stats = data?.stats.responseStats.find(
				x => x.category === Api.EventRegistrationSurveyResponseStatCategory.KnownContacts
			);

			return [
				{
					count: stats.responses,
					id: 'all',
					representedObject: null,
					text: 'All recipients',
				},
				{
					count: stats.attending || 0,
					disabled: !stats.attending,
					id: `attending-${stats.attending || 0}`,
					representedObject: Api.EventRegistrationResponseStatus.Attending,
					text: 'Attending',
				},
				{
					count: stats.notAttending || 0,
					disabled: !stats.notAttending,
					id: `notAttending-${stats.notAttending || 0}`,
					representedObject: Api.EventRegistrationResponseStatus.NotAttending,
					text: 'Not attending',
				},
				{
					count: stats.noResponse || 0,
					disabled: !stats.noResponse,
					id: `noResponse-${stats.noResponse || 0}`,
					representedObject: Api.EventRegistrationResponseStatus.NoResponse,
					text: 'No response',
				},
			];
		}
		return [];
	}, [data?.stats]);

	const [selectedOptions, setSelectedOptions] = React.useState<
		IFollowUpRecipientDiscriminatorOption<Api.EventRegistrationResponseStatus>[]
	>([]);

	const onSelectedOptionsChanged = React.useCallback(
		(o: IFollowUpRecipientDiscriminatorOption<Api.EventRegistrationResponseStatus>[]) => {
			const selected = selectedOptions || [];
			let nextSelected = o;
			const indexOfSelectAll = nextSelected.findIndex(x => x.id === 'all');
			if (indexOfSelectAll >= 0) {
				if (!selected.find(x => x.id === 'all')) {
					nextSelected = options.filter(x => !x.disabled);
				} else {
					nextSelected.splice(indexOfSelectAll, 1);
				}
			} else {
				if (selected.find(x => x.id === 'all')) {
					nextSelected = [];
				} else if (nextSelected.length === options.filter(x => !x.disabled).length - 1) {
					nextSelected = options.filter(x => !x.disabled);
				}
			}

			if (emailComposer.emailMessage.options.followUp) {
				emailComposer.emailMessage.options.followUp.surveyResponseFilter = {
					...(emailComposer.emailMessage.options.followUp.surveyResponseFilter || {}),

					surveyId,
					status: nextSelected.filter(x => x.id !== 'all').map(x => x.representedObject),
					_type: 'EventRegistrationSurveyResponseFilterRequest',
				};
			}
			setSelectedOptions(nextSelected);
		},

		[emailComposer.emailMessage.options, options, selectedOptions, surveyId]
	);

	return (
		<div className={`${css(styleSheet.container, ...styles)}`}>
			<h4 className={css(styleSheet.header)}>
				Select Recipients to Follow-Up
				<TinyPopover
					anchor={
						<figure className={css(baseStyleSheet.flex, baseStyleSheet.itemsCenter)}>
							<InfoIcon fillColor={grayIconFill} />
						</figure>
					}
					anchorStyles={[styleSheet.infoPopoverAnchor]}
					dismissOnOutsideAction={true}
					toggleOnHover={true}
					autoCloseOtherPopoversOnHover={true}
					type={PopoverType.background}
					placement={['right', 'top', 'bottom']}
				>
					<p className={css(styleSheet.infoPopoverContent)}>
						Recipient count is for known contacts not including guests.
					</p>
				</TinyPopover>
			</h4>
			<div className={css(styleSheet.content)}>
				{isLoading ? (
					<LoadingSpinner type='small' />
				) : (
					<FollowUpRecipientDiscriminator
						multiSelect={true}
						onSelectedOptionsChanged={onSelectedOptionsChanged}
						options={options}
						selectedOptions={selectedOptions}
					/>
				)}
			</div>
		</div>
	);
}
