import { css, StyleDeclarationValue } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import { accountHasComplianceRequired, isValidEmail } from '../../../../models/UiUtils';
import { useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import { baseStyleSheet } from '../../../styles/styles';
import { Button } from '../../Button';
import { TextInput } from '../../TextInput';
import { lastUsedComplianceRecipientEmail } from '../../campaigns/CampaignDetailsSummary';
import { ComplianceIcon } from '../../svgs/icons/ComplianceIcon';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps {
	campaignType?: CampaignType;
	disabled?: boolean;
	isLoading?: boolean;
	onScheduleCompliance(e: React.MouseEvent<HTMLElement>, sendWithComplianceEmail: string): void;
	onScheduleWithoutCompliance(e: React.MouseEvent<HTMLElement>): void;
	styles?: StyleDeclarationValue[];
}

export const ComplianceApprovalPrompt: React.FC<IProps> = inject(ImpersonationContextKey)(
	observer(
		({
			campaignType,
			disabled,
			impersonationContext,
			isLoading,
			onScheduleCompliance,
			onScheduleWithoutCompliance,
			styles = [],
		}) => {
			const userSession = useUserSession();
			const toaster = useToaster();

			const isImpersonating = impersonationContext?.isValid ?? false;
			const accountId = isImpersonating ? impersonationContext.account : userSession.account;
			const complianceRequired = accountHasComplianceRequired(userSession, impersonationContext);
			const [sendWithComplianceEmail, setSendWithComplianceEmail] = React.useState<string>(() => {
				return (
					localStorage.getItem(
						!isImpersonating ? lastUsedComplianceRecipientEmail : `${accountId}-${lastUsedComplianceRecipientEmail}`
					) ?? ''
				);
			});

			let campaignDisplayName = 'campaign';
			let campaignCtaName = 'Campaign';
			switch (campaignType) {
				case CampaignType.Blog: {
					campaignDisplayName = 'blog post';
					campaignCtaName = 'Post';
					break;
				}
				case CampaignType.Social: {
					campaignDisplayName = 'social media post';
					campaignCtaName = 'Post';
					break;
				}
				default: {
					break;
				}
			}

			const checkComplianceEmail = () => {
				if (sendWithComplianceEmail.length > 0 && !isValidEmail(sendWithComplianceEmail)) {
					toaster.push({
						message: `Please enter a valid email address`,
						type: 'errorMessage',
					});
					return false;
				}
				return true;
			};

			return (
				<div className={`${css(styleSheet.container, ...styles)} compliance-approval-prompt`}>
					<div className={css(styleSheet.iconContainer)}>
						<ComplianceIcon className={css(styleSheet.icon)} />
					</div>
					<div>Do you need approval from compliance officer?</div>
					<div className={css(styleSheet.detailMessage)}>
						{`We will email you a copy of the ${campaignDisplayName} and you can work with your compliance officer to get approved. In the meantime, you can schedule this ${campaignDisplayName}, and it'll be held in a pending state until you come back and mark it as approved.`}
					</div>
					<div className={css(baseStyleSheet.verticalStack, styleSheet.stack)}>
						<TextInput
							inputId='user-emailAddress'
							placeholder='Please enter Compliance Email'
							type='text'
							onBlur={() => {
								if (!checkComplianceEmail()) {
									return;
								}
								localStorage.setItem(lastUsedComplianceRecipientEmail, sendWithComplianceEmail);
							}}
							onChange={ev => {
								setSendWithComplianceEmail(ev.target.value);
							}}
							className={css(styleSheet.complianceInput)}
							value={sendWithComplianceEmail}
						/>
						<Button
							disabled={disabled || isLoading}
							className={css(styleSheet.stack)}
							onClick={(e: React.MouseEvent<HTMLElement>) => {
								if (!checkComplianceEmail()) {
									return;
								}
								onScheduleCompliance(e, sendWithComplianceEmail);
							}}
							label={`Schedule ${campaignCtaName} with Compliance Pending`}
							isLoading={isLoading}
						/>

						{!isImpersonating ? (
							// Only show this button if we are not impersonating and it is not required.
							!complianceRequired ? (
								<Button
									disabled={disabled || isLoading}
									kind='custom'
									className={css(baseStyleSheet.ctaButtonReverse, styleSheet.stack)}
									onClick={onScheduleWithoutCompliance}
									isLoading={isLoading}
									label={`Send ${campaignCtaName} without Compliance`}
								/>
							) : null
						) : null}
					</div>
				</div>
			);
		}
	)
);
